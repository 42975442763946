import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { Tabs, TabNav, TabNavItem, TabContent, TabPanel } from 'react-smarttab'

// Components
import Details from "../common/details";

import 'react-smarttab/dist/index.css'

class Page extends Component {
    
    render () {
        return (
            <form className="well form-horizontal" action=" " method="post"  id="contact_form">
                <Tabs
                    selected="0"
                    orientation={'horizontal'}
                    theme={'github'}
                >
                    <TabNav>
                        <TabNavItem>Detalhes</TabNavItem>
                    </TabNav>

                    <TabContent>
                        <TabPanel>
                            <Details />
                        </TabPanel>
                    </TabContent>
                </Tabs>
            </form>
        );
    }
}

export default withCookies(Page);