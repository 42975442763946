import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './TabPanel'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function HorizontalTabs({ tabs, tabIndex }) {
  const [value, setValue] = React.useState(tabIndex && Object.keys(tabs).includes(tabIndex) ? tabIndex : Object.keys(tabs)[0]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="rootTab">
      <AppBar position="static" color="transparent">
        <Tabs variant="scrollable" value={value} onChange={handleChange} centered>
          {Object.keys(tabs).map((tab, index) => (
              <Tab value={tab} label={tabs[tab].label} {...a11yProps(index)} />
          ))}
        </Tabs>
      </AppBar>
        {Object.keys(tabs).map((tab, index) => (
            <TabPanel value={value} index={tab}>
                {tabs[tab].page}
            </TabPanel>
        ))}
    </div>
  );
}
