import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import axios from "axios";

// Components
import Menu from "@components/Menu";

/* Import Components */
import Input from "@components/Input";
import TextArea from "@components/TextArea";
import Button from "@components/Button";
import Checkbox from "@components/CheckBox";
import AutoComplete from "@components/AutoComplete";

import UserContext from "@components/context/userContext";
class Form extends Component {
  static contextType = UserContext;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    const {
      match: { params },
      type,
    } = this.props;

    this.state = {
      loaded: false,
      name: "",
      email: "",
      nif: "",
      phone: "",
      address: "",
      birthday: "",
      idagregado: "",
      kinship: "",
      number_ss: "",
      numero_utente: "",
      kinships: [],
      gdpr: false,
      idhousehold: type === "edit" && params.idhousehold,
      idcustomer: params.idcustomer,
      edit: type === "edit",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.loadFunction = this.loadFunction.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  loadFunction() {
    const profile = this.context;

    if (!this.state.loaded) {
      axios
        .get(`https://eposgestofinal.pt/api/kinship/get.php`)
        .then((response) => {
          const kinships = response.data !== "NOK" ? response.data : [];
          this.setState(
            {
              kinships: [
                {
                  label: "Não definido",
                  value: undefined,
                },
                ...kinships.map((kinship) => ({
                  ...kinship,
                  label: kinship.name,
                  value: kinship.idkinship,
                })),
              ],
              loaded: true,
            },
            () => {
              if (this.props.type === "edit") {
                axios
                  .get(
                    `https://eposgestofinal.pt/api/customers/household/getById.php?idempresa=${profile.idEmpresa}&idcustomer=${this.state.idcustomer}&idhousehold=${this.state.idhousehold}`
                  )
                  .then((response) => {
                    const household =
                      response.data !== "NOK" ? response.data : {};

                    this.setState({
                      ...household,
                      gdpr: household.gdpr === "t",
                      kinship: this.state.kinships.find((kinship) => kinship.value == household.kinship) || {
                        label: "Não definido",
                        value: undefined,
                      },
                      loaded: true,
                    });
                  })
                  .catch((error) => {
                    console.error(error);
                  });

                this.setState({ loaded: true });
              } else {
                this.setState({ loaded: true });
              }
            }
          );
        });
    }
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({
      [name]: value,
    });
  }

  handleCheckboxChange(e, callback) {
    let value = e.target.checked;
    let name = e.target.name;

    this.setState({
      [name]: value,
    });
  }

  handleFormSubmit(e) {
    const profile = this.context;
    const { history } = this.props;
    e.preventDefault();

    axios
      .post("https://eposgestofinal.pt/api/customers/household/set.php", {
        name: this.state.name,
        nif: this.state.nif,
        email: this.state.email,
        phone: this.state.phone,
        address: this.state.address,
        birthday: this.state.birthday,
        gdpr: `${this.state.gdpr}`,
        idempresa: profile.idEmpresa,
        idcustomer: this.state.idcustomer,
        idhousehold: this.state.idhousehold,
        idagregado: this.state.idagregado,
        number_ss: this.state.number_ss,
        numero_utente: this.state.numero_utente,
        kinship: this.state.kinship.value,
      })
      .then((response) => {
        if (
          this.props.location &&
          this.props.location.query &&
          this.props.location.query.closeAfter
        ) {
          window.close();
        } else {
          history.push(`/customers/${this.state.idcustomer}`);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleFormCancel() {
    const { history } = this.props;
    if (
      this.props.location &&
      this.props.location.query &&
      this.props.location.query.closeAfter
    ) {
      window.close();
    } else {
      history.push(`/customers/${this.state.idcustomer}`);
    }
  }

  render() {
    const { location, history } = this.props;
    const {
      edit,
      idcustomer,
      loaded,
      name,
      email,
      phone,
      nif,
      address,
      birthday,
      gdpr,
      kinship,
      idagregado,
      number_ss,
      numero_utente,
      kinships,
    } = this.state;
    const cookies = new URLSearchParams(location.search);
    const profile = this.context;

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li>
                <a href="/customers">Clientes</a>
              </li>
              <li>
                <a href={`/customers/${idcustomer}`}>{idcustomer}</a>
              </li>
              <li className="active">
                {edit ? (
                  <span>Membro do agregado existente</span>
                ) : (
                  <span>Novo membro do agregado</span>
                )}
              </li>
            </ol>
          </div>
        </div>

        {loaded && (
          <div className="fullContainer" style={{ maxWidth: "100%" }}>
            <div className="col-sm-12" style={{ textAlign: "center" }}>
              <div className="panel panel-default">
                <div
                  className="panel-heading"
                  style={{
                    fontWeight: "bold",
                    fontSize: "15pt",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    Detalhes
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "left",
                    width: "100%",
                  }}
                  className="container-fluid"
                >
                  <form
                    class="well form-horizontal"
                    action=" "
                    method="post"
                    id="contact_form"
                  >
                    <div class="st st-theme-github st-horizontal">
                      <fieldset>
                        <div className="form-group">
                          <label className="col-md-1 control-label">Nome</label>
                          <div className="col-md-11 inputGroupContainer">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="fas fa-user"></i>
                              </span>
                              <Input
                                inputType={"text"}
                                name={"name"}
                                value={name}
                                handleChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="col-md-1 control-label">NIF</label>
                          <div className="col-md-11 inputGroupContainer">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="fas fa-id-card"></i>
                              </span>
                              <Input
                                inputType={"text"}
                                name={"nif"}
                                value={nif}
                                handleChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="col-md-1 control-label">
                            E-Mail
                          </label>
                          <div className="col-md-11 inputGroupContainer">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="fas fa-envelope"></i>
                              </span>
                              <Input
                                inputType={"text"}
                                name={"email"}
                                value={email}
                                handleChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="col-md-1 control-label">
                            Telefone
                          </label>
                          <div className="col-md-11 inputGroupContainer">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="fas fa-phone"></i>
                              </span>
                              <Input
                                inputType={"text"}
                                name={"phone"}
                                value={phone}
                                handleChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="col-md-1 control-label">
                            Morada
                          </label>
                          <div className="col-md-11 inputGroupContainer">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="fas fa-home"></i>
                              </span>
                              <TextArea
                                rows={5}
                                value={address}
                                name={"address"}
                                handleChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="col-md-1 control-label">
                            ID Agregado
                          </label>
                          <div className="col-md-11 inputGroupContainer">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="fas fa-handshake"></i>
                              </span>
                              <Input
                                inputType={"text"}
                                name={"idagregado"}
                                value={idagregado}
                                handleChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="col-md-1 control-label">
                            Parentesco:
                          </label>
                          <div className="col-md-11 inputGroupContainer">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="fas fa-heartbeat"></i>
                              </span>
                              <AutoComplete
                                name={"kinship"}
                                value={kinship}
                                handleChange={this.handleChange}
                                options={kinships}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="col-md-1 control-label">
                            Número SS:
                          </label>
                          <div className="col-md-11 inputGroupContainer">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="fas fa-id-card"></i>
                              </span>
                              <Input
                                inputType={"text"}
                                name={"number_ss"}
                                value={number_ss}
                                handleChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="col-md-1 control-label">
                            Número Utente SNS:
                          </label>
                          <div className="col-md-11 inputGroupContainer">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="fas fa-id-card"></i>
                              </span>
                              <Input
                                inputType={"text"}
                                name={"numero_utente"}
                                value={numero_utente}
                                handleChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="col-md-1 control-label">RGPD</label>
                          <div className="col-md-11">
                            <Checkbox
                              name={"gdpr"}
                              checked={gdpr}
                              handleChange={this.handleCheckboxChange}
                            />
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </form>
                  {/* About you */}
                  <Button
                    action={this.handleFormSubmit}
                    type={"primary"}
                    title={"Submeter"}
                    style={buttonStyle}
                  />
                  {/*Submit */}
                  <Button
                    action={this.handleFormCancel}
                    type={"secondary"}
                    title={"cancelar"}
                    style={buttonStyle}
                  />
                  {/* Clear the form */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px",
};

export default withCookies(Form);
