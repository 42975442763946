import React, { Component } from "react";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import axios from 'axios';

// Components
import Menu from '@components/Menu'
import { confirmAlert } from 'react-confirm-alert';

/* Import Components */
import Button from "@components/Button";

import { TimestampToDate } from '@utils/date'
import { threeDecimal, twoDecimal } from '@utils/format'
import { totals } from './utils/totals'

import UserContext from "@components/context/userContext";

const payments = [{
    value: 1,
    label: 'Dinheiro',
},{
    value: 2,
    label: 'Crédito',
},{
    value: 3,
    label: 'Cheque',
},{
    value: 4,
    label: 'TPA',
},{
    value: 8,
    label: 'Frota',
},{
    value: 14,
    label: 'Serviços Internos',
}];

const translatePayments = (payment) => (payments.find((eachPayment) => eachPayment.value === parseInt(payment, 10)) || {}).label;

class Form extends Component {
    static contextType = UserContext;

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

  constructor(props) {
    super(props);

    const { match: { params } } = this.props;

    this.state = {
        loaded: false,
        valid: true,
        hideDelete: false,
        date: '',
        doc: '',
        doctype: params.doctype,
        provider_name: '',
        lines: [],
        related: [],
        idempresa: this.props.cookies.get('member_idempresa'),
        idsale: params.idsale,
        idproduct: params.idproduct,
        talao: params.talao,
        posto: params.posto,
        serie: params.serie,
        tpv: params.tpv,
        doctypes: [{
            value: 'sales',
            label: 'Talões',
        },{
            value: 'receipts',
            label: 'Recibos',
        },{
            value: 'invoices',
            label: 'Faturas',
        },{
            value: 'earnings',
            label: 'Retificação de contagens (entradas)',
        },{
            value: 'losses',
            label: 'Retificação de contagens (saídas)',
        }]
    };
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.loadFunction = this.loadFunction.bind(this);
    this.deleteFunction = this.deleteFunction.bind(this);
  }

  loadFunction() {
    if (!this.state.loaded) {
        const { match: { params } } = this.props;
        if (params.talao && params.posto && params.serie && params.tpv) {
            axios.get(`https://eposgestofinal.pt/api/sales/invoices/getById.php?idempresa=${this.props.cookies.get('member_idempresa')}&talao=${params.talao}&posto=${params.posto}&serie=${params.serie}&tpv=${params.tpv}`)
            .then(response => {
                const sale = response.data !== 'NOK' ? response.data[0] : [];

                this.setState({ ...this.state, ...sale, date: TimestampToDate(sale.date_time), loaded: true });
            })
            .catch(error => {
                console.error(error);
            });
        } else {
            axios.get(`https://eposgestofinal.pt/api/sales/getById.php?idempresa=${this.props.cookies.get('member_idempresa')}&idsale=${this.state.idsale}&doctype=${this.state.doctype}`)
            .then(response => {
                const sale = response.data !== 'NOK' ? response.data[0] : [];

                this.setState({ ...this.state, ...sale, date: TimestampToDate(sale.date_time), hideDelete: sale.doctype === 'invoices', loaded: true });
            })
            .catch(error => {
                console.error(error);
            });
        }
    }
  }

  async deleteFunction() {
    confirmAlert({
        title: 'Confirmação',
        message: `Tem a certeza que deseja apagar a venda ${this.state.doc}?`,
        buttons: [
            {
                label: 'Sim',
                onClick: () => {
                    axios.get(`https://eposgestofinal.pt/api/sales/unset.php?idempresa=${this.props.cookies.get('member_idempresa')}&idsale=${this.state.idsale}`)
                    .then(response => {
                        if (response.data !== 'NOK') {
                            this.handleFormCancel();
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
                }
            },
            {
                label: 'Não',
                onClick: () => {}
            }
        ]
    });
  }

  handleFormCancel() {
    const { history } = this.props;
    history.push('/sales');
  }

  handleFormSubmit(e) {
    const profile = this.context;
    const { history } = this.props;
    e.preventDefault();
    
    axios.post('https://eposgestofinal.pt/api/sales/set.php', {
        doc: new Date().getTime(),
        doctype: 'invoices',
        date_time: Math.floor(Date.now() / 1000),
        idprovider: -1,
        idcustomer: 0,
        payment: 1,
        idsalesource: this.state.idsale,
        lines: this.state.lines.map((line) => ({
            ...line,
            idproduct: line.idproduct,
            idposto: line.idposto,
            idpostosource: undefined,
            price: Number(line.price),
            desc: Number(line.desc),
            desc1: Number(line.desc1),
            desc2: Number(line.desc2),
            iva: line.iva ? Number(line.iva) : Number(line.product.iva),
            costprice: line.costprice ? Number(line.costprice) : Number(line.product.costprice),
        })),
        idempresa: profile.idEmpresa,
        idemployee: profile.idEmployee || 0,
    })
    .then(response => {
        ['losses', 'earnings'].includes(this.state.doctype) ? history.push('/counters') : history.push('/sales');
    })
    .catch(error => {
        console.error(error);
    });
  }

  render() {
    const { location, history } = this.props;
    const {
        loaded,
        valid,
        hideDelete,
        doc,
        doctype,
        doctypes,
        date,
        related,
        provider_name,
        lines,
        serie,
        tpv,
        idproduct,
        payment,
        customer_name,
    } = this.state;
    const cookies = new URLSearchParams(location.search);

    const results = totals(lines, doctype);

    const desconto = results.desconto;
    const total = results.total;
    const subtotal = results.subtotal;
    const iva = results.iva;

    return (
        <div>
            <Menu loadFunction={ this.loadFunction() } location={ location } history={ history } newCookies={ cookies } />
            <div className="fullContainer" style={{ maxWidth: '100%' }}>
                <div className="col-xs-12">
                    <ol
                        style={{
                            textAlign: 'left',
                            'marginTop': '75px',
                        }}
                        className="breadcrumb"
                    >
                        <li><a href="http://eposgestofinal.pt/index.php">Portal</a></li>
                        <li><a href="/sales">Vendas</a></li>
                        <li className="active">
                            <span>{doc}</span>
                        </li>
                    </ol>
                </div>
            </div>
            {loaded && (
            <div
                className="fullContainer" style={{ maxWidth: '100%' }}
            >
                <div
                    className="col-sm-12"
                    style={{ textAlign: 'center' }}
                >
                    <div className="panel panel-default">
                        <div
                            className="panel-heading"
                            style={{
                                fontWeight: 'bold',
                                fontSize: '15pt',
                            }}
                        >
                            Documento
                        </div>
                        <form
                            style={{
                                textAlign: 'left',
                            }}
                            className="container-fluid"
                        >
                            <table className="table table-bordered table-hover table-sortable">
                                <thead>
                                    <tr >
                                        <th className="text-center">Tipo Doc</th>
                                        <th className="text-center">Data</th>
                                        {['orders'].indexOf(doctype) >= 0 ? (
                                            <>
                                                <th className="text-center">Fornecedor</th>
                                                <th className="text-center">Nº Documento</th>
                                            </>
                                        ) : null}
                                        {doctype === 'returns' ? (
                                            <>
                                                <th className="text-center">Fornecedor</th>
                                            </>
                                        ) : null}
                                        {doctype === 'inventory' ? (
                                            <>
                                                <th className="text-center">Posto</th>
                                            </>
                                        ) : null}
                                        {doctype === 'sales' ? (
                                            <>
                                                <th className="text-center">Posto</th>
                                                <th className="text-center">Série</th>
                                                <th className="text-center">TPV</th>
                                            </>
                                        ) : null}
                                        {doctype === 'invoices' ? (
                                            <>
                                                <th className="text-center">Cliente</th>
                                                <th className="text-center">Pagamento</th>
                                            </>
                                        ) : null}
                                        {!valid ? (
                                            <>
                                                <th className="text-center">Anulada</th>
                                            </>
                                        ) : null}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '250px' }}>
                                            { (doctypes.find((docType) => docType.value === doctype) || {}).label }
                                        </td>
                                        <td style={{ width: '250px' }}>
                                            {date}
                                        </td>
                                        {['orders', 'returns'].indexOf(doctype) >= 0 ? (
                                            <>
                                                <td>
                                                    {provider_name}
                                                </td>
                                                {doctype !== 'returns' ? (
                                                    <td style={{ width: '250px' }}>
                                                        {doc}
                                                    </td>
                                                ) : null}
                                            </>
                                        ) : null}
                                        {doctype === 'inventory' ? (
                                            <>
                                                <td>{lines[0]['posto_name']}</td>
                                            </>
                                        ) : null}
                                        {doctype === 'sales' ? (
                                            <>
                                                <td>{lines[0]['posto_name']}</td>
                                                <td>{serie}</td>
                                                <td>{tpv}</td>
                                            </>
                                        ) : null}
                                        {doctype === 'invoices' ? (
                                            <>
                                                <td>{customer_name}</td>
                                                <td>{translatePayments(payment)}</td>
                                            </>
                                        ) : null}
                                        {!valid ? (
                                            <>
                                                <td>TRUE</td>
                                            </>
                                        ) : null}
                                    </tr>
                                </tbody>
                            </table>
                            {['invoices', 'earnings', 'losses'].indexOf(doctype) >= 0 && (
                                <>
                                    <h3>Documentos Associados</h3>
                                    <table className="table table-bordered table-hover table-sortable">
                                        <tbody>
                                        {related.map((rel) => (
                                            <tr>
                                                <td style={{ textAlign: 'left' }}>
                                                    <a href={ `/sales/${rel.doctype}/${rel.idsale}` }>{(doctypes.find((docType) => docType.value === rel.doctype) || { label: rel.doc }).label}</a>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                            <h3>Items</h3>
                            <table className="table table-bordered table-hover table-sortable" id="tab_logic">
                                <thead>
                                    <tr >
                                        {['receipts'].indexOf(doctype) < 0 ? (
                                            <th className="text-center">Produto</th>
                                        ) : (
                                            <th className="text-center">Fatura</th>
                                        )}
                                        {['receipts'].indexOf(doctype) < 0 ? (
                                            <th className="text-center">Quantity</th>
                                        ) : null}
                                        {['orders', 'returns'].indexOf(doctype) >= 0 ? (
                                            <th className="text-center">Preço de Compra</th>
                                        ) : null}
                                        {['inventory'].indexOf(doctype) < 0 ? (
                                            <th className="text-center">PVP</th>
                                        ) : null}
                                        {['orders', 'returns'].indexOf(doctype) >= 0 ? (
                                            <>
                                                <th className="text-center">Desc1</th>
                                                <th className="text-center">Desc2</th>
                                                <th className="text-center">Desc(€)</th>
                                            </>
                                        ) : null}
                                        {['inventory', 'receipts'].indexOf(doctype) < 0 ? (
                                            <th className="text-center">IVA</th>
                                        ) : null}
                                        {doctype === 'inventory' ? (
                                            <th className="text-center">Diferença</th>
                                        ) : null}
                                        {doctype === 'transfer' ? (
                                            <th className="text-center">Posto origem</th>
                                        ) : null}
                                        {['inventory', 'sales', 'receipts'].indexOf(doctype) < 0 ? (
                                            <th className="text-center">Posto</th>
                                        ) : null}
                                    </tr>
                                </thead>
                                <tbody>
                                    {lines.map((line) => (
                                        <tr  style={{
                                            backgroundColor: idproduct === line['idproduct'] && '#f5f5f5',
                                        }}>
                                            {['receipts'].indexOf(doctype) < 0 ? (
                                                <td>
                                                    [{line['idproduct']}] {line['product_name']}
                                                </td>
                                            ) : (
                                                <td>
                                                    {line['idproduct']}
                                                </td>
                                            )}
                                            {['receipts'].indexOf(doctype) < 0 ? (
                                                <td>
                                                    {twoDecimal(line['quantity'])}
                                                </td>
                                            ) : null}
                                            {['orders', 'returns'].indexOf(doctype) >= 0 ? (
                                                <td>
                                                    {threeDecimal(line['costprice'])}
                                                </td>
                                            ) : null}
                                            {['inventory'].indexOf(doctype) < 0 ? (
                                                <td>
                                                    {threeDecimal(line['price'])}
                                                </td>
                                            ) : null}
                                            {['orders', 'returns'].indexOf(doctype) >= 0 ? (
                                                <>
                                                    <td>
                                                        {line['desc'] ? '' : line['desc1']}
                                                    </td>
                                                    <td>
                                                        {line['desc'] ? '' : line['desc2']}
                                                    </td>
                                                    <td>
                                                        {line['desc']}
                                                    </td>
                                                </>
                                            ) : null}
                                            {['inventory', 'receipts'].indexOf(doctype) < 0 ? (
                                                <td>
                                                    {line['iva']}
                                                </td>
                                            ) : null}
                                            {doctype === 'inventory' ? (
                                                <td>
                                                    {line['diff']}
                                                </td>
                                            ) : null}
                                            {doctype === 'transfer' ? (
                                                <td>
                                                    {line['postosource_name']}
                                                </td>
                                            ) : null}
                                            {['inventory', 'sales', 'receipts'].indexOf(doctype) < 0 ? (
                                                <td>
                                                    {line['posto_name']}
                                                </td>
                                            ) : null}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {['inventory'].indexOf(doctype) < 0 ? (
                                <table className="table table-bordered table-hover table-sortable" style={{ width: '200px' }}>
                                    <tbody>
                                        {['receipts'].indexOf(doctype) < 0 ? (
                                            <>
                                                <tr>
                                                    <td>Subtotal</td>
                                                    <td>{threeDecimal(subtotal)}</td>
                                                </tr>
                                                {desconto ? (
                                                    <tr>
                                                        <td>Descontos</td>
                                                        <td>{threeDecimal(desconto)}</td>
                                                    </tr>
                                                ) : null}
                                                <tr>
                                                    <td>IVA</td>
                                                    <td>{threeDecimal(iva)}</td>
                                                </tr>
                                            </>
                                        ) : null}
                                        <tr>
                                            <td>Total</td>
                                            <td>{threeDecimal(total)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : null}
                            <Button
                            action={['losses', 'earnings'].includes(doctype) ? () => history.push('/counters') : this.handleFormCancel}
                            type={"secondary"}
                            title={"voltar"}
                            style={buttonStyle}
                            />
                            {valid && !hideDelete && doctype === 'losses' && related.length === 0 ? (
                                <Button
                                action={this.handleFormSubmit}
                                type={"primary"}
                                title={"Converter em fatura"}
                                />
                            ) : null}
                            {/* Clear the form */}
                        </form>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px"
};

export default withCookies(Form);
