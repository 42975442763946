import React, { Component } from "react";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import axios from 'axios';

// Components
import Menu from '@components/Menu'

/* Import Components */
import Input from "@components/Input";
import AutoComplete from "@components/AutoComplete";
import Button from "@components/Button";
import TextArea from "@components/TextArea";

import { dateToTimestamp } from '@utils/date'

import UserContext from '@components/context/userContext';

const convertHMS = (value) => {
    const sec = parseInt(value, 10);
    let hours   = Math.floor(sec / 60);
    let minutes = Math.floor((sec - (hours * 60)));
    if (hours   < 10) {hours   = "0"+hours}
    if (minutes < 10) {minutes = "0"+minutes}
    return hours+':'+minutes;
}

class Form extends Component {
    static contextType = UserContext;
    
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

  constructor(props) {
    super(props);
    const today = new Date();

    const { match: { params }, type } = this.props;

    this.state = {
        loaded: false,
        type: '',
        time: '',
        date_time: today.toISOString().substr(0,10),
        idcustomer: params.idcustomer,
        idservice: type === 'edit' && params.idservice,
        edit: type === 'edit',
        productServices: [],
        method: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleProductAdd = this.handleProductAdd.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.loadFunction = this.loadFunction.bind(this);
  }

  loadFunction() {
    const profile = this.context;

    if (!this.state.loaded) {
        if (this.props.type === 'edit') {
            axios.get(`https://eposgestofinal.pt/api/customers/services/getById.php?idempresa=${profile.idEmpresa}&idcustomer=${this.state.idcustomer}&idservice=${this.state.idservice}`)
            .then(response => {
                const services = response.data !== 'NOK' ? response.data[0] : [];
                const date_time = new Date(services.date_time * 1000);

                this.setState({ ...services, date_time: date_time.toISOString().substr(0,10), time: convertHMS(services.time), productServices: JSON.parse(services.products), loaded: true });
            })
            .catch(error => {
                console.error(error);
            });
        } else {
            this.setState({ loaded: true });
        }
    }
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;
    
    this.setState({
        [name]: value,
    });
    
    if (callback) {
        callback();
    }
  }

  handleProductAdd(e) {
    let value = e.target.value;
    let newProductServices = this.state.productServices;
    
    if (value !== '') {
        newProductServices.push(value)

        this.setState({
            productServices: newProductServices,
        });
    }
  }

  handleProductRemove(value) {
    let newProductServices = this.state.productServices;

    newProductServices = newProductServices.filter((prod) => {        
        return prod !== value;
    });

    this.setState({
        productServices: newProductServices,
    });
  }

  handleFormSubmit(e) {
    const profile = this.context;
    const { history } = this.props;
    e.preventDefault();
    
    axios.post('https://eposgestofinal.pt/api/customers/services/set.php', {
        idempresa: profile.idEmpresa,
        idcustomer: this.state.idcustomer,
        idservice: this.state.edit ? this.state.idservice : null,
        date_time: dateToTimestamp(this.state.date_time),
        type: this.state.type,
        time: this.state.time,
        method: this.state.method,
        products: this.state.productServices,
    })
    .then(response => {
        history.push(`/customers/${this.state.idcustomer}`);
    })
    .catch(error => {
        console.error(error);
    });
  }

  handleFormCancel() {
    const { history } = this.props;
    if (this.props.location.query.closeAfter) {
        window.close();
    } else {
        history.push('/customers');
    }
  }

  render() {
    const { location, history } = this.props;
    const {
        edit,
        loaded,
        idcustomer,
        date_time,
        time,
        productServices,
        type,
        method,
    } = this.state;
    const cookies = new URLSearchParams(location.search);    

    return (
        <div>
            <Menu loadFunction={ this.loadFunction() } location={ location } history={ history } newCookies={ cookies } />
            <div className="fullContainer" style={{ maxWidth: '100%' }}>
                <div className="col-xs-12">
                    <ol
                        style={{
                            textAlign: 'left',
                            'marginTop': '75px',
                        }}
                        className="breadcrumb"
                    >
                        <li><a href="http://eposgestofinal.pt/index.php">Portal</a></li>
                        <li><a href="/customers">Clientes</a></li>
                        <li><a href={`/customers/${idcustomer}`}>{ idcustomer }</a></li>
                        <li className="active">
                            {edit ? (<span>Serviço existente</span>) : (<span>Novo serviço</span>)}
                        </li>
                    </ol>
                </div>
            </div>
            {loaded && (
            <div
                className="fullContainer" style={{ maxWidth: '100%' }}
            >
                <div
                    className="col-sm-12"
                    style={{ textAlign: 'center' }}
                >
                    <div className="panel panel-default">
                        <div
                            className="panel-heading"
                            style={{
                                fontWeight: 'bold',
                                fontSize: '15pt',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'left',
                                }}
                            >
                                Detalhes
                            </div>
                        </div>
                        <div
                            style={{
                                textAlign: 'left',
                                width: '100%',
                            }}
                            className="container-fluid"
                        >
                            <table className="table table-bordered table-hover table-sortable">
                                <thead>
                                    <tr >
                                        <th className="text-center">Data</th>
                                        <th className="text-center">Tipo de serviço</th>
                                        <th className="text-center">Tempo de pose</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td>
                                            <Input
                                                inputType={"date"}
                                                name={"date_time"}
                                                value={date_time}
                                                handleChange={this.handleChange}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                inputType={"text"}
                                                name={"type"}
                                                value={type}
                                                handleChange={this.handleChange}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                inputType={"time"}
                                                name={"time"}
                                                value={time}
                                                handleChange={this.handleChange}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <AutoComplete
                                title={"Produtos utilizados"}
                                handleChange={this.handleProductAdd}
                                type="input"
                            />
                            <ul>
                                {productServices.map((productService) => (<li>{productService} <button onClick={ () => this.handleProductRemove(productService) }>X</button></li>))}
                            </ul>
                            <TextArea
                            title={"Nuances aplicadas"}
                            rows={5}
                            value={method}
                            name={"method"}
                            handleChange={this.handleChange}
                            />
                            <Button
                            action={this.handleFormSubmit}
                            type={"success"}
                            title={"Submeter"}
                            style={buttonStyle}
                            />
                            <Button
                            action={this.handleFormCancel}
                            type={"secondary"}
                            title={"cancelar"}
                            style={buttonStyle}
                            />
                        </div>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px"
};

export default withCookies(Form);
