import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom'

import useLongPress from "@utils/useLongPress";

import './Item.css';

const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
};
function Item(props) {
    const { goTo, handleLongPress, modifier, icon, customIcon, color = 'black', text, active, link, click, counter } = props;

    const onLongPress = () => {
        if (handleLongPress) {
            handleLongPress(props)
        }
    };
    
    const longPressEvent = useLongPress(onLongPress, () => link ? goTo(link) : click(), defaultOptions);
    // eslint-disable-next-line no-unused-vars

    return (
        <div
            className={classNames({
                'card': true,
                'active': active,
                [`card-${modifier}`]: modifier,
            })}
            {...longPressEvent}
        >
            {icon ? (
                <div className="card_image">
                    <i
                        className={classNames({
                            'fa': true,
                            [icon]: true,
                        })}
                    ></i>
                </div>
            ) : null}
            {customIcon ? (
                <div className="card_image">
                    <img
                    alt=""
                    src={customIcon}
                    />
                </div>
            ) : null}
            {text ? (
                <div
                    className={classNames({
                        'card_title': true,
                        [`title-${color}`]: true,
                    })}
                >
                    <p>{ text }</p>
                </div>
            ) : null}
            {counter ? (
                <span claclassNamess="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {counter}
                </span>
            ) : null}
        </div>
    );
}

export default withRouter(Item);