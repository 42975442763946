import React from "react";
import Select from "react-select";
import Button from "./Button";
import classNames from "classnames";

class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: "",
    };
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleButtonSubmit = this.handleButtonSubmit.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  handleChangeSelect(name, value) {
    this.props.handleChange({ target: { value, name } });
  }

  handleButtonSubmit() {
    this.props.handleChange({
      target: { value: this.state.input, name: this.props.name },
    });
    this.setState({
      input: "",
    });
  }

  handleChangeInput(e) {
    let value = e.target.value;

    this.setState({
      input: value,
    });
  }

  render() {
    const classname = this.props.form ? "form-group" : "";

    return (
      <div className={classNames(classname, "selectBox")}>
        {this.props.title ? (
          <label for={this.props.name}> {this.props.title} </label>
        ) : null}
        {this.props.type === "input" ? (
          <div>
            <input
              type="text"
              value={this.state.input}
              onChange={this.handleChangeInput}
            />
            <Button
              action={this.handleButtonSubmit}
              type={"primary"}
              title={"+"}
            />
          </div>
        ) : (
          <Select
            menuPosition={"fixed"}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            name={this.props.name}
            value={this.props.value}
            onChange={(value) =>
              this.handleChangeSelect(this.props.name, value)
            }
            options={this.props.options}
          />
        )}
      </div>
    );
  }
}

export default AutoComplete;
