import React from "react";
import Checkbox from '@material-ui/core/Checkbox';

const CheckBox = props => {
    const classname = props.form ? "form-group" : "";

    return (
    <div className={ classname }>
      {props.position !== 'left' ? (props.title ? (<label for={props.name} className="form-label"> {props.title} </label>) : null) : null}
      <Checkbox
        disabled={props.disabled}
        name={props.name}
        color="default"
        checked={props.checked}
        onChange={props.handleChange}
        value="true"
        inputProps={{
          'aria-label': 'primary checkbox',
        }}
      />
      {props.position === 'left' ? (props.title ? (<label for={props.name} className="form-label"> {props.title} </label>) : null) : null}
    </div>
  );
};

export default CheckBox;
