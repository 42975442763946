import React, { Component } from 'react';

// Components
import POSMenu from '@components/POSMenu'
import Cards from '@components/Cards'

class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navigation: [{
                icon: 'fa-arrow-left',
                text: 'Voltar',
                link: '/pos',
            }, {
                icon: 'fa-plus-square',
                text: 'Criar',
                link: '/pos/inventory/new',
                condition: '[1,3,4,10].includes(profile.memberType)'
            }],
        };
    }
    
    render () {
        const { navigation } = this.state;

        return (
            <>
                <POSMenu { ...this.props } />
                <section className="main-section">
                    <Cards items={ navigation } />
                </section>
            </>
        );
    }
}

export default Page;