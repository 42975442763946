import React, { useState, useContext } from "react";
import axios from 'axios';

import AutoComplete from "@components/AutoComplete";

import UserContext from "@components/context/userContext";


const setCookie = (name,value,days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/; domain=.eposgestofinal.pt";
}

const AtLogin = ({ onLogin, cookies }) => {
    const profile = useContext(UserContext);
    const [ empresa, setEmpresa ] = useState();
    const [ empresas, setEmpresas ] = useState([]);
    const [ loaded, setLoaded ] = useState(false);
    
    if (profile['memberType'] < 9) {
        return null;
    }

    const loadFunction = () => {
        if (!loaded) {
            setLoaded(true);
            axios
                .get(
                    'https://eposgestofinal.pt/api/empresas/get.php'
                )
                .then((response) => {
                    const empresasResp = response.data;
                    const hydratateEmpresasResp = empresasResp.map((empresaResp) => ({
                        ...empresaResp,
                        value: empresaResp.idempresa,
                        label: empresaResp.nomeempresa,
                    }));
                    setEmpresas(hydratateEmpresasResp);
                    setEmpresa(hydratateEmpresasResp.find((empresaResp) => `${empresaResp.idempresa}` === `${(profile.idEmpresaSec)}`));
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    loadFunction();

    const handleChange = (e) => {
        let value = e.target.value;
        setEmpresa(value.idempresa);
        setCookie("member_idempresa_sec", value.idempresa);
        window.location.reload();
    }

    return (
    <div
        className="fullContainer" style={{ maxWidth: '100%' }}
    >
        <div
            className="col-sm-12"
            style={{ textAlign: 'center' }}
        >
            <div className="panel panel-default">
                <div
                    className="panel-heading"
                    style={{
                        fontWeight: 'bold',
                        fontSize: '15pt',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'left',
                        }}
                    >
                        Empresa
                    </div>
                </div>
                <div
                    style={{
                        textAlign: 'left',
                        width: '100%',
                    }}
                    className="container-fluid"
                >
                    <AutoComplete
                        name={"empresa"}
                        value={empresa}
                        handleChange={handleChange}
                        options={empresas}
                    />
                </div>
            </div>
        </div>
    </div>
  );
};

export default AtLogin;
