import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import QrReader from "@components/QR/read";
import { NotificationManager } from 'react-notifications';

import { valueWithIva } from "@utils/format.js";

// Components
import POSStatus from '@components/POSStatus'
import Button from "@components/Button";

import UserContext from '@components/context/userContext';

import { EventBus as eventBus } from "@components/EventBus";

class RetryButton extends React.Component {
    render() {
        const { action } = this.props;
        return (
            <Button
                action={action}
                type={"success"}
                title={"Validar nova senha"}
            />
        );
    }
}

class Page extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            state: 'unknow',
            error: {},
            success: {},
            currency: '',
            lastQr: '',
            loaded: false,
            lock: false,
            summary: {
                product: { name: '' },
            }
        };

        this.handleRetry = this.handleRetry.bind(this);
        this.handleScan = this.handleScan.bind(this);
        this.setSummaryData = this.setSummaryData.bind(this);
    }

    setSummaryData(summary) {
        this.setState({ summary })
    }

    componentDidMount() {
        eventBus.$on("GET/GET/steps/form", this.setSummaryData);
        eventBus.$emit("GET/steps/form");
    }

    componentWillUnmount() {
        eventBus.$off("GET/GET/steps/form", this.setSummaryData);
    }

    handleRetry(e) {
        const { lock } = this.state;
        if (lock) {
            window.location.href = 'https://eposgestofinal.pt/index.php?logout=1&redirect=https://react.eposgestofinal.pt/pos/tickets';
        }
        e.preventDefault();
        window.location.href='/pos/tickets/read';
    }

    handleScan = data => {
        const { lastQr, currency, lock, summary } = this.state;
        const profile = this.context;

        if (data && data !== lastQr) {
            this.setState({ lastQr: data })
            const qr = data

            axios.post('https://eposgestofinal.pt/api/tickets/validate.php', {
                idempresa: profile.idEmpresa,
                idemployeeread: profile.idEmployee,
                qr: qr,
            })
                .then((response) => {
                    const responseData = response.data;

                    if (responseData.lock) {
                        this.setState({ lock: true })
                    }

                    if (responseData.valid) {
                        NotificationManager.success('Senha válida!', 'Senhas');

                        if (summary.configs && summary.configs.invoices === '1') {
                            const currentDate = new Date().getTime();

                            axios
                                .get(
                                `https://eposgestofinal.pt/api/products/price/getById.php?idempresa=${summary.product.idempresa}&idproduct=${summary.product.idproduct}`
                                )
                                .then(async (response) => {
                                    const prices =
                                        response.data !== "NOK" ? response.data : [];
                                    const pricesByPosto = prices.reduce(
                                        (acc, price) => ({
                                        ...acc,
                                        [price.idposto]: {
                                            ...price,
                                        },
                                        }),
                                        {}
                                    );
                                    const currentValueProduct = pricesByPosto[summary.warehouse.posto];
                                    axios.post('https://eposgestofinal.pt/api/sales/set.php', {
                                        doc: currentDate,
                                        doctype: "invoices",
                                        date_time: parseInt(currentDate.toString().slice(0, -3)),
                                        idprovider: -1,
                                        idcustomer: responseData.data[0].idcustomer,
                                        payment: 1,
                                        lines: [
                                        {
                                            product: summary.product,
                                            quantity: parseFloat(responseData.data[0].value) / valueWithIva(currentValueProduct.price, currentValueProduct.iva),
                                            costprice: currentValueProduct.costprice,
                                            price: currentValueProduct.price,
                                            iva: currentValueProduct.iva,
                                            idposto: summary.warehouse.posto,
                                            desc1: 0,
                                            desc2: 0,
                                            desc: 0,
                                            invoice: {
                                            value: ""
                                            },
                                            value: "",
                                            total: responseData.data[0].value,
                                            idproduct: summary.product.idproduct
                                        }
                                        ],
                                        idempresa: profile.idEmpresa,
                                        idemployee: profile.idEmployee
                                    })
                                        .then((response) => {
                                            this.setState({ lastQr: '', state: 'valid', success: { ...responseData.data[0], currency: currency } });
                                        })
                                        .catch(error => {
                                            console.error(error);
                                            NotificationManager.error(error.message, 'Fatura');
                                            this.setState({ lastQr: '', state: 'invalid', error: { message: 'Ocorreu um erro!' } })
                                        });

                                });
                        } else {
                            this.setState({ lastQr: '', state: 'valid', success: { ...responseData.data[0], currency: currency } })
                        }

                    } else {
                        NotificationManager.error('Senha inválida!', 'Senhas');
                        this.setState({ lastQr: '', state: 'invalid', error: { message: responseData.reason || 'Senha inválida!' } })
                    }
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Senhas');
                    this.setState({ lastQr: '', state: 'invalid', error: { message: 'Ocorreu um erro!' } })
                });
        }
    }

    handleError = err => {
        console.error(err)
    }

    loadFunction() {
        const profile = this.context;

        if (!this.state.loaded) {
            axios.get(`https://eposgestofinal.pt/api/config/currency/get.php?idempresa=${profile.idEmpresa}`)
                .then(response => {
                    const currency = response.data !== 'NOK' ? response.data[0].currency : '';

                    this.setState({ currency, loaded: true });
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Moeda');
                });
        }
    }

    render () {
        const { state, error, success, summary } = this.state;
        this.loadFunction();

        switch (state) {
            case 'valid':
                const { name, value, currency } = success;
                return <POSStatus status="success" title={'Senha válida!'} message={(<><p><b>Utilizador:</b> {name}</p><p><b>Valor:</b> {currency} {value}</p></>)} action={<RetryButton action={this.handleRetry} />} />;
            case 'invalid':
                return <POSStatus status="error" title={error.message} action={<RetryButton action={this.handleRetry} />} />;
            default:
                return (
                    <QrReader
                        onScan={this.handleScan}
                    />
                )
        }
    }
}

export default withCookies(Page);