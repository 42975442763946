import React, { Component } from 'react';

// Components
import POSMenu from '../POSMenu'
import POSPage from '../POSPage'
class Page extends Component {
    render () {
        return (
            <>
                <POSMenu { ...this.props } />
                <section className="main-section">
                    <POSPage {...this.props} />
                </section>
            </>
        );
    }
}

export default Page;