import React, { Component } from 'react';

// Components

import { EventBus as eventBus } from "@components/EventBus";
class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            summary: {
                customer: { name: '' },
                ticketType: { name: '' },
                paymentMethod: { name: '' },
                values: []
            }
        };

        this.setSummaryData = this.setSummaryData.bind(this);
    }

    setSummaryData(summary) {
        this.setState({ summary })
    }

    componentDidMount() {
        eventBus.$on("GET/GET/steps/form", this.setSummaryData);
        eventBus.$emit("GET/steps/form");
    }

    componentWillUnmount() {
        eventBus.$off("GET/GET/steps/form", this.setSummaryData);
    }
    
    render () {
        const { summary } = this.state;

        return (
            <div
                style={{
                    textAlign: 'left',
                    width: '100%',
                }}
                className="container"
            >
                <h2>Cliente</h2>
                <h4><b>Nome:</b> { summary.customer.name }</h4>
                <h4><b>NIF:</b> { summary.customer.nif }</h4>
                <h4><b>Email:</b> { summary.customer.email }</h4>
                <h4><b>Telefone:</b> { summary.customer.phone }</h4>
                <h2>Senhas</h2>
                {['1'].includes(summary.ticketType.idtickettype) && (<h4><b>Método de pagamento:</b> { summary.paymentMethod.name }</h4>)}
                <h4><b>Tipo:</b> { summary.ticketType.name }</h4>
                <table className="table table-bordered table-hover table-sortable">
                    <thead>
                        <tr >
                            <th className="text-center">Valor</th>
                            <th className="text-center">Quantidade</th>
                        </tr>
                    </thead>
                    <tbody>
                        {summary.values.filter((value) => value.counter > 0).map((value) => (
                            <tr>
                                <td>{ value.value }</td>
                                <td>{ value.counter }</td>
                            </tr>
                        ))}
                        <tr className="bg-info">
                            <td >Total</td>
                            <td>
                                {summary.values.filter((value) => value.counter > 0).reduce((accum, value) => accum + (value.value * value.counter), 0)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Page;