import React, { useState, useRef, useEffect } from 'react';
// @ts-expect-error
import ReactBarcode from 'react-barcode';

function numberOfBars(value) {
  // from https://www.adams1.com/128code.html
  // this is correct as long as there aren't consecutive numbers.
  return 11 * value.length + 35;
}

export default function BarcodeWidget(props) {
  // The ReactBarcode API only takes bar width as a param, but we want to scale to the width argument
  // We'll start with an estimate for the width, based on code128.
  const [barWidth, setBarWidth] = useState(props.width / numberOfBars(props.value));
  const barcodeEl = useRef(null);

  // Once we've rendered, use the actual width to figure out what the barWidth should have been.
  useEffect(() => {
    if (!barcodeEl || !barcodeEl.current || props.width == null || props.height == null) {
      return;
    }
    const widthString = barcodeEl.current.refs.renderElement.getAttribute('width');
    const trueWidth = parseInt(widthString.split('px')[0], 10);
    const scale = props.width / trueWidth;
    setBarWidth(barWidth * scale);
  }, [props.value, props.width, props.height, barcodeEl.current]);

  return (
    <ReactBarcode
      ref={barcodeEl}
      value={props.value}
      width={barWidth}
      height={props.height}
      font={props.font}
      lineColor={props.lineColor}
      fontSize={props.fontSize}
      margin={0}
      displayValue={props.displayValue}
    />
  );
}