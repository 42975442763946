import React, { Component } from 'react';

// Components
import Cards from '../Cards'

import './POSPage.css';

class Page extends Component {
    constructor(props) {
        super(props);

        const { goBack } = this.props;

        this.state = {
            pagination: {
                left: [{
                    icon: 'fa-angle-double-left',
                    text: 'Primeira',
                    key: 'first',
                }, {
                    icon: 'fa-angle-left',
                    text: 'Anterior',
                    key: 'previous',
                }],
                right: [{
                    icon: 'fa-angle-right',
                    text: 'Próxima',
                    key: 'next',
                }, {
                    icon: 'fa-angle-double-right',
                    text: 'Última',
                    key: 'last',
                }]
            },
            navigation: goBack ? [{
                icon: 'fa-arrow-left',
                text: 'Voltar',
                link: goBack(),
            }] : [],
        };
    }
    
    render () {
        const { navigation, pagination } = this.state;
        const { children, title = '', filters = [], loadFunction, pagination: propsPagination = { left: {}, right: {} } } = this.props;

        if (loadFunction) {
            loadFunction();
        }

        const realPaginationLeft = pagination.left.reduce((accum, item) => ([
            ...accum,
            {
                ...item,
                ...propsPagination.left[item.key],
                show: (propsPagination.left[item.key] || {}).show || false,
            } 
        ]), []);

        const realPaginationRight = pagination.right.reduce((accum, item) => ([
            ...accum,
            {
                ...item,
                ...propsPagination.right[item.key],
                show: (propsPagination.right[item.key] || {}).show || false,
            } 
        ]), []);

        const paginationButtons = {
            left: <Cards modifier="extra-small" position="left" items={ realPaginationLeft } />,
            right: <Cards modifier="extra-small" position="right" items={ realPaginationRight } />,
        };

        return (
            <>
                <section className="header-section">
                    <div className="header">
                        <div className="navigation">
                            <Cards modifier="extra-small" position="left" items={ navigation } />
                        </div>
                        <div className="title">
                            { title }
                        </div>
                    </div>
                </section>
                <section className="filter-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                {filters && filters.length ? (
                                    <>
                                        <div className="col-lg-8 col-lg-push-2 col-xs-12 p-0">
                                            <div className="col-xs-12 filterTitle">
                                                Filtros
                                            </div>
                                            {filters.map(({ label, field, value, placeholder, handleChange, type = 'text' }) => (
                                                <div className="col-lg-4 col-md-4 col-sm-12 p-0">
                                                    <input className="balloon" id={ field } name={ field } type={ type } onChange={ handleChange } placeholder={ placeholder } />
                                                    <label for={ field }>{ label }</label>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-lg-2 col-lg-pull-8 col-xs-6 p-0">
                                            { paginationButtons.left }
                                        </div>
                                    </>
                                ) : (
                                    <div className="col-lg-10 col-xs-6 p-0">
                                        { paginationButtons.left }
                                    </div>
                                )}
                                <div className="col-lg-2 col-xs-6 p-0">
                                    { paginationButtons.right }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home-section">
                    { children }
                </section>
            </>
        );
    }
}

export default Page;