import React from "react";
import Switch from 'react-switch';

const CheckBox = props => {
    const classname = props.form ? "form-group" : "";

    return (
      <div className={ classname }>
        <label>
          <span>{props.title}</span>
          <Switch onChange={(value) => props.handleChange({ target: { name: props.name, value }})} checked={props.checked} />
        </label>
      </div>
  );
};

export default CheckBox;
