import React from "react";
import classNames from 'classnames';

const Input = props => {
    const classname = props.form ? "form-group" : "";

    return (
    <div className={ classname }>
      {props.title ? (<label for={props.name}> {props.title} </label>) : null}
      <input
        className={ props.className ? classNames('form-control', props.className) : "form-control" }
        id={props.name}
        name={props.name}
        type={props.inputType}
        value={props.value}
        onChange={props.handleChange}
        placeholder={props.placeholder}
        {...props}
      />
    </div>
  );
};

export default Input;
