import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

// Components
import POSStructure from '@components/POSStructure'
import Cards from '@components/Cards'
import paginator from '@utils/paginator'
import Modal from '@components/Modal'
import Edit from '../edit'

import UserContext from '@components/context/userContext';

import { EventBus as eventBus } from "@components/EventBus";

class Page extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            pagination: {
                left: {
                    first: {
                        click: () => this.setState({ page: 1 }),
                        show: true,
                    },
                    previous: {
                        click: () => this.setState({ page: this.state.page - 1 }),
                        show: true,
                    }
                },
                right: {
                    next: {
                        click: () => this.setState({ page: this.state.page + 1 }),
                        show: true,
                    },
                    last: {
                        click: () => this.setState({ page: this.state.lastPage }),
                        show: true,
                    }
                }
            },
            goBack: () => '/pos/customers',
            title: 'Clientes',
            filters: [{
                label: 'Nome',
                field: 'name',
                value: '',
                placeholder: 'Pesquise pelo Nome',
                handleChange: (e) => this.handleChange(e),
            }, {
                label: 'Email',
                field: 'email',
                value: '',
                placeholder: 'Pesquise pelo Email',
                handleChange: (e) => this.handleChange(e),
            }, {
                label: 'Telefone',
                field: 'phone',
                value: '',
                placeholder: 'Pesquise pelo Telefone',
                handleChange: (e) => this.handleChange(e),
            }],
            items: [],
            page: 1,
            lastPage: 0,
            pageSize: 24,
            loaded: false,
        };

        this.loadFunction = this.loadFunction.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({ filters: this.state.filters.map((filter) => {
            return {
                ...filter,
                value: e.target.name === filter.field ? e.target.value : filter.value,
            }
        }) });
    }

    loadFunction() {
        const profile = this.context;

        if (!this.state.loaded) {
            axios.get(`https://eposgestofinal.pt/api/customers/get.php?idempresa=${profile.idEmpresa}`)
            .then(response => {
                const customers = response.data !== 'NOK' ? response.data : [];

                const enrichedCustomers = customers.map((customer) => ({
                        ...customer,
                        icon: 'fa-user',
                        text: customer.name,
                        click: () => eventBus.$emit("Modal-customers", { header: "Editar cliente", body: <Edit idcustomer={customer.idcustomer}/> }),
                }));

                const lastPage = Math.ceil(enrichedCustomers.length / this.state.pageSize);

                this.setState({ items: enrichedCustomers, lastPage, loaded: true });
            })
            .catch(error => {
                NotificationManager.error(error.message, 'Clientes');
            });
        }
    }
    
    render () {
        const { items, title, filters, goBack, pagination, page, pageSize } = this.state;
        const filteredItems = items.filter((item) => {
            return filters
                .every((filter) =>
                    filter.value
                    ? filter.value.split(' ')
                        .every((filterValue) => 
                            item[filter.field].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                                .includes(filterValue.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
                        )
                    : true
                );
        });
        
        const pageInfo = paginator(filteredItems, page, pageSize);

        const realPagination = {
            left: pageInfo.pre_page ? pagination.left : { first: {}, previous: {} },
            right: pageInfo.next_page ? pagination.right : { next: {}, last: {} },
        }

        return (
            <>
                <POSStructure
                    {...this.props}
                    title={ pageInfo.total ? `${title} (${pageInfo.total})` : title }
                    pagination={ realPagination }
                    goBack={ goBack }
                    filters={ filters }
                    loadFunction={ this.loadFunction }
                >
                    <Cards modifier="small" items={ pageInfo.data } />
                </POSStructure>
                <Modal id="customers" modifier="big" />
            </>
        );
    }
}

export default withCookies(Page);