import React, { Component } from "react";
import { withCookies } from "react-cookie";

// Components
import POSStructure from "@components/POSStructure";
import CountersList from '@pages/counters/list'

import UserContext from "@components/context/userContext";

class Page extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      goBack: () => "/pos",
    };
  }

  render() {
    const { goBack } = this.state;

    return (
      <>
        <POSStructure {...this.props} title={"Contadores"} goBack={goBack}>
            <CountersList { ...this.props } />
        </POSStructure>
      </>
    );
  }
}

export default withCookies(Page);
