import React from "react";
import Print from "@components/Print";
import Layout from './layout';

class Example extends React.Component {
  render() {
    return (
        <Print
            {...this.props}
            button="Imprimir"
            printComponent={Layout}
            ref={el => (this.componentRef = el)}
        />
    );
  }
}

export default Example;
