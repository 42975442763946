import React, { Component } from 'react';
import './pricetagsdocument.css';

class Page extends Component {
    componentDidMount() {
        if (typeof this.props.handleDidMount === 'function') {
            this.props.handleDidMount();
        }
    }

    render() {
        const { pricetag, toPrintTags, page } = this.props;
        const a4Width = 21; // A4 page width in cm
        const containerWidth = page.a4 ? a4Width : JSON.parse(pricetag.size || '{}').width;

        // Adjust the width to account for margins
        const contentWidth = containerWidth - 2 * page.margin;

        const containerStyle = {
            width: `${contentWidth}cm`, // Adjusted width excluding margins
            margin: `${page.margin}cm`, // Outer margin of the container
            display: 'flex',
            flexWrap: 'wrap',
            gap: `${page.spacing}cm`, // Spacing between child components
            boxSizing: 'border-box',
        };

        return (
            <div style={containerStyle}>
                {toPrintTags.map((tag, index) => (
                    <div key={index} className="pricetag-item">
                        {tag}
                    </div>
                ))}
            </div>
        );
    }
}

export default Page;
