import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";
import _ from "lodash";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

// Components
import EmpresaSelect from "@components/EmpresaSelect";
import Menu from "@components/Menu";
import AutoComplete from "@components/AutoComplete";
import Button from "@components/Button";
import PriceTag from "@components/PriceTag";

import UserContext from "@components/context/userContext";

class Page extends Component {
  static contextType = UserContext;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      warehouses: [],
      products: {},
      pricetags: [],
      warehouse: {},
      pricetag: {},
    };
    this.loadFunction = this.loadFunction.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
  }

  loadFunction() {
    if (!this.state.loaded) {
      const profile = this.context;

      axios
        .get(
          `https://eposgestofinal.pt/api/postos/get.php?idempresa=${profile.idEmpresaSec}`
        )
        .then((response) => {
          const warehouses = response.data !== "NOK" ? response.data : [];

          this.setState({
            warehouses: warehouses.map((warehouse) => ({ ...warehouse, value: warehouse.posto, label: warehouse.nome !== '' ? warehouse.nome : warehouse.posto })),
          });

          axios
            .get(
              `https://eposgestofinal.pt/api/pricetags/get.php?idempresa=${profile.idEmpresaSec}`
            )
            .then((response) => {
              const pricetags = response.data !== "NOK" ? response.data : [];
    
              this.setState({
                pricetags: pricetags.map((pricetag) => ({ ...pricetag, value: pricetag.idpricetag, label: pricetag.name })),
                loaded: true,
              });
    
            });
        });
    }
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({
      [name]: value,
    });
  }

  handleFormCancel() {
    const { history } = this.props;
    history.push("/products");
  }

  render() {
    const profile = this.context;
    const { warehouses, warehouse, pricetags, pricetag } = this.state;
    const { location, history } = this.props;
    const cookies = new URLSearchParams(location.search);

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li>
                <a href="/products">Produtos</a>
              </li>
              <li className="active">Etiquetas</li>
            </ol>
          </div>
        </div>
        <EmpresaSelect />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Etiquetas
                </div>
              </div>
              <table className="table table-bordered table-hover table-sortable">
                <thead>
                  <tr>
                    <th className="text-center">Posto</th>
                    <th className="text-center">Etiqueta</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "200px" }}>
                      <AutoComplete
                          name={'warehouse'}
                          value={warehouse}
                          handleChange={this.handleChange}
                          options={warehouses}
                      />
                    </td>
                    <td style={{ width: "200px" }}>
                      <AutoComplete
                          name={'pricetag'}
                          value={pricetag}
                          handleChange={this.handleChange}
                          options={pricetags}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-bordered table-hover table-sortable">
                <thead>
                  <tr>
                    <th className="text-center">Pré-Visualização</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "200px" }}>
                      {pricetag && pricetag.config && pricetag.size && (
                        <PriceTag config={JSON.parse(pricetag.config)} pricetag={JSON.parse(pricetag.size)} empresa={profile} />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-bordered table-hover table-sortable">
                <thead>
                  <tr>
                    <th className="text-center">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "200px" }}>
                      {warehouse.value && pricetag.value && pricetag.config && pricetag.size && (
                        <Button
                            action={() => history.push(`/products/pricetags/${warehouse.value}/${pricetag.value}`)}
                            type={"success"}
                            title={"Escolher Produtos"}
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(Page));
