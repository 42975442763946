import React, { Component } from "react";
import { withCookies } from "react-cookie";
import moment from "moment";

// Components
import POSPage from "@components/POSPage";
import DatePicker from "@components/DatePicker";
import TimePicker from "@components/TimePicker";
import paginator from "@utils/paginator";

import { EventBus as eventBus } from "@components/EventBus";

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pagination: {
        left: {
          first: {
            click: () => this.setState({ page: 1 }),
            show: true,
          },
          previous: {
            click: () => this.setState({ page: this.state.page - 1 }),
            show: true,
          },
        },
        right: {
          next: {
            click: () => this.setState({ page: this.state.page + 1 }),
            show: true,
          },
          last: {
            click: () => this.setState({ page: this.state.lastPage }),
            show: true,
          },
        },
      },
      filters: [],
      items: [],
      page: 1,
      lastPage: 0,
      pageSize: 24,
      loaded: false,
      date: moment(this.props.form.date, "YYYY-MM-DD").toDate(),
      time: this.props.form.time,
    };

    this.handleChange = this.handleChange.bind(this);
    this.localDateChange = this.localDateChange.bind(this);
    this.localTimeChange = this.localTimeChange.bind(this);
  }

  handleChange(e) {
    this.setState({
      filters: this.state.filters.map((filter) => {
        return {
          ...filter,
          value: e.target.name === filter.field ? e.target.value : filter.value,
        };
      }),
    });
  }

  loadFunction() {}

  localDateChange(date) {
    this.setState({ date });
    eventBus.$emit("SET/steps/form", {
      date: moment(date).format("YYYY-MM-DD"),
    });
  }

  localTimeChange(time) {
    this.setState({ time });
    eventBus.$emit("SET/steps/form", { time });
  }

  render() {
    const { items, filters, pagination, page, pageSize, date, time } =
      this.state;

    const pageInfo = paginator(items, page, pageSize);

    const realPagination = {
      left: pageInfo.pre_page ? pagination.left : { first: {}, previous: {} },
      right: pageInfo.next_page ? pagination.right : { next: {}, last: {} },
    };

    return (
      <POSPage
        pagination={realPagination}
        filters={filters}
        loadFunction={this.loadFunction}
      >
        <div className="row" style={ { padding: '100px' } }>
          <div className="col-md-6">
            <DatePicker value={date} onChange={this.localDateChange} />
          </div>
          <div className="col-md-6">
            <TimePicker value={time} onChange={this.localTimeChange} />
          </div>
        </div>
      </POSPage>
    );
  }
}

export default withCookies(Page);
