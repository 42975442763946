import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";

// Components
import Menu from "@components/Menu";
import Input from "@components/Input";
import ReactTable from "@components/ReactTable";
import { MdVisibility, MdAddCircleOutline } from "react-icons/md";

import UserContext from "@components/context/userContext";

const employeeTypes = {
  frontoffice: "Lojista",
  backoffice: "Escritório",
};

class Page extends Component {
  static contextType = UserContext;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      table: {
        columns: [
          {
            Header: "Avatar",
            accessor: "avatar",
          },
          {
            Header: "User",
            accessor: "username",
          },
          {
            Header: "Nome",
            accessor: "name",
          },
          {
            Header: "Tipo",
            accessor: "type",
          },
          {
            Header: "",
            accessor: "edit",
          },
        ],
        rows: [],
        loading: true,
      },
      search: "",
    };
    this.loadFunction = this.loadFunction.bind(this);
    this.filterData = this.filterData.bind(this);
  }

  loadFunction() {
    if (!this.state.loaded) {
      const { history } = this.props;
      const profile = this.context;

      axios
        .get(
          `https://eposgestofinal.pt/api/employees/get.php?idempresa=${profile.idEmpresa}`
        )
        .then((response) => {
          const employees = response.data !== "NOK" ? response.data : [];

          const enrichedUsers = employees.map((employee) => ({
            ...employee,
            type: employeeTypes[employee.type],
            avatar: employee.avatar ? (
              <img
                width="50px"
                src={`https://eposgestofinal.pt/${employee.avatar}`}
              />
            ) : (
              <img
                width="50px"
                src="https://eposgestofinal.pt/site/images/profile.jpg"
              />
            ),
            edit: (
              <MdVisibility
                style={{ fontSize: "15px", cursor: "pointer" }}
                onClick={() =>
                  history.push(`/employees/${employee.idemployee}`)
                }
              />
            ),
          }));

          this.setState({
            table: { ...this.state.table, rows: enrichedUsers, loading: false },
            loaded: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  filterData(searchTerm) {
    this.setState({ search: searchTerm });
  }

  render() {
    const { table, search } = this.state;
    const { location, history } = this.props;
    const cookies = new URLSearchParams(location.search);

    const filterSearch = search.split(" ");

    const tableRows = table.rows.length
      ? table.rows.filter((tickets) =>
          filterSearch.every((eachSearch) => {
            return (
              eachSearch === "" ||
              (tickets.username &&
                tickets.username
                  .toLowerCase()
                  .includes(eachSearch.toLowerCase())) ||
              (tickets.name &&
                tickets.name
                  .toLowerCase()
                  .includes(eachSearch.toLowerCase())) ||
              (tickets.type &&
                tickets.type.toLowerCase().includes(eachSearch.toLowerCase()))
            );
          })
        )
      : [];

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li className="active">Utilizadores</li>
            </ol>
          </div>
        </div>
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Utilizadores
                </div>
                <div>
                  <table
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/employees/new")}
                  >
                    <tr>
                      <td>
                        <MdAddCircleOutline style={{ fontSize: "20px" }} />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: "xx-small" }}>Criar utilizador</td>
                    </tr>
                  </table>
                </div>
              </div>
              <table className="table table-bordered table-hover table-sortable">
                <thead>
                  <tr>
                    <th className="text-center">Pesquisa</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "250px" }}>
                      <Input
                        inputType={"text"}
                        name={"search"}
                        value={search}
                        placeholder="pesquisa..."
                        handleChange={(e) => this.filterData(e.target.value)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <ReactTable
                columns={table.columns}
                sortable={true}
                loading={table.loading}
                data={tableRows}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(Page));
