import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";

import { NotificationManager } from 'react-notifications';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { MdAddCircleOutline, MdVisibility } from "react-icons/md";
import ReactTable from "@components/ReactTable";

// Components
import Menu from "@components/Menu";

import './tourniquet.css';

import UserContext from "@components/context/userContext";

class Page extends Component {
  static contextType = UserContext;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    this.intervalIndoor = null;

    this.state = {
      loaded: false,
      toruniquet: {
        columns: [
            {
                Header: "Data",
                id: "date",
                accessor: d => d.timestamp.split(' ')[0],
            },
            {
                Header: "Hora",
                id: "time",
                accessor: d => d.timestamp.split(' ')[1].split('.')[0],
            },
            {
                Header: "Cliente",
                accessor: 'nome_1',
            },
            {
                Header: 'Entrada/Saída',
                id: 'way',
                accessor: d => d.way === 'in' ? (
                    <b>Entrada</b>
                    ) : (
                    <span>Saída</span>
                    ),
            },
            {
                Header: 'Método',
                id: 'method',
                accessor: d => d.method === 'card' ? (
                  <i class="far fa-id-card"></i>
                ) : d.method === 'phone' ? (
                  <i class="fas fa-mobile-alt"></i>
                ) : (
                  <i class="fas fa-users-cog"></i>
                ),
              },
              {
                  Header: "Estado",
                  id: "status",
                  accessor: d => d.status === 'SUCCESS' ? (
                      <span className="badge badge-success">ok</span>
                    ) : (
                      <span className="badge badge-warning">aviso</span>
                    ),
              },
          {
            Header: "",
            accessor: "view",
          },
        ],
        rows: [],
        loading: true,
      },
      induty: [],
    };
    this.loadFunction = this.loadFunction.bind(this);
    this.exit = this.exit.bind(this);
    this.indoorClients = this.indoorClients.bind(this);
    this.getIndoorClients = this.getIndoorClients.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.intervalIndoor);
    this.intervalIndoor = null;
  }

  loadFunction() {
    const profile = this.context;

    if (!this.state.loaded) {
        const { history } = this.props;

        this.getIndoorClients();
        this.indoorClients();
    }
  }

  getIndoorClients() {
    const { history } = this.props;
    const profile = this.context;

    axios
        .get(
          `https://eposgestofinal.pt/api/clientes/members/access/get.php?idempresa=${profile.idEmpresa}`
        )
        .then((response) => {
          const toruniquet = response.data !== "NOK" ? response.data : [];

          this.setState({
            toruniquet: {
              ...this.state.table,
              rows: toruniquet.map((toruniquetItem) => ({
                ...toruniquetItem,
                view: (
                    <MdVisibility
                        style={{ fontSize: "15px", cursor: "pointer" }}
                        onClick={() =>
                            history.push(`/clientes/${toruniquetItem.codigo}`)
                        }
                    />
                )
              })),
              loading: false,
            },
            loaded: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });

    axios
    .get(
        `https://eposgestofinal.pt/api/clientes/members/access/get.php?idempresa=${profile.idEmpresa}&induty=true`
    )
        .then((response) => {
        const induty = response.data !== "NOK" ? response.data : [];

        this.setState({
            induty: induty.map((indutyItem) => ({
                ...indutyItem,
                view: (
                    <MdVisibility
                        style={{ fontSize: "15px", cursor: "pointer" }}
                        onClick={() =>
                            history.push(`/clientes/${indutyItem.codigo}`)
                        }
                    />
                )
            })),
            loaded: true,
        });
        })
        .catch((error) => {
        console.error(error);
        });
  }

  indoorClients() {
    this.intervalIndoor = setInterval(() => {
        this.getIndoorClients();
    }, 10000)
  }

  exit(indutyItem) {
    const profile = this.context;

    confirmAlert({
        title: "Saída manual",
        message: `Tem certeza que pretende registar saída de ${indutyItem.nome_1}?`,
        buttons: [
          {
            label: "Sim",
            onClick: () => {
                axios
                    .get(
                        `https://eposgestofinal.pt/api/clientes/members/out.php?idempresa=${profile.idEmpresa}&codigo=${indutyItem.codigo}`
                    )
                        .then((response) => {
                            if (response.data !== "NOK") {
                                NotificationManager.success(
                                    "Saida manual",
                                    "Saida registada com sucesso",
                                    1000,
                                );
                                this.getIndoorClients();
                            } else {
                                NotificationManager.error(
                                    "Saida manual",
                                    "Erro ao registar Saida",
                                    1000,
                                );
                            }
                        })
                        .catch((error) => {
                            NotificationManager.error(
                                "Saida manual",
                                "Erro ao registar Saida",
                                1000,
                            );
                        });
            },
          },
          {
            label: "Não",
            onClick: () => {},
          },
        ],
    });
  }

  render() {
    const { toruniquet, induty } = this.state;
    const { location, history } = this.props;
    const cookies = new URLSearchParams(location.search);

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li className="active">Torniquete</li>
            </ol>
          </div>
        </div>
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default" style={{ minHeight: 'inherit' }}>
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Indoor
                </div>
              </div>
                  <br />
                  <div class="flex-wrapper">
                  {induty.length > 0 ? induty.map((indutyItem) => (
                        <div style={{ maxWidth: '195px', maxHeight: '255px', minWidth: '195px', minHeight: '255px' }}>
                        <div class="single_advisor_profile wow fadeInUp" data-wow-delay="0.2s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp' }}>
                            <div class="advisor_thumb">
                                <img style={{ maxWidth: '200px', maxHeight: '200px', padding: '0' }} src={indutyItem.fotografia || 'https://eposgestofinal.pt/site/images/dude.png'} alt=""/>
                                <div class="social-info">
                                    <a href="#" onClick={() => this.exit(indutyItem)}>Dar saída</a>
                                </div>
                            </div>
                            <div class="single_advisor_details_info">
                                <h6>
                                    {indutyItem.status !== 'SUCCESS' && (
                                        <span className="badge badge-warning">aviso</span>
                                    )}&nbsp;
                                    {indutyItem.timestamp.split(' ')[1].split('.')[0]}
                                </h6>
                                <p class="designation">{indutyItem.nome_1}</p>
                            </div>
                        </div>
                    </div>
                      )) : (<h1>Nenhum cliente registado.</h1>)}
                  </div>
                  <br />
            </div>
          </div>
        </div>
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Registo Torniquete
                </div>
              </div>
              <ReactTable
                columns={toruniquet.columns}
                sortable={true}
                loading={toruniquet.loading}
                data={toruniquet.rows}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(Page));
