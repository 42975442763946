import React, { Component } from "react";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import axios from 'axios';

// Components
import Menu from '@components/Menu'

/* Import Components */
import Input from "@components/Input";
import Button from "@components/Button";
import AutoComplete from "@components/AutoComplete";
import { MdClear } from "react-icons/md";

import { currentDateTime, dateToTimestamp } from '@utils/date'
import { threeDecimal } from '@utils/format'
import { totals } from './utils/totals'

import UserContext from "@components/context/userContext";

const newLine = (lastline) => ({
    product: { value: "" },
    quantity: "",
    costprice: "",
    price: "",
    iva: {
        tax: "23",
        code: "23",
        reason: null,
        value: "23",
        label: "23"
    },
    idposto: lastline.idposto || { value: '' },
    idpostosource: lastline.idpostosource || { value: '' },
    desc1: "",
    desc2: "",
    desc: "",
    invoice: { value: "" },
    value: "",
});

class Form extends Component {
    static contextType = UserContext;

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

  constructor(props) {
    super(props);

    const { match: { params }, type } = this.props;

    this.state = {
        loaded: false,
        products: [],
        providers: [],
        customers: [],
        warehouses: [],
        date: currentDateTime(),
        doc: '',
        doctypes: [{
            value: 'orders',
            label: 'Compras',
        },{
            value: 'returns',
            label: 'Devoluções',
        },{
            value: 'transfer',
            label: 'Transferências',
        }],
        doctype: {
            value: 'orders',
            label: 'Compras',
        },
        payments: [{
            value: 1,
            label: 'Dinheiro',
        },{
            value: 2,
            label: 'Crédito',
        },{
            value: 3,
            label: 'Cheque',
        },{
            value: 4,
            label: 'TPA',
        },{
            value: 8,
            label: 'Frota',
        },{
            value: 14,
            label: 'Serviços Internos',
        }],
        payment: {
            value: 1,
            label: 'Dinheiro',
        },
        idprovider: {
            value: '-1',
            label: 'Indiferenciado'
        },
        idcustomer: '',
        lines: [],
        idorder: params.idorder,
        orders: [],
        idordersource: { value: '-1' },
        pendingDocs: [],
        taxes: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.loadFunction = this.loadFunction.bind(this);
    this.removeFunction = this.removeFunction.bind(this);
    this.addFunction = this.addFunction.bind(this);
    this.validForm = this.validForm.bind(this);
    this.invalidLines = this.invalidLines.bind(this);
    this.handleReceiptSubmit = this.handleReceiptSubmit.bind(this);
  }

 loadFunction() {
    const profile = this.context;
    if (!this.state.loaded) {
        axios.get(`https://eposgestofinal.pt/api/products/get.php?idempresa=${profile.idEmpresa}`)
        .then(response => {
            const products = response.data !== 'NOK' ? response.data : [];
            
            this.setState({ products: products.map((product) => ({ ...product, value: product.idproduct, label: product.inativo === 't' ? `(inativo) ${product.nome}` : product.nome })), loaded: true });

            axios.get(`https://eposgestofinal.pt/api/providers/get.php?idempresa=${profile.idEmpresa}`)
            .then(response => {
                const providers = response.data !== 'NOK' ? response.data : [];

                this.setState({ providers: [
                    {
                        value: '-1',
                        label: 'Indiferenciado'
                    },
                    ...providers.map((provider) => ({ ...provider, value: provider.idprovider, label: provider.name }))
                ], loaded: true });

                axios.get(`https://eposgestofinal.pt/api/postos/get.php?idempresa=${profile.idEmpresa}`)
                .then(response => {
                    const warehouses = response.data !== 'NOK' ? response.data : [];

                    this.setState({ warehouses: warehouses.map((warehouse) => ({ ...warehouse, value: warehouse.posto, label: warehouse.nome !== '' ? warehouse.nome : warehouse.posto })), loaded: true });

                    axios.get(`https://eposgestofinal.pt/api/tax/get.php`)
                    .then(response => {
                        const taxes = response.data !== 'NOK' ? response.data : [];

                        this.setState({ taxes: taxes.map((tax) => ({ ...tax, value: tax.code, label: tax.reason ? `${tax.code < 100 ? '(não usar) - ' : ''}Isenção - ${tax.reason} - ${tax.name} - ${tax.description}` : `${tax.tax}%` })), loaded: true });

                        this.setState({ lines: [
                            {
                                ...newLine({}),
                                iva: this.state.taxes.find((tax) => tax.code == 23)
                            }
                        ]})

                        if (this.props.type === "edit") {
                            axios.get(`https://eposgestofinal.pt/api/orders/getById.php?idempresa=${profile.idEmpresa}&idorder=${this.state.idorder}`)
                            .then(response => {
                                let order = response.data !== 'NOK' ? response.data[0] : [];
                                
                                this.setState({
                                    ...order,
                                    doctype: this.state.doctypes.find(({value}) => value === order.doctype),
                                    idprovider: this.state.providers.find(({value}) => value == order.idprovider),
                                    lines: order.lines.map((line) => ({
                                        ...line,
                                        product: this.state.products.find(({idproduct}) => idproduct == line.idproduct),
                                        idposto: this.state.warehouses.find(({posto}) => posto === line.idposto),
                                        idpostosource: this.state.warehouses.find(({posto}) => posto === line.idpostosource),
                                        iva: this.state.taxes.find(({code}) => code == line.iva),
                                    })),
                                    loaded: true
                                }, () => console.log(this.state.lines));
                            })
                            .catch(error => {
                                console.error(error);
                            });
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
                })
                .catch(error => {
                    console.error(error);
                });

            })
            .catch(error => {
                console.error(error);
            });
        })
        .catch(error => {
            console.error(error);
        });

        axios.get(`https://eposgestofinal.pt/api/customers/get.php?idempresa=${profile.idEmpresa}`)
        .then(response => {
            const customers = response.data !== 'NOK' ? response.data : [];

            this.setState({ customers: customers.map((customer) => ({ ...customer, value: customer.idcustomer, label: customer.name })), loaded: true });
        })
        .catch(error => {
            console.error(error);
        });

        axios.get(`https://eposgestofinal.pt/api/orders/get.php?idempresa=${profile.idEmpresa}`)
        .then(response => {
            const orders = response.data !== 'NOK' ? response.data : [];

            this.setState({ orders: [
                {
                    idorder: '-1',
                    valid: true,
                    doc: 'Indiferenciado',
                    doctype: 'orders',
                    idordersource: null,
                    idprovider: '-1',
                    idcustomer: '-1',
                    provider_name: '',
                    customer_name: null,
                    date_time: '0',
                    payment: '-1',
                    lines: [],
                    total: 0,
                    value: '-1',
                    label: 'Indiferenciado'
                },
                ...orders.filter((order) => order.doctype === 'orders' && order.valid).map((order) => ({ ...order, value: order.idorder, label: `[${order.provider_name}] ${order.doc}` }))
            ], loaded: true });
        })
        .catch(error => {
            console.error(error);
        });

        
        axios.get(`https://eposgestofinal.pt/api/cc/get.php?idempresa=${profile.idEmpresa}`)
        .then(response => {
            const pendingDocs = response.data !== 'NOK' ? response.data : [];

            const hydratatePendingDocs = Object.keys(pendingDocs.customers).reduce((acc, customer) => {
                const invoicesOnly = Object.keys(pendingDocs.customers[customer].items).filter((id) => {
                    return pendingDocs.customers[customer].items[id].tipo === 'fatura'
                });



                const invoicesOnlyArray = invoicesOnly.map((id) => pendingDocs.customers[customer].items[id]);
                return [
                    ...acc,
                    ...invoicesOnlyArray,
                ]
            }, [])

            this.setState({ pendingDocs: hydratatePendingDocs.map((pendingDoc) => {
                const valorliquido = pendingDoc['valor_liquido'];
                const valorpendente = !pendingDoc['recibo_valor_liquido']
                    ? pendingDoc['valor_liquido']
                    : pendingDoc['recibo_valor_inicial'] - pendingDoc['recibo_valor_liquido'];
                return {...pendingDoc, valorliquido, valorpendente, value: pendingDoc.numero, label: pendingDoc.doc };
            }), loaded: true });
        })
        .catch(error => {
            console.error(error);
        });
    }
  }

  handleChange(e, callback) {
    const profile = this.context;
    let value = e.target.value;
    let name = e.target.name;
    
    let newState = this.state;

    if (name.includes("|")) {
        const properties = name.split("|");

        newState[properties[0]][properties[1]][properties[2]] = value;
        if (this.state.doctype.value === 'receipts' && properties[2] === 'invoice') {
            newState[properties[0]][properties[1]]['value'] = parseFloat(value.valorpendente).toFixed(2);
        }
        if (this.state.doctype.value === 'orders' && properties[2] === 'product') {
            newState[properties[0]][properties[1]]['iva'] = this.state.taxes.find((tax) => tax.code == value.maxcodeiva);
        }
        this.setState({
            ...newState,
        });
    } else {
        this.setState({
            [name]: value,
        });
        if (name === 'idordersource') {
            axios.get(`https://eposgestofinal.pt/api/orders/getById.php?idempresa=${profile.idEmpresa}&idorder=${value.value}`)
            .then(response => {
                let order = response.data !== 'NOK' ? response.data[0] : [];
                if (value.value === '-1') {
                    order = this.state.orders.find(({ idorder }) => idorder === value.value);
                }

                this.setState({ idprovider: order.idprovider !== '-1' ? this.state.providers.find((provider) => provider.idprovider === order.idprovider) : { value: '-1', label: '' }, lines: order.idorder !== '-1' ? order.lines.map((line) => ({
                    ...line,
                    product: this.state.products.find((product) => product.idproduct === line.idproduct),
                    idposto: this.state.warehouses.find((warehouse) => warehouse.posto === line.idposto),
                })) : [{}], loaded: true });
            })
            .catch(error => {
                console.error(error);
            });
        }
    }

    
    if (callback) {
        callback();
    }
  }

  handleFormCancel() {
    const { history } = this.props;
    history.push('/orders');
  }

  handleFormSubmit(e) {
    const profile = this.context;
    const { history } = this.props;
    const doctype = this.state.doctype.value;
    e.preventDefault();
    
    axios.post('https://eposgestofinal.pt/api/orders/set.php', {
        idorder: this.props.type === "edit" ? this.state.idorder : null,
        doc: ['orders'].includes(doctype) ? this.state.doc : new Date().getTime(),
        doctype: doctype,
        date_time: dateToTimestamp(this.state.date),
        idprovider: ['orders', 'returns'].includes(doctype) ? this.state.idprovider.value : -1,
        idcustomer: ['invoices', 'receipts'].includes(doctype) ? this.state.idcustomer.value : -1,
        payment: ['invoices', 'receipts'].includes(doctype) ? this.state.payment.value : -1,
        idordersource: doctype === 'returns' ? this.state.idordersource.value : undefined,
        lines: this.state.lines.map((line) => ({
            ...line,
            idproduct: line.product.value,
            idposto: line.idposto.value,
            idpostosource: doctype === 'transfer' ? line.idpostosource.value : undefined,
            price: Number(line.product.price),
            desc: Number(line.desc),
            desc1: Number(line.desc1),
            desc2: Number(line.desc2),
            iva: Number(line.iva.value),
            costprice: line.costprice ? Number(line.costprice) : Number(line.product.costprice),
        })),
        idempresa: profile.idEmpresa,
        idemployee: profile.idEmployee || 0,
    })
    .then(response => {
        history.push('/orders');
    })
    .catch(error => {
        console.error(error);
    });
  }

  handleReceiptSubmit(e) {
    const profile = this.context;
    const { history } = this.props;
    const doctype = this.state.doctype.value;
    e.preventDefault();
    
    axios.post('https://eposgestofinal.pt/api/receipts/set.php', {
        idcustomer: ['invoices','receipts'].includes(doctype) ? this.state.idcustomer.value : -1,
        date_time: dateToTimestamp(this.state.date),
        payment: ['invoices','receipts'].includes(doctype) ? this.state.payment.value : -1,
        lines: this.state.lines,
        idempresa: profile.idEmpresa,
        idemployee: profile.idEmployee || 0,
    })
    .then(response => {
        history.push('/orders');
    })
    .catch(error => {
        console.error(error);
    });
  }

  removeFunction(key) {
    this.setState({
        lines: this.state.lines.filter((line, index) => index !== key),
    });
  }

  addFunction(key) {
    this.setState({
        lines: [ ...this.state.lines, newLine(this.state.lines[this.state.lines.length - 1] || {}) ],
    });
  }

  validForm() {
    const {
        doc,
        idprovider,
        idcustomer,
        doctype,
    } = this.state;

    switch (doctype.value) {
        case 'orders':
            return idprovider && doc;
        case 'returns':
            return idprovider;
        case 'invoices':
        case 'receipts':
            return idcustomer;
        case 'transfer':
            return true
        default:
            return false;
    }
  }

  invalidLines() {
    const {
        lines,
        doctype,
    } = this.state;

    switch (doctype.value) {
        case 'invoices':
            return lines.find(({ product, quantity, idposto }) => !product.value || !quantity || !idposto.value );
        case 'receipts':
            return lines.find(({ value, invoice }) => !invoice.numero || !value || parseFloat(parseFloat(value).toFixed(2)) > parseFloat(parseFloat(invoice.valorpendente).toFixed(2)));
        case 'orders':
            return lines.find(({ product, quantity, idposto }) => !product.value || !quantity || !idposto.value );
        case 'returns':
        case 'transfer':
        default:
            return false;
    }
  }

  render() {
    const profile = this.context;
    const { location, history } = this.props;
    const {
        loaded,
        doc,
        date,
        idprovider,
        providers,
        products,
        warehouses,
        lines,
        doctype,
        doctypes,
        idordersource,
        orders,
        idcustomer,
        customers,
        payments,
        payment,
        pendingDocs,
        taxes,
    } = this.state;
    const cookies = new URLSearchParams(location.search);

    const filterPendingDocs = pendingDocs.filter((pendingDoc) => pendingDoc.cliente === idcustomer.value);

    const filterDocTypes = doctypes.filter(({condition}) => !condition || eval(condition));

    const results = totals(lines, doctype.value, taxes);

    const fakeIdordersource = idordersource && idordersource.value === '-1' ? orders.find(({ idorder }) => idorder === '-1') : idordersource;

    const desconto = results.desconto;
    const total = results.total;
    const subtotal = results.subtotal;
    const iva = results.iva;

    return (
        <div>
            <Menu loadFunction={ this.loadFunction() } location={ location } history={ history } newCookies={ cookies } />
            <div className="fullContainer" style={{ maxWidth: '100%' }}>
                <div className="col-xs-12">
                    <ol
                        style={{
                            textAlign: 'left',
                            'marginTop': '75px',
                        }}
                        className="breadcrumb"
                    >
                        <li><a href="http://eposgestofinal.pt/index.php">Portal</a></li>
                        <li><a href="/orders">Compras</a></li>
                        <li className="active">
                            <span>Nova Compra</span>
                        </li>
                    </ol>
                </div>
            </div>
            {loaded && (
            <div
                className="fullContainer"
                style={{ marginTop:'25px' }}
            >
                <div
                    className="col-sm-12"
                    style={{ textAlign: 'center', fontSize: '10px' }}
                >
                    <div className="panel panel-default">
                        <div
                            className="panel-heading"
                            style={{
                                fontWeight: 'bold',
                                fontSize: '15pt',
                            }}
                        >
                            Documento
                        </div>
                        <form
                            style={{
                                textAlign: 'left',
                            }}
                            className="container-fluid"
                            onSubmit={this.handleFormSubmit}
                        >
                            <h3>Cabeçalho</h3>
                            <table className="table table-bordered table-hover table-sortable">
                                <thead>
                                    <tr >
                                        <th className="text-center">Tipo Doc</th>
                                        <th className="text-center">Data</th>
                                        {['orders'].includes(doctype.value) ? (
                                            <>
                                                <th className="text-center">Fornecedor</th>
                                                <th className="text-center">Nº Documento</th>
                                            </>
                                        ) : null}
                                        {['returns'].includes(doctype.value) ? (
                                            <>
                                                <th className="text-center">Compra</th>
                                                <th className="text-center">Fornecedor</th>
                                            </>
                                        ) : null}
                                        {['invoices', 'receipts'].includes(doctype.value) ? (
                                            <>
                                                <th className="text-center">Cliente</th>
                                                <th className="text-center">Pagamento</th>
                                            </>
                                        ) : null}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td style={{ width: '250px' }}>
                                            <AutoComplete
                                                name={"doctype"}
                                                value={doctype}
                                                handleChange={this.handleChange}
                                                options={filterDocTypes}
                                            />
                                        </td>
                                        <td style={{ width: '250px' }}>
                                            <Input
                                                inputType={"datetime-local"}
                                                name={"date"}
                                                value={date}
                                                handleChange={this.handleChange}
                                            />
                                        </td>
                                        {['orders'].includes(doctype.value) ? (
                                            <>
                                                <td>
                                                    <AutoComplete
                                                        name={"idprovider"}
                                                        value={idprovider}
                                                        handleChange={this.handleChange}
                                                        options={providers}
                                                    />
                                                </td>
                                                <td style={{ width: '250px' }}>
                                                    <Input
                                                        inputType={"text"}
                                                        name={"doc"}
                                                        value={doc}
                                                        handleChange={this.handleChange}
                                                    />
                                                </td>
                                            </>
                                        ) : null}
                                        {['returns'].includes(doctype.value) ? (
                                            <>
                                                <td style={{ width: '250px' }}>
                                                    <AutoComplete
                                                        name={"idordersource"}
                                                        value={fakeIdordersource}
                                                        handleChange={this.handleChange}
                                                        options={orders}
                                                    />
                                                </td>
                                                {fakeIdordersource.idorder === '-1' ? (
                                                    <td>
                                                        <AutoComplete
                                                            name={"idprovider"}
                                                            value={idprovider}
                                                            handleChange={this.handleChange}
                                                            options={providers}
                                                        />
                                                    </td>
                                                ) : (
                                                    <td style={{ width: '250px' }}>
                                                        {idprovider.label}
                                                    </td>
                                                )}
                                            </>
                                        ) : null}
                                        {['invoices', 'receipts'].includes(doctype.value) ? (
                                            <>
                                                <td>
                                                    <AutoComplete
                                                        name={"idcustomer"}
                                                        value={idcustomer}
                                                        handleChange={this.handleChange}
                                                        options={customers}
                                                    />
                                                </td>
                                                <td>
                                                    <AutoComplete
                                                        name={"payment"}
                                                        value={payment}
                                                        handleChange={this.handleChange}
                                                        options={payments}
                                                    />
                                                </td>
                                            </>
                                        ) : null}
                                    </tr>
                                </tbody>
                            </table>
                            {this.validForm() && (
                            <>
                                <h3>Linhas</h3>
                                <table className="table table-bordered table-hover table-sortable" id="tab_logic">
                                    <thead>
                                        <tr >
                                            {!['receipts'].includes(doctype.value) ? (
                                                <>
                                                    <th className="text-center">Produto</th>
                                                    <th className="text-center">Quantity</th>
                                                    {['orders', 'returns'].includes(doctype.value)  && (doctype.value === 'returns' ? fakeIdordersource.idorder !== '-1' : true) ? (
                                                        <th className="text-center">PCompra</th>
                                                    ) : null}
                                                    {['returns'].includes(doctype.value) && fakeIdordersource.idorder === '-1' ? null : (
                                                        <th className="text-center">PVP</th>
                                                    )}
                                                    {['orders', 'returns'].includes(doctype.value)  && (doctype.value === 'returns' ? fakeIdordersource.idorder !== '-1' : true) ? (
                                                        <>
                                                            <th className="text-center">Desc1</th>
                                                            <th className="text-center">Desc2</th>
                                                            <th className="text-center">Desc€</th>
                                                        </>
                                                    ) : null}
                                                    {['returns'].includes(doctype.value) && fakeIdordersource.idorder === '-1' ? null : (
                                                        <th className="text-center">IVA</th>
                                                    )}
                                                    {['transfer'].includes(doctype.value) ? (
                                                        <th className="text-center">Posto Origem</th>
                                                    ) : null}
                                                    <th className="text-center">Posto</th>
                                                    <th className="text-center">&nbsp;</th>
                                                </>
                                            ) : null}
                                            {['receipts'].includes(doctype.value) ? (
                                                <>
                                                    <th className="text-center">Fatura</th>
                                                    <th className="text-center">Valor em falta</th>
                                                    <th className="text-center">Valor a abater</th>
                                                </>
                                            ) : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {lines.map((line, key) => (
                                            <tr>
                                                {!['receipts'].includes(doctype.value) ? (
                                                    <>
                                                        {doctype.value !== 'returns' || (doctype.value === 'returns' ? fakeIdordersource.idorder === '-1' : true) ? (
                                                            <td style={{ width: '400px' }}>
                                                                <AutoComplete
                                                                    name={`lines|${key}|product`}
                                                                    value={line.product}
                                                                    handleChange={this.handleChange}
                                                                    options={products.filter(({inativo}) => inativo !== 't')}
                                                                />
                                                            </td>
                                                        ) : (
                                                            <td>{line.product ? line.product.label : ''}</td>
                                                        )}
                                                        <td style={{ width: '100px' }}>
                                                            <Input
                                                                inputType={"text"}
                                                                name={`lines|${key}|quantity`}
                                                                value={line.quantity}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </td>
                                                        {['orders', 'returns'].includes(doctype.value) && (doctype.value === 'returns' ? fakeIdordersource.idorder !== '-1' : true) ? (
                                                            ['orders'].includes(doctype.value) ? (
                                                                <td style={{ width: '80px' }}>
                                                                    <Input
                                                                        inputType={"text"}
                                                                        name={`lines|${key}|costprice`}
                                                                        value={line ? (line.costprice ? line.costprice : (line.product ? line.product.costprice : '')) : ''}
                                                                        handleChange={this.handleChange}
                                                                    />
                                                                </td>
                                                            ) : (
                                                                <td>{ line ? (line.costprice ? line.costprice : (line.product ? line.product.costprice : '')) : ''}</td>
                                                            )
                                                        ) : null}
                                                        {['returns'].includes(doctype.value) && fakeIdordersource.idorder === '-1' ? null : (
                                                            <td style={{ width: '80px' }}>
                                                                {line.product ? line.product.price : ''}
                                                            </td>
                                                        )}
                                                        {['orders', 'returns'].includes(doctype.value)  && (doctype.value === 'returns' ? fakeIdordersource.idorder !== '-1' : true) ? (
                                                            ['orders'].includes(doctype.value) ? (
                                                                <>
                                                                    <td style={{ width: '80px' }}>
                                                                        <Input
                                                                            inputType={"text"}
                                                                            name={`lines|${key}|desc1`}
                                                                            value={line.desc1}
                                                                            handleChange={this.handleChange}
                                                                        />
                                                                    </td>
                                                                    <td style={{ width: '80px' }}>
                                                                        <Input
                                                                            inputType={"text"}
                                                                            name={`lines|${key}|desc2`}
                                                                            value={line.desc2}
                                                                            handleChange={this.handleChange}
                                                                        />
                                                                    </td>
                                                                    <td style={{ width: '80px' }}>
                                                                        <Input
                                                                            inputType={"text"}
                                                                            name={`lines|${key}|desc`}
                                                                            value={line.desc}
                                                                            handleChange={this.handleChange}
                                                                        />
                                                                    </td>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <td>{line.desc1}</td>
                                                                    <td>{line.desc2}</td>
                                                                    <td>{line.desc}</td>
                                                                </>
                                                            )
                                                        ) : null}
                                                        {['returns'].includes(doctype.value) && fakeIdordersource.idorder === '-1' ? null : (['orders'].includes(doctype.value) ? (
                                                            <td>
                                                                <AutoComplete
                                                                name={`lines|${key}|iva`}
                                                                value={line.iva || this.state.taxes.find((tax) => tax.code == 23)}
                                                                handleChange={this.handleChange}
                                                                options={taxes}
                                                                />
                                                            </td>
                                                        ) : (
                                                            <td>{line.iva}</td>
                                                        ))}
                                                        {['transfer'].includes(doctype.value) ? (
                                                            <td style={{ width: '200px' }}>
                                                                <AutoComplete
                                                                    name={`lines|${key}|idpostosource`}
                                                                    value={line.idpostosource}
                                                                    handleChange={this.handleChange}
                                                                    options={warehouses}
                                                                />
                                                            </td>
                                                        ) : null}
                                                        
                                                        {['returns'].includes(doctype.value) && fakeIdordersource.idorder !== '-1' ? (
                                                            <td>{line.idposto.label}</td>
                                                        ) : (
                                                            <td style={{ width: '200px' }}>
                                                                <AutoComplete
                                                                    name={`lines|${key}|idposto`}
                                                                    value={line.idposto}
                                                                    handleChange={this.handleChange}
                                                                    options={warehouses}
                                                                />
                                                            </td>
                                                        )}
                                                        <td style={{ width: '20px' }}>
                                                            <MdClear style={{ color: 'red', fontSize: '15px', cursor: 'pointer' }} onClick={ () => this.removeFunction(key) } />
                                                        </td>
                                                    </>
                                                ) : null}
                                                {['receipts'].includes(doctype.value) ? (
                                                    <>
                                                        <td style={{ width: '200px' }}>
                                                            <AutoComplete
                                                                name={`lines|${key}|invoice`}
                                                                value={line.invoice}
                                                                handleChange={this.handleChange}
                                                                options={filterPendingDocs}
                                                            />
                                                        </td>
                                                        <td style={{ width: '100px' }}>
                                                            { parseFloat(line.invoice.valorpendente || 0).toFixed(2) }
                                                        </td>
                                                        <td style={{ width: '100px' }}>
                                                            <Input
                                                                inputType={"text"}
                                                                name={`lines|${key}|value`}
                                                                value={line.value}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </td>
                                                        <td style={{ width: '20px' }}>
                                                            <MdClear style={{ color: 'red', fontSize: '15px', cursor: 'pointer' }} onClick={ () => this.removeFunction(key) } />
                                                        </td>
                                                    </>
                                                ) : null}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {doctype.value !== 'returns' || (doctype.value === 'returns' ? fakeIdordersource.idorder === '-1' : true) ? (
                                    <div className="row" style={{ textAlign: 'right' }}>
                                        <Button
                                            disabled={this.invalidLines()}
                                            action={this.addFunction}
                                            type={"info"}
                                            title={"Adicionar item"}
                                            style={buttonStyle}
                                        />
                                    </div>
                                ) : null}
                                {['returns'].includes(doctype.value) && fakeIdordersource.idorder === '-1' ? null : (
                                <table className="table table-bordered table-hover table-sortable" style={{ width: '200px' }}>
                                    <tbody>
                                        {!['receipts'].includes(doctype.value) ? (
                                            <>
                                                <tr>
                                                    <td>Subtotal</td>
                                                    <td>{threeDecimal(subtotal)}</td>
                                                </tr>
                                                {desconto ? (
                                                    <tr>
                                                        <td>Descontos</td>
                                                        <td>{threeDecimal(desconto)}</td>
                                                    </tr>
                                                ) : null}
                                                <tr>
                                                    <td>IVA</td>
                                                    <td>{threeDecimal(iva)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total</td>
                                                    <td>{threeDecimal(total)}</td>
                                                </tr>
                                            </>
                                        ) : null}
                                        {['receipts'].includes(doctype.value) ? (
                                            <>
                                                <tr>
                                                    <td>Total em falta</td>
                                                    <td>{parseFloat(lines.reduce((acc, line) => acc + parseFloat(line.invoice.valorpendente || 0), 0) - lines.reduce((acc, line) => acc + parseFloat(line.value || 0), 0)).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total a abater</td>
                                                    <td>{parseFloat(lines.reduce((acc, line) => acc + parseFloat(line.value || 0), 0)).toFixed(2)}</td>
                                                </tr>
                                            </>
                                        ) : null}
                                    </tbody>
                                </table>
                                )}
                                <Button
                                    disabled={this.invalidLines()}
                                    action={['receipts'].includes(doctype.value) ? this.handleReceiptSubmit : this.handleFormSubmit}
                                    type={"success"}
                                    title={"Submeter"}
                                    style={buttonStyle}
                                />
                            </>
                            )}
                            <Button
                            action={this.handleFormCancel}
                            type={"secondary"}
                            title={"cancelar"}
                            style={buttonStyle}
                            />
                            {/* Clear the form */}
                        </form>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px"
};

export default withCookies(Form);
