import React, { Component } from "react";
import { withCookies } from "react-cookie";
import classNames from "classnames";
import { withRouter } from "react-router-dom";

import "./POSMenu.css";

import { EventBus as eventBus } from "../EventBus";

import UserContext from "../context/userContext";
class Page extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    const { POSMenu } = this.props;

    this.state = {
      open: this.props.cookies.get("posMenu") === "true",
      selected: POSMenu,
      menus: [
        {
          id: "home",
          icon: "fa-home",
          text: "Inicio",
          condition:
            '[1,3,4,10].includes(profile.memberType) && (profile.permissions.includes("pos.customers") || profile.permissions.includes("pos.tickets") || profile.permissions.includes("pos.inventory"))',
        },
        {
          id: "customers",
          icon: "fa-users",
          text: "Clientes",
          condition:
            '[1,3,10].includes(profile.memberType) && profile.permissions.includes("pos.customers")',
        },
        {
          id: "tickets",
          icon: "fa-ticket-alt",
          text: "Senhas",
          condition:
            '[3,4].includes(profile.memberType) && profile.permissions.includes("pos.tickets")',
        },
        {
          id: "inventory",
          icon: "fa-boxes",
          text: "Inventário",
          condition:
            '[1,3,4,10].includes(profile.memberType) && profile.permissions.includes("pos.inventory")',
        },
        {
          id: "counters",
          customIcon: "https://eposgestofinal.pt/site/images/counter.png",
          text: "Contadroes",
          condition:
            '[1,3,4,10].includes(profile.memberType) && profile.permissions.includes("pos.counters")',
        },
      ],
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.goToProfile = this.goToProfile.bind(this);
    this.goToLogout = this.goToLogout.bind(this);
  }

  toggleMenu() {
    const newMenuPosition = !this.state.open;
    this.setState({ open: newMenuPosition });
    this.props.cookies.set("posMenu", newMenuPosition);
  }

  selectMenu(menu) {
    const { history } = this.props;
    history.push(`/pos/${menu}`);
  }

  goToProfile() {
    window.location.href = 'https://eposgestofinal.pt/index.php?page=profile';
  }

  goToLogout() {
    window.location.href = 'https://eposgestofinal.pt/index.php?logout=1';
  }

  componentDidMount() {
    eventBus.$emit("footer", {
      company: { name: "Walk In DEV", url: "https://www.walkindev.com/" },
      className: "site-footer",
    });
  }

  render() {
    const { open, menus, selected } = this.state;

    const profile = this.context;

    return (
      <>
        <link
          href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css"
          rel="stylesheet"
        ></link>
        <div
          className={classNames({
            sidebar: true,
            open: open,
          })}
        >
          {!open && (<div>
            <img
              alt="logo ePOS"
              className="closed-icon"
              src="https://eposgestofinal.pt/site/images/epos.png"
            />
            <div className="logo_name">ePOS</div>
          </div>)}
          <div className="logo-details">
            {open && (<><img
              alt="logo ePOS"
              className="icon"
              src="https://eposgestofinal.pt/site/images/epos.png"
            />
            <div className="logo_name">ePOS</div></>)}
            <i
              className={classNames({
                bx: true,
                "bx-menu": !open,
                "bx-menu-alt-right": open,
              })}
              id="btn"
              onClick={this.toggleMenu}
            ></i>
          </div>
          <ul className="nav-list">
            {
              // eslint-disable-next-line
              menus.map(
                ({ id, icon, customIcon, text, condition = "true" }) =>
                  eval(condition) && (
                    <li className="nav-list-item">
                      <div
                        className={classNames({
                          active: selected === id,
                        })}
                        onClick={() => this.selectMenu(id)}
                      >
                        {icon ? (
                          <i
                            className={classNames({
                              fas: true,
                              [icon]: true,
                            })}
                          ></i>
                        ) : null}
                        {customIcon ? (
                          <img
                            alt=""
                            src={customIcon}
                          />
                        ) : null}
                        <span className="links_name">{text}</span>
                      </div>
                      <span className="tooltip">{text}</span>
                    </li>
                  )
              )
            }
            <li className="profile">
              <div className="profile-details">
                <img onClick={ this.goToProfile } src={`https://eposgestofinal.pt/${profile['avatar'] || (profile['idemployee'] ? 'site/images/profile.jpg' : 'site/images/suitcase.png')}`} alt="profileImg" />
                <div className="name_job">
                  <div className="name" onClick={ this.goToProfile }>{profile["name"]}</div>
                </div>
                <div className="logout">
                  <i onClick={ this.goToLogout } className="bx bx-log-out" id="log_out"></i>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default withCookies(withRouter(Page));
