import React, { Component } from 'react';

// Components
import { twoDecimal, numberWithCommas } from '@utils/format.js'

import './table.css';

class Page extends Component {
    componentDidMount() {
        if (typeof this.props.handleDidMount == 'function') {
            this.props.handleDidMount();
        }
    }

    render () {
        const { table, total, debit, credit, currency } = this.props;

        return (
            <table style={{ width: '100%' }}>
                <tr>
                    <td>
                        <table style={{ width: '100%' }}>
                            <tr>
                                <th>Débito</th>
                                <th>Crédito</th>
                                <th>Saldo Total</th>
                            </tr>
                            <tr>
                                <td>{currency} {numberWithCommas(twoDecimal(debit))}</td>
                                <td>{currency} {numberWithCommas(twoDecimal(credit))}</td>
                                <td>{currency} {numberWithCommas(twoDecimal(total))}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table style={{ width: '100%' }}>
                            <tr>
                                {table.columns.filter(({ hideFromPrint }) => !hideFromPrint).map(({ Header }) => (
                                    <th>{ Header }</th>
                                ))}
                            </tr>
                            {table.rows.map((row) => (
                                <tr>
                                    <td>{ row.idticket }</td>
                                    <td>{ row.created_at }</td>
                                    <td>{ row.customer }</td>
                                    <td>{ row.employee }</td>
                                    <td>{ row.value }</td>
                                    <td>{ row.used_at }</td>
                                    <td>{ row.employeeread }</td>
                                    { row.status === 't' ? (<td className="greenColorPrint" style={{ color: 'green !important'}}>Disponível</td>) : (<td className="redColorPrint" style={{ color: 'red !important' }}>Resgatada</td>) }
                                </tr>
                            ))}
                        </table>
                    </td>
                </tr>
            </table>
        );
    }
}

export default Page;