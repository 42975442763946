import React from "react";

import Input from "@components/Input";

class Example extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            custom: props.customValue || '',
        };
    
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e, callback) {
        const { setCustomValue, id } = this.props;
        let value = e.target.value;
        let name = e.target.name;
        
        this.setState({
            [name]: value,
        });

        setCustomValue(value, id);
    }

    render() {
        const { custom } = this.state;

        return (
            <div>
                <Input
                    inputType={"number"}
                    name={"custom"}
                    value={custom}
                    handleChange={this.handleChange}
                />
            </div>
        );
    }
}

export default Example;
