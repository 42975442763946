import React, { Component } from "react";
import { withCookies } from "react-cookie";
import axios from "axios";
import { NotificationManager } from 'react-notifications';

// Components
import POSPage from "@components/POSPage";
import Cards from "@components/Cards";
import paginator from "@utils/paginator";

import { EventBus as eventBus } from "@components/EventBus";

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pagination: {
        left: {
          first: {
            click: () => this.setState({ page: 1 }),
            show: true,
          },
          previous: {
            click: () => this.setState({ page: this.state.page - 1 }),
            show: true,
          },
        },
        right: {
          next: {
            click: () => this.setState({ page: this.state.page + 1 }),
            show: true,
          },
          last: {
            click: () => this.setState({ page: this.state.lastPage }),
            show: true,
          },
        },
      },
      filters: [
        {
          label: "Nome",
          field: "name",
          value: "",
          placeholder: "Pesquise pelo Nome",
          handleChange: (e) => this.handleChange(e),
        },
        {
          label: "ID Familia",
          field: "idcategoria",
          value: "",
          placeholder: "Pesquise pelo ID da Familia",
          handleChange: (e) => this.handleChange(e),
        },
      ],
      items: [],
      page: 1,
      lastPage: 0,
      pageSize: 24,
      loaded: false,
    };

    this.loadFunction = this.loadFunction.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({
      filters: this.state.filters.map((filter) => {
        return {
          ...filter,
          value: e.target.name === filter.field ? e.target.value : filter.value,
        };
      }),
    });
  }

  loadFunction() {
    if (!this.state.loaded) {
      axios
        .get(
          `https://eposgestofinal.pt/api/categories/get.php?idempresa=${this.props.cookies.get(
            "member_idempresa"
          )}`
        )
        .then((response) => {
          const categories = response.data !== "NOK" ? response.data : [];

          const enrichedCategories = categories
            .map((category) => ({
              ...category,
              icon: "fa-archive",
              text: category.descricaocategoria || category.idcategoria,
              name: category.descricaocategoria || category.idcategoria,
            }))
            .map((category) => ({
              ...category,
              click: () => eventBus.$emit("SET/steps/form", { category }),
            }));

          const lastPage = Math.ceil(
            enrichedCategories.length / this.state.pageSize
          );

          this.setState({ items: enrichedCategories, lastPage, loaded: true });
        })
        .catch((error) => {
          NotificationManager.error(error.message, 'Familias');
        });
    }
  }

  render() {
    const { items, filters, pagination, page, pageSize } = this.state;
    const { form } = this.props;
    const filteredItems = items.filter((item) => {
      return filters.every((filter) =>
        filter.value
          ? filter.value.split(" ").every((filterValue) =>
              (item[filter.field] || "")
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  filterValue
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )
            )
          : true
      );
    });

    const pageInfo = paginator(filteredItems, page, pageSize);

    const realPagination = {
      left: pageInfo.pre_page ? pagination.left : { first: {}, previous: {} },
      right: pageInfo.next_page ? pagination.right : { next: {}, last: {} },
    };

    const data = pageInfo.data.map((item) => ({
      ...item,
      active: item.idcategoria === form.category.idcategoria,
    }));

    return (
      <POSPage
        pagination={realPagination}
        filters={filters}
        loadFunction={this.loadFunction}
      >
        <Cards modifier="small" items={data} />
      </POSPage>
    );
  }
}

export default withCookies(Page);
