import React, { Component } from "react";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import axios from 'axios';

// Components
import Menu from '@components/Menu'

/* Import Components */
import EmpresaSelect from "@components/EmpresaSelect";
import Button from "@components/Button";

import { TimestampToDate } from '@utils/date'

import UserContext from "@components/context/userContext";

class Form extends Component {
    static contextType = UserContext;

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

  constructor(props) {
    super(props);

    const { match: { params } } = this.props;

    this.state = {
        loaded: false,
        date: '',
        lines: [],
        idempresa: this.props.cookies.get('member_idempresa'),
        doc: params.doc,
        matricula: '',
        kilometros: '',
        requisicao: '',
    };
    this.loadFunction = this.loadFunction.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
  }

  loadFunction() {
    const profile = this.context;

    if (!this.state.loaded) {
        const { match: { params } } = this.props;

        axios.get(`https://eposgestofinal.pt/api/guias/getById.php?idempresa=${profile.idEmpresaSec}&doc=${this.state.doc}`)
        .then(response => {
            const guia = response.data !== 'NOK' ? response.data[0] : [];

            this.setState({ ...this.state, ...guia, date: TimestampToDate(guia.date_time), customer_name: guia.customer_name || guia.nome, kilometros: guia.kilometros === '-2147483648' ? '' : guia.kilometros, requisicao: guia.requisicao === '-2147483648' ? '' : guia.requisicao, loaded: true });
        })
        .catch(error => {
            console.error(error);
        });
    }
  }


  handleFormCancel() {
    const { history } = this.props;
    history.push('/guias');
  }

  render() {
    const { location, history } = this.props;
    const {
        loaded,
        date,
        lines,
        serie,
        tpv,
        customer_name,
        doc,
        matricula,
        kilometros,
        requisicao,
    } = this.state;
    const cookies = new URLSearchParams(location.search);

    return (
        <div>
            <Menu loadFunction={ this.loadFunction() } location={ location } history={ history } newCookies={ cookies } />
            <div className="fullContainer" style={{ maxWidth: '100%' }}>
                <div className="col-xs-12">
                    <ol
                        style={{
                            textAlign: 'left',
                            'marginTop': '75px',
                        }}
                        className="breadcrumb"
                    >
                        <li><a href="http://eposgestofinal.pt/index.php">Portal</a></li>
                        <li><a href="/guias">Guias</a></li>
                        <li className="active">
                            <span>{doc}</span>
                        </li>
                    </ol>
                </div>
            </div>
            <EmpresaSelect />
            {loaded && (
            <div
                className="fullContainer" style={{ maxWidth: '100%' }}
            >
                <div
                    className="col-sm-12"
                    style={{ textAlign: 'center' }}
                >
                    <div className="panel panel-default">
                        <div
                            className="panel-heading"
                            style={{
                                fontWeight: 'bold',
                                fontSize: '15pt',
                            }}
                        >
                            Guia
                        </div>
                        <form
                            style={{
                                textAlign: 'left',
                            }}
                            className="container-fluid"
                        >
                            <table className="table table-bordered table-hover table-sortable">
                                <thead>
                                    <tr >
                                        <th className="text-center">Data</th>
                                        <th className="text-center">Posto</th>
                                        <th className="text-center">Série</th>
                                        <th className="text-center">TPV</th>
                                        <th className="text-center">Cliente</th>
                                        <th className="text-center">Matricula</th>
                                        <th className="text-center">Km Atuais</th>
                                        <th className="text-center">Requisição</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '250px' }}>
                                            {date}
                                        </td>
                                        <td>{lines[0]['posto_name']}</td>
                                        <td>{serie}</td>
                                        <td>{tpv}</td>
                                        <td>{customer_name}</td>
                                        <td>{matricula}</td>
                                        <td>{kilometros}</td>
                                        <td>{requisicao}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <h3>Items</h3>
                            <table className="table table-bordered table-hover table-sortable" id="tab_logic">
                                <thead>
                                    <tr >
                                        <th className="text-center">Produto</th>
                                        <th className="text-center">Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lines.map((line) => (
                                        <tr>
                                            <td>
                                                [{line['idproduct']}] {line['product_name']}
                                            </td>
                                            <td>
                                                {line['quantity']}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Button
                            action={this.handleFormCancel}
                            type={"secondary"}
                            title={"voltar"}
                            style={buttonStyle}
                            />
                            {/* Clear the form */}
                        </form>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px"
};

export default withCookies(Form);
