import React, { Component } from 'react';
import classNames from 'classnames';

import './POSStatus.css';

class POSStatus extends Component {
    render () {
        const { status, title, message, action } = this.props;
        const icon =
            (status === 'success' && 'fa-check') ||
            (status === 'error' && 'fa-times');
        return (
            <div className={classNames('status-card', status)}>
                <div className="status-card-icon">
                    <i className={ classNames('fas', icon, 'status-card-i') }></i>
                </div>
                {title && (<h1 className="status-card-h1">{title}</h1>)}
                {message && (<p className="status-card-p">{message}</p>)}
                {action && (<br />)}
                {action && (<p className="status-card-p">{action}</p>)}
            </div>

        );
    }
}

export default POSStatus;