import React, { Component } from 'react';

// Components
import POSMenu from '@components/POSMenu'
import Cards from '@components/Cards'

class Page extends Component {

    constructor(props) {
        super(props);

        this.state = {
            navigation: [{
                icon: 'fa-users',
                text: 'Clientes',
                link: '/pos/customers',
                condition: '[1,3,10].includes(profile.memberType) && profile.permissions.includes("pos.customers")',
            }, {
                icon: 'fa-ticket-alt',
                text: 'Senhas',
                link: '/pos/tickets',
                condition: '[3,4].includes(profile.memberType) && profile.permissions.includes("pos.tickets")',
            }, {
                icon: 'fa-boxes',
                text: 'Inventário',
                link: '/pos/inventory',
                condition: '[1,3,4,10].includes(profile.memberType) && profile.permissions.includes("pos.inventory")',
            }, {
                customIcon: "https://eposgestofinal.pt/site/images/counter.png",
                text: 'Contadores',
                link: '/pos/counters',
                condition: '[1,3,4,10].includes(profile.memberType) && profile.permissions.includes("pos.counters")',
            }],
        };
    }
    
    render () {
        const { navigation } = this.state;

        return (
            <>
                <POSMenu { ...this.props } />
                <section className="main-section">
                    <Cards items={ navigation } />
                </section>
            </>
        );
    }
}

export default Page;