import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

// Components
import Input from "@components/Input";
import TextArea from "@components/TextArea";
import Checkbox from "@components/CheckBox";

import UserContext from '@components/context/userContext';

import { EventBus as eventBus } from "@components/EventBus";

class Page extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        const idCustomer = this.props.idcustomer;

        this.state = {
            name: '',
            email: '',
            nif: '',
            phone: '',
            address: '',
            birthday: '',
            gdpr: false,
            edit: idCustomer,
            loaded: false,
        };

        this.loadFunction = this.loadFunction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    loadFunction() {
        const profile = this.context;
        const { idcustomer } = this.props;

        if (!this.state.loaded && this.state.edit) {
            axios.get(`https://eposgestofinal.pt/api/customers/getById.php?idempresa=${profile.idEmpresa}&idcustomer=${idcustomer}`)
                .then(response => {
                    const customers = response.data !== 'NOK' ? response.data[0] : [];

                    this.setState({ ...customers, gdpr: customers.gdpr === 't', loaded: true });
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Cliente');
                });
        }
    }

    handleChange(e, callback) {
        let value = e.target.value;
        let name = e.target.name;
        
        this.setState({
            [name]: value,
        });
    }
    
    handleCheckboxChange(e, callback) {
        let value = e.target.checked;
        let name = e.target.name;

        this.setState({
            [name]: value,
        });
    };

    handleFormSubmit(e) {
        e.preventDefault();
        const profile = this.context;
        const { idcustomer } = this.props;
        const { edit } = this.state;
        
        axios.post('https://eposgestofinal.pt/api/customers/set.php', {
            name: this.state.name,
            nif: this.state.nif,
            email: this.state.email,
            phone: this.state.phone,
            address: this.state.address,
            birthday: this.state.birthday,
            gdpr: `${this.state.gdpr}`,
            idempresa: profile.idEmpresa,
            idcustomer: idcustomer,
        })
        .then((response) => {
            NotificationManager.success('Cliente submetido com sucesso!', 'Cliente');
            if (!edit) {
                eventBus.$emit("Modal-edit_customer", { isOpen: false })
            }
        })
        .catch(error => {
            NotificationManager.error(error.message, 'Cliente');
        });
    }

    render () {
        const { name, nif, email, phone, address, gdpr, edit } = this.state;
        this.loadFunction()

        return (
            <fieldset>
                <div className="form-group">
                    <label className="col-md-3 control-label">Nome</label>  
                    <div className="col-md-9 inputGroupContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="fas fa-user"></i></span>
                            <Input
                                inputType={"text"}
                                name={"name"}
                                value={name}
                                handleChange={this.handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-3 control-label">NIF</label>  
                    <div className="col-md-9 inputGroupContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="fas fa-id-card"></i></span>
                            <Input
                                inputType={"text"}
                                name={"nif"}
                                value={nif}
                                handleChange={this.handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-3 control-label">E-Mail</label>  
                    <div className="col-md-9 inputGroupContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="fas fa-envelope"></i></span>
                            <Input
                                inputType={"text"}
                                name={"email"}
                                value={email}
                                handleChange={this.handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-3 control-label">Telefone</label>  
                    <div className="col-md-9 inputGroupContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="fas fa-phone"></i></span>
                            <Input
                                inputType={"text"}
                                name={"phone"}
                                value={phone}
                                handleChange={this.handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-3 control-label">Morada</label>  
                    <div className="col-md-9 inputGroupContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="fas fa-home"></i></span>
                            <TextArea
                                rows={5}
                                value={address}
                                name={"address"}
                                handleChange={this.handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-md-3 control-label">RGPD</label>
                    <div className="col-md-9">
                        <Checkbox
                            name={"gdpr"}
                            checked={gdpr}
                            handleChange={this.handleCheckboxChange}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-xs-12">
                        <button type="button" onClick={this.handleFormSubmit} style={{ width: '100%' }} className="btn btn-success" >{edit ? 'Guardar' : 'Criar'}</button>
                    </div>
                </div>
            </fieldset>
        );
    }
}

export default withCookies(Page);