import React from 'react';
import classNames from 'classnames';
import Submenu from './submenu';

import UserContext from '../context/userContext';

import { EventBus as eventBus } from "../EventBus";

const submenuToolsLojista = (profile) => [
    {
        condition: profile['memberType'] > 0,
        title: 'Agenda',
        url: '/agenda',
    },
    {
        condition: profile['memberType'] > 0,
        title: 'Calendário',
        url: '/calendar',
    },
];

const submenuTools = (profile) => [
    {
        condition: profile['memberType'] > 0,
        title: 'Produtos',
        url: '/products',
    },
    {
        condition: profile['memberType'] > 0,
        title: 'Etiquetas',
        url: '/pricetags',
    },
    {
        condition: profile['memberType'] > 0,
        title: 'Existências em stock',
        url: '/stocks',
    },
    {
        condition: profile['memberType'] > 0 && profile['combustivel'],
        title: 'Preços Combustiveis',
        url: '/prices/combustivel',
    },
    {
        condition: profile['memberType'] > 0 && profile['combustivel'],
        title: 'Impostos',
        url: '/taxes',
    },
    {
        condition: profile['memberType'] > 0,
        title: 'Compras',
        url: '/orders',
    },
    {
        condition: profile['memberType'] > 0,
        title: 'Vendas',
        url: '/sales',
    },
    {
        condition: profile['memberType'] > 0,
        title: 'Guias de Transporte',
        url: '/guias',
    },
    {
        condition: profile['memberType'] > 0,
        title: 'Fornecedores',
        url: '/providers',
    },
    {
        condition: profile['memberType'] > 0,
        title: profile.permissions.includes("site.newclientes") ? 'Clientes (beta)' : 'Clientes',
        url: profile.permissions.includes("site.newclientes") ?  '/clientes' : '/customers',
    },
    {
        condition: profile['memberType'] > 0,
        title: 'Parceiros',
        url: '/partners',
    },
    {
        condition: profile['memberType'] > 0,
        title: 'Sondagens',
        url: '/estimates',
    },
    {
        condition: profile['memberType'] === 10,
        title: 'Pump Server Apollo',
        url: '/psa',
    },
    {
        condition: profile['memberType'] > 0,
        title: 'Veeder-Root',
        url: '/veeder-root',
    },
    {
        condition: profile['memberType'] > 0,
        title: 'Senhas',
        url: '/tickets',
    },
    {
        condition: profile['memberType'] > 0,
        title: 'Declaração de Caixa',
        url: 'https://eposgestofinal.pt/index.php?page=turno',
    },
    {
        condition: profile['memberType'] > 0,
        title: 'XML ctb',
        url: 'https://eposgestofinal.pt/index.php?page=saft',
    },
    {
        condition: profile['memberType'] > 0,
        title: 'Séries',
        url: '/series',
    },
    {
        condition: profile['memberType'] > 0,
        title: 'Agenda',
        url: '/agenda',
    },
    {
        condition: profile['memberType'] > 0,
        title: 'Calendário',
        url: '/calendar',
    },
    {
        condition: profile['memberType'] > 0,
        title: 'Torniquete',
        url: '/tourniquet',
    },
    {
        condition: profile['memberType'] > 0,
        title: 'Downloads',
        url: 'https://eposgestofinal.pt/index.php?page=downloads',
    },
];

const submenuDocuments = (profile) => [
    {
        condition: profile['memberType'] > 0,
        title: 'Documentos Pendentes',
        url: 'https://eposgestofinal.pt/index.php?page=cc',
    },
];

const adminTools = (profile) => [
    {
        condition: profile['memberType'] > 9,
        title: 'Utilizadores',
        url: 'https://eposgestofinal.pt/index.php?page=users_management',
    },
    {
        condition: profile['memberType'] > 9,
        title: 'FTP',
        url: 'https://eposgestofinal.pt/index.php?page=ftp_admin',
    },
    {
        condition: profile['memberType'] > 9,
        title: 'POS',
        url: 'https://eposgestofinal.pt/index.php?page=pos_admin',
    },
    {
        condition: profile['memberType'] > 9,
        title: 'Site',
        url: 'https://eposgestofinal.pt/index.php?page=site_admin',
    },
]

const configTools = (profile) => [
    {
        condition: [1, 10].includes(profile['memberType']),
        title: 'Gestão de utilizadores',
        url: '/employees',
    },
    {
        condition: profile['memberType'] > 0,
        title: 'Gestão de Categorias',
        url: 'https://eposgestofinal.pt/index.php?page=category_management',
    },
]

class Menu extends React.Component {
    static contextType = UserContext;

    componentDidMount() {
        eventBus.$emit("footer", { company: { name: "Walk In DEV", url: "https://www.walkindev.com/" } });
    }

    render () {
        const { loadFunction, ePOSOptions = { menu: true } } = this.props;

        const profile = this.context;

        if (loadFunction) {
            loadFunction(profile);
        }

        const exitDemo = () => {
            // cookies.set('demo', 'false', { path: '/' });
            if (window.location.hostname !== "localhost") {
                window.location.href = `https://react.eposgestofinal.pt${window.location.pathname}`;
            } else {
                window.location.reload();
            }
        };

        if (!ePOSOptions.menu) {
            return null;
        }

      return (
        <div>
            <link href="https://eposgestofinal.pt/site/css/menu.css" rel="stylesheet" />
            <nav role="navigation"
                className={classNames({
                    'navbar': true,
                    'navbar-default': true,
                    'navbar-fixed-top': true,
                })}
            >
                {profile['demo'] === 'true' || window.location.hostname === 'demo-react.eposgestofinal.pt' ? (
                    <div style={{ width: '100%', backgroundColor: 'yellow' }}>DEMO MODE (<button type="button" className="btn btn-link" onClick={exitDemo}>Sair</button>)</div>
                ) : null}
                <div className={classNames({ 'navbar-header': true })}>
                    <button type="button" data-target="#navbarCollapse" data-toggle="collapse" className={classNames({ 'navbar-toggle': true })}>
                    <span className={classNames({ 'sr-only': true })}>
                        Toggle navigation
                    </span>
                    <span className={classNames({ 'icon-bar': true })}></span>
                    <span className={classNames({ 'icon-bar': true })}></span>
                    <span className={classNames({ 'icon-bar': true })}></span>
                    </button>
                    <a href="https://eposgestofinal.pt/" className={classNames({ 'navbar-brand': true })}>ePOS</a>
                </div>
                <div id="navbarCollapse"
                    className={classNames({
                        'collapse': true,
                        'navbar-collapse': true
                    })}
                >
                    <ul
                        className={classNames({
                            'nav': true,
                            'navbar-nav': true
                        })}
                        style={{
                            fontSize: '10pt'
                        }}
                    >
                        {profile['memberType'] > 0 && (
                            <>
                                {[1,3,4,10].includes(profile['memberType']) && (
                                    <li>
                                        <a href="/pos" target="_blank">POS</a>
                                    </li>
                                )}
                                {[4].includes(profile['memberType']) && (
                                    <Submenu
                                        title={ 'Ferramentas' }
                                        width={ '110px' }
                                        submenus={ submenuToolsLojista(profile) }
                                    />
                                )}
                                {[1,2,10].includes(profile['memberType']) && (
                                    <li className={classNames({ 'active': profile['page'] === 'statistics' })}>
                                        <a href="https://eposgestofinal.pt/index.php?page=statistics">Estatísticas</a>
                                    </li>
                                )}
                                {[1,3,10].includes(profile['memberType']) && (
                                    <Submenu
                                        title={ 'Ferramentas' }
                                        width={ '110px' }
                                        submenus={ submenuTools(profile) }
                                    />
                                )}
                                {[1,3,10].includes(profile['memberType']) && (
                                    <Submenu
                                        title={ 'Documentos' }
                                        width={ '110px' }
                                        submenus={ submenuDocuments(profile) }
                                    />
                                )}
                                {[1,3,10].includes(profile['memberType']) && (
                                    <li className={classNames({ 'active': profile['page'] === 'assistencias' })}>
                                        <a href="https://eposgestofinal.pt/index.php?page=assistencias">Tickets</a>
                                    </li>
                                )}
                            </>
                        )}
                        {[1, 10].includes(profile['memberType']) && (
                            <Submenu
                                title={ 'Configuração' }
                                width={ '115px' }
                                submenus={ configTools(profile) }
                            />
                        )}
                        {[10].includes(profile['memberType']) && (
                            <Submenu
                                title={ 'Administração' }
                                width={ '120px' }
                                submenus={ adminTools(profile) }
                            />
                        )}
                    </ul>
                    <ul
                        className={classNames({
                            'nav': true,
                            'navbar-nav': true,
                            'navbar-right': true
                        })}
                        style={{
                            marginRight: '5px',
                            textAlign: 'right',
                        }}
                    >
                        <li>
                            <a href="/counters">Contadores</a>
                        </li>
                        {profile['prizes'] === 't' && [1, 3, 10].includes(profile['memberType']) && (
                            <li className={classNames({ 'active': profile['page'] === 'premios' })}>
                                <a href="https://eposgestofinal.pt/index.php?page=premios">Prémios</a>
                            </li>
                        )}
                        <li>
                            <table
                                style={{
                                    height: '100%'
                                }}
                            >
                                <tbody>
                                    <tr
                                        style={{
                                            height: '100%'
                                        }}
                                    >
                                        {profile['pontos'] > 0 && [2].includes(profile['memberType']) && (
                                            <td rowSpan="2"
                                                style={{
                                                    height: '100%',
                                                    'padding-left': '10px'
                                                }}
                                            >
                                                <table
                                                    style={{
                                                        'textAlign': 'center',
                                                        'borderRight': '1px solid black',
                                                        'borderLeft': '1px solid black',
                                                    }}
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    'padding-right': '10px',
                                                                    'padding-left': '10px',
                                                                }}
                                                            >
                                                                <b>Pontos</b>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    'padding-right': '10px',
                                                                    'padding-left': '10px'
                                                                }}
                                                                id="pontosempresa"
                                                            >
                                                                { profile['pontos'] }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        )}
                                        <td rowSpan="2"
                                            style={{
                                                'padding': '5px',
                                                'cursor': 'pointer'
                                            }}
                                        ></td>
                                        <td rowSpan="2"
                                            style={{
                                                'padding': '5px',
                                                'cursor': 'pointer'
                                            }}
                                        >
                                            <img
                                                style={{
                                                    'border': '2px solid #afafaf',
                                                    'height': '40px'
                                                }}
                                                onClick={() => window.location.href = 'https://eposgestofinal.pt/index.php?page=profile' }
                                                src={ 'https://eposgestofinal.pt/' + (profile['avatar'] || (profile['idemployee'] ? 'site/images/profile.jpg' : 'site/images/suitcase.png')) }
                                                className={classNames({ 'img-rounded': true })}
                                                alt={ profile['name'] }
                                            />
                                        </td>
                                        <td
                                            valign="bottom"
                                            style={{
                                                'textAlign': 'center',
                                                'cursor': 'pointer'
                                            }}
                                                onClick={() => window.location.href = 'https://eposgestofinal.pt/index.php?page=profile' }
                                        >
                                            <table style={{ fontSize: '12px' }}>
                                                <tr>
                                                    <td>
                                                        { profile['name'] }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <a
                                                            className={classNames({
                                                                'logout': true,
                                                            })}
                                                            href="https://eposgestofinal.pt/index.php?logout=1"
                                                        >
                                                            Terminar Sessão
                                                        </a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
      );
    }
  }

export default Menu;