import * as React from "react";
import QrReader from "react-qr-reader";

export default class QRReader extends React.Component {
    render() {
        return (
            <QrReader
                delay={300}
                onScan={this.props.onScan}
                style={{ width: '100%' }}
            />
        );
    }
}