import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import axios from 'axios';

// Components
import Menu from '@components/Menu';
import PriceAndLiter from '@components/PriceAndLiter';

import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';

const pastSeconds = (seconds) => {
    var t = new Date();
    t.setSeconds(t.getSeconds() - seconds);

    return t.getTime();
}

class Page extends Component {
    constructor(props) {
        super(props);

        const { match: { params } } = this.props;

        this.state = {
            loaded: false,
            idstation: params.idstation,
            name: '',
            available: false,
            servers: []
        };
        this.loadFunction = this.loadFunction.bind(this);
        this.getServers = this.getServers.bind(this);
    }
    
    getServers({ idEmpresa, adminProfile }) {
        const { idstation } = this.state;

        if (adminProfile) {
            return axios.get(`https://eposgestofinal.pt/api/raspberry/getById.php?idraspberry=${idstation}`)
                .then(response => {
                    const raspberry = response.data !== 'NOK' ? response.data : {};

                    return axios.get(`https://eposgestofinal.pt/api/stations/servers/get.php?idempresa=${raspberry.idempresa}&idstation=${idstation}`)
                        .then(response => {
                            const station = response.data !== 'NOK' ? response.data : {};

                            const lastSeconds = pastSeconds(6);

                            this.setState({ name: station.name, available: new Date(station.raspberry['checked_at']).getTime() > lastSeconds, servers: station.servers, loaded: true });
                        })
                        .catch(error => {
                            console.error(error);
                        });
                })
                .catch(error => {
                    console.error(error);
                });
        } else {
            return axios.get(`https://eposgestofinal.pt/api/stations/servers/get.php?idempresa=${idEmpresa}&idstation=${idstation}`)
                .then(response => {
                    const station = response.data !== 'NOK' ? response.data : {};

                    const lastSeconds = pastSeconds(6);

                    this.setState({ name: station.name, available: new Date(station.raspberry['checked_at']).getTime() > lastSeconds, servers: station.servers, loaded: true });
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }

    loadFunction(profile) {
        if (!this.state.loaded) {
            if (window.autoRefresh) {
                clearInterval(window.autoRefresh);
            }
            this.getServers(profile)
                .then(() => {
                    window.autoRefresh = setInterval(() => {
                        this.getServers(profile);
                    }, 5000)
                })
        }
    }

    getLedColor(nozzle, server) {
        if (server.nozzle === nozzle.nozzle) {
            switch (server.status) {
                case 'busy':
                    return 'yellow'
            
                case 'ongoing':
                    return 'blue';
                default:
                    return 'green';
            }
        }

        return 'green';
    }

    render () {
        const { location, history } = this.props;
        const {
            name,
            servers,
            available,
        } = this.state;

        return (
            <div>
                <Menu loadFunction={ this.loadFunction } location={ location } history={ history } />
                <div className="fullContainer" style={{ maxWidth: '100%' }}>
                    <div className="col-xs-12">
                        <ol
                            style={{
                                textAlign: 'left',
                                'marginTop': '75px',
                            }}
                            className="breadcrumb"
                        >
                            <li><a href="http://eposgestofinal.pt/index.php">Portal</a></li>
                            <li><a href="/stations">Postos de Combustível</a></li>
                            <li className="active">
                                { name }
                            </li>
                        </ol>
                    </div>
                </div>
                <div
                    style= {{
                        display: 'inline-flex',
                        flexDirection: 'row',
                        maxWidth: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexWrap: 'wrap'
                    }}
                    className="fullContainer"
                >
                    { servers.map((server) => {
                        if (!server.nozzles.length) {
                            return null;
                        }
                        const maincolor = available && server.enable === 't' ? 'green' : 'red';
                        const apollocolor = available && ((server.client || { connections: [] }).connections || []).length ? 'green' : 'red';

                        return (
                            <div
                                style= {{
                                    display: 'inline-flex',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    border: `4px solid ${ maincolor }`,
                                    borderRadius: '15px',
                                    margin: '30px 30px 0px 30px',
                                    minHeight: '335px'
                                }}
                            >
                                <div
                                    style= {{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        height: '50px',
                                        width: '100%'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            height: '50px',
                                            color: maincolor,
                                            width: '50%',
                                            justifyContent: 'flex-start'
                                        }}
                                    >
                                        <LocalGasStationIcon style={{ height: '50px', width: '50px' }} />
                                        <span style= {{ marginLeft: '15px', alignSelf: 'center' }}>{server.name}</span>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '50px',
                                            color: apollocolor,
                                            width: '50%',
                                            justifyContent: 'flex-end',
                                            paddingRight: '20px'
                                        }}
                                    >Apollo ({ (server.client || {}).port })</div>
                                </div>
                                <PriceAndLiter value={ !server.value || server.value.length === 0 ? '0000000000000' : server.value } />
                                <div
                                    style= {{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        margin: '20px',
                                        minWidth: '213px',
                                        justifyContent: 'center'
                                    }}
                                >
                                    {server.nozzles.map((nozzle) => {
                                        const ledColor = maincolor === 'red' ? 'red' : this.getLedColor(nozzle, server);
                                        const currentNozzle = server.nozzle === nozzle.nozzle;

                                        return (
                                            <div className="led-box">
                                                <div className={ `led-${ledColor}` }></div>
                                                {currentNozzle ? (<p><b>{ nozzle["product_name"] }</b></p>) : (<p>{ nozzle["product_name"] }</p>)}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    }) }
                </div>
            </div>
        );
    }
}

export default withRouter(Page);