import React from "react";
import Print from "@components/Print";
import QRShow from "@components/QR/show";

class Example extends React.Component {
  render() {
    return (
        <Print
            {...this.props}
            button="Imprimir senhas"
            printComponent={QRShow}
            ref={el => (this.componentRef = el)}
            avoidLandscape={true}
        />
    );
  }
}

export default Example;
