import React from 'react';
import classNames from 'classnames';

class Submenu extends React.Component {

    render () {
        return (
            <li
                className={classNames({
                    'visible-xs': true,
                    'dropdown': true,
                })}
                id="menu1"
            >
                <hr />
            </li>
        )
    }
}

export default Submenu;