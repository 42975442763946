import React, {Component} from 'react';

// Pages
import Router from './Router'

class Page extends Component {
    render () {
        return (
            <div>
                <Router />
            </div>
        );
    }
}

export default Page;