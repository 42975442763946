import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import axios from "axios";

// Components
import Menu from "@components/Menu";

/* Import Components */
import Input from "@components/Input";
import TextArea from "@components/TextArea";
import Button from "@components/Button";
import AutoComplete from "@components/AutoComplete";
import { MDBDataTable } from "mdbreact";
import { MdLaunch } from "react-icons/md";
import ReactTable from "@components/ReactTable";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { threeDecimal, valueWithIva, valueWithoutIva } from "@utils/format.js";

class Form extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    const {
      match: { params },
      type,
    } = this.props;

    this.state = {
      loaded: false,
      name: "",
      email: "",
      phone: "",
      address: "",
      idempresa: this.props.cookies.get("member_idempresa"),
      idpartner: type === "edit" && params.idpartner,
      edit: type === "edit",
      prices: {
        columns: [
          {
            label: "Produto",
            field: "name",
          },
          {
            label: "P.C. S/IVA",
            field: "costprice",
          },
          {
            label: "P.C. C/IVA",
            field: "costprice_iva",
          },
          {
            label: "PVP s/IVA",
            field: "price",
          },
          {
            label: "PVP c/IVA",
            field: "price_iva",
          },
          {
            label: "Ganho s/IVA",
            field: "comission",
          },
          {
            label: "Ganho c/IVA",
            field: "comission_iva",
          },
          {
            label: "Preço a cobrar s/IVA",
            field: "finalprice",
          },
          {
            label: "Preço a cobrar c/IVA",
            field: "finalprice_iva",
          },
        ],
        rows: [],
      },
      dataChanged: -1,
      emails: {
        columns: [
          {
            Header: "Data",
            accessor: "emailAt",
          },
          {
            Header: "Assunto",
            accessor: "subject",
          },
          {
            Header: "Ver email",
            accessor: "view",
          },
        ],
        rows: [],
        loading: true,
      },
      categories: [],
      search: "",
      idcategory: { value: "", label: "TODAS" },
    };

    this.handleEmailSend = this.handleEmailSend.bind(this);
    this.handleTableSubmit = this.handleTableSubmit.bind(this);
    this.handleComissionUpdate = this.handleComissionUpdate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.loadFunction = this.loadFunction.bind(this);
  }

  loadFunction() {
    if (!this.state.loaded) {
      if (this.props.type === "edit") {
        axios
          .get(
            `https://eposgestofinal.pt/api/partners/getById.php?idempresa=${this.props.cookies.get(
              "member_idempresa"
            )}&idpartner=${this.state.idpartner}`
          )
          .then((response) => {
            const partners = response.data !== "NOK" ? response.data[0] : [];

            this.setState({ ...partners, loaded: true });
          })
          .catch((error) => {
            console.error(error);
          });

        axios
          .get(
            `https://eposgestofinal.pt/api/categories/get.php?idempresa=${this.props.cookies.get(
              "member_idempresa"
            )}`
          )
          .then((response) => {
            const categories = response.data !== "NOK" ? response.data : [];

            this.setState({
              categories: [
                this.state.idcategory,
                ...categories.map((category) => ({
                  value: category.idcategoria,
                  label: `[${category.idcategoria}] ${category.descricaocategoria}`,
                })),
              ],
              loaded: true,
            });
          })
          .catch((error) => {
            console.error(error);
          });

        axios
          .get(
            `https://eposgestofinal.pt/api/partners/comissions/get.php?idempresa=${this.props.cookies.get(
              "member_idempresa"
            )}&idpartner=${this.state.idpartner}`
          )
          .then((response) => {
            const comissions = response.data !== "NOK" ? response.data : [];

            axios
              .get(
                `https://eposgestofinal.pt/api/products/get.php?idempresa=${this.props.cookies.get(
                  "member_idempresa"
                )}`
              )
              .then((response) => {
                const products = response.data !== "NOK" ? response.data : [];

                const enrichedProducts = products.map((product) => {
                  const comission = comissions.find(
                    (value) => value.idproduct === product.idproduct
                  );

                  return {
                    ...product,
                    name: `[${product.idproduct}] ${product.nome}`,
                    costprice: threeDecimal(product.costprice),
                    costprice_iva: threeDecimal(
                      valueWithIva(product.costprice, product.iva)
                    ),
                    price: threeDecimal(product.price),
                    price_iva: threeDecimal(
                      valueWithIva(product.price, product.iva)
                    ),
                    comission:
                      comission &&
                      threeDecimal(parseFloat(comission.comission || 0)),
                    comission_iva:
                      comission &&
                      threeDecimal(
                        valueWithIva(
                          parseFloat(comission.comission || 0),
                          product.iva
                        )
                      ),
                    finalprice: comission
                      ? threeDecimal(
                          parseFloat(comission.comission || 0) +
                            parseFloat(product.costprice || 0)
                        )
                      : threeDecimal(parseFloat(product.costprice || 0)),
                    finalprice_iva: comission
                      ? threeDecimal(
                          valueWithIva(
                            parseFloat(comission.comission || 0) +
                              parseFloat(product.costprice || 0),
                            product.iva
                          )
                        )
                      : threeDecimal(
                          valueWithIva(
                            parseFloat(product.costprice || 0),
                            product.iva
                          )
                        ),
                  };
                });

                this.setState({
                  prices: { ...this.state.prices, rows: enrichedProducts },
                  loaded: true,
                  dataChanged: 0,
                });
              })
              .catch((error) => {
                console.error(error);
              });
          })
          .catch((error) => {
            console.error(error);
          });

        axios
          .get(
            `https://eposgestofinal.pt/api/partners/comissions/getEmails.php?idempresa=${this.props.cookies.get(
              "member_idempresa"
            )}&idpartner=${this.state.idpartner}`
          )
          .then((response) => {
            const emails = response.data !== "NOK" ? response.data : [];

            const enrichedEmails = emails.map(
              ({ email_at, subject, message }) => ({
                emailAt: email_at,
                subject,
                view: (
                  <MdLaunch
                    style={{ fontSize: "15px", cursor: "pointer" }}
                    onClick={() =>
                      window
                        .open("url", "Email", "height=300,width=500")
                        .document.write(message)
                    }
                  />
                ),
              })
            );

            this.setState({
                emails: {
                ...this.state.emails,
                rows: enrichedEmails,
                loading: false,
              },
              loaded: true,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.setState({ loaded: true });
      }
    }
  }

  handleComissionUpdate = (idproduct, comissionInfo) => {
    const { prices } = this.state;
    const newData = prices.rows.map((row) => {
      if (row.idproduct === idproduct) {
        const comission = comissionInfo.comission || row.comission;
        return {
          ...row,
          ...comissionInfo,
          finalprice: threeDecimal(
            parseFloat(row.costprice || 0) + parseFloat(comission || 0)
          ),
          finalprice_iva: threeDecimal(
            valueWithIva(
              parseFloat(row.costprice || 0) + parseFloat(comission || 0),
              row.iva
            )
          ),
        };
      }
      return row;
    });

    this.setState({
      prices: { ...this.state.prices, rows: newData },
      dataChanged: 1,
    });
  };

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({
      [name]: value,
    });
  }

  handleTableSubmit(e) {
    e.preventDefault();

    axios
      .post("https://eposgestofinal.pt/api/partners/comissions/set.php", {
        comissions: this.state.prices.rows.filter(
          (product) => product.comission
        ),
        idempresa: this.state.idempresa,
        idpartner: this.state.idpartner,
      })
      .then((response) => {
        this.setState({
          search: "",
          idcategory: { value: "" },
          dataChanged: 0,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleEmailSend(e) {
    e.preventDefault();
    confirmAlert({
      title: "Confirmação",
      message: `Tem a certeza que deseja enviar estes dados por email para o parceiro ${this.state.name} <${this.state.email}>?`,
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            const { history } = this.props;

            axios
              .get(
                `https://eposgestofinal.pt/api/partners/comissions/sendEmail.php?idempresa=${this.props.cookies.get(
                  "member_idempresa"
                )}&idpartner=${this.state.idpartner}`
              )
              .then((response) => {
                history.push("/partners");
              })
              .catch((error) => {
                console.error(error);
              });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  }

  handleFormSubmit(e) {
    const { history } = this.props;
    e.preventDefault();

    axios
      .post("https://eposgestofinal.pt/api/partners/set.php", {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        address: this.state.address,
        idempresa: this.state.idempresa,
        idpartner: this.state.idpartner,
      })
      .then((response) => {
        history.push("/partners");
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleFormCancel() {
    const { history } = this.props;
    history.push("/partners");
  }

  render() {
    const { location, history } = this.props;
    const {
      edit,
      loaded,
      name,
      email,
      phone,
      address,
      prices,
      dataChanged,
      emails,
      idcategory,
      categories,
      search,
    } = this.state;
    const cookies = new URLSearchParams(location.search);

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li>
                <a href="/partners">Parceiros</a>
              </li>
              <li className="active">
                {edit ? <span>{name}</span> : <span>Novo Parceiro</span>}
              </li>
            </ol>
          </div>
        </div>
        <Tabs>
          <TabList>
            <Tab>Detalhes</Tab>
            {dataChanged !== -1 && <Tab>Tabela de preços</Tab>}
            <Tab>Emails enviados</Tab>
          </TabList>

          <TabPanel>
            {loaded && (
              <div className="fullContainer" style={{ maxWidth: "100%" }}>
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <div className="panel panel-default">
                    <div
                      className="panel-heading"
                      style={{
                        fontWeight: "bold",
                        fontSize: "15pt",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        Detalhes
                      </div>
                    </div>
                    <form
                      style={{
                        textAlign: "left",
                        width: "100%",
                      }}
                      className="container-fluid"
                      onSubmit={this.handleFormSubmit}
                    >
                      <table className="table table-bordered table-hover table-sortable">
                        <thead>
                          <tr>
                            <th className="text-center">Nome</th>
                            <th className="text-center">Telefone</th>
                            <th className="text-center">Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Input
                                inputType={"text"}
                                name={"name"}
                                value={name}
                                handleChange={this.handleChange}
                              />
                            </td>
                            <td>
                              <Input
                                inputType={"number"}
                                name={"phone"}
                                value={phone}
                                handleChange={this.handleChange}
                              />
                            </td>
                            <td>
                              <Input
                                inputType={"email"}
                                name={"email"}
                                value={email}
                                handleChange={this.handleChange}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <TextArea
                        title={"Morada"}
                        rows={5}
                        value={address}
                        name={"address"}
                        handleChange={this.handleChange}
                      />
                      {/* About you */}
                      <Button
                        action={this.handleFormSubmit}
                        type={"primary"}
                        title={"Submeter"}
                        style={buttonStyle}
                      />
                      {/*Submit */}
                      <Button
                        action={this.handleFormCancel}
                        type={"secondary"}
                        title={"cancelar"}
                        style={buttonStyle}
                      />
                      {/* Clear the form */}
                    </form>
                  </div>
                </div>
              </div>
            )}
          </TabPanel>
          {dataChanged !== -1 && (
            <TabPanel>
              <div className="fullContainer" style={{ maxWidth: "100%" }}>
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <div className="panel panel-default">
                    <div
                      className="panel-heading"
                      style={{
                        fontWeight: "bold",
                        fontSize: "15pt",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        Tabela de preços
                      </div>
                    </div>
                    {loaded && (
                      <div>
                        <table className="table table-bordered table-hover table-sortable">
                          <thead>
                            <tr>
                              <th className="text-center">Família</th>
                              <th className="text-center">Pesquisa</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ width: "250px" }}>
                                <AutoComplete
                                  name={"idcategory"}
                                  value={idcategory}
                                  handleChange={this.handleChange}
                                  options={categories}
                                />
                              </td>
                              <td style={{ width: "250px" }}>
                                <Input
                                  inputType={"text"}
                                  name={"search"}
                                  value={search}
                                  handleChange={this.handleChange}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <MDBDataTable
                          data={{
                            ...prices,
                            rows: prices.rows
                              .filter((product) =>
                                idcategory.value
                                  ? product.idcategoria === idcategory.value
                                  : true
                              )
                              .filter(
                                (product) =>
                                  product.name
                                    .toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                  product.idproduct
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                              )
                              .map((product) => ({
                                name: `[${product.idproduct}] ${product.nome}`,
                                costprice: threeDecimal(product.costprice),
                                costprice_iva: threeDecimal(
                                  valueWithIva(product.costprice, product.iva)
                                ),
                                price: threeDecimal(product.price),
                                price_iva: threeDecimal(
                                  valueWithIva(product.price, product.iva)
                                ),
                                comission: (
                                  <input
                                    type="number"
                                    key={`comission_${product.idproduct}`}
                                    name={"comission"}
                                    value={product.comission}
                                    onChange={(e) =>
                                      this.handleComissionUpdate(
                                        product.idproduct,
                                        {
                                          comission: e.target.value,
                                          comission_iva: threeDecimal(
                                            valueWithIva(
                                              e.target.value,
                                              product.iva
                                            )
                                          ),
                                        }
                                      )
                                    }
                                  />
                                ),
                                comission_iva: (
                                  <input
                                    type="number"
                                    key={`comission_iva_${product.idproduct}`}
                                    name={"comission_iva"}
                                    value={product.comission_iva}
                                    onChange={(e) =>
                                      this.handleComissionUpdate(
                                        product.idproduct,
                                        {
                                          comission: threeDecimal(
                                            valueWithoutIva(
                                              e.target.value,
                                              product.iva
                                            )
                                          ),
                                          comission_iva: e.target.value,
                                        }
                                      )
                                    }
                                  />
                                ),
                                finalprice: product.comission
                                  ? threeDecimal(
                                      parseFloat(product.comission || 0) +
                                        parseFloat(product.costprice || 0)
                                    )
                                  : threeDecimal(
                                      parseFloat(product.costprice || 0)
                                    ),
                                finalprice_iva: product.comission
                                  ? threeDecimal(
                                      valueWithIva(
                                        parseFloat(product.comission || 0) +
                                          parseFloat(product.costprice || 0),
                                        product.iva
                                      )
                                    )
                                  : threeDecimal(
                                      valueWithIva(
                                        parseFloat(product.costprice || 0),
                                        product.iva
                                      )
                                    ),
                              })),
                          }}
                          striped
                          bordered
                          searching={false}
                        />
                        {dataChanged === 0 && (
                          <Button
                            action={this.handleEmailSend}
                            type={"primary"}
                            title={"Enviar Email"}
                            style={buttonStyle}
                          />
                        )}
                        {dataChanged === 1 && (
                          <Button
                            action={this.handleTableSubmit}
                            type={"primary"}
                            title={"Submeter"}
                            style={buttonStyle}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </TabPanel>
          )}
          <TabPanel>
            <div className="fullContainer" style={{ maxWidth: "100%" }}>
              <div className="col-sm-12" style={{ textAlign: "center" }}>
                <div className="panel panel-default">
                  <div
                    className="panel-heading"
                    style={{
                      fontWeight: "bold",
                      fontSize: "15pt",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      Emails enviados
                    </div>
                  </div>
                  {loaded && (
                    <div>
                      <ReactTable
                        columns={emails.columns}
                        sortable={true}
                        loading={emails.loading}
                        data={emails.rows}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px",
};

export default withCookies(Form);
