import React, { Component } from 'react';
import classNames from 'classnames';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

// Components
import ReactTable from '@components/ReactTable';
import Modal from '@components/Modal'
import Input from "@components/Input";
import Print from './print'
import Audit from './audit'

import 'react-smarttab/dist/index.css'

import './tickets.css';

import { twoDecimal, numberWithCommas } from '@utils/format.js'

import UserContext from '@components/context/userContext';

import { EventBus as eventBus } from "@components/EventBus";

class Page extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            table: {
                columns: [
                    {
                        Header: 'Sequencia',
                        accessor: 'idticket',
                    },
                    {
                      Header: 'Data',
                      accessor: 'created_at',
                    },
                    {
                      Header: 'Colaborador',
                      accessor: 'employee',
                    },
                    {
                      Header: 'Valor',
                      accessor: 'value',
                    },
                    {
                        Header: 'Usada',
                        accessor: 'used_at',
                    },
                    {
                        Header: 'Colaborador',
                        accessor: 'employeeread',
                    },
                    {
                        Header: 'Scans',
                        accessor: 'auditValues',
                    },
                    {
                        Header: 'Estado',
                        accessor: 'visualStatus',
                    },
                ],
                rows: [],
                loading: true,
            },
            total: 0,
            debit: 0,
            credit: 0,
            currency: '',
            search: '',
            loaded: false,
        };

        this.loadFunction = this.loadFunction.bind(this);
        this.filterData = this.filterData.bind(this);
    }

    loadFunction() {
        const profile = this.context;
        const { idcustomer } = this.props;

        if (!this.state.loaded) {
            axios.get(`https://eposgestofinal.pt/api/customers/tickets/getById.php?idempresa=${profile.idEmpresa}&idcustomer=${idcustomer}`)
                .then(response => {
                    const tickets = response.data !== 'NOK' ? response.data : [];
                    const { debit, credit } = tickets
                        .reduce((accum, { status, value }) => ({
                            debit: status === 'f' ? (accum.debit + parseFloat(value)) : accum.debit,
                            credit: accum.credit + parseFloat(value)
                        }), { debit: 0, credit: 0 });
                    const total = credit - debit;

                    this.setState({ table: { ...this.state.table, rows: tickets.map((ticket) => ({
                        ...ticket,
                        auditValues: ticket.audit.length ? (<i
                            onClick={() => eventBus.$emit("Modal-customers-audittickets", { header: "Auditoria", body: <Audit idticket={ticket.idticket}/> })}
                            className={classNames({
                                'fa': true,
                                'fa-eye': true,
                            })}
                            style={{
                                cursor: 'pointer',
                            }}
                        ></i>) : null,
                        visualStatus: ticket.status === 't' ? (<span className="badge badge-success">Disponível</span>) : (<span className="badge badge-danger">Resgatada</span>),
                    })), loading: false }, debit, credit, total, loaded: true });
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Senhas');
                });
            axios.get(`https://eposgestofinal.pt/api/config/currency/get.php?idempresa=${profile.idEmpresa}`)
                .then(response => {
                    const currency = response.data !== 'NOK' ? response.data[0].currency : '';

                    this.setState({ currency, loaded: true });
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Moeda');
                });
        }
    }

    filterData(searchTerm) {
        this.setState({ search: searchTerm });
    }

    render () {
        const { table, total, debit, credit, currency, search } = this.state;
        this.loadFunction()

        const filterSearch = search.split(" ");

        const tableRows = table.rows.length
            ? table.rows.filter((tickets) =>
                filterSearch.every(
                    (eachSearch) => {
                        return eachSearch === "" ||
                        tickets.idticket.toLowerCase().includes(eachSearch.toLowerCase()) ||
                        tickets.created_at.includes(eachSearch.toLowerCase()) ||
                        (tickets.used_at && tickets.used_at.includes(eachSearch.toLowerCase())) ||
                        tickets.employee.toLowerCase().includes(eachSearch.toLowerCase()) ||
                        (tickets.employeeread && tickets.employeeread.toLowerCase().includes(eachSearch.toLowerCase()))
                    }
                )
                )
            : [];

        return (
            <>
                <table style={{ marginBottom: 0 }} className="table table-bordered-only-inside">
                    <tbody>
                        <tr>
                            <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>Débito<h4>{currency} {numberWithCommas(twoDecimal(debit))}</h4></td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>Crédito<h4>{currency} {numberWithCommas(twoDecimal(credit))}</h4></td>
                            <td style={{ fontWeight: 'bolder', verticalAlign: 'middle', textAlign: 'right' }}>
                                Saldo Total<h4 style={{ color: total < 0 ? '#E03241' : '#1BA73F' }}>{currency} {numberWithCommas(twoDecimal(total))}</h4>
                                {total > 0 ? (
                                    <Print
                                        table={ table }
                                        total={ total }
                                        debit={ debit }
                                        credit={ credit }
                                        currency={ currency }
                                    />
                                ) : null}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr style={{ marginTop: 0, borderColor: '#DDDDDD' }} />
                <table className="table table-bordered table-hover table-sortable">
                    <thead>
                    <tr>
                        <th className="text-center">Pesquisa</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{ width: "250px" }}>
                        <Input
                            inputType={"text"}
                            name={"search"}
                            value={search}
                            placeholder="pesquisa..."
                            handleChange={(e) => this.filterData(e.target.value)}
                        />
                        </td>
                    </tr>
                    </tbody>
                </table>
                <ReactTable
                    columns={ table.columns }
                    sortable={ true }
                    loading={ table.loading }
                    data={ tableRows }
                />
                <Modal id="customers-audittickets" />
            </>
        );
    }
}

export default withCookies(Page);