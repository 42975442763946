import React, { Component } from 'react';

// Components
import POSMenu from '@components/POSMenu'
import Cards from '@components/Cards'
import Modal from '@components/Modal'
import New from './new'

import { EventBus as eventBus } from "@components/EventBus";

class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navigation: [{
                icon: 'fa-arrow-left',
                text: 'Voltar',
                link: '/pos',
            }, {
                icon: 'fa-eye',
                text: 'Consultar',
                link: '/pos/customers/list',
                condition: '[1,3,10].includes(profile.memberType)',
            }, {
                icon: 'fa-plus',
                text: 'Criar',
                click: () => eventBus.$emit("Modal-newcustomer", { header: "Criar cliente", body: <New /> }),
                condition: '[1,3,10].includes(profile.memberType)',
            }],
        };
    }
    
    render () {
        const { navigation } = this.state;

        return (
            <>
                <POSMenu { ...this.props } />
                <section className="main-section">
                    <Cards items={ navigation } />
                </section>
                <Modal id="newcustomer" />
            </>
        );
    }
}

export default Page;