import React, { Component } from 'react';

import Stepper from 'react-stepper-horizontal';
import Button from "@components/Button";

import { EventBus as eventBus } from "../EventBus";

class Steps extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
        };

        this.getStepData = this.getStepData.bind(this);
        this.performSubmit = this.performSubmit.bind(this);
    }

    getStepData() {
        const { form } = this.props;

        eventBus.$emit("GET/GET/steps/form", form);
    }

    componentDidMount() {
        const { changeStepData } = this.props;

        eventBus.$on("SET/steps/form", changeStepData)
        eventBus.$on("GET/steps/form", this.getStepData)
    }

    componentWillUnmount() {
        const { changeStepData } = this.props;

        eventBus.$off("SET/steps/form", changeStepData);
        eventBus.$off("GET/steps/form", this.getStepData);
    }

    performSubmit() {
        this.setState({ submitting: true });
 
        const { submit } = this.props;

        submit();
    }

    render() {
        const { step, steps, form, changeStepData } = this.props;
        const { submitting } = this.state;

        return (
            <div>
                <div>
                    <Stepper
                        completeColor="green"
                        size={50}
                        steps={ steps.map((stepItem, index) => ({
                            ...stepItem,
                            onClick: (e) => {
                                e.preventDefault()
                                changeStepData(undefined, index)
                            },
                        })) }
                        activeStep={ step } />
                        {steps[step].multiple ? (
                            <Button
                                action={() => changeStepData(undefined, step + 1)}
                                type={"success"}
                                title={"Avançar >"}
                            />
                        ) : null}
                        {steps[step].submit ? (
                            <Button
                                loading={submitting}
                                action={this.performSubmit}
                                type={"success"}
                                title={"Submeter"}
                            />
                        ) : null}
                </div>
                { steps[step].component({ form }) }
            </div>
        );
    }
}

export default Steps;