import React, { Component } from "react";
import { withCookies } from "react-cookie";
import axios from "axios";
import moment from "moment";

// Components
import POSStructure from "@components/POSStructure";
import POSStatus from "@components/POSStatus";
import Steps from "@components/Steps";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import Warehouses from "./warehouses";
import Categories from "./categories";
import Date from "./date";
import Values from "./values";
import Summary from './summary'

import UserContext from "@components/context/userContext";

const extraZero = (value) => (parseFloat(value) < 10 ? `0${parseFloat(value)}` : value);

class Page extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    const key = window.location.pathname;

    this.state = {
      form: JSON.parse(localStorage.getItem(`${key}.form`)) || {
        warehouse: { name: "" },
        category: { name: "" },
        values: [],
        date: moment().format("YYYY-MM-DD"),
        time: moment().format("HH:mm"),
        loaded: false,
      },
      step: parseInt(localStorage.getItem(`${key}.step`), 10) || 0,
      steps: [
        {
          title: "Posto",
          component: ({ form }) => <Warehouses form={form} />,
        },
        {
          title: "Familia",
          component: ({ form }) => <Categories form={form} />,
        },
        {
          title: "Valor",
          multiple: true,
          component: ({ form }) => <Values form={form} />,
        },
        {
          title: "Data e Hora",
          multiple: true,
          component: ({ form }) => <Date form={form} />,
        },
        {
          title: "Resumo",
          submit: true,
          component: ({ form }) => <Summary form={form} />,
        },
      ],
      goBack: () => "/pos/inventory",
      state: "initial",
      success: {},
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.changeStepData = this.changeStepData.bind(this);
  }

  changeStepData(data, step) {
    const key = window.location.pathname;
    if (data) {
      this.setState({ form: { ...this.state.form, ...data } }, () =>
        localStorage.setItem(`${key}.form`, JSON.stringify(this.state.form))
      );
      if (!this.state.steps[this.state.step].multiple) {
        this.setState({ step: this.state.step + 1 }, () =>
          localStorage.setItem(`${key}.step`, this.state.step)
        );
      }
    } else if (step > -1) {
      this.setState({ step }, () =>
        localStorage.setItem(`${key}.step`, this.state.step)
      );
    }
  }

  handleFormSubmit() {
    const { form } = this.state;
    const profile = this.context;

    let formDateAux = form.date.split('-');
    formDateAux = formDateAux.map((dateItem) => extraZero(dateItem));
    const formDate = formDateAux.join('-');

    let formTimeAux = form.time.split(':');
    formTimeAux = formTimeAux.map((dateItem) => extraZero(dateItem));
    const formTime = formTimeAux.join(':');

    axios
      .post("https://eposgestofinal.pt/api/inventory/generate.php", {
        idempresa: profile.idEmpresa,
        posto: form.warehouse.posto,
        date: formDate,
        time: formTime,
        values: form.values.filter(({ counter }) => counter !== ''),
      })
      .then((response) => {
        const key = window.location.pathname;
        localStorage.removeItem(`${key}.form`);
        localStorage.removeItem(`${key}.step`);
        this.setState({ state: "success", success: response });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ state: "error" });
      });
  }

  loadFunction() {
    if (!this.state.loaded) {
      this.setState({ loaded: true });
      const key = window.location.pathname;
      if (localStorage.getItem(`${key}.form`) !== null) {
        confirmAlert({
          title: "Rascunho",
          message: `Tem dados não submetidos nesta página, deseja voltar a editar?`,
          buttons: [
            {
              label: "Sim",
              onClick: () => {},
            },
            {
              label: "Não",
              onClick: () => {
                localStorage.removeItem(`${key}.form`);
                localStorage.removeItem(`${key}.step`);
                window.location.reload();
              },
            },
          ],
        });
      }
    }
  }

  render() {
    const { steps, step, form, state, goBack } = this.state;
    this.loadFunction();

    return (
      <>
        <POSStructure {...this.props} title={"Criar inventário"} goBack={goBack}>
          {
            {
              initial: (
                <Steps
                  steps={steps}
                  step={step}
                  form={form}
                  changeStepData={this.changeStepData}
                  submit={this.handleFormSubmit}
                />
              ),
              success: (
                <POSStatus
                  status="success"
                  title="Sucesso"
                  message="Inventário sumetido com sucesso."
                  action={
                    <div>
                      <p>Pode abandonar a página!</p>
                    </div>
                  }
                />
              ),
              error: (
                <POSStatus
                  status="error"
                  title="Erro"
                  message="Algo correu mal"
                  action="Volte a tentar mais tarde!"
                />
              ),
            }[state]
          }
        </POSStructure>
      </>
    );
  }
}

export default withCookies(Page);
