import React, { Component } from "react";


class Page extends Component {
  componentDidMount() {
    if (typeof this.props.handleDidMount == "function") {
      this.props.handleDidMount();
    }
  }

  render() {
    const { date, time, description, employee, customer, household, healthsystem, number_ss_associado, number_ss_agregado, numero_utente_associado, numero_utente_agregado, emptyLinesNumber } = this.props;

    const emptyLines = [];
    for (let i = 0; i < emptyLinesNumber; i++) {
      emptyLines.push(<tr><td style={{ border: "1px solid #000", padding: "0.5em", width: "100%" }}>&nbsp;</td></tr>);
    }

    return (
      <table style={{ margin: "15px", width: "100%" }}>
        <tr>
          <td style={{ textAlign: 'left' }}>Data: {date} {time}</td>
        </tr>
        <tr>
          <td style={{ textAlign: 'left' }}>{description}</td>
        </tr>
        <tr>
          <td style={{ textAlign: 'left' }}>Colaborador: {employee}</td>
        </tr>
        {customer && (<tr>
          <td style={{ textAlign: 'left' }}>Cliente: {customer}</td>
        </tr>)}
        {!household && number_ss_associado && (
          <tr>
            <td style={{ textAlign: 'left' }}>Número SS: {number_ss_associado}</td>
          </tr>
        )}
        {!household && numero_utente_associado && (
          <tr>
            <td style={{ textAlign: 'left' }}>Número Utente SNS: {number_ss_associado}</td>
          </tr>
        )}
        {healthsystem && healthsystem.name && (
        <tr>
          <td style={{ textAlign: 'left' }}>subsistem de saúde: {healthsystem.name}</td>
        </tr>
        )}
        {household && (
        <tr>
          <td style={{ textAlign: 'left' }}>Membro do agregado: {household}</td>
        </tr>
        )}
        {number_ss_agregado ? (
        <tr>
          <td style={{ textAlign: 'left' }}>Número SS Agregado: {number_ss_agregado}</td>
        </tr>
        ) : (household && !number_ss_agregado && (
          <tr>
            <td style={{ textAlign: 'left' }}>Número SS Agregado: Não definido</td>
          </tr>
        ))}
        {numero_utente_agregado ? (
        <tr>
          <td style={{ textAlign: 'left' }}>Número Utente SNS Agregado: {numero_utente_agregado}</td>
        </tr>
        ) : (household && !numero_utente_agregado && (
          <tr>
            <td style={{ textAlign: 'left' }}>Número Utente SNS Agregado: Não definido</td>
          </tr>
        ))}
        <tr>
          <td>
            Observações:
            <table style={{ width: "95%" }}>
              {emptyLines}
            </table>
          </td>
        </tr>
      </table>
    );
  }
}

export default Page;
