import React, { Component } from "react";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import axios from 'axios';
import GaugeChart from 'react-gauge-chart'

// Components
import Menu from '@components/Menu'

/* Import Components */
import VerticalTabs from "@components/VerticalTabs/.";

import './view.css';

class Form extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

  constructor(props) {
    super(props);

    this.state = {
        loaded: false,
        veederroot: {},
        warehouses: [],
    };
    this.loadFunction = this.loadFunction.bind(this);
  }

  loadFunction() {
    if (!this.state.loaded) {
        axios.get(`https://eposgestofinal.pt/api/postos/get.php?idempresa=${this.props.cookies.get('member_idempresa')}`)
            .then(response => {
                const warehouses = response.data !== 'NOK' ? response.data : [];

                this.setState({ warehouses, loaded: true })

                warehouses.forEach((warehouse) => {
                    axios.get(`https://eposgestofinal.pt/api/veeder-root/get.php?idempresa=${this.props.cookies.get('member_idempresa')}&idposto=${warehouse.posto}`)
                    .then(response => {
                        const data = response.data !== 'NOK' ? response.data : [];

                        const vr = data.reduce((accum, item) => {
                            if (!accum[item['idposto']]) {
                                accum[item['idposto']] = {
                                    name: item['pname'],
                                    items: [],
                                };
                            }
                            accum[item['idposto']].items.push(item);

                            return accum;
                        }, this.state.veederroot)

                        this.setState({ veederroot: vr, loaded: true });
                    })
                    .catch(error => {
                        console.error(error);
                    }); 
                })
            })
            .catch(error => {
                console.error(error);
            });
    }
  }

  render() {
    const { location, history } = this.props;
    const {
        loaded,
        veederroot,
        warehouses,
    } = this.state;
    const cookies = new URLSearchParams(location.search);

    return (
        <div>
            <Menu loadFunction={ this.loadFunction() } location={ location } history={ history } newCookies={ cookies } />
            <div className="fullContainer" style={{ maxWidth: '100%' }}>
                <div className="col-xs-12">
                    <ol
                        style={{
                            textAlign: 'left',
                            'marginTop': '75px',
                        }}
                        className="breadcrumb"
                    >
                        <li><a href="http://eposgestofinal.pt/index.php">Portal</a></li>
                        <li className="active">Veeder-Root</li>
                    </ol>
                </div>
            </div>
            <div
                    className="col-sm-12"
                    style={{ textAlign: 'center' }}
                >
                    <div className="panel panel-default">
                        <div
                            className="panel-heading"
                            style={{
                                fontWeight: 'bold',
                                fontSize: '15pt',
                            }}
                        >
                            Veeder-Root
                        </div>
                        {loaded && (<VerticalTabs
                            tabs={ [
                                ...Object.keys(veederroot).map((warehouse) => {
                                    return {
                                        label: veederroot[warehouse].name || warehouse,
                                        component: veederroot[warehouse].items.map((item) => {
                                            const { product, vol, tcvol, rvol, height, watter, temp } = item;

                                            const percent = (parseFloat(vol) * 100 / (parseFloat(vol) + parseFloat(rvol))) / 100;
                                            return (
                                                <div className="col-md-3" style={{ textAlign: 'center' }}>
                                                    <GaugeChart id="gauge-chart2" 
                                                        percent={percent}
                                                        nrOfLevels={20}
                                                        colors={["rgb(255, 0, 0)", "rgb(255, 255, 0)", "rgb(0, 255, 0)"]}
                                                        needleColor="#345243"
                                                        textColor="black"
                                                    />
                                                    <h4>{vol} L</h4>
                                                    <hr />
                                                    <div style={{ height: '75px', width: '100%', textAlign: 'center', display: 'table' }}>
                                                        <h3 style={{ display: 'table-cell', verticalAlign: 'middle' }}>{product}</h3>
                                                    </div>
                                                    <hr />
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <td>Volume</td>
                                                                <td>{vol}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Volume Água</td>
                                                                <td>{tcvol}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Altura Água</td>
                                                                <td>{watter}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Altura</td>
                                                                <td>{height}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Escoamento</td>
                                                                <td>{rvol}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Temperatura</td>
                                                                <td>{temp}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            );
                                        })
                                    };
                                }),
                                ...warehouses.filter((warehouse) => !Object.keys(veederroot).includes(warehouse.posto)).map((warehouse) => ({
                                    label: warehouse.nome || warehouse.posto,
                                    component: "Sem dados",
                                }))
                            ] }
                        />)}
                    </div>
            </div>
        </div>
    );
  }
}

export default withCookies(Form);
