import React from "react";
import RetroHitCounter from 'react-retro-hit-counter';

const PriceAndLiter = ({ value }) => {
    // 0133402000173
    const liter1 = value.substr(0,3);
    const liter2 = value.substr(3,2);

    const euro1 = value.substr(5,3);
    const euro2 = value.substr(8,2);

    return (
    <>
      <div
        style= {{
            fontSize: '20px',
            fontWeight: 'bold',
            width: '100%'
        }}
        >Euros</div>
        <div
            style= {{
                display: 'flex',
                alignItems: 'flex-start',
                alignSelf: 'center'
            }}
        >
            <RetroHitCounter
                hits={euro1}
                /* The following are all default values: */
                withBorder={false}
                withGlow={false}
                minLength={3}
                size={40}
                padding={4}
                digitSpacing={3}
                segmentThickness={4}
                segmentSpacing={0.5}
                segmentActiveColor="#FFFFFF"
                segmentInactiveColor="#454545"
                backgroundColor="#222222"
                borderThickness={7}
                glowStrength={0.5}
            />
            <span
                style={{
                    fontSize: '40px',
                    background: '#222222',
                    color: 'white',
                    lineHeight: '48px'
                }}
            >.</span>
            <RetroHitCounter
                hits={euro2}
                /* The following are all default values: */
                withBorder={false}
                withGlow={false}
                minLength={2}
                size={40}
                padding={4}
                digitSpacing={3}
                segmentThickness={4}
                segmentSpacing={0.5}
                segmentActiveColor="#FFFFFF"
                segmentInactiveColor="#454545"
                backgroundColor="#222222"
                borderThickness={7}
                glowStrength={0.5}
            />
        </div>
        <div
            style= {{
                fontSize: '20px',
                fontWeight: 'bold',
                width: '100%'
            }}
        >Litros</div>
        <div
            style= {{
                display: 'flex',
                alignItems: 'flex-start',
                alignSelf: 'center'
            }}
        >
            <RetroHitCounter
                hits={liter1}
                /* The following are all default values: */
                withBorder={false}
                withGlow={false}
                minLength={3}
                size={40}
                padding={4}
                digitSpacing={3}
                segmentThickness={4}
                segmentSpacing={0.5}
                segmentActiveColor="#FFFFFF"
                segmentInactiveColor="#454545"
                backgroundColor="#222222"
                borderThickness={7}
                glowStrength={0.5}
            />
            <span
                style={{
                    fontSize: '40px',
                    background: '#222222',
                    color: 'white',
                    lineHeight: '48px'
                }}
            >.</span>
            <RetroHitCounter
                hits={liter2}
                /* The following are all default values: */
                withBorder={false}
                withGlow={false}
                minLength={2}
                size={40}
                padding={4}
                digitSpacing={3}
                segmentThickness={4}
                segmentSpacing={0.5}
                segmentActiveColor="#FFFFFF"
                segmentInactiveColor="#454545"
                backgroundColor="#222222"
                borderThickness={7}
                glowStrength={0.5}
            />
        </div>
    </>
  );
};

export default PriceAndLiter;