import React, { Component } from "react";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import axios from 'axios';
import { set, get } from 'lodash'; 

// Components
import Menu from '@components/Menu'

/* Import Components */
import Input from "@components/Input";
import Button from "@components/Button";
import AutoComplete from "@components/AutoComplete";

const newLineNozzles = () => ({ product: '' });

const newLine = () => ({
    name: "",
    host: "",
    port: "",
    client: {
        port: "",
    },
    nozzles: [newLineNozzles()]
});

class Form extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

  constructor(props) {
    super(props);

    const { match: { params } } = props;

    this.state = {
        loaded: false,
        products: [],
        empresas: [],
        warehouses: [],
        tanks: [],
        idempresa: '',
        servers: [newLine()],
        idposto: '',
        idpsa: params.idpsa,
        macaddress: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.loadFunction = this.loadFunction.bind(this);
    this.removeFunction = this.removeFunction.bind(this);
    this.addFunction = this.addFunction.bind(this);
  }

  loadFunction() {
    if (!this.state.loaded) {
        axios.get('https://eposgestofinal.pt/api/empresas/get.php?combustivel=true')
            .then(response => {
                const empresas = response.data !== 'NOK' ? response.data : [];

                this.setState({ empresas: empresas.map((empresa) => ({ ...empresa, value: empresa.idempresa, label: empresa.nomeempresa })).filter((empresa) => empresa.combustivel), loaded: true });

                axios.get(`https://eposgestofinal.pt/api/psa/getById.php?idpsa=${this.state.idpsa}`)
                    .then(response => {
                        const raspberry = response.data !== 'NOK' ? response.data : {};

                        let currentEmpresa = '';
                        if (raspberry.idempresa) {
                            //https://eposgestofinal.pt/api/stations/servers/get.php?idempresa=${idEmpresa}&idstation=${idstation}
                            currentEmpresa = this.state.empresas.find((empresa) => raspberry.idempresa === empresa.idempresa)
                            this.handleChange({ target: { value: currentEmpresa, name: 'idempresa' } });
                        }

                        this.setState({ ...raspberry, idempresa: currentEmpresa, loaded: true });
                    })
                    .catch(error => {
                        console.error(error);
                    });
            })
            .catch(error => {
                console.error(error);
            });
    }
  }

  getPostoInfo (idempresa, idposto) {
    axios.get(`https://eposgestofinal.pt/api/stations/servers/get.php?idempresa=${idempresa}&idposto=${idposto}`)
        .then(response => {
            const station = response.data !== 'NOK' ? response.data : {};

            const hydratedServers = station.servers.map((server) => ({
                    ...server,
                    nozzles: server.nozzles.map((nozzle) => ({
                        ...nozzle,
                        product: this.state.products.find((product) => product.idproduct === nozzle.idproduct)
                    })),
            }));

            this.setState({ servers: hydratedServers.length ? hydratedServers : [newLine()], loaded: true });
        })
        .catch(error => {
            console.error(error);
        })

    axios.get(`https://eposgestofinal.pt/api/veeder-root/get.php?idempresa=${idempresa}&idposto=${idposto}`)
        .then(response => {
            const tanks = response.data !== 'NOK' ? response.data : {};

            const hydratedTanks = tanks.map((tank) => ({
                    ...tank,
                    produto: tank.product,
                    product: (this.state.products || []).find((product) => product.idproduct === tank.idproduct),
            }));

            this.setState({ tanks: hydratedTanks, loaded: true });
        })
        .catch(error => {
            console.error(error);
        })
  }

  getPostosProdutos (idempresa, callBack) {
    axios.get(`https://eposgestofinal.pt/api/products/get.php?idempresa=${idempresa}&combustivel=true`)
        .then(response => {
            const products = response.data !== 'NOK' ? response.data : [];

            this.setState({ products: products.map((product) => ({ ...product, value: product.idproduct, label: product.nome })), loaded: true });

            axios.get(`https://eposgestofinal.pt/api/postos/get.php?idempresa=${idempresa}`)
                .then(response => {
                    const warehouses = response.data !== 'NOK' ? response.data : [];

                    const hydratedWarehouses = warehouses.map((warehouse) => ({ ...warehouse, value: warehouse.posto, label: warehouse.nome !== '' ? warehouse.nome : warehouse.posto }));

                    this.setState({ warehouses: hydratedWarehouses, loaded: true });

                    let currentPosto = '';
                    if (this.state.idposto) {
                        currentPosto = hydratedWarehouses.find((warehouse) => this.state.idposto === warehouse.posto)
                        this.handleChange({ target: { value: currentPosto, name: 'idposto' } });
                    }

                    this.setState({ idposto: currentPosto, loaded: true });
                    if (callBack) {
                        callBack();
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        })
        .catch(error => {
            console.error(error);
        });
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;
    
    let newState = this.state;

    if (name.includes("|")) {
        const properties = name.split("|");

        set(newState, properties, value);

        this.setState({
            ...newState,
        });
    } else {
        this.setState({
            [name]: value,
        });
        if (name === 'idempresa') {
            return this.getPostosProdutos(value.value, callback);
        }
        if (name === 'idposto') {
            return this.getPostoInfo(newState.idempresa.value, value.value);
        }
    }

    
    if (callback) {
        callback();
    }
  }

  handleFormCancel() {
    const { history } = this.props;
    history.push('/psa');
  }

  handleFormSubmit(e) {
    const { history } = this.props;
    const { servers, tanks, idempresa, idposto, idpsa, macaddress } = this.state;
    e.preventDefault();
    
    axios.post('https://eposgestofinal.pt/api/psa/set.php', {
        idempresa,
        idposto,
        idpsa,
        macaddress,
        servers,
        tanks,
    })
    .then(response => {
        history.push('/psa');
    })
    .catch(error => {
        console.error(error);
    });
  }

  removeFunction(property, key) {
    const properties = property.split("|");
    let newState = this.state;

    set(newState, properties, get(newState, properties, []).filter((arrayItem, index) => index !== key));

    this.setState(newState);
  }

  addFunction(property) {
    const properties = property.split("|");
    let newState = this.state;

    let newLineValue = newLine();
    if (property.endsWith('.nozzles')) {
        newLineValue = newLineNozzles();
    }

    set(newState, properties, [ ...get(newState, properties, []), ...[newLineValue]]);

    this.setState(newState);
  }

  render() {
    const { location, history } = this.props;
    const {
        loaded,
        idempresa,
        empresas,
        products,
        warehouses,
        idposto,
        macaddress,
        idpsa,
        tanks,
    } = this.state;
    const cookies = new URLSearchParams(location.search);

    return (
        <div>
            <Menu loadFunction={ this.loadFunction() } location={ location } history={ history } newCookies={ cookies } />
            <div className="fullContainer" style={{ maxWidth: '100%' }}>
                <div className="col-xs-12">
                    <ol
                        style={{
                            textAlign: 'left',
                            'marginTop': '75px',
                        }}
                        className="breadcrumb"
                    >
                        <li><a href="http://eposgestofinal.pt/index.php">Portal</a></li>
                        <li><a href="/psa">Raspberry</a></li>
                        <li><a href={`/psa/${idpsa}`}>{ macaddress }</a></li>
                        <li className="active">
                            <span>Configuração</span>
                        </li>
                    </ol>
                </div>
            </div>
            {loaded && (
            <div
                className="fullContainer"
                style={{ marginTop:'25px' }}
            >
                <div
                    className="col-sm-12"
                    style={{ textAlign: 'center', fontSize: '10px' }}
                >
                    <div className="panel panel-default">
                        <div
                            className="panel-heading"
                            style={{
                                fontWeight: 'bold',
                                fontSize: '15pt',
                            }}
                        >
                           Raspberry
                        </div>
                        <form
                            style={{
                                textAlign: 'left',
                            }}
                            className="container-fluid"
                            onSubmit={this.handleFormSubmit}
                        >
                            <table className="table table-bordered table-hover table-sortable">
                                <thead>
                                    <tr >
                                        <th className="text-center">Mac</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td>
                                            <Input
                                                inputType={"text"}
                                                name={`macaddress`}
                                                value={macaddress}
                                                handleChange={this.handleChange}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h3>Posto</h3>
                            <table className="table table-bordered table-hover table-sortable">
                                <thead>
                                    <tr >
                                        <th className="text-center">Empresa</th>
                                        <th className="text-center">Posto</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td>
                                            <AutoComplete
                                                name={"idempresa"}
                                                value={idempresa}
                                                handleChange={this.handleChange}
                                                options={empresas}
                                            />
                                        </td>
                                        <td>
                                            <AutoComplete
                                                name={"idposto"}
                                                value={idposto}
                                                handleChange={this.handleChange}
                                                options={warehouses}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {tanks.length ? (
                                <>
                                    <h3>Tanques</h3>
                                    <table className="table table-bordered table-hover table-sortable" id="tab_logic">
                                        <thead>
                                            <tr >
                                                <th className="text-center">Veeder-Root</th>
                                                <th className="text-center">Produto</th>
                                                <th className="text-center">Litros não pescáveis</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tanks.map((tank, key) => (
                                                <tr>
                                                    <td style={{ width: '100px' }}>
                                                        {tank.produto}
                                                    </td>
                                                    <td style={{ width: '100px' }}>
                                                        <AutoComplete
                                                            name={`tanks|${key}|product`}
                                                            value={tank.product}
                                                            handleChange={this.handleChange}
                                                            options={products}
                                                        />
                                                    </td>
                                                    <td style={{ width: '100px' }}>
                                                        <Input
                                                            inputType={"number"}
                                                            name={`tanks|${key}|loss`}
                                                            value={tank.loss}
                                                            handleChange={this.handleChange}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </>
                            ) : null}
                            {/* <h3>Bombas</h3>
                                {servers.map((server, key) => (
                                    <table className="table table-bordered table-hover table-sortable" id="tab_logic">
                                        <thead>
                                            <tr >
                                                <th className="text-center">Nome</th>
                                                <th className="text-center">IP</th>
                                                <th className="text-center">Porta</th>
                                                <th className="text-center">Porta Apollo</th>
                                                <th className="text-center">&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '100px' }}>
                                                    <Input
                                                        inputType={"text"}
                                                        name={`servers|${key}|name`}
                                                        value={server.name}
                                                        handleChange={this.handleChange}
                                                    />
                                                </td>
                                                <td style={{ width: '100px' }}>
                                                    <Input
                                                        inputType={"text"}
                                                        name={`servers|${key}|host`}
                                                        value={server.host}
                                                        handleChange={this.handleChange}
                                                    />
                                                </td>
                                                <td style={{ width: '100px' }}>
                                                    <Input
                                                        inputType={"number"}
                                                        name={`servers|${key}|port`}
                                                        value={server.port}
                                                        handleChange={this.handleChange}
                                                    />
                                                </td>
                                                <td style={{ width: '100px' }}>
                                                    <Input
                                                        inputType={"number"}
                                                        name={`servers|${key}|client|port`}
                                                        value={server.client.port}
                                                        handleChange={this.handleChange}
                                                    />
                                                </td>
                                                <td rowspan="2" style={{ width: '20px' }}>
                                                    <MdClear style={{ color: 'red', fontSize: '15px', cursor: 'pointer' }} onClick={ () => this.removeFunction('servers', key) } />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h3>Mangueiras</h3>
                                                    <table className="table table-bordered table-hover table-sortable" id="tab_logic">
                                                        <thead>
                                                            <tr >
                                                                <th className="text-center">Número</th>
                                                                <th className="text-center">Produto</th>
                                                                <th className="text-center">&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(server.nozzles || []).map((nozzle, key2) => (
                                                                <>
                                                                    <tr>
                                                                        <td style={{ width: '100px' }}>
                                                                            { key2 + 1 }
                                                                        </td>
                                                                        <td style={{ width: '100px' }}>
                                                                            <AutoComplete
                                                                                name={`servers|${key}|nozzles|${key2}|product`}
                                                                                value={nozzle.product}
                                                                                handleChange={this.handleChange}
                                                                                options={products}
                                                                            />
                                                                        </td>
                                                                        <td style={{ width: '20px' }}>
                                                                            <MdClear style={{ color: 'red', fontSize: '15px', cursor: 'pointer' }} onClick={ () => this.removeFunction(`servers|${key}|nozzles`, key2) } />
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <div className="row" style={{ textAlign: 'right' }}>
                                                        <Button
                                                            action={ () => this.addFunction(`servers|${key}|nozzles`) }
                                                            type={"info"}
                                                            title={"Adicionar Mangueira"}
                                                            style={buttonStyle}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                ))}
                            <div className="row" style={{ textAlign: 'right' }}>
                                <Button
                                    action={ () => this.addFunction('servers') }
                                    type={"info"}
                                    title={"Adicionar Bomba"}
                                    style={buttonStyle}
                                />
                            </div> */}
                            <Button
                                action={this.handleFormSubmit}
                                type={"success"}
                                title={"Submeter"}
                                style={buttonStyle}
                                />
                            <Button
                            action={this.handleFormCancel}
                            type={"secondary"}
                            title={"cancelar"}
                            style={buttonStyle}
                            />
                            {/* Clear the form */}
                        </form>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px"
};

export default withCookies(Form);
