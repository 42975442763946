import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";

// Components
import AutoComplete from "@components/AutoComplete";
import Button from "@components/Button";
import Menu from "@components/Menu";
import Input from "@components/Input";
import ReactTable from "@components/ReactTable";
import Modal from '@components/Modal';
import { MdVisibility, MdAddCircleOutline } from "react-icons/md";
import { FaFileInvoiceDollar } from "react-icons/fa";

import { EventBus as eventBus } from "@components/EventBus";

import UserContext from "@components/context/userContext";

import "./list.css";

class Page extends Component {
  static contextType = UserContext;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    const { ePOSOptions = { columnsToHide: [] } } = this.props;


    this.state = {
      loaded: false,
      table: {
        columns: [
          {
            Header: "Data",
            accessor: "counted_at",
          },
          {
            Header: "Posto",
            id: "posto",
            accessor: (d) => d.warehouse_name || d.posto,
          },
          {
            Header: "Colaborador",
            id: "idemployee",
            accessor: (d) => d.idemployee > 0 ? d.employee_name : 'Admin',
          },
          {
            Header: 'Resultado',
            id: 'losses',
            show: !ePOSOptions.columnsToHide.includes('losses'),
            accessor: d => d.losses ? (
              <>
                <span className="badge badge-danger">Perdas</span>
              </>
            ) : (d.earnings ? (
              <span className="badge badge-success">Ganhos</span>
            ) : <span className="badge badge-success">Esperado</span>),
          },
          {
            Header: "",
            accessor: "view",
            show: !ePOSOptions.columnsToHide.includes('view'),
          },
        ],
        rows: [],
        loading: true,
      },
      posto: '',
      warehouses: [],
      nozzles: [],
      newNozzles: [],
      viewCounter: {},
      readyToSubmit: false,
    };
    this.loadFunction = this.loadFunction.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCountersSubmitSuccess = this.handleCountersSubmitSuccess.bind(this);
  }

  loadFunction() {
    if (!this.state.loaded) {
      const { history } = this.props;
      const profile = this.context;

      axios
        .get(
          `https://eposgestofinal.pt/api/nozzles/get.php?idempresa=${profile.idEmpresa}`
        )
        .then((response) => {
          const nozzles = response.data !== "NOK" ? response.data : [];

          const hydratateNozzles = nozzles.reduce((acc, nozzle) => {
            if (!acc[nozzle.posto]) {
              acc[nozzle.posto] = {
                posto: nozzle.posto,
                pumps: [],
              };
            }
            if (!acc[nozzle.posto].pumps[nozzle.sequence]) {
              acc[nozzle.posto].pumps[nozzle.sequence] = {
                idpump: nozzle.idpump,
                name: nozzle.name !== '' ? nozzle.name : `Bomba ${nozzle.sequence}`,
                sequence: nozzle.idpump.sequence,
                nozzles: [],
              };
            }
            acc[nozzle.posto].pumps[nozzle.sequence].nozzles.push({
              idnozzle: nozzle.idnozzle,
              name: nozzle.nozzle_name !== '' ? nozzle.nozzle_name : `Mangueira ${nozzle.nozzle_sequence}`,
              sequence: nozzle.nozzle_sequence,
              idproduct: nozzle.idproduct,
              last_counter: nozzle.counter,
              counter: '',
            });
            return acc;
          }, {})

          this.setState({
            nozzles: Object.values(hydratateNozzles),
            loaded: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          `https://eposgestofinal.pt/api/postos/get.php?idempresa=${profile.idEmpresa}`
        )
        .then((response) => {
          const warehouses = response.data !== "NOK" ? response.data : [];

          this.setState({
            warehouses: warehouses.map((warehouse) => ({ ...warehouse, value: warehouse.posto, label: warehouse.nome !== '' ? warehouse.nome : warehouse.posto })),
            loaded: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          `https://eposgestofinal.pt/api/pumps/counters/get.php?idempresa=${profile.idEmpresa}`
        )
        .then((response) => {
          const counters = response.data !== "NOK" ? response.data : [];

          const hydratateCounter = counters.map((counter) => ({
            ...counter,

            view: (
              <>
                {counter.losses && counter['losses_paid'] === 'f' ? (
                  <FaFileInvoiceDollar
                    title="Gerar fatura"
                    style={{ fontSize: "15px", cursor: "pointer", marginRight: '10px' }}
                    onClick={() => {
                      history.push(counter.losses);
                    }}
                  />
                ) : null}
                <MdVisibility
                  style={{ fontSize: "15px", cursor: "pointer" }}
                  onClick={() => {
                    this.setState({
                      viewCounter: counter
                    });
                    eventBus.$emit("Modal-counters-view", { header: "Contagem registada", body: null })
                  }}
                />
              </>
            ),
          }))

          const viewCounter = hydratateCounter[0];

          this.setState({
            table: { ...this.state.table, rows: hydratateCounter, loading: false },
            viewCounter,
            loaded: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;

    
    let newState = this.state;

    if (name.includes("|")) {
      const properties = name.split("|");

      newState[properties[0]][properties[1]][properties[2]][properties[3]][properties[4]][properties[5]][properties[6]] = value;

      this.setState({
          ...newState,
          readyToSubmit: (newState.newNozzles[0] || { pumps: [] }).pumps.every((pump) =>
            pump.nozzles.every((nozzle) =>
              nozzle.counter !== '' && nozzle.counter > 0
            )
          ),
      });
    } else {
      this.setState({
        [name]: value,
      });
  
      if (name === 'posto') {
        this.setState({
          newNozzles: this.state.nozzles.filter((nozzle) => nozzle.posto === value.value),
        })
      }
    }
  }

  handleCountersSubmitSuccess() {
    const profile = this.context;
    if ((this.state.newNozzles[0] || { pumps: [] }).pumps.every((pump) =>
      pump.nozzles.every((nozzle) =>
        nozzle.counter !== '' && nozzle.counter > 0
      )
    )) {
      const values = [];
      
      this.state.newNozzles[0].pumps.map((pump) => pump.nozzles.map((nozzle) => {
        values.push({
          idnozzle: nozzle.idnozzle,
          counter: nozzle.counter,
        });
      }));


      axios
        .post("https://eposgestofinal.pt/api/pumps/counters/set.php", {
          idempresa: profile.idEmpresa,
          idemployee: profile.idEmployee || 0,
          posto: this.state.posto.value,
          values,
        })
        .then((response) => {
          if (response && response.data && response.data !== 'NOK') {
            eventBus.$emit("Modal-counters", { isOpen: false });
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  render() {
    const { table, posto, warehouses, newNozzles, nozzles, viewCounter, readyToSubmit } = this.state;
    const { location, history, ePOSOptions = { menu: true, breadcrumbs: true } } = this.props;
    const cookies = new URLSearchParams(location.search);

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
          ePOSOptions={ePOSOptions}
        />
        {ePOSOptions.breadcrumbs && (
          <div className="fullContainer" style={{ maxWidth: "100%" }}>
            <div className="col-xs-12">
              <ol
                style={{
                  textAlign: "left",
                  marginTop: "75px"
                }}
                className="breadcrumb"
              >
                <li>
                  <a href="http://eposgestofinal.pt/index.php">Portal</a>
                </li>
                <li className="active">Contadores</li>
              </ol>
            </div>
          </div>
        )}
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Contadores
                </div>
                <div>
                  <table
                    style={{ cursor: "pointer" }}
                    onClick={() => eventBus.$emit("Modal-counters", { header: "Registar contagens", body: null })}
                  >
                    <tr>
                      <td>
                        <MdAddCircleOutline style={{ fontSize: "20px" }} />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: "xx-small" }}>Adicionar contagem</td>
                    </tr>
                  </table>
                </div>
              </div>
              <ReactTable
                columns={table.columns}
                sortable={true}
                loading={table.loading}
                data={table.rows}
              />
              <Modal id="counters">
                <div>
                  <AutoComplete
                      name={'posto'}
                      value={posto}
                      handleChange={this.handleChange}
                      options={warehouses}
                  />
                  {(newNozzles[0] || { pumps: [] }).pumps.map((newPump, key) => {
                    return (
                      <>
                        <h4>{newPump.name}</h4>
                        <table className="table table-bordered table-hover">
                          <thead>
                              <tr>
                                  <th style={{ textAlign: 'center' }}>Bomba</th>
                                  <th style={{ textAlign: 'center' }}>Mangueira</th>
                                  <th style={{ textAlign: 'center' }}>Produto</th>
                                  <th style={{ textAlign: 'center' }}>Cont. Ant.</th>
                                  <th style={{ textAlign: 'center' }}>Contador</th>
                              </tr>
                          </thead>
                          <tbody>
                            {newPump.nozzles.map((newNozzle, key1) => (
                              <tr>
                                <td>
                                  {newPump.name}
                                </td>
                                <td>
                                  {newNozzle.name}
                                </td>
                                <td>
                                  {newNozzle.idproduct}
                                </td>
                                <td>
                                  {newNozzle['last_counter']}
                                </td>
                                <td>
                                  <Input
                                      inputType={"number"}
                                      name={`newNozzles|0|pumps|${key}|nozzles|${key1}|counter`}
                                      value={newNozzle.counter}
                                      handleChange={this.handleChange}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    );
                  })}
                  <Button
                      action={this.handleCountersSubmitSuccess}
                      disabled={!readyToSubmit}
                      type={"success"}
                      title={"Submeter"}
                      style={buttonStyle}
                  />
                </div>
              </Modal>
              <Modal id="counters-view">
                <div>
                  {
                    viewCounter && viewCounter['counters'] && (
                      <>
                        {viewCounter['counters']['warehouse_name'] || viewCounter['counters']['posto']}
                        {(viewCounter.losses || viewCounter.earnings) && (<h4>Documentos Associados</h4>)}
                        {viewCounter.losses && (<p>
                          <Button
                            action={() => history.push(viewCounter.losses)}
                            type={"secondary"}
                            title={"Documentos de Perdas"}
                            style={buttonStyle}
                          />
                        </p>)}
                        {viewCounter.earnings && (<p>
                          <Button
                            action={() => history.push(viewCounter.earnings)}
                            type={"primary"}
                            title={"Documentos de Ganhos"}
                            style={buttonStyle}
                          />
                        </p>)}
                        {Object.keys(viewCounter['counters']).map((pump) => (
                          <>
                            <h4>{viewCounter['counters'][pump].name || viewCounter['counters'][pump].sequence}</h4>
                            <table className="table table-bordered table-hover">
                              <thead>
                                  <tr>
                                      <th style={{ textAlign: 'center' }}>Bomba</th>
                                      <th style={{ textAlign: 'center' }}>Mangueira</th>
                                      <th style={{ textAlign: 'center' }}>Produto</th>
                                      <th style={{ textAlign: 'center' }}>Contador</th>
                                  </tr>
                              </thead>
                              <tbody>
                                {Object.keys(viewCounter['counters'][pump].nozzles).map((nozzle) => (
                                  <tr>
                                    <td>
                                      {viewCounter['counters'][pump].name || viewCounter['counters'][pump].sequence}
                                    </td>
                                    <td>
                                      {viewCounter['counters'][pump].nozzles[nozzle].name || viewCounter['counters'][pump].nozzles[nozzle].sequence}
                                    </td>
                                    <td>
                                      {viewCounter['counters'][pump].nozzles[nozzle].idproduct}
                                    </td>
                                    <td>
                                      {viewCounter['counters'][pump].nozzles[nozzle].counter}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </>
                        ))}
                      </>
                    )
                  }
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px"
};

export default withCookies(withRouter(Page));
