import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import uuidv4 from 'uuid/v4';
import { NotificationManager } from 'react-notifications';

// Components
import POSPage from '@components/POSPage'
import Cards from '@components/Cards'
import paginator from '@utils/paginator'

import Custom from './Custom'

import { EventBus as eventBus } from "@components/EventBus";

class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pagination: {
                left: {
                    first: {
                        click: () => this.setState({ page: 1 }),
                        show: true,
                    },
                    previous: {
                        click: () => this.setState({ page: this.state.page - 1 }),
                        show: true,
                    }
                },
                right: {
                    next: {
                        click: () => this.setState({ page: this.state.page + 1 }),
                        show: true,
                    },
                    last: {
                        click: () => this.setState({ page: this.state.lastPage }),
                        show: true,
                    }
                }
            },
            items: [],
            page: 1,
            lastPage: 0,
            pageSize: 24,
            loaded: false,
        };

        this.loadFunction = this.loadFunction.bind(this);
        this.setCustomValue = this.setCustomValue.bind(this);
        this.setCustomQuantity = this.setCustomQuantity.bind(this);
        this.handleLongPress = this.handleLongPress.bind(this);
    }

    setCustomValue (value) {
        eventBus.$emit("Modal-ticketsvalues", { isOpen: false })

        const uniqID = uuidv4()
        
        const newValue = {
            value,
            icon: 'fa-ticket-alt',
            id: uniqID,
            text: value,
            counter: 1,
            custom: true,
            click: () => {
                const newItems = this.state.items.map((item) => ({
                    ...item,
                    counter: item.value === value ? item.counter + 1 : item.counter,
                }));
                this.setState({
                    items: newItems,
                });
                eventBus.$emit("SET/steps/form", { values: newItems });
            },
        };
        eventBus.$emit("SET/steps/form", { values: [
            newValue,
            ...this.state.items,
        ] });
        this.setState({ items: [
            newValue,
            ...this.state.items,
        ]});
    }

    loadFunction() {
        if (!this.state.loaded) {
            axios.get(`https://eposgestofinal.pt/api/tickets/values/get.php?idempresa=${this.props.cookies.get('member_idempresa')}`)
            .then(response => {
                const values = response.data !== 'NOK' ? response.data : [];

                const oldCustomValues = this.props.form.values.filter((item) => item.custom);

                const enrichedCustomers = [...oldCustomValues, ...values].map((value) => {
                    const specificValue = this.props.form.values.find((item) => value.value === item.value && item.counter > 0);
                    const uniqID = uuidv4()
                    return {
                        ...value,
                        id: uniqID,
                        icon: 'fa-ticket-alt',
                        text: value.value,
                        counter: (specificValue || {}).counter || 0,
                        click: () => {
                            const newItems = this.state.items.map((item) => ({
                                ...item,
                                counter: item.value === value.value ? item.counter + 1 : item.counter,
                            }));
                            this.setState({
                                items: newItems,
                            });
                            eventBus.$emit("SET/steps/form", { values: newItems });
                        },
                    };
                });

                const lastPage = Math.ceil(enrichedCustomers.length / this.state.pageSize);

                this.setState({ items: enrichedCustomers, lastPage, loaded: true });
            })
            .catch(error => {
                NotificationManager.success(error.message, 'Valores');
            });
        }
    }

    setCustomQuantity (quantity, id) {
        eventBus.$emit("Modal-ticketsvalues", { isOpen: false })

        const newItems = this.state.items.map((item) => ({
            ...item,
            counter: item.id === id ? parseInt(quantity, 10) : item.counter,
        }));
        eventBus.$emit("SET/steps/form", { values: newItems });
        this.setState({ items: newItems });
    }

    handleLongPress(info) {
        eventBus.$emit("Modal-ticketsvalues", { header: "Quantidade personalizado", body: <Custom id={info.id} customValue={info.counter} setCustomValue={this.setCustomQuantity} /> })
    }
    
    render () {
        const { items, pagination, page, pageSize } = this.state;
        const { form } = this.props;
        
        const pageInfo = paginator(items, page, pageSize);

        const realPagination = {
            left: pageInfo.pre_page ? pagination.left : { first: {}, previous: {} },
            right: pageInfo.next_page ? pagination.right : { next: {}, last: {} },
        }

        pageInfo.data.unshift({
            icon: 'fa-plus-square',
            text: 'Valor',
            click: () => eventBus.$emit("Modal-ticketsvalues", { header: "Novo Valor Personalizado", body: <Custom setCustomValue={this.setCustomValue} /> }),
        })

        const data = pageInfo.data.map((item) => {
            const specificValue = form.values.find((value) => value.value === item.value && value.counter > 0);
            return {
                ...item,
                active: specificValue,
                counter: (specificValue || {}).counter,
            };
        });

        return (
            <POSPage
                pagination={ realPagination }
                loadFunction={ this.loadFunction }
            >
                <Cards modifier="small" handleLongPress={this.handleLongPress} items={ data } />
            </POSPage>
        );
    }
}

export default withCookies(Page);