import React, { Component } from "react";
import { withCookies } from "react-cookie";
import axios from "axios";
import { NotificationManager } from "react-notifications";

// Components
import Input from "@components/Input";
import TextArea from "@components/TextArea";
import Checkbox from "@components/CheckBox";
import AutoComplete from "@components/AutoComplete";

import UserContext from "@components/context/userContext";

import { EventBus as eventBus } from "@components/EventBus";

class Page extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    const idCustomer = this.props.idcustomer;

    this.state = {
      name: "",
      email: "",
      nif: "",
      phone: "",
      address: "",
      birthday: "",
      idassociado: "",
      quota: {
        value: 0,
        label: "Nenhuma",
      },
      healthSystem: '',
    number_ss: '',
    numero_utente: '',
    healthSystems: [],
      gdpr: false,
      quotaTypes: [
        {
          value: 0,
          label: "Nenhuma",
        },
        {
          value: 3,
          label: "Mensal",
        },
        {
          value: 2,
          label: "Trimestral",
        },
        {
          value: 5,
          label: "Quadrimestral",
        },
        {
          value: 4,
          label: "Semestral",
        },
        {
          value: 1,
          label: "Anual",
        },
      ],
      edit: idCustomer,
      loaded: false,
    };

    this.loadFunction = this.loadFunction.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  loadFunction() {
    const profile = this.context;
    const { idcustomer } = this.props;

    if (!this.state.loaded) {
        axios
            .get(
            `https://eposgestofinal.pt/api/health_systems/get.php`
            )
            .then((response) => {
                const healthSystems = response.data !== "NOK" ? response.data : [];
                this.setState({
                    healthSystems: [
                        {
                            label: 'Nenhum',
                            value: undefined,
                        },
                        ...healthSystems.map((healthSystem) => ({
                            ...healthSystem,
                            label: healthSystem.name,
                            value: healthSystem.idhealthsystem,
                        }))
                    ],
                    loaded: true,
                });
            });
    }
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({
      [name]: value,
    });
  }

  handleCheckboxChange(e, callback) {
    let value = e.target.checked;
    let name = e.target.name;

    this.setState({
      [name]: value,
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const profile = this.context;
    const { history } = this.props;
    const { idcustomer } = this.props;
    const { edit } = this.state;

    axios
      .post("https://eposgestofinal.pt/api/customers/set.php", {
        name: this.state.name,
        nif: this.state.nif,
        email: this.state.email,
        phone: this.state.phone,
        idassociado: this.state.idassociado,
        quota: this.state.quota.value,
        address: this.state.address,
        birthday: this.state.birthday,
        gdpr: `${this.state.gdpr}`,
        idempresa: profile.idEmpresa,
        idcustomer: idcustomer,
        healthsystem: this.state.healthSystem.value,
        number_ss: this.state.number_ss,
        numero_utente: this.state.numero_utente,
      })
      .then((response) => {
        NotificationManager.success(
          "Cliente submetido com sucesso!",
          "Cliente"
        );
        eventBus.$emit("Modal-newcustomer", { isOpen: false });
        window.location.href = "/customers";
      })
      .catch((error) => {
        NotificationManager.error(error.message, "Cliente");
      });
  }

  render() {
    const {
      name,
      nif,
      email,
      phone,
      birthday,
      idassociado,
      quota,
      quotaTypes,
      address,
      gdpr,
      edit,
      healthSystems,
      healthSystem,
      number_ss,
      numero_utente,
    } = this.state;
    this.loadFunction();

    return (
    <form class="well form-horizontal" action=" " method="post" id="contact_form">
        <div class="st st-theme-github st-horizontal">
            <fieldset>
                <div className="form-group">
                <label className="col-md-3 control-label">Nome</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-user"></i>
                    </span>
                    <Input
                        inputType={"text"}
                        name={"name"}
                        value={name}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">NIF</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-id-card"></i>
                    </span>
                    <Input
                        inputType={"text"}
                        name={"nif"}
                        value={nif}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">E-Mail</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-envelope"></i>
                    </span>
                    <Input
                        inputType={"text"}
                        name={"email"}
                        value={email}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">Telefone</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-phone"></i>
                    </span>
                    <Input
                        inputType={"text"}
                        name={"phone"}
                        value={phone}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                  <label className="col-md-3 control-label">Data de nascimento</label>
                  <div className="col-md-9 inputGroupContainer">
                      <div className="input-group">
                      <span className="input-group-addon">
                          <i className="fas fa-birthday-cake"></i>
                      </span>
                      <Input
                          inputType={"date"}
                          name={"birthday"}
                          value={birthday}
                          handleChange={this.handleChange}
                      />
                      </div>
                  </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">Morada</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-home"></i>
                    </span>
                    <TextArea
                        rows={5}
                        value={address}
                        name={"address"}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">ID Associado</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-handshake"></i>
                    </span>
                    <Input
                        inputType={"text"}
                        name={"idassociado"}
                        value={idassociado}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">Tipo de Quota</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-money-bill"></i>
                    </span>
                    <AutoComplete
                        name={"quota"}
                        value={quota}
                        handleChange={this.handleChange}
                        options={quotaTypes}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">Subsistema de Saúde:</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-heartbeat"></i>
                    </span>
                    <AutoComplete
                        name={"healthSystem"}
                        value={healthSystem}
                        handleChange={this.handleChange}
                        options={healthSystems}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">Número SS:</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-id-card"></i>
                    </span>
                    <Input
                        inputType={"text"}
                        name={"number_ss"}
                        value={number_ss}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">Número Utente SNS:</label>
                <div className="col-md-9 inputGroupContainer">
                    <div className="input-group">
                    <span className="input-group-addon">
                        <i className="fas fa-id-card"></i>
                    </span>
                    <Input
                        inputType={"text"}
                        name={"numero_utente"}
                        value={numero_utente}
                        handleChange={this.handleChange}
                    />
                    </div>
                </div>
                </div>
                <div className="form-group">
                <label className="col-md-3 control-label">RGPD</label>
                <div className="col-md-9">
                    <Checkbox
                    name={"gdpr"}
                    checked={gdpr}
                    handleChange={this.handleCheckboxChange}
                    />
                </div>
                </div>
                <div className="form-group">
                <div className="col-xs-12">
                    <button
                    type="button"
                    onClick={this.handleFormSubmit}
                    style={{ width: "100%" }}
                    className="btn btn-success"
                    >
                    {edit ? "Guardar" : "Criar"}
                    </button>
                </div>
                </div>
            </fieldset>
        </div>
    </form>
    );
  }
}

export default withCookies(Page);
