export const valueWithIva = (value, percentage) => {
    return parseFloat(value) + (parseFloat(value) * ((parseFloat(percentage) / 100)));
}

export const valueWithoutIva = (value, percentage) => {
    if (!percentage) return '';
    return parseFloat(value) / (1 + (parseFloat(percentage) / 100));
}

export const threeDecimal = (value) => {
    if (!value) return '';
    return parseFloat(value).toFixed(3);
}

export const applyDiscount = (value, descPer) => {
    const discount = descPer ? value * (descPer / 100) : 0;
    return value - discount;
}

export const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const twoDecimal = (value) => {
    return parseFloat(value).toFixed(2);
}