import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import generateExcel from "zipcelx";

const LocalReactTable = props => {
  function getHeader(column) {
    return [
      {
        value: column.Header,
        type: "string"
      }
    ];
  }

  function getExcel() {
    let nameFile = prompt("Nome do ficheiro:");
    const config = {
      filename: nameFile || "download",
      sheet: {
        data: []
      }
    };

    const dataSet = config.sheet.data;

    const headerRow = [];
    const showColumns = [];
    const logicColumn = [];
    props.columns.forEach(column => {
      if (column.excel !== false) {
        headerRow.push(...getHeader(column));
        if (column.id) {
          logicColumn[column.id] = column.accessor;
        }
        showColumns.push(column.id || column.accessor)
      }
    });

    const extraShowColumns = [];
    if (props.excelType === 'lines') {
      props.excelLinesExtraDetails.forEach(({ label, value }) => {
        headerRow.push({
          value: label,
          type: "string"
        });
        extraShowColumns.push(value);
      })
    }

    dataSet.push(headerRow);

    if (props.data.length > 0) {
      let total = 0;
      props.data.forEach(row => {
        const dataRow = [];

        showColumns.forEach((key) => {
          const value = logicColumn[key] ? logicColumn[key](row) : row[key];
          if (props.excelTotal === key) {
            total += parseFloat(value);
          }
          dataRow.push({
            value,
            type: typeof value === "number" ? "number" : "string"
          })
        });

        if (props.excelType === 'lines') {
          row.lines.forEach((eachLine) => {
            const extraDataRow = [];
            extraShowColumns.forEach((key) => {
              const value = eachLine[key];
              if (props.excelTotal === key) {
                total += parseFloat(value);
              }
              extraDataRow.push({
                value,
                type: typeof value === "number" ? "number" : "string"
              });
            })
            dataSet.push([ ...dataRow, ...extraDataRow ]);
          })
        } else {
          dataSet.push(dataRow);
        }
      });
      if (props.excelTotal) {
        const dataRow = [];
        const totalPosition = [...showColumns, ...extraShowColumns].findIndex((key) => props.excelTotal === key);
        for (let position = 0; position < [...showColumns, ...extraShowColumns].length; position++) {
          if (position === totalPosition - 1) {
            dataRow.push({
              value: 'TOTAL',
              type: "string"
            })
          } else if (position === totalPosition) {
            dataRow.push({
              value: total,
              type: "number"
            });
          } else {
            dataRow.push({
              value: '',
              type: "string"
            })
          }
        }
        dataSet.push(dataRow);
      }
    } else {
      dataSet.push([
        {
          value: "Sem resultados",
          type: "string"
        }
      ]);
    }

    return generateExcel(config);
  }

  return (
    <>
      {props.excelDownload && props.data.length > 0 && (<table style={{ marginBottom: 0 }} className="table">
          <tbody>
              <tr>
                  <td  style={{ fontWeight: 'bolder', verticalAlign: 'middle', textAlign: 'right' }}>
                    <button onClick={getExcel}>
                      <i class="fas fa-download" /> Excel
                    </button>
                  </td>
              </tr>
          </tbody>
      </table>)}
      <ReactTable
        previousText={'Anterior'}
        nextText={'Seguinte'}
        loadingText={'Carregando...'}
        noDataText={'Sem resultados'}
        pageText={'Página'}
        ofText={'de'}
        rowsText={'linhas'}
        pageJumpText={'saltar para página'}
        rowsSelectorText={'linhas por página'}
        defaultPageSize={ props.pageSize || 10 }
        { ...props }
      />
    </>
  );
};

export default LocalReactTable;
