import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

// Components
import POSPage from '@components/POSPage'
import Cards from '@components/Cards'
import paginator from '@utils/paginator'

import { EventBus as eventBus } from "@components/EventBus";

class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pagination: {
                left: {
                    first: {
                        click: () => this.setState({ page: 1 }),
                        show: true,
                    },
                    previous: {
                        click: () => this.setState({ page: this.state.page - 1 }),
                        show: true,
                    }
                },
                right: {
                    next: {
                        click: () => this.setState({ page: this.state.page + 1 }),
                        show: true,
                    },
                    last: {
                        click: () => this.setState({ page: this.state.lastPage }),
                        show: true,
                    }
                }
            },
            items: [],
            page: 1,
            lastPage: 0,
            pageSize: 24,
            loaded: false,
        };

        this.loadFunction = this.loadFunction.bind(this);
    }

    loadFunction() {
        if (!this.state.loaded) {
            axios.get(`https://eposgestofinal.pt/api/tickets/types/get.php?idempresa=${this.props.cookies.get('member_idempresa')}`)
            .then(response => {
                const ticketTypes = response.data !== 'NOK' ? response.data : [];

                const enrichedCustomers = ticketTypes.map((ticketType) => ({
                        ...ticketType,
                        icon: 'fa-ticket-alt',
                        text: ticketType.name,
                        click: () => eventBus.$emit("SET/steps/form", { ticketType }),
                }));

                const lastPage = Math.ceil(enrichedCustomers.length / this.state.pageSize);

                this.setState({ items: enrichedCustomers, lastPage, loaded: true });
            })
            .catch(error => {
                NotificationManager.error(error.message, 'Tipos');
            });
        }
    }
    
    render () {
        const { items, pagination, page, pageSize } = this.state;
        const { form } = this.props;
        
        const pageInfo = paginator(items, page, pageSize);

        const realPagination = {
            left: pageInfo.pre_page ? pagination.left : { first: {}, previous: {} },
            right: pageInfo.next_page ? pagination.right : { next: {}, last: {} },
        }

        const data = pageInfo.data.map((item) => ({ ...item, active: item.idtickettype === form.ticketType.idtickettype}))

        return (
            <POSPage
                pagination={ realPagination }
                loadFunction={ this.loadFunction }
            >
                <Cards modifier="small" items={ data } />
            </POSPage>
        );
    }
}

export default withCookies(Page);