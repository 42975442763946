import React, { Component } from "react";

// Components
import POSMenu from "@components/POSMenu";

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navigation: [
        {
          icon: "fa-arrow-left",
          text: "Voltar",
          link: "/pos",
        },
      ],
    };
  }

  render() {
    return (
      <>
        <POSMenu {...this.props} />
        <section className="main-section">
          <div style={ { padding: '100px' } }>
            <i className="fas fa-lock" style={ { fontSize: '50px' } }></i>
            <h1>Não tem permissões para ver esta página</h1>
            <p>
              Por favor, contacte o administrador se você acredita que se trata
              de um erro.
            </p>
          </div>
        </section>
      </>
    );
  }
}

export default Page;
