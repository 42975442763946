import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";

import Input from "@components/Input";
import Modal from "@components/Modal";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { MdAddCircleOutline, MdVisibility } from "react-icons/md";

// Components
import Menu from "@components/Menu";
import ReactTable from "@components/ReactTable";
import AutoComplete from "@components/AutoComplete";


import UserContext from "@components/context/userContext";

import New from "./new";

import { EventBus as eventBus } from "@components/EventBus";

class Page extends Component {
  static contextType = UserContext;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    const customers = [{}];

    this.state = {
      loaded: false,
      customers,
      table: {
        columns: [
          {
            Header: "Codigo",
            accessor: "codigo",
          },
          {
            Header: "Nome 1",
            accessor: "nome_1",
          },
          {
            Header: "Morada 1",
            accessor: "morada_1",
          },
          {
            Header: "Telefone",
            accessor: "telefone",
          },
          {
            Header: "Telemovel",
            accessor: "telemovel",
          },
          {
            Header: "Email",
            accessor: "email",
          },
          {
            Header: 'Estado',
            id: 'inativo',
            accessor: d => d.inativo === 't' ? (
              <span className="badge badge-danger">INATIVO</span>
            ) : (
              <span className="badge badge-success">ATIVO</span>
            ),
          },
          {
            Header: "",
            accessor: "view",
          },
        ],
        rows: [],
        loading: true,
      },
      search: JSON.parse(localStorage.getItem("clientes.search")) || "",
      status: JSON.parse(localStorage.getItem("clientes.status")) || {
        value: "",
        label: "TODOS",
      },
      allStatus: [
        { value: "", label: "TODOS" },
        { value: "f", label: "ATIVOS" },
        { value: "t", label: "INATIVOS" },
      ]
    };
    this.loadFunction = this.loadFunction.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.uploadFunction = this.uploadFunction.bind(this);
  }

  loadFunction() {
    const profile = this.context;

    if (!this.state.loaded) {
      const { history } = this.props;

      axios
        .get(
          `https://eposgestofinal.pt/api/clientes/get.php?idempresa=${profile.idEmpresa}`
        )
        .then((response) => {
          const customers = response.data !== "NOK" ? response.data : [];

          const enrichedCustomers = customers.map((customer) => ({
            ...customer,
            inativo: customer.inactivo === 't' ? 't' : 'f',
            //gdpr: customer.gdpr === "t" ? "TRUE" : "FALSE",
            view: (
              <MdVisibility
                style={{ fontSize: "15px", cursor: "pointer" }}
                onClick={() =>
                  history.push(`/clientes/${customer.codigo}`)
                }
              />
            ),
          }));

          this.setState({
            table: {
              ...this.state.table,
              rows: enrichedCustomers,
              loading: false,
            },
            loaded: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  async removeFunction(data) {
    const profile = this.context;

    confirmAlert({
      title: "Confirmação",
      message: `Tem a certeza que deseja apagar o cliente ${data.name}?`,
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            axios
              .get(
                `https://eposgestofinal.pt/api/clientes/unset.php?idempresa=${profile.idEmpresa}&codigo=${data.codigo}`
              )
              .then((response) => {
                if (response.data !== "NOK") {
                  this.setState({
                    customers: this.state.customers.filter(
                      (client) => client.codigo !== data.codigo
                    ),
                  });
                }
              })
              .catch((error) => {
                console.error(error);
              });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  }

  filterData(searchTerm) {
    this.setState({ search: searchTerm });
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState({
      [name]: value,
    }, () => {
        switch (name) {
            case "search":
            case "status":
                localStorage.setItem(`clientes.${name}`, JSON.stringify(value));
                break;
            default:
                break;
            }
    });
  }

  async uploadFunction() {
    confirmAlert({
      title: "Confirmação",
      message: `Tem a certeza que deseja enviar todos os clientes para o Apollo?`,
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            const profile = this.context;
            axios
              .get(
                `https://eposgestofinal.pt/api/clientes/export.php?idempresa=${profile.idEmpresa}`
              )
              .catch((error) => {
                console.error(error);
              });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    const { table, search, status, allStatus } = this.state;
    const { location, history } = this.props;
    const cookies = new URLSearchParams(location.search);

    const filterSearch = search.split(" ");

    const oneOfAll = (customer, search) => {
      const keys = Object.keys(customer);
      return keys.some((key) => `${customer[key] || ''}`.toLowerCase().includes(search.toLowerCase()))
    }

    const tableRows = table.rows.length
      ? table.rows.filter((customer) =>
          filterSearch.every(
            (eachSearch) =>
              eachSearch === "" ||
              oneOfAll(customer, eachSearch)
          )
        ).filter((product) =>
          status.value !== ''
            ? product.inativo === status.value
            : true
        )
      : [];

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li className="active">Clientes (beta)</li>
            </ol>
          </div>
        </div>
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Clientes (beta)
                </div>
                <div>
                  <table
                    style={{ cursor: "pointer" }}
                    onClick={this.uploadFunction}
                  >
                    <tr>
                      <td>
                        <i className="fas fa-upload"></i>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: "xx-small" }}>
                        Enviar Clientes
                      </td>
                    </tr>
                  </table>
                </div>
                <div>
                  <table
                    style={{ cursor: "pointer" }}
                    onClick={() => eventBus.$emit("Modal-newcustomer", { header: "Criar cliente", body: <New /> })}
                  >
                    <tr>
                      <td>
                        <MdAddCircleOutline style={{ fontSize: "20px" }} />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: "xx-small" }}>Criar cliente</td>
                    </tr>
                  </table>
                </div>
              </div>

              <table className="table table-bordered table-hover table-sortable">
                <thead>
                  <tr>
                    <th className="text-center">Pesquisa</th>
                    <th className="text-center">Estado</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Input
                        inputType={"text"}
                        name={"search"}
                        value={search}
                        placeholder="pesquisa..."
                        handleChange={(e) => this.filterData(e.target.value)}
                      />
                    </td>
                    <td style={{ width: "200px" }}>
                      <AutoComplete
                        name={"status"}
                        value={status} 
                        handleChange={this.handleChange}
                        options={allStatus}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <ReactTable
                columns={table.columns}
                sortable={true}
                loading={table.loading}
                data={tableRows}
              />
            </div>
          </div>
        </div>
        <Modal id="newcustomer" />
      </div>
    );
  }
}

export default withCookies(withRouter(Page));
