import React, { useState } from "react";
import moment from "moment";
import TimeKeeper from "react-timekeeper";

const TimePicker = (props) => {
  const [value, onChange] = useState(props.value || moment().format("HH:mm"));

  const localOnChange = (value) => {
    if (typeof props.onChange == "function") {
      props.onChange(value);
    }
    onChange(value);
  };

  return (
    <TimeKeeper
      time={value}
      onChange={(newTime) => localOnChange(newTime.formatted24)}
      hour24Mode
      coarseMinutes={1}
      forceCoarseMinutes
    />
  );
};

export default TimePicker;
