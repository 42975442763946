import React, { Component } from 'react';
import { Modal as BaseModal } from 'react-responsive-modal';

import { EventBus as eventBus } from "../EventBus";

import 'react-responsive-modal/styles.css';
import './Modal.css';

class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventName: `Modal${this.props.id ? `-${this.props.id}` : '' }`,
            isOpen: this.props.isOpen || false,
            header: this.props.header || '',
            body: this.props.body || '',
            modifier: this.props.modifier || 'small',
        };
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        eventBus.$on(this.state.eventName, (data) => {
            this.setState({ ...data, isOpen: data.isOpen !== undefined ? data.isOpen : true })
        });
    }

    componentWillUnmount() {
        eventBus.$off(this.state.eventName);
    }

    toggleModal(e) {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        this.setState({ isOpen: false });
    }

    render() {
        const { isOpen, header, body, modifier } = this.state;
        const { children } = this.props;

        return (
            <BaseModal classNames={{
                modal: `${modifier}-react-responsive-modal-modal`,
              }} open={isOpen} onClose={this.toggleModal} center>
                <h2>{header}</h2>
                { children || (<div>{body}</div>) }
            </BaseModal>
        );
    }
}

export default Modal;