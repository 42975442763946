import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

// Components
import POSStructure from '@components/POSStructure'
import POSStatus from '@components/POSStatus'
import Steps from '@components/Steps'
import Modal from '@components/Modal'

import Customers from './customers'
import Types from './types'
import Payment from './payment'
import Values from './values'
import Summary from './summary'
import Print from './print'

import UserContext from '@components/context/userContext';

class Page extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            form: {
                currency: '',
                customer: { name: '' },
                ticketType: { name: '' },
                paymentMethod: { name: '' },
                values: [],
                loaded: false,
            },
            step: 0,
            steps: [{
                title: "Cliente",
                component: ({ form }) => (<Customers form={ form } />)
            }, {
                title: "Tipo",
                component: ({ form }) => (<Types form={ form } />)
            }, {
                title: "M.P.",
                component: ({ form }) => (<Payment form={ form } />)
            }, {
                title: "Valor",
                multiple: true,
                component: ({ form }) => (<Values form={ form } />)
            }, {
                title: "Resumo",
                submit: true,
                component: ({ form }) => (<Summary form={ form } />)
            }],
            goBack: () => '/pos/tickets',
            state: 'initial',
            success: {}
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.changeStepData = this.changeStepData.bind(this);
    }

    changeStepData(data, step) {
        if (data) {
            this.setState({ form: { ...this.state.form, ...data } })
            if (!this.state.steps[this.state.step].multiple) {
                this.setState({ step: this.state.step + 1 })
            }
        } else if (step > -1) {
            this.setState({ step })
        }
    }

    handleFormSubmit() {        
        const { form } = this.state;
        const profile = this.context;

        axios.post('https://eposgestofinal.pt/api/tickets/set.php', {
            idcustomer: form.customer.idcustomer,
            idtickettype: form.ticketType.idtickettype,
            idpaymentmethod: ['1'].includes(form.ticketType.idtickettype) ? form.paymentMethod.idmethod : undefined,
            values: form.values.filter((value) => value.counter > 0).map(({ value, counter }) => ({ value, counter })),
            idempresa: profile.idEmpresa,
            idemployee: profile.idEmployee,
        })
            .then((response) => {
                NotificationManager.success('Submetidas com sucesso!', 'Senhas');
                this.setState({ state: 'success', success: response })
            })
            .catch(error => {
                NotificationManager.error(error.message, 'Senhas');
                this.setState({ state: 'error' })
            });
    }

    loadFunction() {
        const profile = this.context;

        if (!this.state.loaded) {
            axios.get(`https://eposgestofinal.pt/api/config/currency/get.php?idempresa=${profile.idEmpresa}`)
                .then(response => {
                    const currency = response.data !== 'NOK' ? response.data[0].currency : '';

                    this.setState({ currency, loaded: true });
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Moeda');
                });
        }
    }

    render () {
        const { currency, steps, step, form, state, success, goBack } = this.state;
        const profile = this.context;
        this.loadFunction()

        return (
            <>
                <POSStructure
                    {...this.props}
                    title={ 'Criar senha' }
                    goBack={ goBack }
                >
                    {
                        {
                            'initial': 
                                <Steps
                                    steps={ steps }
                                    step={ step }
                                    form={ form }
                                    changeStepData={ this.changeStepData }
                                    submit={ this.handleFormSubmit } />,
                            'success':
                                <POSStatus status="success" title="Sucesso" message="Senhas geradas." action={<div><p>Pode abandonar a página!</p><Print customer={form.customer.name} type={form.ticketType.name} employee={profile.name} data={success.data} currency={currency} /></div>} />,
                            'error':
                                <POSStatus status="error" title="Erro" message="Algo correu mal" action="Volte a tentar mais tarde!" />
                        }[state]
                    }
                </POSStructure>
                <Modal id="ticketsvalues"/>
            </>
        );
    }
}

export default withCookies(Page);