import React from "react";

import Input from "@components/Input";
import Button from "@components/Button";

class Example extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            custom: props.customValue || '',
        };
    
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleChange(e, callback) {
        let value = e.target.value;
        let name = e.target.name;
        
        this.setState({
            [name]: value,
        });
    }

    handleFormSubmit() {
        const { custom } = this.state;
        const { setCustomValue, id } = this.props;
        setCustomValue(custom, id);
    }

    render() {
        const { custom } = this.state;

        return (
            <div>
                <Input
                    inputType={"number"}
                    name={"custom"}
                    value={custom}
                    handleChange={this.handleChange}
                />
                <Button
                    action={this.handleFormSubmit}
                    type={"primary"}
                    title={"Submeter"}
                />
            </div>
        );
    }
}

export default Example;
