import React, { Component } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Pages
import CustomersList from "@pages/customers/list";
import EstimatesList from "@pages/estimates/list";
import PartnersList from "@pages/partners/list";
import ProvidersList from "@pages/providers/list";
import ProductsList from "@pages/products/list";
import OrdersList from "@pages/orders/list";
import SalesList from "@pages/sales/list";
import PsaList from "@pages/psa/list";
import EmployeesList from "@pages/employees/list";
import TicketsList from "@pages/tickets/list";
import StocksList from '@pages/stocks/list';
import AgendaList from '@pages/agenda/list';
import CalendarList from '@pages/calendar/list';
import SequencesList from '@pages/sequences/list';
import TaxesList from "@pages/taxes/list";
import CountersList from "@pages/counters/list";
import GuiasList from "@pages/guias/list";
import ClientesList from "@pages/clientes/list";
import PricetagsList from "@pages/pricetags/list";
import TourniquetList from "@pages/tourniquet/list";

import ServicesForm from "@pages/customers/services/form";
import PhotosForm from "@pages/customers/services/photos";
import HouseholdForm from "@pages/customers/household/form";
import QuotasForm from "@pages/customers/quotas/form";
import CustomersForm from "@pages/customers/form";
import EstimatesForm from "@pages/estimates/form";
import PartnersForm from "@pages/partners/form";
import ProvidersForm from "@pages/providers/form";
import ProductsForm from "@pages/products/form";
import OrdersForm from "@pages/orders/form";
import SalesForm from "@pages/sales/form";
import PsaForm from "@pages/psa/form";
import EmployeesForm from "@pages/employees/form";
import ClientesForm from "@pages/clientes/form";
import PricetagsForm from "@pages/pricetags/edit";

import SequencesBulk from '@pages/sequences/bulk';

import PricesCombustiveis from "@pages/prices/combustivel";

import ProductsSync from "@pages/products/sync";
import ProductsPricetags from "@pages/products/pricetags";
import ProductsPricetagsProducts from "@pages/products/pricetagsproducts";

import PsaView from "@pages/psa/view";
import OrdersView from "@pages/orders/view";
import SalesView from "@pages/sales/view";
import VeederRootView from "@pages/veeder-root/view";
import GuiasView from "@pages/guias/view";

import PsaLogs from "@pages/psa/logs";

import HomePOS from "@pages/pos";
import AccessDeniedPOS from "@pages/pos/access-denied";
import CustomersPOS from "@pages/pos/customers";
import CustomersListPOS from "@pages/pos/customers/list";
import TicketsPOS from "@pages/pos/tickets";
import TicketsReadPOS from "@pages/pos/tickets/read";
import TicketsNewPOS from "@pages/pos/tickets/new";
import InventoryPOS from "@pages/pos/inventory";
import InventoryNewPOS from "@pages/pos/inventory/new";
import CountersPOS from "@pages/pos/counters";

import InoutPublic from "@pages/public/inout";

import UserContext from "@components/context/userContext";

const changeFavicon = (url) => {
  let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = url;
  document.getElementsByTagName('head')[0].appendChild(link);
}

class Page extends Component {
  static contextType = UserContext;

  render() {
    const profile = this.context;
    const hydratedProps = (props) => ({ ...props, profile });

    return (
      <div>
        <Router>
          <Switch>
          <Route
              path="/customers"
              exact
              render={(props) => {
                document.title = 'ePOS - Clientes';
                return (
                  <CustomersList {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/customers/new"
              exact
              render={(props) => {
                document.title = 'ePOS - Clientes';
                return (
                  <CustomersForm {...hydratedProps(props)} type="new" />
                );
              }}
            />
            <Route
              path="/customers/:idcustomer/services/new"
              render={(props) => {
                document.title = 'ePOS - Serviços';
                return (
                  <ServicesForm {...hydratedProps(props)} type="new" />
                );
              }}
            />
            <Route
              path="/customers/:idcustomer/services/:idservice/photos"
              render={(props) => {
                document.title = 'ePOS - Serviços';
                return (
                  <PhotosForm {...hydratedProps(props)} type="edit" />
                );
              }}
            />
            <Route
              path="/customers/:idcustomer/services/:idservice"
              render={(props) => {
                document.title = 'ePOS - Serviços';
                return (
                  <ServicesForm {...hydratedProps(props)} type="edit" />
                );
              }}
            />
            <Route
              path="/customers/:idcustomer/household/new"
              render={(props) => {
                document.title = 'ePOS - agregado';
                return (
                  <HouseholdForm {...hydratedProps(props)} type="new" />
                );
              }}
            />
            <Route
              path="/customers/:idcustomer/household/:idhousehold"
              render={(props) => {
                document.title = 'ePOS - Agregado';
                return (
                  <HouseholdForm {...hydratedProps(props)} type="edit" />
                );
              }}
            />
            <Route
              path="/customers/:idcustomer/quotas/new"
              render={(props) => {
                document.title = 'ePOS - Quotas';
                return (
                  <QuotasForm {...hydratedProps(props)} type="new" />
                );
              }}
            />
            <Route
              path="/customers/:idcustomer/quotas/:idquota"
              render={(props) => {
                document.title = 'ePOS - Quotas';
                return (
                  <QuotasForm {...hydratedProps(props)} type="edit" />
                );
              }}
            />
            <Route
              path="/customers/:idcustomer"
              render={(props) => {
                document.title = 'ePOS - Clientes';
                return (
                  <CustomersForm {...hydratedProps(props)} type="edit" />
                );
              }}
            />
            <Route
              path="/estimates"
              exact
              render={(props) => {
                document.title = 'ePOS - Sondagens';
                return (
                  <EstimatesList {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/estimates/new"
              exact
              render={(props) => {
                document.title = 'ePOS - Sondagens';
                return (
                  <EstimatesForm {...hydratedProps(props)} type="new" />
                );
              }}
            />
            <Route
              path="/estimates/:idproduct"
              render={(props) => {
                document.title = 'ePOS - Sondagens';
                return (
                  <EstimatesForm {...hydratedProps(props)} type="edit" />
                );
              }}
            />
            <Route
              path="/partners"
              exact
              render={(props) => {
                document.title = 'ePOS - Parceiros';
                return (
                  <PartnersList {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/partners/new"
              exact
              render={(props) => {
                document.title = 'ePOS - Parceiros';
                return (
                  <PartnersForm {...hydratedProps(props)} type="new" />
                );
              }}
            />
            <Route
              path="/partners/:idpartner"
              render={(props) => {
                document.title = 'ePOS - Parceiros';
                return (
                  <PartnersForm {...hydratedProps(props)} type="edit" />
                );
              }}
            />
            <Route
              path="/providers"
              exact
              render={(props) => {
                document.title = 'ePOS - Fornecedores';
                return (
                  <ProvidersList {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/providers/new"
              exact
              render={(props) => {
                document.title = 'ePOS - Fornecedores';
                return (
                  <ProvidersForm {...hydratedProps(props)} type="new" />
                );
              }}
            />
            <Route
              path="/providers/:idprovider"
              render={(props) => {
                document.title = 'ePOS - Fornecedores';
                return (
                  <ProvidersForm {...hydratedProps(props)} type="edit" />
                );
              }}
            />
            <Route
              path="/products"
              exact
              render={(props) => {
                document.title = 'ePOS - Produtos';
                return (
                  <ProductsList {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/products/new"
              exact
              render={(props) => {
                document.title = 'ePOS - Produtos';
                return (
                  <ProductsForm {...hydratedProps(props)} type="new" />
                );
              }}
            />
            <Route
              path="/products/sync"
              exact
              render={(props) => {
                document.title = 'ePOS - Produtos';
                return (
                  <ProductsSync {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/products/pricetags/:warehouse/:pricetag"
              exact
              render={(props) => {
                document.title = 'ePOS - Etiquetas';
                return (
                  <ProductsPricetagsProducts {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/products/pricetags"
              exact
              render={(props) => {
                document.title = 'ePOS - Etiquetas';
                return (
                  <ProductsPricetags {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/products/:idproduct/:tabIndex/:redirect"
              render={(props) => {
                document.title = 'ePOS - Produtos';
                return (
                  <ProductsForm {...hydratedProps(props)} type="edit" />
                );
              }}
            />
            <Route
              path="/products/:idproduct/:tabIndex"
              render={(props) => {
                document.title = 'ePOS - Produtos';
                return (
                  <ProductsForm {...hydratedProps(props)} type="edit" />
                );
              }}
            />
            <Route
              path="/products/:idproduct"
              render={(props) => {
                document.title = 'ePOS - Produtos';
                return (
                  <ProductsForm {...hydratedProps(props)} type="edit" />
                );
              }}
            />
            <Route
              path="/orders"
              exact
              render={(props) => {
                document.title = 'ePOS - Compras';
                return (
                  <OrdersList {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/orders/new"
              exact
              render={(props) => {
                document.title = 'ePOS - Compras';
                return (
                  <OrdersForm {...hydratedProps(props)} type="new" />
                );
              }}
            />
            <Route
              path="/orders/edit/:idorder"
              render={(props) => {
                document.title = 'ePOS - Compras';
                return (
                  <OrdersForm {...hydratedProps(props)} type="edit" />
                );
              }}
            />
            <Route
              path="/invoices/:talao/warehouses/:posto/series/:serie/tpvs/:tpv/products/:idproduct"
              render={(props) => {
                document.title = 'ePOS - Compras';
                return (
                  <OrdersView {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/orders/:idorder/products/:idproduct"
              render={(props) => {
                document.title = 'ePOS - Compras';
                return (
                  <OrdersView {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/orders/:idorder"
              render={(props) => {
                document.title = 'ePOS - Compras';
                return (
                  <OrdersView {...hydratedProps(props)} />
                );
              }}
            />



            <Route
              path="/sales"
              exact
              render={(props) => {
                document.title = 'ePOS - Vendas';
                return (
                  <SalesList {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/sales/new"
              exact
              render={(props) => {
                document.title = 'ePOS - Vendas';
                return (
                  <SalesForm {...hydratedProps(props)} type="new" />
                );
              }}
            />
            <Route
              path="/sales/:idsale/products/:idproduct"
              render={(props) => {
                document.title = 'ePOS - Vendas';
                return (
                  <SalesView {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/sales/:doctype/:idsale"
              render={(props) => {
                document.title = 'ePOS - Vendas';
                return (
                  <SalesView {...hydratedProps(props)} />
                );
              }}
            />

            <Route
              path="/pricetags"
              exact
              render={(props) => {
                document.title = 'ePOS - Etiquetas';
                return (
                  <PricetagsList {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/pricetags/new"
              exact
              render={(props) => {
                document.title = 'ePOS - Etiquetas';
                return (
                  <PricetagsForm {...hydratedProps(props)} type="new" />
                );
              }}
            />
            <Route
              path="/pricetags/:idpricetag"
              render={(props) => {
                document.title = 'ePOS - Etiquetas';
                return (
                  <PricetagsForm {...hydratedProps(props)} type="edit" />
                );
              }}
            />


            <Route
              path="/psa"
              exact
              render={(props) => {
                document.title = 'ePOS - PumpServerApollo';
                return (
                  <PsaList {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/psa/:idpsa/config"
              render={(props) => {
                document.title = 'ePOS - PumpServerApollo';
                return (
                  <PsaForm {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/psa/:idpsa/logs"
              render={(props) => {
                document.title = 'ePOS - PumpServerApollo';
                return (
                  <PsaLogs {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/psa/:idpsa"
              render={(props) => {
                document.title = 'ePOS - PumpServerApollo';
                return (
                  <PsaView {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/veeder-root"
              exact
              render={(props) => {
                document.title = 'ePOS - VeederRoot';
                return (
                  <VeederRootView {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/employees"
              exact
              render={(props) => {
                document.title = 'ePOS - Colaboradores';
                return (
                  <EmployeesList {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/employees/new"
              exact
              render={(props) => {
                document.title = 'ePOS - Colaboradores';
                return (
                  <EmployeesForm {...hydratedProps(props)} type="new" />
                );
              }}
            />
            <Route
              path="/prices/combustivel"
              render={(props) => {
                document.title = 'ePOS - Preços';
                return (
                  <PricesCombustiveis {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/employees/:idemployee"
              render={(props) => {
                document.title = 'ePOS - Colaboradores';
                return (
                  <EmployeesForm {...hydratedProps(props)} type="edit" />
                );
              }}
            />

            <Route
              path="/tickets"
              exact
              render={(props) => {
                document.title = 'ePOS - Senhas';
                return (
                  <TicketsList {...hydratedProps(props)} />
                );
              }}
            />

            <Route
              path="/stocks"
              exact
              render={(props) => {
                document.title = 'ePOS - Stocks';
                return (
                  <StocksList {...hydratedProps(props)} />
                );
              }}
            />

            <Route
              path="/agenda"
              exact
              render={(props) => {
                document.title = 'ePOS - Agenda';
                return (
                  <AgendaList {...hydratedProps(props)} />
                );
              }}
            />

            <Route
              path="/calendar"
              exact
              render={(props) => {
                document.title = 'ePOS - Calendário';
                return (
                  <CalendarList {...hydratedProps(props)} />
                );
              }}
            />

            <Route
              path="/series"
              exact
              render={(props) => {
                document.title = 'ePOS - Séries';
                return (
                  <SequencesList {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/series/bulk"
              exact
              render={(props) => {
                document.title = 'ePOS - Séries';
                return (
                  <SequencesBulk {...hydratedProps(props)} type="bulk" />
                );
              }}
            />

            <Route
              path="/taxes"
              exact
              render={(props) => {
                document.title = 'ePOS - Impostos';
                return (
                  <TaxesList {...hydratedProps(props)} />
                );
              }}
            />

            <Route
              path="/counters"
              exact
              render={(props) => {
                document.title = 'ePOS - Contadores';
                return (
                  <CountersList {...hydratedProps(props)} />
                );
              }}
            />

            <Route
              path="/guias"
              exact
              render={(props) => {
                document.title = 'ePOS - Guias';
                return (
                  <GuiasList {...hydratedProps(props)} />
                );
              }}
            />

            <Route
              path="/guias/:doc"
              render={(props) => {
                document.title = 'ePOS - Guias';
                return (
                  <GuiasView {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/clientes"
              exact
              render={(props) => {
                document.title = 'ePOS - Clientes';
                return (
                  <ClientesList {...hydratedProps(props)} />
                );
              }}
            />
            <Route
              path="/clientes/new"
              exact
              render={(props) => {
                document.title = 'ePOS - Clientes';
                return (
                  <ClientesForm {...hydratedProps(props)} type="new" />
                );
              }}
            />
            <Route
              path="/clientes/:codigo"
              render={(props) => {
                document.title = 'ePOS - Clientes';
                return (
                  <ClientesForm {...hydratedProps(props)} type="edit" />
                );
              }}
            />

            <Route
              path="/tourniquet"
              exact
              render={(props) => {
                document.title = 'ePOS - Torniquete';
                return (
                  <TourniquetList {...hydratedProps(props)} />
                );
              }}
            />





            <Route
              path="/pos"
              exact
              render={(props) => {
                document.title = 'ePOS - POS';
                return profile.permissions.includes("pos.customers") ||
                  profile.permissions.includes("pos.tickets") ||
                  profile.permissions.includes("pos.inventory") ? (
                    <HomePOS {...hydratedProps(props)} POSMenu="home" />
                  ) : (
                    <AccessDeniedPOS {...hydratedProps(props)} />
                  );
              }}
            />
            <Route
              path="/pos/home"
              exact
              render={(props) => {
                document.title = 'ePOS - POS';
                return profile.permissions.includes("pos.customers") ||
                  profile.permissions.includes("pos.tickets") ||
                  profile.permissions.includes("pos.inventory") ? (
                    <HomePOS {...hydratedProps(props)} POSMenu="home" />
                  ) : (
                    <AccessDeniedPOS {...hydratedProps(props)} />
                  );
              }
              }
            />
            <Route
              path="/pos/customers"
              exact
              render={(props) => {
                document.title = 'ePOS - POS';
                return profile.permissions.includes("pos.customers") ? (
                    <CustomersPOS {...hydratedProps(props)} POSMenu="customers" />
                  ) : (
                    <AccessDeniedPOS {...hydratedProps(props)} />
                  );
              }
              }
            />
            <Route
              path="/pos/customers/list"
              exact
              render={(props) => {
                document.title = 'ePOS - POS';
                return profile.permissions.includes("pos.customers") ? (
                    <CustomersListPOS
                      {...hydratedProps(props)}
                      POSMenu="customers"
                    />
                  ) : (
                    <AccessDeniedPOS {...hydratedProps(props)} />
                  );
              }
              }
            />
            <Route
              path="/pos/tickets"
              exact
              render={(props) => {
                document.title = 'ePOS - POS';
                return profile.permissions.includes("pos.tickets") ? (
                    <TicketsPOS {...hydratedProps(props)} POSMenu="tickets" />
                  ) : (
                    <AccessDeniedPOS {...hydratedProps(props)} />
                  );
              }
              }
            />
            <Route
              path="/pos/tickets/read"
              exact
              render={(props) => {
                document.title = 'ePOS - POS';
                return profile.permissions.includes("pos.tickets") ? (
                    <TicketsReadPOS {...hydratedProps(props)} POSMenu="tickets" />
                  ) : (
                    <AccessDeniedPOS {...hydratedProps(props)} />
                  );
              }
              }
            />
            <Route
              path="/pos/tickets/new"
              exact
              render={(props) => {
                document.title = 'ePOS - POS';
                return profile.permissions.includes("pos.tickets") ? (
                    <TicketsNewPOS {...hydratedProps(props)} POSMenu="tickets" />
                  ) : (
                    <AccessDeniedPOS {...hydratedProps(props)} />
                  );
              }
              }
            />
            <Route
              path="/pos/inventory"
              exact
              render={(props) => {
                document.title = 'ePOS - POS';
                return profile.permissions.includes("pos.inventory") ? (
                    <InventoryPOS {...hydratedProps(props)} POSMenu="inventory" />
                  ) : (
                    <AccessDeniedPOS {...hydratedProps(props)} />
                  );
              }
              }
            />
            <Route
              path="/pos/inventory/new"
              exact
              render={(props) => {
                document.title = 'ePOS - POS';
                return profile.permissions.includes("pos.inventory") ? (
                    <InventoryNewPOS
                      {...hydratedProps(props)}
                      POSMenu="inventory"
                    />
                  ) : (
                    <AccessDeniedPOS {...hydratedProps(props)} />
                  );
              }
              }
            />
            <Route
              path="/pos/counters"
              exact
              render={(props) => {
                document.title = 'ePOS - POS';
                return profile.permissions.includes("pos.counters") ? (
                    <CountersPOS {...hydratedProps(props)} POSMenu="counters" ePOSOptions={{ menu: false, breadCrumbs: false, columnsToHide: ['losses', 'view'] }} />
                  ) : (
                    <AccessDeniedPOS {...hydratedProps(props)} />
                  );
              }
              }
            />
            <Route
              path="/public/inout"
              exact
              render={(props) => {
                document.title = 'Registo de Entradas';
                changeFavicon('https://eposgestofinal.pt/site/images/clock.ico');
                return profile.permissions.includes("pos.inout") ? (
                    <InoutPublic {...hydratedProps(props)} ePOSOptions={{ menu: false, breadCrumbs: false, public: true }} />
                  ) : (
                    <InoutPublic {...hydratedProps(props)} ePOSOptions={{ menu: false, breadCrumbs: false, public: true }} />
                  );
              }
              }
            />
            <Route
              path="/"
              exact
              component={() => {
                window.location.href = "https://eposgestofinal.pt/";
                return null;
              }}
            />
            <Route
              path="*"
              component={({ location: { pathname } }) => {
                window.location.href = `https://eposgestofinal.pt${pathname}`;
                return null;
              }}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default Page;
