import React, { useState, useContext } from "react";
import axios from 'axios';

import Input from "@components/Input";
import Button from "@components/Button";

import UserContext from "@components/context/userContext";

const AtLogin = ({ onLogin, selectedEmpresas }) => {
    const profile = useContext(UserContext)
    const [ loaded, setLoaded ] = useState(false);
    const [ edit, setEdit ] = useState(true);
    const [ login, setLogin ] = useState({
        username: '',
        password: '',
    });

    const loadFunction = () => {
        if (!loaded) {
            setLoaded(true);
            axios.get(`https://eposgestofinal.pt/api/at/logins/getById.php?idempresa=${selectedEmpresas ? profile.idEmpresaSec : profile.idEmpresa}`)
                .then(response => {
                    const loginResult = response.data !== 'NOK' ? response.data : {};
        
                    if (loginResult.username) {
                        onLogin(loginResult);
                        setEdit(false);
                    }

                    setLogin(loginResult);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    };

    loadFunction();

    const editMode = () => {
        setEdit(true);
    };

    const saveData = () => {
        setEdit(false);
        axios.post('https://eposgestofinal.pt/api/at/logins/set.php', {
            idempresa: selectedEmpresas ? profile.idEmpresaSec : profile.idEmpresa,
            username: login.username,
            password: login.password,
        })
        .then(() => {
            onLogin();
        })
    };

    const handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        
       setLogin({
        ...login,
        [name]: value,
       });
    }

    return (
    <div
        className="fullContainer" style={{ maxWidth: '100%' }}
    >
        <div
            className="col-sm-12"
            style={{ textAlign: 'center' }}
        >
            <div className="panel panel-default">
                <div
                    className="panel-heading"
                    style={{
                        fontWeight: 'bold',
                        fontSize: '15pt',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'left',
                        }}
                    >
                        AT Login
                    </div>
                </div>
                <div
                    style={{
                        textAlign: 'left',
                        width: '100%',
                    }}
                    className="container-fluid"
                >
                    <table className="table table-bordered table-hover table-sortable">
                        <thead>
                            <tr >
                                <th style={{ textAlign: 'left' }}>Username</th>
                                <th style={{ textAlign: 'left' }}>Password</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td>
                                    {!edit ? login.username : <Input
                                        inputType={"text"}
                                        name={"username"}
                                        value={login.username}
                                        handleChange={handleChange}
                                    />}
                                </td>
                                <td>
                                    {!edit ? '*****' : <Input
                                        inputType={"password"}
                                        name={"password"}
                                        value={login.password}
                                        handleChange={handleChange}
                                    />}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {/* About you */}
                    {!edit ? <Button
                    type={"primary"}
                    title={"Editar"}
                    action={editMode}
                    /> : <Button
                    type={"primary"}
                    title={"Guardar"}
                    action={saveData}
                    />}
                </div>
            </div>
        </div>
    </div>
  );
};

export default AtLogin;
