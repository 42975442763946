import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

// Components
import ReactTable from '@components/ReactTable';
import Checkbox from "@components/CheckBox";
import Input from "@components/Input";

import 'react-smarttab/dist/index.css'

import UserContext from '@components/context/userContext';

class Page extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            table: {
                columns: [
                    {
                      Header: '',
                      id: 'selected',
                      accessor: d => (
                        <Checkbox
                            name={"selected"}
                            checked={ this.state.selected.includes[d.idbill] }
                            handleChange={() => this.handleCheckboxChange(d.idbill)}
                        />
                      ),
                    },
                    {
                      Header: 'Documento',
                      accessor: 'doc',
                    },
                    {
                      Header: 'Data',
                      accessor: 'date',
                    },
                    {
                      Header: 'Débito',
                      id: 'debit',
                      accessor: d => d.debit > 0 ? `${this.state.currency} ${d.debit}` : '',
                    },
                    {
                      Header: 'Em falta',
                      id: 'missing',
                      accessor: d => {
                        return (
                            <Input
                                inputType={"number"}
                                value={this.state.missingValues[d.idbill]}
                                handleChange={(e) => this.handleChange(d.idbill, e.target.value)}
                            />
                        )
                      }
                    },
                ],
                rows: [],
                loading: true,
            },
            currency: '',
            payments: {},
            selected: [],
            missingValues: {},
            loaded: false,
        };

        this.loadFunction = this.loadFunction.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    loadFunction() {
        const profile = this.context;
        const { idcustomer } = this.props;

        if (!this.state.loaded) {
            axios.get(`https://eposgestofinal.pt/api/config/payment/methods/get.php?idempresa=${profile.idEmpresa}`)
                .then(response => {
                    const payments = response.data !== 'NOK' ? response.data : [];

                    const enrichedPayments = payments.reduce((accum, payment) => ({
                            ...accum,
                            [payment.idmethod]: payment.name, 
                    }), {});

                    this.setState({ payments: enrichedPayments, loaded: true });
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Métodos de Pagamento');
                });
            axios.get(`https://eposgestofinal.pt/api/config/currency/get.php?idempresa=${profile.idEmpresa}`)
                .then(response => {
                    const currency = response.data !== 'NOK' ? response.data[0].currency : '';

                    this.setState({ currency, loaded: true });
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Moeda');
                });
            axios.get(`https://eposgestofinal.pt/api/customers/bills/get.php?idempresa=${profile.idEmpresa}&idcustomer=${idcustomer}`)
                .then(response => {
                    const docs = response.data !== 'NOK' ? response.data : [];

                    const enrichedDocs = docs
                        .filter(({ type, debit, paid }) => type === 'S' && debit > 0 && paid < debit)
                        .map((doc) => ({ ...doc, missing: doc.debit - doc.paid, selected: false }))

                    this.setState({ table: {
                        ...this.state.table,
                        rows: enrichedDocs,
                        loading: false
                    }, missingValues: enrichedDocs.reduce((accum, doc) => ({
                        ...accum,
                        [doc.idbill]: doc.missing
                    }), {}), loaded: true });
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Movimentos');
                });
        }
    }

    handleCheckboxChange(idbill) {
        if (this.state.selected.includes(idbill)) {
            this.setState({ selected: this.state.selected.filter((sel) => sel !== idbill) })
        } else {
            this.setState({ selected: [ ...this.state.selected, idbill ] })
        }
    };

    handleChange(idbill, value) {
        this.setState({
            missingValues: {
                ...this.state.missingValues,
                [idbill]: value,
            },
        });
    }

    render () {
        const { table, missingValues, selected } = this.state;
        this.loadFunction()

        return (
            <>
                <p>{JSON.stringify(missingValues)}</p>
                <p>{JSON.stringify(selected)}</p>
                <ReactTable
                    columns={ table.columns }
                    sortable={ true }
                    loading={ table.loading }
                    data={ table.rows }
                />
            </>
        );
    }
}

export default withCookies(Page);