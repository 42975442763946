import React, { useState } from "react";
import moment from "moment";
import Calendar from "react-calendar";

import "react-calendar/dist/Calendar.css";

const DateTime = (props) => {
  const [value, onChange] = useState(props.value || moment().toDate());

  const localOnChange = (value) => {
    if (typeof props.onChange == 'function') {
        props.onChange(value)
    }
    onChange(value)
  }

  return (<Calendar onChange={localOnChange} defaultValue={value} />);
};

export default DateTime;
