import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { TaskQueue } from 'cwait';


// Components
import Menu from "@components/Menu";
import Modal from '@components/Modal';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

/* Import Components */
import EditableTable from "@components/EditableTable";
import Button from "@components/Button";
import Input from "@components/Input";
import AtLogin from "@components/AtLogin";
import EmpresaSelect from "@components/EmpresaSelect";
import Checkbox from "@components/CheckBox";

import { EventBus as eventBus } from "@components/EventBus";

import UserContext from "@components/context/userContext";

import "./bulk.css";

const MAX_SIMULTANEOUS_DOWNLOADS = 10;

const zeroPad = (num, places) => String(num).padStart(places, "0");

function getDocType(doctype) {
  switch (doctype) {
    case "FT":
    case "FT_epos":
      return "Faturas";
    case "FR":
    case "FR_epos":
      return "Vendas a Dinheiro";
    case "NC":
      return "Notas de Crédito";
    case "RG":
    case "RG_epos":
      return "Recibos";
    case "OU":
      return "Serviços Internos";
    case "GT":
        return "Guias Transporte";
    case "GA":
      return "Guias de Ativos";
    default:
      return "Talões";
  }
}

function getATMapDocType(doctype) {
  switch (doctype) {
    case "FT":
    case "FT_epos":
      return {
        tipo: "FT",
        classe: "SI",
      };
    case "FR":
    case "FR_epos":
      return {
        tipo: "FR",
        classe: "SI",
      };
    case "NC":
      return {
        tipo: "NC",
        classe: "SI",
      };
    case "RG":
    case "RG_epos":
      return {
        tipo: "RG",
        classe: "PY",
      };
    case "OU":
      return {
        tipo: "OU",
        classe: "WD",
      };
    case "GT":
      return {
        tipo: "GT",
        classe: "MG",
      };
    case "GA":
        return {
            tipo: "GA",
            classe: "MG",
        };
    default:
      return {
        tipo: "FT",
        classe: "SI",
      };
  }
}

function categorize(string){
  if (string.includes('FT')){
    return 'FT';
  }
  if (string.includes('NC')){
    return 'NC';
  }
  if (string.includes('FR')){
    return 'FR';
  }
  if (string.includes('RG')){
    return 'RG';
  }
  if (string.includes('GT')){
    return 'GT';
  }
  if (string.includes('F')){
    return 'F';
  }
  if (string.includes('EPOS')){
    return 'EPOS';
  }
}

function nextSerie(serie, isbackoffice) {
  const prefix = categorize(serie);
  if (isbackoffice) {
    return `${prefix}${zeroPad(parseInt(serie.replace(prefix, ""), 10) + 1, 2)}`;
  }
  return `${prefix}${parseInt(serie.replace(prefix, ""), 10) + 1}`;
}

function nextInitial(startdate, isbackoffice, doctype) {
  const currentYear = startdate.substring(0, 4);
  if (isbackoffice) {
    if (doctype.includes('_epos')) {
      return 1;
    }
    return `${currentYear}000001`;
  }
  return 1;
}

class Form extends Component {
  static contextType = UserContext;
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    const {
      match: { params },
      type,
    } = this.props;

    const startDate = new Date().getMonth() === 11 ? new Date(new Date().getFullYear() + 1, 0, 1) : new Date();

    this.state = {
      form: {
        next: "",
        previous: "",
        loading: {
          save: false,
        },
        avoidSubmit: false,
      },
      loaded: false,
      name: "",
      nif: "",
      email: "",
      phone: "",
      address: "",
      idapollo: "",
      idprovider: type === "edit" && params.idprovider,
      edit: type === "edit",
      data: [],
      dataChanged: -1,
      tpvs: [],
      atlogin: false,
      date: startDate.toISOString().substr(0, 10),
      environment: 'production',
      allSelected: false,
    };
    this.preHandleFormSubmit = this.preHandleFormSubmit.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.loadFunction = this.loadFunction.bind(this);
    this.onTableChange = this.onTableChange.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.validForm = this.validForm.bind(this);
  }

  loadFunction() {
    if (!this.state.loaded) {
      const profile = this.context;

      axios
        .get(
          `https://eposgestofinal.pt/api/tpvs/get.php?idempresa=${profile.idEmpresaSec}&environment=${this.state.environment}`
        )
        .then((response) => {
          const tpvs = response.data !== "NOK" ? response.data : [];

          const enrichedTpvs = tpvs.map((tpv, index) => {
            const { tipo, classe } = getATMapDocType(tpv.doctype);
            return {
              ...tpv,
              id: index,
              serie: nextSerie(tpv.serie, tpv.posto === ''),
              initial: nextInitial(this.state.date, tpv.posto === '', tpv.doctype),
              doctypedesc: getDocType(tpv.doctype),
              tipo,
              classe,
            };
          });

          const enrichedTpvsByDocumentType = enrichedTpvs.reduce((acc, item) => {
            if (item.posto !== '') {
              if (!acc[item.posto]) {
                acc[item.posto] = { name: item.nome, selected: false, disabled: false, childs: {} };
              }
              if (!acc[item.posto].childs[item.tpv]) {
                acc[item.posto].childs[item.tpv] = { selected: false, disabled: false, name: item.tpv, items: [] };
              }
              acc[item.posto].childs[item.tpv].items.push({
                ...item,
                id: `${item.posto}|${item.tpv}|${item.index}`
              });
            } else {
              if (!acc.bo) {
                acc.bo = { name: 'Backoffice', selected: false, disabled: false, childs: {} };
              }
              if (!acc.bo.childs[item.doctype]) {
                acc.bo.childs[item.doctype] = { selected: false, disabled: false, name: getDocType(item.doctype), items: [] };
              }
              acc.bo.childs[item.doctype].items.push({
                ...item,
                id: `bo|${item.doctype}|${item.index}`
              });
            }
            return acc;
          }, {});

          this.setState({ tpvs: enrichedTpvsByDocumentType, loaded: true });
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          `https://eposgestofinal.pt/api/sequences/get.php?idempresa=${profile.idEmpresaSec}&environment=${this.state.environment}`
        )
        .then((response) => {
          const sequences = response.data !== "NOK" ? response.data : [];

          this.setState({ sequences: sequences, loaded: true });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  preHandleFormSubmit(e) {
    const { form } = this.state;
    e.preventDefault();

    eventBus.$emit("Modal-dateToSeries", {
      header: "Data de ínicio",
    })
  }

  async handleFormSubmit() {
    const { history } = this.props;
    const { tpvs: localTpvs, date } = this.state;

    confirmAlert({
      title: 'Confirmação',
      message: `Confirma que deseja criar séries automáticas para o dia ${date}?`,
      buttons: [
          {
              label: 'Sim',
              onClick: async () => {
                eventBus.$emit("Modal-dateToSeries", { isOpen: false })
                this.setState({
                  form: {
                    ...this.state.form,
                    loading: {
                      ...this.state.loading,
                      save: true,
                    },
                    avoidSubmit: true,
                  },
                  loaded: true,
                });
            
                const profile = this.context;
            
                const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
            
                let tpvs = [];

                Object.keys(localTpvs).map((posto) => {
                  Object.keys(localTpvs[posto].childs).map((tpv) => {
                    if (localTpvs[posto].childs[tpv].selected) {
                      tpvs = [
                        ...tpvs,
                        ...localTpvs[posto].childs[tpv].items
                      ];
                    }
                  })
                })

                await Promise.all(
                  tpvs.map(queue.wrap(async (tpv, index) => {
                    const { tipo, classe } = getATMapDocType(tpv.doctype);
                    return await axios
                      .post(
                        "https://eposgestofinal.pt/api/at/webservice/series/registarSerie.php",
                        {
                          idempresa: profile.idEmpresaSec,
                          environment: this.state.environment,
                          posto: tpv.posto,
                          tpv: tpv.tpv,
                          serie: tpv.serie,
                          numInicialSeq: tpv.initial,
                          startAt: date,
                          doctype: tpv.doctype,
                          data: {
                            serie: `${tpv.posto}${tpv.tpv}${tpv.serie}`,
                            tipoSerie: "N",
                            classeDoc: classe,
                            tipoDoc: tipo,
                            numInicialSeq: tpv.initial,
                            dataInicioPrevUtiliz: date,
                            numCertSWFatur: 942,
                            meioProcessamento: "PI",
                          },
                        }
                      )
                      .catch((error) => {
                        console.error(error);
                      });
                  }))
                )
                  .then((responses) => {
                    responses.map((response = { data: 'NOK' }, index) => {
                      const tpv = tpvs[index];
                      let statusResponse = "";
                      let atcud = "";
                      if (response.data !== "NOK" && response.data.success) {
                        NotificationManager.success(
                          "Submetida com sucesso para a AT",
                          `${tpv.posto}${tpv.tpv}${tpv.serie}`,
                          100,
                          );
                        statusResponse = (
                          <span className="badge badge-success">SUCESSO</span>
                        );
                        atcud = response.data.data;
                      } else {
                        if (response.data.status === 4001) {
                          NotificationManager.warning(
                            "Série já existente",
                            `${tpv.posto}${tpv.tpv}${tpv.serie}`,
                            1000,
                          );
                          statusResponse = (
                            <span className="badge badge-warning">Repetida</span>
                          );
                        } else {
                          NotificationManager.error(
                            "Não submetida para a AT",
                            `${tpv.posto}${tpv.tpv}${tpv.serie}`,
                            1000,
                          );
                          statusResponse = JSON.stringify(response.data);
                        }
                      }
                      localTpvs[tpv.posto !== '' ? tpv.posto : 'bo'].childs[tpv.tpv !== '' ? tpv.tpv : tpv.doctype].items =
                        localTpvs[tpv.posto !== '' ? tpv.posto : 'bo'].childs[tpv.tpv !== '' ? tpv.tpv : tpv.doctype].items.map((tpvAux) => {
                          if (tpv.doctype == tpvAux.doctype) {
                            return {
                              ...tpvAux,
                              result: statusResponse,
                              atcud,
                              date,
                            };
                          } else {
                            return tpvAux;
                          }
                        })

                      this.setState({
                        tpvs: localTpvs,
                        form: {
                          ...this.state.form,
                          loading: {
                            ...this.state.loading,
                            save: false,
                          },
                        },
                      });
                    });
                  })
                  .finally(() => {
                    const { tpvs: localTpvs } = this.state;

                    let tpvs = [];

                    Object.keys(localTpvs).map((posto) => {
                      Object.keys(localTpvs[posto].childs).map((tpv) => {
                        if (localTpvs[posto].childs[tpv].selected) {
                          tpvs = [
                            ...tpvs,
                            ...localTpvs[posto].childs[tpv].items
                          ];
                        }
                      })
                    })
            
                    axios.post("https://eposgestofinal.pt/api/apollo/sync/series.php", {
                      idempresa: profile.idEmpresaSec,
                      series: tpvs
                        .filter(({ atcud }) => atcud)
                        .map((item) => ({
                          ...item,
                          result: "",
                        })),
                    })
                    .then(() => {
                      eventBus.$emit("Modal-archive", { header: "Séries geradas com sucesso!", body: (
                        <div className="row" style={{ textAlign: 'center' }}>
                            <Button
                                action={() => window.location.href = 'https://eposgestofinal.pt/index.php?page=downloads&path=EnviadoServidor'}
                                type={"info"}
                                title={"Download ficheiros gerados"}
                                style={buttonStyle}
                            />
                        </div>
                      ) })
                    });
                  });
              },
          },
          {
              label: 'Não',
              onClick: () => {}
          }
      ]
    });
  }

  handleFormCancel() {
    const { history } = this.props;
    history.push("/series");
  }

  handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;

    if (name === "date") {
      this.setState(
        {
          [name]: value,
        },
        () => {
          this.setState({
            tpvs: this.state.tpvs.map((tpv) => ({
              ...tpv,
              serie: nextSerie(tpv.serie, tpv.posto === ''),
              initial: nextInitial(this.state.date, tpv.posto === '', tpv.doctype)
            })),
          });
        }
      );
      return;
    }

    this.setState({
      [name]: value,
    });
  }

  handleCheckboxChange(e, callback) {
    const { tpvs: localTpvs } = this.state;
    let value = e.target.checked;
    let name = e.target.name;

    const pathToTpv = name.split('|');

    if (pathToTpv.length === 2) {
      localTpvs[pathToTpv[1]].selected = value;
      Object.keys(localTpvs[pathToTpv[1]].childs).map((tpv) => {
        localTpvs[pathToTpv[1]].childs[tpv].selected = value;
        localTpvs[pathToTpv[1]].childs[tpv].disabled = value;
      })
      this.setState({
        [name]: value,
        tpvs: localTpvs,
      });
    } else if (pathToTpv.length === 3) {
      localTpvs[pathToTpv[1]].childs[pathToTpv[2]].selected = value;
      this.setState({
        [name]: value,
        tpvs: localTpvs,
      });
    } else if (name === 'allSelected') {
      Object.keys(localTpvs).map((posto) => {
        localTpvs[posto].selected = value;
        localTpvs[posto].disabled = value;
        Object.keys(localTpvs[posto].childs).map((tpv) => {
          localTpvs[posto].childs[tpv].selected = value;
          localTpvs[posto].childs[tpv].disabled = value;
        })
      })
      this.setState({
        [name]: value,
        tpvs: localTpvs,
      });
    } else {
      this.setState({
          [name]: value,
      });
    }
  };

  onTableChange(newValue, row, column) {
    const { tpvs: localTpvs } = this.state;

    const [posto, tpv, index] = row.id.split('|');

    localTpvs[posto].childs[tpv].items = localTpvs[posto].childs[tpv].items.map((value) => {
      if (value.id === index) {
        const newRow = { ...value };
        newRow[column.dataField] = newValue;
        return newRow;
      }
      return value;
    })
    
    this.setState({ tpvs: localTpvs });
    return true;
  }

  onLogin(loginResult) {
    this.setState({
      atlogin: true,
      environment: loginResult.environment
    });
    this.loadFunction();
  }

  validForm() {
    const { tpvs: localTpvs } = this.state;
    let tpvs = [];

    Object.keys(localTpvs).map((posto) => {
      Object.keys(localTpvs[posto].childs).map((tpv) => {
        if (localTpvs[posto].childs[tpv].selected) {
          tpvs = [
            ...tpvs,
            ...localTpvs[posto].childs[tpv].items
          ];
        }
      })
    })

    return tpvs.length === 0;
  }

  render() {
    const profile = this.context;
    const { location, history } = this.props;
    const { loaded, tpvs, atlogin, form, allSelected } = this.state;
    const cookies = new URLSearchParams(location.search);

    return (
      <div>
        <Menu
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li>
                <a href="/series">Séries</a>
              </li>
              <li className="active">Automáticas</li>
            </ol>
          </div>
        </div>
        <EmpresaSelect />
        <AtLogin onLogin={this.onLogin} selectedEmpresas={true} />
        {loaded && atlogin && (
          <div className="fullContainer" style={{ maxWidth: "100%" }}>
            <div className="col-sm-12" style={{ textAlign: "center" }}>
              <div className="panel panel-default">
                <div
                  className="panel-heading"
                  style={{
                    fontWeight: "bold",
                    fontSize: "15pt",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    Novas séries
                  </div>
                </div>
                <table className="table table-bordered table-hover table-sortable">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left" }}>Data de ínicio</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Input
                          name={"date"}
                          inputType={"date"}
                          value={this.state.date}
                          handleChange={this.handleChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-bordered table-hover table-sortable">
                  <thead>
                    <tr>
                      <th style={{ textAlign: "left" }}>Séries</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <form
                          style={{
                            textAlign: "left",
                            width: "100%",
                          }}
                          className="container-fluid"
                          onSubmit={this.preHandleFormSubmit}
                        >
                          <Checkbox
                          position={"left"}
                          name={"allSelected"}
                          title={(<h1>Todos documentos</h1>)}
                          checked={allSelected}
                          handleChange={this.handleCheckboxChange}
                          />
                          {Object.keys(tpvs).map((posto) => (
                            <div style={{ margin: '30px', border: '1px solid black' }}>
                                <Checkbox
                                position={"left"}
                                name={`tpvs|${posto}`}
                                title={(<h3>{tpvs[posto].name}</h3>)}
                                checked={tpvs[posto].selected}
                                disabled={tpvs[posto].disabled}
                                handleChange={this.handleCheckboxChange}
                                />
                                {Object.keys(tpvs[posto].childs).map((tpv) => (
                                  <div style={{ margin: '30px' }}>
                                    <Checkbox
                                    position={"left"}
                                    name={`tpvs|${posto}|${tpv}`}
                                    title={(<h5>{`${posto !== 'bo' ? 'TPV: ' : ''}${tpvs[posto].childs[tpv].name}`}</h5>)}
                                    checked={tpvs[posto].childs[tpv].selected}
                                    disabled={tpvs[posto].childs[tpv].disabled}
                                    handleChange={this.handleCheckboxChange}
                                    />
                                    <EditableTable
                                      columns={[
                                        {
                                          dataField: "serie",
                                          text: "Série",
                                          validator: this.onTableChange,
                                          editable: true,
                                        },
                                        {
                                          dataField: "initial",
                                          text: "Inicial",
                                          validator: this.onTableChange,
                                          editable: true,
                                        },
                                        {
                                          dataField: "tipo",
                                          text: "Tipo",
                                          validator: this.onTableChange,
                                          editable: false,
                                        },
                                        {
                                          dataField: "classe",
                                          text: "Classe",
                                          validator: this.onTableChange,
                                          editable: false,
                                        },
                                        {
                                          dataField: "doctypedesc",
                                          text: "Documento",
                                          editable: false,
                                        },
                                        {
                                          dataField: "tpv",
                                          text: "TPV",
                                          editable: false,
                                        },
                                        {
                                          dataField: "posto",
                                          text: "Posto",
                                          editable: false,
                                        },
                                        {
                                          dataField: "nome",
                                          text: "Nome Posto",
                                          editable: false,
                                        },
                                        {
                                          dataField: "result",
                                          text: "Resultado",
                                          editable: false,
                                        },
                                      ]}
                                      data={tpvs[posto].childs[tpv].items}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          ))}
                          <Checkbox
                          position={"left"}
                          name={"allSelected"}
                          title={(<h1>Todos documentos</h1>)}
                          checked={allSelected}
                          handleChange={this.handleCheckboxChange}
                          />
                          <>
                            {!form.avoidSubmit && (
                              <Button
                                disabled={this.validForm()}
                                action={this.preHandleFormSubmit}
                                type={"success"}
                                title={"Submeter"}
                                loading={form.loading.save}
                                style={buttonStyle}
                              />
                            )}
                            {/*Submit */}
                            <Button
                              action={this.handleFormCancel}
                              type={"secondary"}
                              title={"voltar"}
                              style={buttonStyle}
                            />
                          </>
                        </form>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Modal id="dateToSeries">
                  <div className="row">
                    <Input
                      name={"date"}
                      inputType={"date"}
                      value={this.state.date}
                      handleChange={this.handleChange}
                    />
                  </div>
                  <div className="row">
                    <Button
                      action={this.handleFormSubmit}
                      type={"success"}
                      title={"Submeter"}
                      loading={form.loading.save}
                      style={buttonStyle}
                    />
                  </div>
                </Modal>
                <Modal id="archive" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px",
};

export default withCookies(Form);
