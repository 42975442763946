import React, { Component } from "react";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import axios from 'axios';

// Components
import Menu from '@components/Menu'

/* Import Components */
import Input from "@components/Input";
import Button from "@components/Button";
import AutoComplete from "@components/AutoComplete";
import { MdClear } from "react-icons/md";

import { currentDateTime, dateToTimestamp } from '@utils/date'
import { twoDecimal } from '@utils/format'
import { totals } from './utils/totals'

import UserContext from "@components/context/userContext";

const newLine = (lastline) => ({
    product: { value: "" },
    quantity: "",
    costprice: "",
    price: "",
    iva: "",
    idposto: lastline.idposto || { value: '' },
    idpostosource: lastline.idpostosource || { value: '' },
    desc1: "",
    desc2: "",
    desc: "",
    invoice: { value: "" },
    value: "",
});

class Form extends Component {
    static contextType = UserContext;

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

  constructor(props) {
    super(props);

    const { match: { params } } = this.props;

    this.state = {
        loaded: false,
        products: [],
        providers: [],
        customers: [],
        warehouses: [],
        date: currentDateTime(),
        doc: '',
        doctypes: [{
            value: 'invoices',
            label: 'Faturas'
        },{
            value: 'receipts',
            label: 'Recibos'
        }],
        doctype: {
            value: 'invoices',
            label: 'Faturas',
        },
        payments: [{
            value: 1,
            label: 'Dinheiro',
        },{
            value: 2,
            label: 'Crédito',
        },{
            value: 3,
            label: 'Cheque',
        },{
            value: 4,
            label: 'TPA',
        },{
            value: 8,
            label: 'Frota',
        },{
            value: 14,
            label: 'Serviços Internos',
        }],
        payment: {
            value: 1,
            label: 'Dinheiro',
        },
        idprovider: '',
        idcustomer: '',
        lines: [newLine({})],
        idsale: params.idsale,
        sales: [],
        idsalesource: { value: '' },
        pendingDocs: [],
        allowedDocTypes: {
            invoices: false,
            receipts: false,
        },
        warehouseProducts: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.loadFunction = this.loadFunction.bind(this);
    this.removeFunction = this.removeFunction.bind(this);
    this.addFunction = this.addFunction.bind(this);
    this.validForm = this.validForm.bind(this);
    this.invalidLines = this.invalidLines.bind(this);
    this.handleReceiptSubmit = this.handleReceiptSubmit.bind(this);
  }

  loadFunction() {
    const profile = this.context;
    if (!this.state.loaded) {
        axios.get(`https://eposgestofinal.pt/api/sequences/get.php?idempresa=${profile.idEmpresa}&doctype=FT_epos`)
        .then(response => {
            const ftEpos = response.data !== 'NOK' ? response.data : [];

            this.setState({ allowedDocTypes: {
                ...this.state.allowedDocTypes,
                invoices: ftEpos.length > 0,
            }, loaded: true });
        })
        .catch(error => {
            console.error(error);
        });

        axios.get(`https://eposgestofinal.pt/api/sequences/get.php?idempresa=${profile.idEmpresa}&doctype=RG_epos`)
        .then(response => {
            const ftEpos = response.data !== 'NOK' ? response.data : [];

            this.setState({ allowedDocTypes: {
                ...this.state.allowedDocTypes,
                receipts: ftEpos.length > 0,
            }, loaded: true });
        })
        .catch(error => {
            console.error(error);
        });

        axios.get(`https://eposgestofinal.pt/api/products/get.php?idempresa=${profile.idEmpresa}`)
        .then(response => {
            const products = response.data !== 'NOK' ? response.data : [];

            this.setState({ products: products.map((product) => ({ ...product, value: product.idproduct, label: product.nome })), loaded: true });
        })
        .catch(error => {
            console.error(error);
        });

        axios.get(`https://eposgestofinal.pt/api/providers/get.php?idempresa=${profile.idEmpresa}`)
        .then(response => {
            const providers = response.data !== 'NOK' ? response.data : [];

            this.setState({ providers: providers.map((provider) => ({ ...provider, value: provider.idprovider, label: provider.name })), loaded: true });
        })
        .catch(error => {
            console.error(error);
        });

        axios.get(`https://eposgestofinal.pt/api/customers/get.php?idempresa=${profile.idEmpresa}`)
        .then(response => {
            const customers = response.data !== 'NOK' ? response.data : [];

            this.setState({ customers: customers.map((customer) => ({ ...customer, value: customer.idcustomer, label: customer.name })), loaded: true });
        })
        .catch(error => {
            console.error(error);
        });

        axios.get(`https://eposgestofinal.pt/api/sales/get.php?idempresa=${profile.idEmpresa}`)
        .then(response => {
            const sales = response.data !== 'NOK' ? response.data : [];

            this.setState({ sales: sales.filter((sale) => sale.doctype === 'sales' && sale.valid).map((sale) => ({ ...sale, value: sale.idsale, label: `[${sale.provider_name}] ${sale.doc}` })), loaded: true });
        })
        .catch(error => {
            console.error(error);
        });

        axios.get(`https://eposgestofinal.pt/api/postos/get.php?idempresa=${profile.idEmpresa}`)
        .then(response => {
            const warehouses = response.data !== 'NOK' ? response.data : [];

            this.setState({ warehouses: warehouses.map((warehouse) => ({ ...warehouse, value: warehouse.posto, label: warehouse.nome !== '' ? warehouse.nome : warehouse.posto })), loaded: true });
        })
        .catch(error => {
            console.error(error);
        });

        axios.get(`https://eposgestofinal.pt/api/cc/get.php?idempresa=${profile.idEmpresa}`)
        .then(response => {
            const pendingDocs = response.data !== 'NOK' ? response.data : [];

            const hydratatePendingDocs = Object.keys(pendingDocs.customers).reduce((acc, customer) => {
                const invoicesOnly = Object.keys(pendingDocs.customers[customer].items).filter((id) => {
                    return pendingDocs.customers[customer].items[id].tipo === 'fatura'
                });



                const invoicesOnlyArray = invoicesOnly.map((id) => pendingDocs.customers[customer].items[id]);
                return [
                    ...acc,
                    ...invoicesOnlyArray,
                ]
            }, [])

            this.setState({ pendingDocs: hydratatePendingDocs.map((pendingDoc) => {
                const valorliquido = pendingDoc['valor_liquido'];
                const valorpendente = !pendingDoc['recibo_valor_liquido']
                    ? pendingDoc['valor_liquido']
                    : pendingDoc['recibo_valor_inicial'] - pendingDoc['recibo_valor_liquido'];
                return {...pendingDoc, valorliquido, valorpendente, value: pendingDoc.numero, label: pendingDoc.doc };
            }), loaded: true });
        })
        .catch(error => {
            console.error(error);
        });
    }
  }

  handleChange(e, callback) {
    const profile = this.context;
    let value = e.target.value;
    let name = e.target.name;
    
    let newState = this.state;

    if (name.includes("|")) {
        const properties = name.split("|");

        newState[properties[0]][properties[1]][properties[2]] = value;
        if (this.state.doctype.value === 'receipts' && properties[2] === 'invoice') {
            newState[properties[0]][properties[1]]['value'] = twoDecimal(parseFloat(value.valorpendente));
        }
        if (this.state.doctype.value === 'invoices') {
            if (properties[2] === 'product' || properties[2] === 'idposto') {
                let idproduct = '';
                let idposto = '';
                if (properties[2] === 'product') {
                    idproduct = value.value;
                    idposto = newState[properties[0]][properties[1]]['idposto'].value;
                } else {
                    idproduct = newState[properties[0]][properties[1]]['product'].value;
                    idposto = value.value;
                }
                if (!this.state.warehouseProducts[idproduct]) {
                    axios.get(`https://eposgestofinal.pt/api/products/getById.php?idempresa=${profile.idEmpresa}&idproduct=${idproduct}`)
                        .then(response => {
                            const warehouseProductInfo = response.data !== 'NOK' ? response.data[0].values : [];

                            this.setState({ warehouseProducts: {
                                ...this.state.warehouseProducts,
                                [idproduct]: warehouseProductInfo
                            }, loaded: true });

                            const findProduct = warehouseProductInfo.find((warehouseProduct) => warehouseProduct.idposto === idposto) || {};
                            newState[properties[0]][properties[1]]['price'] = findProduct.price;
                            newState[properties[0]][properties[1]]['iva'] = findProduct.iva;
                            newState[properties[0]][properties[1]]['costprice'] = findProduct.costprice;
                            this.setState({
                                ...newState,
                            });
                        })
                        .catch(error => {
                            console.error(error);
                        });
                } else {
                    const findProduct = this.state.warehouseProducts[idproduct].find((warehouseProduct) => warehouseProduct.idposto === idposto) || {};
                    newState[properties[0]][properties[1]]['price'] = findProduct.price;
                    newState[properties[0]][properties[1]]['iva'] = findProduct.iva;
                    newState[properties[0]][properties[1]]['costprice'] = findProduct.costprice;
                }
            }
            if (properties[2] === 'price' && newState[properties[0]][properties[1]]['quantity']) {
                newState[properties[0]][properties[1]]['total'] = twoDecimal(parseFloat(value) * parseFloat(newState[properties[0]][properties[1]]['quantity']));
            }
            if (properties[2] === 'total' && newState[properties[0]][properties[1]]['quantity']) {
                newState[properties[0]][properties[1]]['price'] = twoDecimal(parseFloat(value) / parseFloat(newState[properties[0]][properties[1]]['quantity']));
            }
            if (properties[2] === 'quantity' && newState[properties[0]][properties[1]]['price']) {
                newState[properties[0]][properties[1]]['total'] = twoDecimal(parseFloat(value) * parseFloat(newState[properties[0]][properties[1]]['price']));
            }
        }
        this.setState({
            ...newState,
        });
    } else {
        this.setState({
            [name]: value,
        });
        if (name === 'idsalesource') {
            axios.get(`https://eposgestofinal.pt/api/sales/getById.php?idempresa=${profile.idEmpresa}&idsale=${value.value}`)
            .then(response => {
                const sale = response.data !== 'NOK' ? response.data[0] : [];

                this.setState({ idprovider: this.state.providers.find((provider) => provider.idprovider === sale.idprovider), lines: sale.lines.map((line) => ({
                    ...line,
                    product: this.state.products.find((product) => product.idproduct === line.idproduct),
                    idposto: this.state.warehouses.find((warehouse) => warehouse.posto === line.idposto),
                })), loaded: true });
            })
            .catch(error => {
                console.error(error);
            });
        }
    }

    
    if (callback) {
        callback();
    }
  }

  handleFormCancel() {
    const { history } = this.props;
    history.push('/sales');
  }

  handleFormSubmit(e) {
    const profile = this.context;
    const { history } = this.props;
    const doctype = this.state.doctype.value;
    e.preventDefault();
    
    axios.post('https://eposgestofinal.pt/api/sales/set.php', {
        doc: ['orders'].includes(doctype) ? this.state.doc : new Date().getTime(),
        doctype: doctype,
        date_time: dateToTimestamp(this.state.date),
        idprovider: ['orders', 'returns'].includes(doctype) ? this.state.idprovider.value : -1,
        idcustomer: ['invoices', 'receipts'].includes(doctype) ? this.state.idcustomer.value : -1,
        payment: ['invoices', 'receipts'].includes(doctype) ? this.state.payment.value : -1,
        idsalesource: doctype === 'returns' ? this.state.idsalesource.value : undefined,
        lines: this.state.lines.map((line) => ({
            ...line,
            idproduct: line.product.value,
            idposto: line.idposto.value,
            idpostosource: doctype === 'transfer' ? line.idpostosource.value : undefined,
            price: Number(line.price),
            desc: Number(line.desc),
            desc1: Number(line.desc1),
            desc2: Number(line.desc2),
            iva: line.iva ? Number(line.iva) : Number(line.product.iva),
            costprice: line.costprice ? Number(line.costprice) : Number(line.product.costprice),
        })),
        idempresa: profile.idEmpresa,
        idemployee: profile.idEmployee || 0,
    })
    .then(response => {
        history.push('/sales');
    })
    .catch(error => {
        console.error(error);
    });
  }

  handleReceiptSubmit(e) {
    const profile = this.context;
    const { history } = this.props;
    const doctype = this.state.doctype.value;
    e.preventDefault();
    
    axios.post('https://eposgestofinal.pt/api/receipts/set.php', {
        idcustomer: ['invoices','receipts'].includes(doctype) ? this.state.idcustomer.value : -1,
        date_time: dateToTimestamp(this.state.date),
        payment: ['invoices','receipts'].includes(doctype) ? this.state.payment.value : -1,
        lines: this.state.lines,
        idempresa: profile.idEmpresa,
        idemployee: profile.idEmployee || 0,
    })
    .then(response => {
        history.push('/sales');
    })
    .catch(error => {
        console.error(error);
    });
  }

  removeFunction(key) {
    this.setState({
        lines: this.state.lines.filter((line, index) => index !== key),
    });
  }

  addFunction(key) {
    this.setState({
        lines: [ ...this.state.lines, newLine(this.state.lines[this.state.lines.length - 1] || {}) ],
    });
  }

  validForm() {
    const {
        doc,
        idprovider,
        idcustomer,
        doctype,
    } = this.state;

    switch (doctype.value) {
        case 'orders':
            return idprovider && doc;
        case 'returns':
            return idprovider;
        case 'invoices':
        case 'receipts':
            return idcustomer;
        case 'transfer':
            return true
        default:
            return false;
    }
  }

  invalidLines() {
    const {
        lines,
        doctype,
    } = this.state;

    switch (doctype.value) {
        case 'invoices':
            return lines.find(({ product, quantity, idposto, price }) => !product.value || !quantity || !price || !idposto.value );
        case 'receipts':
            return lines.find(({ value, invoice }) => !invoice.numero || !value || parseFloat(twoDecimal(parseFloat(value))) > parseFloat(twoDecimal(parseFloat(invoice.valorpendente))));
        case 'returns':
        case 'orders':
        case 'transfer':
        default:
            return false;
    }
  }

  render() {
    const profile = this.context;
    const { location, history } = this.props;
    const {
        loaded,
        doc,
        date,
        idprovider,
        providers,
        products,
        warehouses,
        lines,
        doctype,
        doctypes,
        idsalesource,
        sales,
        idcustomer,
        customers,
        payments,
        payment,
        pendingDocs,
        allowedDocTypes,
    } = this.state;
    const cookies = new URLSearchParams(location.search);

    const filterPendingDocs = pendingDocs.filter((pendingDoc) => pendingDoc.cliente === idcustomer.value);

    const filterDocTypes = doctypes.filter(({condition, value}) => (!condition || eval(condition)) && allowedDocTypes[value]);

    const results = totals(lines, doctype.value);
 
    const desconto = results.desconto;
    const total = results.total;
    const subtotal = results.subtotal;
    const iva = results.iva;

    return (
        <div>
            <Menu loadFunction={ this.loadFunction() } location={ location } history={ history } newCookies={ cookies } />
            <div className="fullContainer" style={{ maxWidth: '100%' }}>
                <div className="col-xs-12">
                    <ol
                        style={{
                            textAlign: 'left',
                            'marginTop': '75px',
                        }}
                        className="breadcrumb"
                    >
                        <li><a href="http://eposgestofinal.pt/index.php">Portal</a></li>
                        <li><a href="/sales">Vendas</a></li>
                        <li className="active">
                            <span>Nova Venda</span>
                        </li>
                    </ol>
                </div>
            </div>
            {loaded && filterDocTypes.length > 0 ? (
            <div
                className="fullContainer"
                style={{ marginTop:'25px' }}
            >
                <div
                    className="col-sm-12"
                    style={{ textAlign: 'center', fontSize: '10px' }}
                >
                    <div className="panel panel-default">
                        <div
                            className="panel-heading"
                            style={{
                                fontWeight: 'bold',
                                fontSize: '15pt',
                            }}
                        >
                            Documento
                        </div>
                        <form
                            style={{
                                textAlign: 'left',
                            }}
                            className="container-fluid"
                            onSubmit={this.handleFormSubmit}
                        >
                            <h3>Cabeçalho</h3>
                            <table className="table table-bordered table-hover table-sortable">
                                <thead>
                                    <tr >
                                        <th className="text-center">Tipo Doc</th>
                                        <th className="text-center">Data</th>
                                        {['orders'].includes(doctype.value) ? (
                                            <>
                                                <th className="text-center">Fornecedor</th>
                                                <th className="text-center">Nº Documento</th>
                                            </>
                                        ) : null}
                                        {['returns'].includes(doctype.value) ? (
                                            <>
                                                <th className="text-center">Venda</th>
                                                <th className="text-center">Fornecedor</th>
                                            </>
                                        ) : null}
                                        {['invoices', 'receipts'].includes(doctype.value) ? (
                                            <>
                                                <th className="text-center">Cliente</th>
                                                <th className="text-center">Pagamento</th>
                                            </>
                                        ) : null}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td style={{ width: '250px' }}>
                                            <AutoComplete
                                                name={"doctype"}
                                                value={filterDocTypes.find(({ value }) => value === doctype.value) ? doctype : (filterDocTypes.length > 0 ? filterDocTypes[0] : null )}
                                                handleChange={this.handleChange}
                                                options={filterDocTypes}
                                            />
                                        </td>
                                        <td style={{ width: '250px' }}>
                                            <Input
                                                inputType={"datetime-local"}
                                                name={"date"}
                                                value={date}
                                                handleChange={this.handleChange}
                                            />
                                        </td>
                                        {['orders'].includes(doctype.value) ? (
                                            <>
                                                <td>
                                                    <AutoComplete
                                                        name={"idprovider"}
                                                        value={idprovider}
                                                        handleChange={this.handleChange}
                                                        options={providers}
                                                    />
                                                </td>
                                                <td style={{ width: '250px' }}>
                                                    <Input
                                                        inputType={"text"}
                                                        name={"doc"}
                                                        value={doc}
                                                        handleChange={this.handleChange}
                                                    />
                                                </td>
                                            </>
                                        ) : null}
                                        {['returns'].includes(doctype.value) ? (
                                            <>
                                                <td style={{ width: '250px' }}>
                                                    <AutoComplete
                                                        name={"idsalesource"}
                                                        value={idsalesource}
                                                        handleChange={this.handleChange}
                                                        options={sales}
                                                    />
                                                </td>
                                                <td style={{ width: '250px' }}>
                                                    {idprovider.label}
                                                </td>
                                            </>
                                        ) : null}
                                        {['invoices', 'receipts'].includes(doctype.value) ? (
                                            <>
                                                <td>
                                                    <AutoComplete
                                                        name={"idcustomer"}
                                                        value={idcustomer}
                                                        handleChange={this.handleChange}
                                                        options={customers}
                                                    />
                                                </td>
                                                <td>
                                                    <AutoComplete
                                                        name={"payment"}
                                                        value={payment}
                                                        handleChange={this.handleChange}
                                                        options={payments}
                                                    />
                                                </td>
                                            </>
                                        ) : null}
                                    </tr>
                                </tbody>
                            </table>
                            {this.validForm() && (
                            <>
                                <h3>Linhas</h3>
                                <table className="table table-bordered table-hover table-sortable" id="tab_logic">
                                    <thead>
                                        <tr >
                                            {!['receipts'].includes(doctype.value) ? (
                                                <>
                                                    <th className="text-center">Posto</th>
                                                    <th className="text-center">Produto</th>
                                                    <th className="text-center">Quantity</th>
                                                    {['orders', 'returns'].includes(doctype.value) ? (
                                                        <th className="text-center">PCompra</th>
                                                    ) : null}
                                                    <th className="text-center">PVP</th>
                                                    {['orders', 'returns'].includes(doctype.value) ? (
                                                        <>
                                                            <th className="text-center">Desc1</th>
                                                            <th className="text-center">Desc2</th>
                                                            <th className="text-center">Desc€</th>
                                                        </>
                                                    ) : null}
                                                    <th className="text-center">IVA</th>
                                                    {['transfer'].includes(doctype.value) ? (
                                                        <th className="text-center">Posto Origem</th>
                                                    ) : null}
                                                    <th className="text-center">Líquido</th>
                                                    
                                                    <th className="text-center">&nbsp;</th>
                                                </>
                                            ) : null}
                                            {['receipts'].includes(doctype.value) ? (
                                                <>
                                                    <th className="text-center">Fatura</th>
                                                    <th className="text-center">Valor em falta</th>
                                                    <th className="text-center">Valor a abater</th>
                                                </>
                                            ) : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {lines.map((line, key) => (
                                            <tr>
                                                {!['receipts'].includes(doctype.value) ? (
                                                    <>
                                                        {['returns'].includes(doctype.value) ? (
                                                            <td>{line.idposto.label}</td>
                                                        ) : (
                                                            <td style={{ width: '200px' }}>
                                                                <AutoComplete
                                                                    name={`lines|${key}|idposto`}
                                                                    value={line.idposto}
                                                                    handleChange={this.handleChange}
                                                                    options={warehouses}
                                                                />
                                                            </td>
                                                        )}
                                                        {doctype.value !== 'returns' ? (
                                                            <td>
                                                                <AutoComplete
                                                                    name={`lines|${key}|product`}
                                                                    value={line.product}
                                                                    handleChange={this.handleChange}
                                                                    options={products}
                                                                />
                                                            </td>
                                                        ) : (
                                                            <td>{line.product.label}</td>
                                                        )}
                                                        <td style={{ width: '100px' }}>
                                                            <Input
                                                                inputType={"text"}
                                                                name={`lines|${key}|quantity`}
                                                                value={line.quantity}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </td>
                                                        {['orders', 'returns'].includes(doctype.value) ? (
                                                            ['orders'].includes(doctype.value) ? (
                                                                <td style={{ width: '110px' }}>
                                                                    <Input
                                                                        inputType={"text"}
                                                                        name={`lines|${key}|costprice`}
                                                                        value={line.costprice ? line.costprice : line.product.costprice}
                                                                        handleChange={this.handleChange}
                                                                    />
                                                                </td>
                                                            ) : (
                                                                <td>{line.costprice ? line.costprice : line.product.costprice}</td>
                                                            )
                                                        ) : null}
                                                        <td style={{ width: '100px' }}>
                                                            <Input
                                                                inputType={"text"}
                                                                name={`lines|${key}|price`}
                                                                value={line.price}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </td>
                                                        {['orders', 'returns'].includes(doctype.value) ? (
                                                            ['orders'].includes(doctype.value) ? (
                                                                <>
                                                                    <td style={{ width: '70px' }}>
                                                                        <Input
                                                                            inputType={"text"}
                                                                            name={`lines|${key}|desc1`}
                                                                            value={line.desc1}
                                                                            handleChange={this.handleChange}
                                                                        />
                                                                    </td>
                                                                    <td style={{ width: '70px' }}>
                                                                        <Input
                                                                            inputType={"text"}
                                                                            name={`lines|${key}|desc2`}
                                                                            value={line.desc2}
                                                                            handleChange={this.handleChange}
                                                                        />
                                                                    </td>
                                                                    <td style={{ width: '110px' }}>
                                                                        <Input
                                                                            inputType={"text"}
                                                                            name={`lines|${key}|desc`}
                                                                            value={line.desc}
                                                                            handleChange={this.handleChange}
                                                                        />
                                                                    </td>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <td>{line.desc1}</td>
                                                                    <td>{line.desc2}</td>
                                                                    <td>{line.desc}</td>
                                                                </>
                                                            )
                                                        ) : null}
                                                        <td style={{ width: '70px' }}>
                                                            <Input
                                                                inputType={"text"}
                                                                name={`lines|${key}|iva`}
                                                                value={line.iva ? line.iva : line.product.iva}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </td>
                                                        {['transfer'].includes(doctype.value) ? (
                                                            <td style={{ width: '200px' }}>
                                                                <AutoComplete
                                                                    name={`lines|${key}|idpostosource`}
                                                                    value={line.idpostosource}
                                                                    handleChange={this.handleChange}
                                                                    options={warehouses}
                                                                />
                                                            </td>
                                                        ) : null}
                                                        {!['returns'].includes(doctype.value) && (
                                                            <td style={{ width: '200px' }}>
                                                                <Input
                                                                    inputType={"text"}
                                                                    name={`lines|${key}|total`}
                                                                    value={line.total}
                                                                    handleChange={this.handleChange}
                                                                />
                                                            </td>
                                                        )}
                                                        <td style={{ width: '20px' }}>
                                                            <MdClear style={{ color: 'red', fontSize: '15px', cursor: 'pointer' }} onClick={ () => this.removeFunction(key) } />
                                                        </td>
                                                    </>
                                                ) : null}
                                                {['receipts'].includes(doctype.value) ? (
                                                    <>
                                                        <td style={{ width: '200px' }}>
                                                            <AutoComplete
                                                                name={`lines|${key}|invoice`}
                                                                value={line.invoice}
                                                                handleChange={this.handleChange}
                                                                options={filterPendingDocs}
                                                            />
                                                        </td>
                                                        <td style={{ width: '100px' }}>
                                                            { twoDecimal(parseFloat(line.invoice.valorpendente || 0)) }
                                                        </td>
                                                        <td style={{ width: '100px' }}>
                                                            <Input
                                                                inputType={"text"}
                                                                name={`lines|${key}|value`}
                                                                value={line.value}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </td>
                                                        <td style={{ width: '20px' }}>
                                                            <MdClear style={{ color: 'red', fontSize: '15px', cursor: 'pointer' }} onClick={ () => this.removeFunction(key) } />
                                                        </td>
                                                    </>
                                                ) : null}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {doctype.value !== 'returns' ? (
                                    <div className="row" style={{ textAlign: 'right' }}>
                                        <Button
                                            disabled={this.invalidLines()}
                                            action={this.addFunction}
                                            type={"info"}
                                            title={"Adicionar item"}
                                            style={buttonStyle}
                                        />
                                    </div>
                                ) : null}
                                <table className="table table-bordered table-hover table-sortable" style={{ width: '200px' }}>
                                    <tbody>
                                        {!['receipts'].includes(doctype.value) ? (
                                            <>
                                                <tr>
                                                    <td>Subtotal</td>
                                                    <td>{subtotal ? twoDecimal(subtotal) : ''}</td>
                                                </tr>
                                                {desconto ? (
                                                    <tr>
                                                        <td>Descontos</td>
                                                        <td>{desconto ? twoDecimal(desconto) : ''}</td>
                                                    </tr>
                                                ) : null}
                                                <tr>
                                                    <td>IVA</td>
                                                    <td>{iva ? twoDecimal(iva) : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total</td>
                                                    <td>{total ? twoDecimal(total) : ''}</td>
                                                </tr>
                                            </>
                                        ) : null}
                                        {['receipts'].includes(doctype.value) ? (
                                            <>
                                                <tr>
                                                    <td>Total em falta</td>
                                                    <td>{twoDecimal(parseFloat(lines.reduce((acc, line) => acc + parseFloat(line.invoice.valorpendente || 0), 0) - lines.reduce((acc, line) => acc + parseFloat(line.value || 0), 0)))}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total a abater</td>
                                                    <td>{twoDecimal(parseFloat(lines.reduce((acc, line) => acc + parseFloat(line.value || 0), 0)))}</td>
                                                </tr>
                                            </>
                                        ) : null}
                                    </tbody>
                                </table>
                                <Button
                                    disabled={this.invalidLines()}
                                    action={['receipts'].includes(doctype.value) ? this.handleReceiptSubmit : this.handleFormSubmit}
                                    type={"success"}
                                    title={"Submeter"}
                                    style={buttonStyle}
                                />
                            </>
                            )}
                            <Button
                            action={this.handleFormCancel}
                            type={"secondary"}
                            title={"cancelar"}
                            style={buttonStyle}
                            />
                            {/* Clear the form */}
                        </form>
                    </div>
                </div>
            </div>
            ) : null}
            {filterDocTypes.length === 0 && (
                <h1>Não tem séries criadas para uso do ePOS!</h1>
            )}
        </div>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px"
};

export default withCookies(Form);
