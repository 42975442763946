import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";
import _ from "lodash";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

// Components
import EmpresaSelect from "@components/EmpresaSelect";
import Menu from "@components/Menu";
import ReactTable from "@components/ReactTable";
import Checkbox from "@components/CheckBox";
import PriceTag from "@components/PriceTag";
import Input from "@components/Input";
import AutoComplete from "@components/AutoComplete";
import Print from "@components/Print";

import PricetagDocument from "./pricetagsdocument";

import UserContext from "@components/context/userContext";

import "./pricetagsproducts.css";

class Page extends Component {
  static contextType = UserContext;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    const {
      match: { params },
      type,
    } = this.props;

    this.state = {
      loaded: false,
      warehouseid: params.warehouse,
      pricetagid: params.pricetag,
      page: {
        a4: false,
        margin: 0.5,
        spacing: 0,
      },
      pricetag: {},
      products: {
        columns: [
          {
            Header: "Selecionar",
            accessor: "select",
          },
          {
            Header: "Quantidade",
            accessor: "quantities",
          },
          {
            Header: "Código de barras",
            accessor: "selectbarcodes",
          },
          {
            Header: "Código Produto",
            accessor: "idproduct",
          },
          {
            Header: "Designação",
            accessor: "nome",
          },
          {
            Header: "PVP",
            accessor: "price",
          },
        ],
        rows: [],
        loading: true,
      },
      pricetag: {},
      search: "",
      idcategory: {
        value: "",
        label: "TODAS",
      },
    };
    this.loadFunction = this.loadFunction.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  loadFunction() {
    if (!this.state.loaded) {
      const profile = this.context;

      axios
        .get(
          `https://eposgestofinal.pt/api/categories/get.php?idempresa=${profile.idEmpresaSec}`
        )
        .then((response) => {
          const categories = response.data !== "NOK" ? response.data : [];

          this.setState({
            categories: [
              { value: "", label: "TODAS" },
              ...categories.map((category) => ({
                value: category.idcategoria,
                label: `[${category.idcategoria}] ${category.descricaocategoria}`,
              })),
            ],
            loaded: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          `https://eposgestofinal.pt/api/pricetags/getById.php?idempresa=${profile.idEmpresaSec}&idpricetag=${this.state.pricetagid}`
        )
        .then((response) => {
          const pricetag = response.data !== "NOK" ? response.data[0] : {};

          this.setState({
            pricetag,
            loaded: true,
          });

          axios
            .get(
              `https://eposgestofinal.pt/api/products/posto/getAll.php?idempresa=${profile.idEmpresaSec}&idposto=${this.state.warehouseid}`
            )
            .then((response) => {
              const products = response.data !== "NOK" ? response.data[this.state.warehouseid].items : [];

              console.log(products);

              this.setState({
                products: {
                  ...this.state.products,
                  rows: products.map((product, key) => ({
                    ...product,
                    select: false,
                    key,
                    barcodes: [...product.barcodes.filter((barcode) => (barcode.barcode || barcode) !== product.idproduct), product.idproduct].map((barcode) => ({ label:barcode.barcode || barcode, value: barcode.barcode || barcode })),
                    barcode: [...product.barcodes.filter((barcode) => (barcode.barcode || barcode) !== product.idproduct), product.idproduct].map((barcode) => ({ label:barcode.barcode || barcode, value: barcode.barcode || barcode }))[0],
                    quantity: 1,
                  })),
                  selected: false,
                  loading: false,
                },
                loaded: true,
              });
            });

        });
    }
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;

    let newState = this.state;

    if (name.includes("|")) {
      const properties = name.split("|");

      if (properties.length == 2) {
        newState[properties[0]][properties[1]] = value;
      } else {
        newState[properties[0]][properties[1]][properties[2]][properties[3]] = value;
      }

      this.setState({
        ...newState,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }
  
  handleCheckboxChange(e) {
    let value = e.target.checked;
    let name = e.target.name;

    let newState = this.state;

    if (name.includes("|")) {
      const properties = name.split("|");

      if (properties.length == 2) {
        newState[properties[0]][properties[1]] = value;
      } else {
        newState[properties[0]][properties[1]][properties[2]][properties[3]] = value;
      }

      this.setState({
        ...newState,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  handleFormCancel() {
    const { history } = this.props;
    history.push("/products");
  }

  render() {
    const profile = this.context;
    const { products, pricetag, idcategory, categories, search, page } = this.state;
    const { location, history } = this.props;
    const cookies = new URLSearchParams(location.search);
    const toPrintTags = []

    products.rows = products.rows.map((row, key) => {
      let tag
      if (row.selected) {
        tag = (<PriceTag bordered={false} zoom={1} product={{...row, barcode: row.barcode.value, price: parseFloat(row.values[0].price).toFixed(2).toString().replace('.', ',')}} config={JSON.parse(pricetag.config)} pricetag={JSON.parse(pricetag.size)} empresa={profile} />);
        for (let count = 0; count < row.quantity; count++) {
          toPrintTags.push(tag)
        }
      }
      console.log(row.barcode, row.barcodes);
      return {
        ...row,
        select: (
          <Checkbox
            name={`products|rows|${key}|selected`}
            checked={row.selected}
            handleChange={this.handleCheckboxChange}
          />
        ),
        pricetag: tag || null,
        quantities: (
          <Input
            inputType={"number"}
            name={`products|rows|${key}|quantity`}
            value={row.quantity}
            handleChange={this.handleChange}
          />
        ),
        selectbarcodes: (
          <AutoComplete
            name={`products|rows|${key}|barcode`}
            value={row.barcode}
            handleChange={this.handleChange}
            options={row.barcodes}
          />
        )
      }
    })

    const filterSearch = search.split(" ");

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li>
                <a href="/products">Produtos</a>
              </li>
              <li className="active">Etiquetas</li>
            </ol>
          </div>
        </div>
        <EmpresaSelect />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "left" }}>
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Configuração
                </div>
              </div>
              <Checkbox
                title={"Folha A4"}
                position={"left"}
                name={"page|a4"}
                checked={page.a4}
                handleChange={this.handleCheckboxChange}
              />
              {page.a4 ? (
                <table className="table table-bordered table-hover table-sortable">
                  <thead>
                    <tr>
                      <th className="text-center">Margens da página (cm)</th>
                      <th className="text-center">Espaçamento entre etiquetas (cm)</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                        <Input
                          inputType={"number"}
                          name={"page|margin"}
                          value={page.margin}
                          handleChange={this.handleChange}
                        />
                      </td>
                      <td>
                        <Input
                          inputType={"number"}
                          name={"page|spacing"}
                          value={page.spacing}
                          handleChange={this.handleChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : null}
            </div>
          </div>
        </div>
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Etiquetas
                </div>
              </div>
              <table className="table table-bordered table-hover table-sortable">
                <thead>
                  <tr>
                    <th className="text-center">Família</th>
                    <th className="text-center">Pesquisa</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "400px" }}>
                      <AutoComplete
                        name={"idcategory"}
                        value={idcategory}
                        handleChange={this.handleChange}
                        options={categories}
                      />
                    </td>
                    <td>
                      <Input
                        inputType={"text"}
                        name={"search"}
                        value={search}
                        handleChange={this.handleChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <ReactTable
                columns={products.columns}
                sortable={true}
                loading={products.loading}
                data={products.rows.filter((product) =>
                  idcategory.value
                    ? product.familia === idcategory.value
                    : true
                )
                .filter((product) =>
                  filterSearch.every(
                    (eachSearch) =>
                      (product.nome || '')
                        .toLowerCase()
                        .includes(eachSearch.toLowerCase()) ||
                      product.barcodes
                        .map(({value}) => value)
                        .join(' ')
                        .toLowerCase()
                        .includes(eachSearch.toLowerCase()) ||
                      product.idproduct
                        .toLowerCase()
                        .includes(eachSearch.toLowerCase())
                  )
                )}
              />
            </div>
          </div>
        </div>
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <Print
              button="Imprimir Etiquetas"
              printComponent={PricetagDocument}
              pricetag={pricetag}
              page={page}
              toPrintTags={toPrintTags}
              avoidLandscape={true}
              pageWidth={`${JSON.parse(pricetag.size||'{}').width}cm`}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(Page));
