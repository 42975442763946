import React, { Component } from "react";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import axios from 'axios';

// Components
import Menu from '@components/Menu'

/* Import Components */
import Input from "@components/Input";
import Button from "@components/Button";
import Checkbox from "@components/CheckBox";

import UserContext from '@components/context/userContext';

const extraZero = (value) => (value < 10 ? `0${value}` : value);

const TimestampToDatetime = (timestamp) => {
    if (!timestamp) {
        return '';
    }
    const datum = new Date(timestamp * 1000);
    const year = datum.getFullYear();
    const month = extraZero(datum.getMonth() + 1);
    const day = extraZero(datum.getDate());
    return `${year}-${month}-${day}`;
  };
  
  const datetimeToTimestamp = (strDate) => {
    const date = strDate.split("-");
    const year = parseInt(date[0], 10);
    const month = parseInt(date[1], 10) - 1;
    const day = parseInt(date[2], 10);
    const datum = new Date(year, month, day, 0, 0, 0);
    return datum / 1000;
};

class Form extends Component {
    static contextType = UserContext;

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

  constructor(props) {
    super(props);

    const { match: { params }, type } = this.props;

    const today = new Date();

    this.state = {
        loaded: false,
        year: today.toISOString().substr(0, 4),
        month: today.toISOString().substr(5, 2),
        date: today.toISOString().substr(0, 10),
        valeu: '',
        invoice: '',
        payed: false,
        idquota: type === 'edit' && params.idquota,
        idcustomer: params.idcustomer,
        edit: type === 'edit',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.loadFunction = this.loadFunction.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  loadFunction() {
    const profile = this.context;

    if (!this.state.loaded) {
        if (this.props.type === 'edit') {
            const today = new Date();
            
            axios.get(`https://eposgestofinal.pt/api/customers/quotas/getById.php?idempresa=${profile.idEmpresa}&idcustomer=${this.state.idcustomer}&idquota=${this.state.idquota}`)
            .then(response => {
                const quotas = response.data !== 'NOK' ? response.data : {};

                this.setState({ ...quotas, payed: quotas.date ? true : false, date: quotas.date ? TimestampToDatetime(quotas.date) : today.toISOString().substr(0, 10), loaded: true });
            })
            .catch(error => {
                console.error(error);
            });
            
            this.setState({ loaded: true });
        } else {
            this.setState({ loaded: true });
        }
    }
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;
    
    this.setState({
        [name]: value,
    });
  }

  handleCheckboxChange(e, callback) {
    let value = e.target.checked;
    let name = e.target.name;

    this.setState({
        [name]: value,
    });
  };

  handleFormSubmit(e) {
    const profile = this.context;
    const { history } = this.props;
    e.preventDefault();
    
    axios.post('https://eposgestofinal.pt/api/customers/quotas/set.php', {
        year: this.state.year,
        month: this.state.month,
        value: this.state.value,
        date: this.state.payed ? datetimeToTimestamp(this.state.date) : null,
        invoice: this.state.invoice,
        idempresa: profile.idEmpresa,
        idcustomer: this.state.idcustomer,
        idquota: this.state.idquota,
    })
    .then(response => {
        if (this.props.location && this.props.location.query && this.props.location.query.closeAfter) {
            window.close();
        } else {
            history.push(`/customers/${this.state.idcustomer}`);
        }
    })
    .catch(error => {
        console.error(error);
    });
  }

  handleFormCancel() {
    const { history } = this.props;
    if (this.props.location && this.props.location.query && this.props.location.query.closeAfter) {
        window.close();
    } else {
        history.push(`/customers/${this.state.idcustomer}`);
    }
  }

  render() {
    const { location, history } = this.props;
    const {
        edit,
        idcustomer,
        loaded,
        year,
        month,
        value,
        date,
        invoice,
        payed,
    } = this.state;
    const cookies = new URLSearchParams(location.search);
    const profile = this.context;

    return (
        <div>
            <Menu loadFunction={ this.loadFunction() } location={ location } history={ history } newCookies={ cookies } />
            <div className="fullContainer" style={{ maxWidth: '100%' }}>
                <div className="col-xs-12">
                    <ol
                        style={{
                            textAlign: 'left',
                            'marginTop': '75px',
                        }}
                        className="breadcrumb"
                    >
                        <li><a href="http://eposgestofinal.pt/index.php">Portal</a></li>
                        <li><a href="/customers">Clientes</a></li>
                        <li><a href={`/customers/${idcustomer}`}>{ idcustomer }</a></li>
                        <li className="active">
                            {edit ? (<span>Editar Quota existente</span>) : (<span>Novo Quota</span>)}
                        </li>
                    </ol>
                </div>
            </div>
            
            {loaded && (
            <div
                className="fullContainer" style={{ maxWidth: '100%' }}
            >
                <div
                    className="col-sm-12"
                    style={{ textAlign: 'center' }}
                >
                    <div className="panel panel-default">
                        <div
                            className="panel-heading"
                            style={{
                                fontWeight: 'bold',
                                fontSize: '15pt',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'left',
                                }}
                            >
                                Quota
                            </div>
                        </div>
                        <div
                            style={{
                                textAlign: 'left',
                                width: '100%',
                            }}
                            className="container-fluid"
                        >
                            <table className="table table-bordered table-hover table-sortable">
                                <thead>
                                    <tr >
                                        <th style={{ textAlign: 'left' }}>Ano</th>
                                        <th style={{ textAlign: 'left' }}>Mês</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td>
                                            <Input
                                                inputType={"number"}
                                                name={"year"}
                                                value={year}
                                                handleChange={this.handleChange}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                inputType={"number"}
                                                name={"month"}
                                                value={month}
                                                handleChange={this.handleChange}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table table-bordered table-hover table-sortable">
                                <thead>
                                    <tr >
                                        <th style={{ textAlign: 'left' }}>Data Pagamento</th>
                                        <th style={{ textAlign: 'left' }}>Recibo nr.</th>
                                        <th style={{ textAlign: 'left' }}>Valor quota</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td>
                                            <label className="col-md-3 control-label">Pago</label>
                                            <div className="col-md-9">
                                                <Checkbox
                                                    name={"payed"}
                                                    checked={payed}
                                                    handleChange={this.handleCheckboxChange}
                                                />
                                            </div>
                                            {payed && (<Input
                                                inputType={"date"}
                                                name={"date"}
                                                value={date}
                                                handleChange={this.handleChange}
                                            />)}
                                        </td>
                                        <td>
                                            <Input
                                                inputType={"text"}
                                                name={"invoice"}
                                                value={invoice}
                                                handleChange={this.handleChange}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                inputType={"number"}
                                                name={"value"}
                                                value={value}
                                                handleChange={this.handleChange}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* About you */}
                            <Button
                            action={this.handleFormSubmit}
                            type={"primary"}
                            title={"Submeter"}
                            style={buttonStyle}
                            />
                            {/*Submit */}
                            <Button
                            action={this.handleFormCancel}
                            type={"secondary"}
                            title={"cancelar"}
                            style={buttonStyle}
                            />
                            {/* Clear the form */}
                        </div>
                    </div>
                </div>
            </div>
            )}
        </div>
      
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px"
};

export default withCookies(Form);
