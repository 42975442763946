import React, { Component } from 'react';

// Components

class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            summary: this.props.form || {
                warehouse: { name: '' },
                category: { name: '' },
                values: [],
                date: '',
                time: '',
            }
        };
    }
    
    render () {
        const { summary } = this.state;

        return (
            <div
                style={{
                    textAlign: 'left',
                    width: '100%',
                }}
                className="container"
            >
                <h2>Cabeçalho</h2>
                <h4><b>Posto:</b> { summary.warehouse.name }</h4>
                <h4><b>Familia:</b> { summary.category.name }</h4>
                <h4><b>Data:</b> { summary.date }</h4>
                <h4><b>Hora:</b> { summary.time }</h4>
                <h2>Contagens</h2>
                <table className="table table-bordered table-hover table-sortable">
                    <thead>
                        <tr >
                            <th className="text-center">Produto</th>
                            <th className="text-center">Designação</th>
                            <th className="text-center">Quantidade</th>
                            <th className="text-center">Contagem</th>
                            <th className="text-center">Diferença</th>
                        </tr>
                    </thead>
                    <tbody>
                        {summary.values.filter(({ counter }) => counter !== '').map((value) => (
                            <tr>
                                <td>{ value.idproduct }</td>
                                <td>{ value.name }</td>
                                <td>{ value.qtd }</td>
                                <td>{ value.counter }</td>
                                <td>{ value.qtd !==  '-' ? parseFloat(value.counter) - parseFloat(value.qtd) : '-' }</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Page;