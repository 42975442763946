import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import Button from "../Button";

export const deleteColumnFormatter = (callback) => (cell, row) => (
  <Button
    action={() => callback(cell, row)}
    type={"danger"}
    title={(<i className="fas fa-trash-alt fa-lg"></i>)}
  />
);

class EditableTable extends React.PureComponent {
  render() {
    const { data, columns, onTableChange } = this.props

    return (
      <BootstrapTable
        keyField="id"
        data={ data }
        columns={ columns }
        onTableChange={ onTableChange }
        cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
        noDataIndication="Tabela vazia"
      />
    )
  }
}

export default EditableTable;
