import React, { Component } from "react";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { NotificationManager } from 'react-notifications';

// Components
import Menu from '@components/Menu'

/* Import Components */
import Input from "@components/Input";
import TextArea from "@components/TextArea";
import Button from "@components/Button";

class Form extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

  constructor(props) {
    super(props);

    const { match: { params }, type } = this.props;

    this.state = {
        form: {
            next: '',
            previous: '',
            loading: {
                save: false,
            },
        },
        loaded: false,
        name: '',
        nif: '',
        email: '',
        phone: '',
        address: '',
        idapollo: '',
        idempresa: this.props.cookies.get('member_idempresa'),
        idprovider: type === 'edit' && params.idprovider,
        edit: type === 'edit',
        data: [],
        dataChanged: -1,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.loadFunction = this.loadFunction.bind(this);
    this.deleteFunction = this.deleteFunction.bind(this);
    this.handleFormPrevious = this.handleFormPrevious.bind(this);
    this.handleFormNext = this.handleFormNext.bind(this);
  }

  loadFunction() {
    if (!this.state.loaded) {
        if (this.props.type === 'edit') {
            axios.get(`https://eposgestofinal.pt/api/providers/getById.php?idempresa=${this.props.cookies.get('member_idempresa')}&idprovider=${this.state.idprovider}`)
            .then(response => {
                const providers = response.data !== 'NOK' ? response.data[0] : [];

                this.setState({ ...providers, idapollo: providers.idapollo >= 0 ? providers.idapollo : null , loaded: true });
            })
            .catch(error => {
                console.error(error);
            });

            axios.get(`https://eposgestofinal.pt/api/providers/form.php?idempresa=${this.props.cookies.get('member_idempresa')}&idprovider=${this.state.idprovider}`)
            .then(response => {
                const form = response.data !== 'NOK' && Object.keys(response.data).length ? response.data : this.state.form;

                console.error(form)

                this.setState({ form: {
                    ...this.state.form,
                    ...form,
                }, loaded: true });
            })
            .catch(error => {
                console.error(error);
            });
        } else {
            this.setState({ loaded: true });
        }
    }
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;
    
    this.setState({
        [name]: value,
    });
    
    if (callback) {
        callback();
    }
  }

  handleFormSubmit(e) {
    const { history } = this.props;
    e.preventDefault();
    
    this.setState({ form: {
        ...this.state.form,
        loading: {
            ...this.state.loading,
            save: true,
        },
    }, loaded: true });

    axios.post('https://eposgestofinal.pt/api/providers/set.php', {
        name: this.state.name,
        nif: this.state.nif,
        email: this.state.email,
        phone: this.state.phone,
        address: this.state.address,
        idempresa: this.state.idempresa,
        idprovider: this.state.idprovider,
        idapollo: this.state.idapollo,
    })
    .then(response => {
        NotificationManager.success('Guardado com sucesso', 'Fornecedor');
        if (!this.state.idprovider) {
            history.push('/providers');
        }
    })
    .finally(() => {
        this.setState({ form: {
            ...this.state.form,
            loading: {
                ...this.state.loading,
                save: false,
            },
        }, loaded: true });
    })
    .catch(error => {
        console.error(error);
    });
  }

  async deleteFunction() {
    this.setState({ form: {
        ...this.state.form,
        loading: {
            ...this.state.loading,
            delete: true,
        },
    }});
    confirmAlert({
        title: 'Confirmação',
        message: `Tem a certeza que deseja apagar o fornecedor ${this.state.name}?`,
        buttons: [
            {
                label: 'Sim',
                onClick: () => {
                    axios.get(`https://eposgestofinal.pt/api/providers/unset.php?idempresa=${this.props.cookies.get('member_idempresa')}&idprovider=${this.state.idprovider}`)
                    .then(response => {
                        if (response.data !== 'NOK') {
                            this.handleFormCancel();
                        }
                    })
                    .finally(() => {
                        this.setState({ form: {
                            ...this.state.form,
                            loading: {
                                ...this.state.loading,
                                delete: false,
                            },
                        }});
                    })
                    .catch(error => {
                        console.error(error);
                    });
                }
            },
            {
                label: 'Não',
                onClick: () => {
                    this.setState({ form: {
                        ...this.state.form,
                        loading: {
                            ...this.state.loading,
                            delete: false,
                        },
                    }});
                }
            }
        ]
    });
}

  handleFormCancel() {
    const { history } = this.props;
    history.push('/providers');
  }

  handleFormPrevious() {
    window.location.href = `/providers/${this.state.form.previous}`;
  }

  handleFormNext() {
    window.location.href = `/providers/${this.state.form.next}`;
  }

  render() {
    const { location, history } = this.props;
    const {
        edit,
        loaded,
        name,
        nif,
        email,
        phone,
        address,
        idapollo,
        form,
    } = this.state;
    const cookies = new URLSearchParams(location.search);

    return (
        <div>
            <Menu loadFunction={ this.loadFunction() } location={ location } history={ history } newCookies={ cookies } />
            <div className="fullContainer" style={{ maxWidth: '100%' }}>
                <div className="col-xs-12">
                    <ol
                        style={{
                            textAlign: 'left',
                            'marginTop': '75px',
                        }}
                        className="breadcrumb"
                    >
                        <li><a href="http://eposgestofinal.pt/index.php">Portal</a></li>
                        <li><a href="/providers">Fornecedores</a></li>
                        <li className="active">
                            {edit ? (<span>{name}</span>) : (<span>Novo Fornecedor</span>)}
                        </li>
                    </ol>
                </div>
            </div>
            {loaded && (
            <div
                className="fullContainer" style={{ maxWidth: '100%' }}
            >
                <div
                    className="col-sm-12"
                    style={{ textAlign: 'center' }}
                >
                    <div className="panel panel-default">
                        <div
                            className="panel-heading"
                            style={{
                                fontWeight: 'bold',
                                fontSize: '15pt',
                            }}
                        >
                            <table style={{
                                width: '100%'
                            }}>
                                <tr>

                                    <td style={{ width: '200px', textAlign: 'left' }}>
                                        {form.previous ? (
                                            <Button
                                                action={this.handleFormPrevious}
                                                type={"secondary"}
                                                title={"< Anterior"}
                                                style={buttonStyle}
                                            />
                                        ) : null}
                                    </td>
                                    <td>Detalhes</td>
                                    <td style={{ width: '200px', textAlign: 'right' }}>
                                        {form.next ? (
                                            <Button
                                                action={this.handleFormNext}
                                                type={"secondary"}
                                                title={"Seguinte >"}
                                                style={buttonStyle}
                                            />
                                        ) : null}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <form
                            style={{
                                textAlign: 'left',
                                width: '100%',
                            }}
                            className="container-fluid"
                            onSubmit={this.handleFormSubmit}
                        >
                            <table className="table table-bordered table-hover table-sortable">
                                <thead>
                                    <tr >
                                        <th className="text-center">Nome</th>
                                        <th className="text-center">NIF</th>
                                        <th className="text-center">Email</th>
                                        <th className="text-center">Telefone</th>
                                        <th className="text-center">Id Apollo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td>
                                            <Input
                                                inputType={"text"}
                                                name={"name"}
                                                value={name}
                                                handleChange={this.handleChange}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                inputType={"text"}
                                                name={"nif"}
                                                value={nif}
                                                handleChange={this.handleChange}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                inputType={"email"}
                                                name={"email"}
                                                value={email}
                                                handleChange={this.handleChange}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                inputType={"number"}
                                                name={"phone"}
                                                value={phone}
                                                handleChange={this.handleChange}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                inputType={"number"}
                                                name={"idapollo"}
                                                value={idapollo}
                                                handleChange={this.handleChange}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <TextArea
                            title={"Morada"}
                            rows={5}
                            value={address}
                            name={"address"}
                            handleChange={this.handleChange}
                            />
                            {/* About you */}
                            {edit ? (
                                <>
                                    <Button
                                    action={this.handleFormSubmit}
                                    type={"success"}
                                    title={"Gravar"}
                                    loading={form.loading.save}
                                    style={buttonStyle}
                                    />
                                    <Button
                                    action={this.handleFormCancel}
                                    type={"secondary"}
                                    title={"voltar"}
                                    style={buttonStyle}
                                    />
                                    <Button
                                    action={this.deleteFunction}
                                    type={"secondary"}
                                    title={"Apagar"}
                                    loading={form.loading.delete}
                                    style={{ ...buttonStyle, color: 'red' }}
                                    />
                                </>
                            ) : (
                                <>
                                    <Button
                                    action={this.handleFormSubmit}
                                    type={"success"}
                                    title={"Submeter"}
                                    loading={form.loading.save}
                                    style={buttonStyle}
                                    />
                                    {/*Submit */}
                                    <Button
                                    action={this.handleFormCancel}
                                    type={"secondary"}
                                    title={"cancelar"}
                                    style={buttonStyle}
                                    />
                                </>
                            )}
                            {/* Clear the form */}
                        </form>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px"
};

export default withCookies(Form);
