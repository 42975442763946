import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './TabPanel'

function a11yProps(index) {
  return {
    id: `vertical-tab-r${index}`,
    'aria-controls': `vertical-tabpanel-r${index}`,
  };
}

export default function VerticalTabs({ tabs }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="rootTab rootTabVertical">
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        className="tabsVertical"
      >
          {tabs.map((tab, index) => (
              <Tab label={tab.label} {...a11yProps(index)} />
          ))}
      </Tabs>
        {tabs.map((tab, index) => (
            <TabPanel value={value} index={index}>
                {tab.component}
            </TabPanel>
        ))}
    </div>
  );
}
