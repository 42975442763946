import React, { Component } from "react";
import axios from 'axios';

import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import Popup from "reactjs-popup";
import Input from "@components/Input";

import Gallery from 'react-grid-gallery';

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            photos: [],
            preview: {
                data: '',
                fileName: '',
            },
            upload: {},
            selectedImages: [],
        };
        this.loadFunction = this.loadFunction.bind(this);
        this.onTakePhoto = this.onTakePhoto.bind(this);
        this.cancelPhotoSubmit = this.cancelPhotoSubmit.bind(this);
        this.dataURLtoFile = this.dataURLtoFile.bind(this);
        this.confirmPhotoSubmit = this.confirmPhotoSubmit.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.onSelectImage = this.onSelectImage.bind(this);
    }

  loadFunction() {
    if (!this.state.loaded) {
        axios.get(`https://eposgestofinal.pt/api/customers/services/getPhotosById.php?idempresa=${this.props.showPopup.idempresa}&idcustomer=${this.props.showPopup.idcustomer}&idservice=${this.props.showPopup.idservice}`)
        .then(response => {
            const photos = response.data !== 'NOK' ? response.data : [];

            this.setState({ photos: photos.map((photo) => ({
                src: `https://eposgestofinal.pt/site/images/uploads/${photo.hash}`,
                thumbnail: `https://eposgestofinal.pt/site/images/uploads/${photo.hash}`,
                thumbnailWidth: 320,
                thumbnailHeight: 212,
            })), loaded: true });
        })
        .catch(error => {
            console.error(error);
        });
        this.setState({ loaded: true });
    }
  }


  dataURLtoFile(dataurl, fileName) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
    u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, {type:mime});
  }

  onTakePhoto (dataUri) {
    this.setState({ preview: {
            data: dataUri,
            fileName: 'camera.png',
        }
    });
  }

  cancelPhotoSubmit () {
    this.setState({ preview: {
        data: '',
        fileName: '',
        }
    });
  }

  confirmPhotoSubmit () {
    const formData = new FormData();

    formData.append('photo', this.dataURLtoFile(this.state.preview.data, this.state.preview.fileName));
    formData.append('idempresa', this.props.showPopup.idempresa);
    formData.append('idcustomer', this.props.showPopup.idcustomer);
    formData.append('idservice', this.props.showPopup.idservice);

    axios.post('https://eposgestofinal.pt/api/customers/services/setPhotos.php', formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
    .then(response => {
        this.setState({ loaded: false });
        this.cancelPhotoSubmit();
        this.loadFunction();
    });
  }

  onSelectImage(index) {
    const selImg = this.state.selectedImages;
    let newPhotos = this.state.photos;
    const currentIndex = selImg.indexOf(index);
    
    if (currentIndex !== -1) {
        selImg.splice(currentIndex, 1);
    } else {
        selImg.push(index);
    }

    newPhotos = newPhotos.map((newPhoto, index) => {
        newPhoto.isSelected = selImg.includes(index);
        return newPhoto;
    });
    

    this.setState({
        selectedImages: selImg,
        photos: newPhotos,
    });
  }

  handleUpload(e, callback) {
    const input = e.target;
    
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        
        reader.onload = (e) => {
          this.setState({ preview: {
              data: e.target.result,
              fileName: input.files[0].name,
            }
          });
        }
        
        reader.readAsDataURL(input.files[0]);
      }
  }

  render() {
    const {
        showPopup,
        hidePopupHandler,
        showCameraHandler,
        showUploadHandler,
    } = this.props;
    
    const {
        loaded,
        photos,
        preview,
    } = this.state;

    this.loadFunction();

    return (
        <Popup
            open={showPopup.idservice}
            closeOnDocumentClick
            onClose={hidePopupHandler}
        >
            <button className="close" onClick={hidePopupHandler}>
                &times;
            </button>
            <div className="photo">
                <button disabled={showPopup.photo} onClick={showCameraHandler}>
                    <i className="fas fa-camera fa-lg"></i> Tirar foto
                </button>
                <button disabled={showPopup.upload} onClick={showUploadHandler}>
                    <i className="fas fa-upload fa-lg"></i> Carregar foto
                </button>
                {showPopup.photo && preview.data === '' && (
                    <Camera
                        onTakePhoto = { (dataUri) => { this.onTakePhoto(dataUri); } }
                    />
                )}
                {showPopup.upload && preview.data === '' && (
                    <Input
                    inputType={"file"}
                    handleChange={this.handleUpload}
                    />
                )}
                {(showPopup.photo || showPopup.upload) && preview.data !== '' && (
                    <div>
                        <img alt="" src={preview.data} /><br />
                        <button onClick={this.cancelPhotoSubmit}>
                            <i className="fas fa-times fa-lg"></i> Cancelar
                        </button>
                        <button onClick={this.confirmPhotoSubmit}>
                            <i className="fas fa-check fa-lg"></i> Confirmar
                        </button>
                    </div>
                )}
                {loaded && (
                    <Gallery
                        images={photos}
                        onSelectImage={this.onSelectImage} />
                )}
            </div>
        </Popup>
    );
  }
}

export default Form;
