import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

// Components
import POSStructure from '@components/POSStructure'
import POSStatus from '@components/POSStatus'
import Steps from '@components/Steps'
import Modal from '@components/Modal'

import Qrcode from './qrcode';
import Product from './product';
import Warehouses from './warehouses';

import UserContext from '@components/context/userContext';

class Page extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            form: {
                product: { name: '' },
                warehouse: { name: "" },
                loaded: false,
                configs: {},
            },
            step: 0,
            steps: [{
                title: "Posto",
                component: ({ form }) => <Warehouses form={form} />,
              },{
                title: "Produto",
                component: ({ form }) => (<Product form={ form } />)
            }, {
                title: "Leitura da senha",
                component: ({ form }) => (<Qrcode form={ form } />)
            }],
            goBack: () => '/pos/tickets',
            state: 'initial',
            success: {},
            loaded: false,
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.changeStepData = this.changeStepData.bind(this);
    }

    changeStepData(data, step) {
        if (data) {
            this.setState({ form: { ...this.state.form, ...data } })
            if (!this.state.steps[this.state.step].multiple) {
                this.setState({ step: this.state.step + 1 })
            }
        } else if (step > -1) {
            this.setState({ step })
        }
    }

    handleFormSubmit() {        
        const { form } = this.state;
        const profile = this.context;

        axios.post('https://eposgestofinal.pt/api/tickets/set.php', {
            idcustomer: form.customer.idcustomer,
            idtickettype: form.ticketType.idtickettype,
            idpaymentmethod: ['1'].includes(form.ticketType.idtickettype) ? form.paymentMethod.idmethod : undefined,
            values: form.values.filter((value) => value.counter > 0).map(({ value, counter }) => ({ value, counter })),
            idempresa: profile.idEmpresa,
            idemployee: profile.idEmployee,
        })
            .then((response) => {
                NotificationManager.success('Submetidas com sucesso!', 'Senhas');
                this.setState({ state: 'success', success: response })
            })
            .catch(error => {
                NotificationManager.error(error.message, 'Senhas');
                this.setState({ state: 'error' })
            });
    }

    loadFunction() {
        const profile = this.context;

        if (!this.state.loaded) {
            axios.get(`https://eposgestofinal.pt/api/tickets/config/get.php?idempresa=${profile.idEmpresa}`)
                .then(response => {
                    const result = response.data !== 'NOK' ? response.data : [];
                    
                    const hydratateConfigs = result.reduce((acc, eachConfig) => {
                        return {
                            ...acc,
                            [eachConfig.key]: eachConfig.value,
                        }
                    }, {});

                    this.setState({ form: { ...this.state.form, configs: hydratateConfigs }, loaded: true });                      
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Configs');
                });
        }
    }

    render () {
        const { steps, step, form, state, goBack, loaded } = this.state;
        this.loadFunction()

        const realSteps = form.configs.invoices === '1' ? steps : [...steps.filter((eachStep) => ['Leitura da senha'].includes(eachStep.title))];

        return (
            <>
                <POSStructure
                    {...this.props}
                    title={ 'Validar senha' }
                    goBack={ goBack }
                >
                    {
                        {
                            'initial': 
                                <Steps
                                    steps={ realSteps }
                                    step={ step }
                                    form={ form }
                                    changeStepData={ this.changeStepData }
                                    submit={ this.handleFormSubmit } />,
                        }[state]
                    }
                </POSStructure>
                <Modal id="ticketsvalues"/>
            </>
        );
    }
}

export default withCookies(Page);