import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

// Components
import ReactTable from '@components/ReactTable';
import Input from "@components/Input";

import 'react-confirm-alert/src/react-confirm-alert.css';

import 'react-smarttab/dist/index.css'

import UserContext from '@components/context/userContext';

class Page extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            table: {
                columns: [
                    {
                      Header: 'Colaborador',
                      accessor: 'employee',
                    },
                    {
                      Header: 'Data',
                      accessor: 'date',
                    },
                    {
                      Header: 'Estado',
                      accessor: 'visualStatus',
                    },
                ],
                rows: [],
                loading: true,
            },
            loaded: false,
            search: ''
        };

        this.loadFunction = this.loadFunction.bind(this);
        this.filterData = this.filterData.bind(this);
    }

    loadFunction() {
        const profile = this.context;
        const { idticket } = this.props;

        if (!this.state.loaded) {
            axios.get(`https://eposgestofinal.pt/api/tickets/audit/getById.php?idempresa=${profile.idEmpresa}&idticket=${idticket}`)
                .then(response => {
                    const audits = response.data !== 'NOK' ? response.data : [];

                    this.setState({ table: { ...this.state.table, rows: audits.map((audit) => ({
                        ...audit,
                        visualStatus: audit.status === 'SUCCESS' ? (<span className="badge badge-success">Resgatada</span>) : (<span className="badge badge-warning">Repetida</span>),
                    })), loading: false }, loaded: true });
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Scans');
                });
        }
    }

    filterData(searchTerm) {
        this.setState({ search: searchTerm });
    }

    render () {
        const { table, search } = this.state;
        this.loadFunction()

        const filterSearch = search.split(" ");

        const tableRows = table.rows.length
            ? table.rows.filter((tickets) =>
                filterSearch.every(
                    (eachSearch) => {
                        return eachSearch === "" ||
                        (tickets.employee && tickets.employee.toLowerCase().includes(eachSearch.toLowerCase())) ||
                        (tickets.date && tickets.date.toLowerCase().includes(eachSearch.toLowerCase()))
                    }
                )
                )
            : [];

        return (
            <>
                <table className="table table-bordered table-hover table-sortable">
                    <thead>
                    <tr>
                        <th className="text-center">Pesquisa</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{ width: "250px" }}>
                        <Input
                            inputType={"text"}
                            name={"search"}
                            value={search}
                            placeholder="pesquisa..."
                            handleChange={(e) => this.filterData(e.target.value)}
                        />
                        </td>
                    </tr>
                    </tbody>
                </table>
                <ReactTable
                    columns={ table.columns }
                    sortable={ true }
                    loading={ table.loading }
                    data={ tableRows }
                />
            </>
        );
    }
}

export default withCookies(Page);