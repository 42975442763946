import React, { Component } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom'

import Item from "./Item";

import './Cards.css';

import UserContext from '../context/userContext';
class Cards extends Component {
    static contextType = UserContext;
    
    constructor(props) {
        super(props);
        this.state = { show: false };

        this.goTo = this.goTo.bind(this);
        this.onLongPress = this.onLongPress.bind(this);
    }

    goTo(link) {
        const { history } = this.props;
        history.push(link);
    };

    onLongPress(info) {
        const { handleLongPress } = this.props;

        if (handleLongPress) {
            handleLongPress(info)
        }
    };

    render () {
        const { items = [], modifier, position } = this.props;
        // eslint-disable-next-line no-unused-vars
        const profile = this.context;

        return (
            <div 
                className={classNames({
                    'cards-list': true,
                    [`cards-list-${position}`]: position,
                })}
            >
                {// eslint-disable-next-line
                items.map((item) => (item.show === false ? false : true) && eval((item.condition || 'true')) ? (
                    <Item
                        {...item}
                        goTo={this.goTo}
                        modifier={modifier}
                        handleLongPress={this.onLongPress}
                    />
                ) : null)}
            </div>
        );
    }
}

export default withRouter(Cards);