import React from "react";

const TextArea = props => {
    const classname = props.form ? "form-group" : "";

    return (
    <div className={ classname }>
      {props.title ? (<label for={props.name} className="form-label"> {props.title} </label>) : null}
      <textarea
        className="form-control"
        name={props.name}
        rows={props.rows}
        cols={props.cols}
        value={props.value}
        onChange={props.handleChange}
        placeholder={props.placeholder}
      />
    </div>
  );
};

export default TextArea;
        