import React from "react";
import axios from 'axios';
import QRCode from "react-qr-code";

import './Show.css';

import UserContext from '@components/context/userContext';

class ComponentToPrint extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            empresa: {}
        };
        this.loadFunction = this.loadFunction.bind(this);
    }

    loadFunction() {
        const profile = this.context;

        if (!this.state.loaded) {
            axios.get(`https://eposgestofinal.pt/api/config/get.php?idempresa=${profile.idEmpresa}`)
                .then(response => {
                    const empresa = response.data !== 'NOK' ? response.data[0] : '';

                    this.setState({ empresa, loaded: true });
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }

    componentDidMount() {
        this.props.handleDidMount();
    }

    render() {
        const { data, customer, employee, currency } = this.props;
        const { empresa } = this.state;

        this.loadFunction()

        return (
            <div className="fullTickets">
                {data.values.map((value) => value.qr.map((eachQr, index) => (
                    <div className="ticket">
                        <table style={{ width: '100%' }}>
                            <tr>
                                <td rowspan="2" className="ticketLogo">
                                    <img
                                        className="ticketLogo"
                                        src={ 'https://eposgestofinal.pt/' + (empresa['avatar'] || 'site/images/suitcase.png')}
                                        alt={ empresa['nomeempresa'] }
                                    />
                                </td>
                                <td style={{ fontSize: '7pt', fontWeight: 'bolder' }}>
                                    { empresa['nomeempresa'] }
                                </td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: '5pt' }}>
                                    { empresa['address'] }
                                </td>
                            </tr>
                        </table>
                        <div className="ticketData">
                            <p><span>Cliente:</span> {customer}</p>
                            <p><span>Data:</span> {eachQr['created_at']} <span>Op:</span> {employee}</p>
                        </div>
                        <table style={{ width: '100%' }}>
                            <tr>
                                <td rowspan="3" style={{ width: '120px' }}>
                                    <QRCode
                                        size="95"
                                        value={eachQr['code']}
                                    />
                                    <p className="ticketValue">{currency} {value.value}</p>
                                    <p className="refValue">Ref: {eachQr['idticket']}</p>
                                </td>
                                <td style={{ textAlign: 'left', height: '35px', border: 'none', borderBottom: '1px solid #000' }}><span>Data:</span></td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'left', height: '35px', border: 'none', borderBottom: '1px solid #000' }}><span>Bombeiro:</span></td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'left', height: '35px', border: 'none', borderBottom: '1px solid #000' }}><span>Cliente:</span></td>
                            </tr>
                        </table>
                    </div>
                )))}
            </div>
        )
    }
}

export default ComponentToPrint;
