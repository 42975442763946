import React from "react";
import Print from "@components/Print";
import Table from './table';
import Customers from './customers';

class Example extends React.Component {
  render() {
    return (
        <Print
            {...this.props}
            button="Imprimir relatório"
            printComponent={(this.props.table && Table) || (this.props.customers && Customers)}
            ref={el => (this.componentRef = el)}
        />
    );
  }
}

export default Example;
