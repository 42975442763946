import React, { useState, useEffect } from "react";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { pick } from "lodash";
import axios from "axios";
import 'react-date-picker/dist/DatePicker.css';
import "./App.css";

import Authentication from "./components/Logic/Authentication";
import PublicLogin from "./components/PublicLogin";
import Footer from "./components/Footer";
import POSLoading from "./components/POSLoading";

import { NotificationContainer } from "react-notifications";

import isAbsoluteURL from "axios/lib/helpers/isAbsoluteURL";
import buildURL from "axios/lib/helpers/buildURL";
import combineURLs from "axios/lib/helpers/combineURLs";

import UserContext from "./components/context/userContext";

import { EventBus as eventBus } from "./components/EventBus";

function App({ cookies }) {
  const profile = pick(cookies.getAll(), [
    "member_idempresa",
    "member_idempresa_sec",
    "member_type",
    "combustivel",
    "page",
    "member_idcliente",
    "demo",
    "prizes",
    "pontos",
    "member_avatar",
    "company_avatar",
    "member_rname",
    "member_name",
    "member_idemployee",
  ]);

  const isPublicPage = window.location.href.includes("/public");

  if ((!isPublicPage) && (!profile["member_idempresa"] || !profile["member_type"])) {
    window.location.href = `https://eposgestofinal.pt?redirect=${window.location.href}`;
  }

  const combustivel = parseInt(profile["combustivel"], 10) === 1;
  const memberType = parseInt(profile["member_type"], 10);
  const idEmpresa = parseInt(profile["member_idempresa"], 10);
  const idEmpresaSec = profile["member_idempresa_sec"] ? parseInt(profile["member_idempresa_sec"], 10) : idEmpresa;
  const idEmployee = parseInt(profile["member_idemployee"], 10);
  const name = profile["member_rname"] && profile["member_rname"].split("+").join(" ");
  const avatar = profile["member_avatar"];
  const companyAvatar = profile["member_avatar"];
  const companyPublicAvatar = localStorage.getItem("avatar");
  const identifier = localStorage.getItem("public_indentifier");
  const token = localStorage.getItem("token");
  const loggedIn = identifier && token;

  const [ready, setReady] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [fullName, setFullName] = useState('');

  useEffect(() => {
    axios
      .get(
        `https://eposgestofinal.pt/api/site/permissions/get.php?idempresa=${idEmpresa}`
      )
      .then((response) => {
        const sitePermissions = response.data;
        axios
          .get(
            `https://eposgestofinal.pt/api/pos/permissions/get.php?idempresa=${idEmpresa}`
          )
          .then((response) => {
            const posPermissions = response.data;
            setPermissions([
              ...permissions,
              ...Object.keys(posPermissions)
                .filter((permission) => posPermissions[permission] === "t")
                .map((permission) => `pos.${permission}`),
              ...Object.keys(sitePermissions)
                .filter((permission) => sitePermissions[permission] === "t")
                .map((permission) => `site.${permission}`),
            ]);

            axios
            .get(
              `https://eposgestofinal.pt/api/empresas/getById.php?idempresa=${idEmpresa}`
            )
              .then((response) => {
                const fullName = response.data != 'NOK' ? response.data : '';
                setFullName(fullName.name)
                setReady(true);
              })
              .catch((error) => {
                console.error(error);
              });

            setReady(true);
          })
          .catch((error) => {
            console.error(error);
          });
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const adminProfile = memberType === 10;

  const URL_KEY = "__AXIOS-DEBUG-LOG_URL__";

  const getUrl = (config) => {
    const url =
      config.baseURL && !isAbsoluteURL(config.url)
        ? combineURLs(config.baseURL, config.url)
        : config.url;

    return buildURL(url, config.params, config.paramsSerializer);
  };

  const getUrlAndMethod = (config) => {
    const url = config[URL_KEY] || getUrl(config);
    const method = config.method.toUpperCase();

    return {
      url,
      method,
    };
  };

  require("axios-debug-log")({
    request: (_, config) => {
      eventBus.$emit("Loading", { active: true });

      const { url, method } = getUrlAndMethod(config);

      Object.defineProperty(config, URL_KEY, { value: url });

      console.info(`${method} ${url}`);
    },
    response: (_, response) => {
      const { url, method } = getUrlAndMethod(response.config);
      const { status, statusText } = response;

      console.info(`${status} ${statusText} (${method} ${url})`);

      eventBus.$emit("Loading", { active: false });
    },
    error: (_, error) => {
      const { name, message } = error;

      if (error.config) {
        const { url, method } = getUrlAndMethod(error.config);

        console.error(`${name}: ${message} (${method} ${url})`);
      } else {
        console.error(`${name}: ${message}`);
      }

      eventBus.$emit("Loading", { active: false });
    },
  });

  console.log(permissions);

  return ready ? (
    <UserContext.Provider
      value={{
        ...profile,
        fullName,
        name,
        avatar,
        companyAvatar,
        companyPublicAvatar,
        memberType,
        combustivel,
        idEmpresa,
        idEmpresaSec,
        idEmployee,
        adminProfile,
        permissions,
        identifier,
        token,
      }}
    >
      <POSLoading />
      <div className="App">
        {isPublicPage && !loggedIn && (
          <PublicLogin />
        )}
        {((isPublicPage && loggedIn) || !isPublicPage) &&
          <>
            <Authentication />
          </>
        }
        <NotificationContainer />
        <Footer />
      </div>
    </UserContext.Provider>
  ) : null;
}

App.propTypes = {
  cookies: instanceOf(Cookies).isRequired,
};

export default withCookies(App);
