import React, { Component } from 'react';

// Components
import { twoDecimal, numberWithCommas } from '@utils/format.js'

import './table.css';

class Page extends Component {
    componentDidMount() {
        if (typeof this.props.handleDidMount == 'function') {
            this.props.handleDidMount();
        }
    }

    render () {
        const { table, date, posto, category } = this.props;

        return (
            <table style={{ width: '100%' }}>
                <tr>
                    <td>
                        <table style={{ width: '100%' }}>
                            <tr>
                                <th>Data</th>
                                <th>Posto</th>
                                <th>Familia</th>
                            </tr>
                            <tr>
                                <td>{date}</td>
                                <td>({posto.value}) {posto.label}</td>
                                <td>({category.value}) {category.label}</td>
                            </tr>
                            <tr>
                                <td colspan="3"><hr style={{ width: '100%' }} /></td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td style={{ padding: '40px' }}>
                        <table style={{ width: '100%' }}>
                            <tr>
                                {table.columns.filter(({ accessor }) => accessor !== 'inventory').map(({ Header }, index) => (
                                    <th style={{
                                        textAlign: [0,1].includes(index) ? 'left' : 'center',
                                        fontFamily: '"Arial" , sans-serif',
                                        fontSize: '12px',
                                    }}>{ Header }</th>
                                ))}
                            </tr>
                            {table.rows.map((row) => (
                                <tr>
                                    <td style={{
                                        textAlign: 'left',
                                        fontFamily: '"Arial" , sans-serif',
                                        fontSize: '12px',
                                    }}>{ row.idproduct }</td>
                                    <td style={{
                                        textAlign: 'left',
                                        fontFamily: '"Arial" , sans-serif',
                                        fontSize: '12px',
                                    }}>{ row.nome }</td>
                                    <td style={{
                                        fontFamily: '"Arial" , sans-serif',
                                        fontSize: '12px',
                                    }}>{ row.stock }</td>
                                    <td style={{
                                        fontFamily: '"Arial" , sans-serif',
                                        fontSize: '12px',
                                    }}>{ row.costprice }</td>
                                    <td style={{
                                        fontFamily: '"Arial" , sans-serif',
                                        fontSize: '12px',
                                    }}>{ row.total }</td>
                                </tr>
                            ))}
                        </table>
                    </td>
                </tr>
            </table>
        );
    }
}

export default Page;