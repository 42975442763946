import React, { Component }  from 'react';
import ClipLoader from "react-spinners/ClipLoader";

import { EventBus as eventBus } from "../EventBus";

import './POSLoading.css';

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 0,
            text: '',
        };
    }

    componentDidMount() {
        eventBus.$on("Loading", (data) => {
            if (data.active) {
                this.setState({ active: this.state.active + 1, text: data.text || '' })
            } else {
                this.setState({ active: this.state.active - 1, text: data.text || '' })
            }
        });
    }

    componentWillUnmount() {
        eventBus.$off("Loading");
    }

    render() {
        const { active, text } = this.state;

        return active > 0 ? (
            <div className="loading">
                <div>
                    <ClipLoader
                        css={{
                            color: 'red'
                        }}
                        size={200} />
                    <h1>{text}</h1>
                </div>
            </div>
        ) : null;
    }
}

export default Loading;
