import React, { Component } from 'react';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { withRouter } from 'react-router-dom'
import axios from 'axios';

import 'react-confirm-alert/src/react-confirm-alert.css';
import { MdAddCircleOutline, MdVisibility } from "react-icons/md";
import ReactTable from '@components/ReactTable';

// Components
import Menu from '@components/Menu'

class Page extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    
    constructor(props) {
        super(props);


        this.state = {
            loaded: false,
            table: {
                columns: [
                    {
                      Header: 'Nome',
                      accessor: 'name',
                    },
                    {
                      Header: 'NIF',
                      accessor: 'nif',
                    },
                    {
                      Header: 'Morada',
                      accessor: 'address',
                    },
                    {
                      Header: 'Telefone',
                      accessor: 'phone',
                    },
                    {
                      Header: 'Email',
                      accessor: 'email',
                    },
                    {
                      Header: '',
                      accessor: 'view',
                    }
                ],
                rows: [],
                loading: true,
            },
        };
        this.loadFunction = this.loadFunction.bind(this);
    }
  
    loadFunction() {
        if (!this.state.loaded) {
            const { history } = this.props;

            axios.get(`https://eposgestofinal.pt/api/providers/get.php?idempresa=${this.props.cookies.get('member_idempresa')}`)
            .then(response => {
                const providers = response.data !== 'NOK' ? response.data : [];

                const enrichedProviders = providers.map((provider) => ({
                        ...provider,
                        name: provider.idapollo && parseInt(provider.idapollo, 10) !== -1 ? `[${provider.idapollo}] ${provider.name}` : provider.name,
                        view: (<MdVisibility style={{ fontSize: '15px', cursor: 'pointer' }} onClick={ () => history.push(`/providers/${provider.idprovider}`) } />),
                }));

                this.setState({ table: { ...this.state.table , rows: enrichedProviders, loading: false }, loaded: true });
            })
            .catch(error => {
                console.error(error);
            });
        }
    }
    
    render () {
        const { table } = this.state;
        const { location, history } = this.props;
        const cookies = new URLSearchParams(location.search);

        return (
            <div>
                <Menu loadFunction={ this.loadFunction() } location={ location } history={ history } newCookies={ cookies } />
                <div className="fullContainer" style={{ maxWidth: '100%' }}>
                    <div className="col-xs-12">
                        <ol
                            style={{
                                textAlign: 'left',
                                'marginTop': '75px',
                            }}
                            className="breadcrumb"
                        >
                            <li><a href="http://eposgestofinal.pt/index.php">Portal</a></li>
                            <li className="active">Fornecedores</li>
                        </ol>
                    </div>
                </div>
                <div
                    className="fullContainer" style={{ maxWidth: '100%' }}
                >
                    <div
                        className="col-sm-12"
                        style={{ textAlign: 'center' }}
                    >
                        <div className="panel panel-default">
                            <div
                                className="panel-heading"
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '15pt',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                    }}
                                >
                                    Fornecedores
                                </div>
                                <div>
                                    <MdAddCircleOutline style={{ fontSize: '20px', cursor: 'pointer' }} onClick={ () => history.push('/providers/new') } />
                                </div>
                            </div>
                            <ReactTable
                                columns={ table.columns }
                                sortable={ true }
                                loading={ table.loading }
                                data={ table.rows }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withCookies(withRouter(Page));