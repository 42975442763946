import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

// Components
import ReactTable from '@components/ReactTable';
import Solve from './solve'
import { MdClose } from "react-icons/md";
import Modal from '@components/Modal'

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import 'react-smarttab/dist/index.css'

import UserContext from '@components/context/userContext';

import { twoDecimal, numberWithCommas } from '@utils/format.js'

import { EventBus as eventBus } from "@components/EventBus";

class Page extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            table: {
                columns: [
                    {
                      Header: 'Documento',
                      accessor: 'doc',
                    },
                    {
                      Header: 'Data',
                      accessor: 'date',
                    },
                    {
                      Header: 'Pagamento',
                      id: 'payment_method',
                      accessor: d => this.state.payments[parseInt(d.payment_method, 10)],
                    },
                    {
                      Header: 'Débito',
                      id: 'debit',
                      accessor: d => d.debit > 0 ? `${this.state.currency} ${numberWithCommas(twoDecimal(d.debit))}` : '',
                    },
                    {
                      Header: 'Crédito',
                      id: 'credit',
                      accessor: d => d.credit > 0 ? `${this.state.currency} ${numberWithCommas(twoDecimal(d.credit))}` : '',
                    },
                    {
                      Header: 'Em falta',
                      id: 'missing',
                      accessor: d => d.debit > 0 && d.paid < d.debit ? (<span style={{ color: 'red' }}>{`${this.state.currency} ${numberWithCommas(twoDecimal(d.debit - d.paid))}`}</span>) : '',
                    },
                    {
                        Header: '',
                        accessor: 'delete',
                    },
                ],
                rows: [],
                loading: true,
            },
            total: 0,
            debit: 0,
            credit: 0,
            currency: '',
            payments: {},
            loaded: false,
        };

        this.loadFunction = this.loadFunction.bind(this);
        this.showSolve = this.showSolve.bind(this);
        this.deleteBill = this.deleteBill.bind(this);
    }

    deleteBill(idbill) {
        confirmAlert({
            title: 'Confirmação',
            message: `Tem a certeza que deseja apagar este movimento?`,
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        const profile = this.context;
                        const { idcustomer } = this.props;

                        axios.get(`https://eposgestofinal.pt/api/customers/bills/unset.php?idempresa=${profile.idEmpresa}&idcustomer=${idcustomer}&idbill=${idbill}`)
                        .then(response => {
                            NotificationManager.success('Movimento apagado com sucesso!', 'Movimento');
                            this.setState({ loaded: false });
                            this.loadFunction();
                        })
                        .catch(error => {
                            NotificationManager.error(error.message, 'Movimento');
                        });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => {}
                }
            ]
        });
    }

    loadFunction() {
        const profile = this.context;
        const { idcustomer } = this.props;

        if (!this.state.loaded) {
            axios.get(`https://eposgestofinal.pt/api/config/payment/methods/get.php?idempresa=${profile.idEmpresa}`)
                .then(response => {
                    const payments = response.data !== 'NOK' ? response.data : [];

                    const enrichedPayments = payments.reduce((accum, payment) => ({
                            ...accum,
                            [payment.idmethod]: payment.name, 
                    }), {});

                    this.setState({ payments: enrichedPayments, loaded: true });
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Métodos de APagamento');
                });
            axios.get(`https://eposgestofinal.pt/api/config/currency/get.php?idempresa=${profile.idEmpresa}`)
                .then(response => {
                    const currency = response.data !== 'NOK' ? response.data[0].currency : '';

                    this.setState({ currency, loaded: true });
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Moeda');
                });
            axios.get(`https://eposgestofinal.pt/api/customers/bills/get.php?idempresa=${profile.idEmpresa}&idcustomer=${idcustomer}`)
                .then(response => {
                    const docs = response.data !== 'NOK' ? response.data : [];
                    const debit = docs
                        .reduce((accum, {debit}) => accum + parseFloat(debit), 0);
                    const credit = docs
                        .reduce((accum, {credit}) => accum + parseFloat(credit), 0);
                    const total = credit - debit;

                    const enrichedDocs = docs.map((doc) => ({
                        ...doc,
                        delete: (<MdClose style={{ fontSize: '15px', cursor: 'pointer' }} onClick={ () => this.deleteBill(doc.idbill) } />),
                    }))

                    this.setState({ table: { ...this.state.table, rows: enrichedDocs, loading: false }, debit, credit, total, loaded: true });
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Movimentos');
                });
        }
    }

    showSolve () {
        const { idcustomer } = this.props;

        eventBus.$emit("Modal-customers-checkingaccount", { header: "Liquidar Contas", body: <Solve idcustomer={idcustomer} /> });
    }

    render () {
        const { table, total, debit, credit, currency } = this.state;
        this.loadFunction()

        return (
            <>
                <table style={{ marginBottom: 0 }} className="table table-bordered-only-inside">
                    <tbody>
                        <tr>
                            <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>Débito<h4>{currency} {numberWithCommas(twoDecimal(debit))}</h4></td>
                            <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>Crédito<h4>{currency} {numberWithCommas(twoDecimal(credit))}</h4></td>
                            <td style={{ fontWeight: 'bolder', verticalAlign: 'middle', textAlign: 'right' }}>
                                Saldo Total<h4 style={{ color: total < 0 ? '#E03241' : '#1BA73F' }}>{currency} {numberWithCommas(twoDecimal(total))}</h4>
                                {total < 0 ? (
                                <button
                                    type="button"
                                    onClick={this.showSolve}
                                    className="btn btn-success"
                                    disabled
                                >
                                    Liquidar contas
                                </button>
                                ) : null}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr style={{ marginTop: 0, borderColor: '#DDDDDD' }} />
                <ReactTable
                    columns={ table.columns }
                    sortable={ true }
                    loading={ table.loading }
                    data={ table.rows }
                />
                <Modal id="customers-checkingaccount" />
            </>
        );
    }
}

export default withCookies(Page);