import React, { Component } from "react";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import axios from "axios";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import distinctColors from 'distinct-colors';
import { isRangeOverlap } from "range-overlap"
import _ from "lodash";

import Menu from "@components/Menu";
import Modal from "@components/Modal";
import Input from "@components/Input";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import {
  DayPilotCalendar,
  DayPilotNavigator,
} from "@daypilot/daypilot-lite-react";

import New from "./new";

import "./agenda.css";

import { EventBus as eventBus } from "@components/EventBus";

import UserContext from "@components/context/userContext";

if (!String.prototype.format) {
  String.prototype.format = function () {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != "undefined" ? args[number] : match;
    });
  };
}

const styles = {
  wrap: {
    display: "flex",
  },
  left: {
    marginRight: "10px",
  },
  main: {
    flexGrow: "1",
  },
};

const extraZero = (value) => (value < 10 ? `0${value}` : value);

const TimestampToDatetime = (timestamp) => {
  const datum = new Date(timestamp * 1000);
  const year = datum.getFullYear();
  const month = extraZero(datum.getMonth() + 1);
  const day = extraZero(datum.getDate());
  const hour = extraZero(datum.getHours());
  const min = extraZero(datum.getMinutes());
  return `${year}-${month}-${day}T${hour}:${min}:00`;
};

const datetimeToTimestamp = (strDate) => {
  const dateAndTime = strDate.split("T");
  const date = dateAndTime[0].split("-");
  const time = dateAndTime[1].split(":");
  const year = parseInt(date[0], 10);
  const month = parseInt(date[1], 10) - 1;
  const day = parseInt(date[2], 10);
  const hour = parseInt(time[0], 10);
  const min = parseInt(time[1], 10);
  const datum = new Date(year, month, day, hour, min, 0);
  return datum / 1000;
};

const datetimeToTime = (strDate) => {
  const dateAndTime = strDate.split("T");
  const time = dateAndTime[1].split(":");
  const hour = extraZero(parseInt(time[0], 10));
  const min = extraZero(parseInt(time[1], 10));
  return `${hour}:${min}`;
};

const datetimeToDate = (strDate) => {
  const dateAndTime = strDate.split("T");
  const date = dateAndTime[0].split("-");
  const year = parseInt(date[0], 10);
  const month = extraZero(parseInt(date[1], 10));
  const day = extraZero(parseInt(date[2], 10));
  return `${year}-${month}-${day}`;
};

const areOverlapping = (A, B) => {
  return isRangeOverlap(
    new Date(parseInt(A.start_date, 10)*1000),
    new Date(parseInt(A.end_date, 10)*1000),
    new Date(parseInt(B.start_date, 10)*1000),
    new Date(parseInt(B.end_date, 10)*1000),
    true
  );
}

function getContrastColor(RGBA) {
  const R = RGBA[0];
  const G = RGBA[1];
  const B = RGBA[2];
  const A = RGBA[3];
  const brightness = R * 0.299 + G * 0.587 + B * 0.114 + (1 - A) * 255;

  return brightness > 186 ? "#000000" : "#FFFFFF";
}

const onlyUniqueHousehold = (obj) => obj.idhousehold;

class Agenda extends Component {
  static contextType = UserContext;
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    this.calendarRef = React.createRef();

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    
    const todayMorning = TimestampToDatetime(new Date(today.setHours(0,0,0)).getTime() / 1000);
    const todayNight = TimestampToDatetime(new Date(today.setHours(23,59,59)).getTime() / 1000);

    this.state = {
      crosshairType: "Full",
      today: now,
      date: now.toISOString().substr(0, 10),
      loaded: false,
      eventFormat: isMobile ? "{1}-{2}-{3}" : "{1}\n{2}\n{3}",
      eventBirthdayFormat: isMobile ? "🎁 {0}\n{1}" : "🎁 {0}\n{1}",
      cellHeight: 70,
      durationBarVisible: false,
      timeRangeSelectedHandling: "Enabled",
      description: "",
      employee: "",
      customer: "",
      household: "",
      startTime: "",
      endTime: "",
      eventDate: "",
      customers: [],
      households: [],
      employees: [],
      kinship: [],
      healthSystems: [],
      events: [],
      hideAvailable: true,
      viewStartDate: todayMorning,
      viewEndDate: todayNight,
      onTimeRangeSelected: (args) => this.openNewModal(args),
      eventDeleteHandling: "Update",
      onEventClick: (args) => this.openEditModal(args),
      onEventMoved: (args) => this.moveEvent(args),
      eventResizeHandling: "Update",
      onEventResized: (args) => this.moveEvent(args),
      onEventDeleted: (args) => this.deleteEvent(args),
      customCustomer: false,
      customCustomerName: '',
      customCustomerPhone: '',
      customHousehold: false,
      customHouseholdName: '',
      customHouseholdPhone: '',
    };

    this.newEvent = this.newEvent.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openNewModal = this.openNewModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.moveEvent = this.moveEvent.bind(this);
    this.getEvents = this.getEvents.bind(this);
  }

  get calendar() {
    return this.calendarRef.current.control;
  }

  async deleteEvent(args) {
    confirmAlert({
        title: 'Confirmação',
        message: `Tem a certeza que deseja apagar este evento?`,
        buttons: [
            {
                label: 'Sim',
                onClick: () => {
                  const profile = this.context;
                  axios
                    .get(
                      `https://eposgestofinal.pt/api/calendar/unset.php?idempresa=${profile.idEmpresa}&idevent=${args.e.data.id}`
                    )
                    .catch((error) => {
                      console.error(error);
                    });
                    this.setState({ eventsBackup: JSON.parse(JSON.stringify(this.state.events.filter((event) => event.id !== args.e.data.id))) });
                }
            },
            {
                label: 'Não',
                onClick: () => {
                  const dp = this.calendar;
                  dp.events.add({
                    ...args.e.data
                  });
                }
            }
        ]
    });
  }

  async openNewModal(args) {
    const profile = this.context;
    await this.setState({
      description: "",
      employee: this.state.employees.find((employee) => `${args.resource}` === `${employee.idemployee}`),
      customer: this.state.customers.find((customer) => customer.idcustomer === "-1"),
      household: this.state.households.find((household) => household.idhousehold === "-1"),
      startTime: datetimeToTime(args.start.value),
      endTime: datetimeToTime(args.end.value),
      eventDate: datetimeToDate(args.start.value),
    });
    eventBus.$emit("Modal-agenda", {
      header: "Criar evento",
      body: (
        <New
          newEvent={() => this.newEvent(args)}
          handleChange={this.handleChange}
          description={this.state.description}
          employee={this.state.employee}
          customer={this.state.customer}
          household={this.state.household}
          employees={this.state.employees}
          customers={this.state.customers}
          households={this.state.households}
          startTime={this.state.startTime}
          endTime={this.state.endTime}
          eventDate={this.state.eventDate}
          edit={true}
          customCustomer={this.state.customCustomer}
          customCustomerName={this.state.customCustomerName}
          customCustomerPhone={this.state.customCustomerPhone}
          customHousehold={this.state.customHousehold}
          customHouseholdName={this.state.customHouseholdName}
          customHouseholdPhone={this.state.customHouseholdPhone}
        />
      ),
    });
  }

  async openEditModal(args) {
    await this.setState({
      description: args.e.data.description,
      employee: args.e.data.employee,
      customer: args.e.data.customer,
      moreThanOneCustomer: args.e.data.moreThanOneCustomer,
      household: args.e.data.household,
      startTime: datetimeToTime(args.e.data.start.value),
      endTime: datetimeToTime(args.e.data.end.value),
      eventDate: datetimeToDate(args.e.data.end.value)
    });
    eventBus.$emit("Modal-agenda", {
      header: "Editar evento",
      modifier: args.e.data.employee.idemployee === '-2' ? "big" : "small",
      body: (
        <New
          newEvent={() => this.newEvent(args, true)}
          handleChange={this.handleChange}
          description={this.state.description}
          employee={this.state.employee}
          customer={this.state.customer}
          moreThanOneCustomer={this.state.moreThanOneCustomer}
          household={this.state.household}
          employees={this.state.employees}
          customers={this.state.customers}
          households={this.state.households}
          startTime={this.state.startTime}
          endTime={this.state.endTime}
          eventDate={this.state.eventDate}
          customCustomer={this.state.customCustomer}
          customCustomerName={this.state.customCustomerName}
          customCustomerPhone={this.state.customCustomerPhone}
          customHousehold={this.state.customHousehold}
          customHouseholdName={this.state.customHouseholdName}
          customHouseholdPhone={this.state.customHouseholdPhone}
        />
      ),
    });
  }

  async moveEvent(args) {
    if (args.e.data.employee.idemployee != -2) {
      await this.setState({
        description: args.e.data.description,
        employee: args.e.data.employee,
        customer: args.e.data.customer,
        household: args.e.data.household,
        startTime: datetimeToTime(args.newStart.value),
        endTime: datetimeToTime(args.newEnd.value),
        eventDate: datetimeToDate(args.newStart.value),
      });
      args.e.data.start = args.newStart;
      args.e.data.end = args.newEnd;
      args.e.data.resource = args.newResource || args.e.data.resource;
      args.e.data.resourceChanged = true;
      this.newEvent(args, true);
    }
  }

  newEvent(args, edit) {
    const startDate = args.startDate;
    const profile = this.context;
    const dp = this.calendar;
    if (
      edit &&
      this.state.description &&
      this.state.customer &&
      this.state.household &&
      this.state.employee &&
      this.state.startTime &&
      this.state.endTime &&
      this.state.eventDate
    ) {
      args.e.data.description = this.state.description;
      args.e.data.customer = this.state.customer;
      args.e.data.household = this.state.household;
      args.e.data.resource = args.e.data.resourceChanged ? args.e.data.resource : this.state.employee.idemployee;
      args.e.data.employee = args.e.data.resourceChanged ? this.state.employees.find((employee) => employee.idemployee === args.e.data.resource) : this.state.employee;
      args.e.data.backColor = this.state.employees.find((employee) => employee.idemployee === args.e.data.employee.idemployee).color;
      args.e.data.resourceChanged = false;
      args.e.data.start = `${this.state.eventDate}T${this.state.startTime}:00`;
      args.e.data.end = `${this.state.eventDate}T${this.state.endTime}:00`;
      args.e.data.text = this.state.eventFormat.format(
        this.state.employee.name,
        this.state.description,
        this.state.household && this.state.household.idhousehold !== '-1' ? this.state.household.name : this.state.customer.name,
        this.state.household && this.state.household.idhousehold !== '-1' ? this.state.household.phone : this.state.customer.phone
      );

      const startTimestamp = datetimeToTimestamp(args.e.data.start);
      const endTimestamp = datetimeToTimestamp(args.e.data.end);
      
      const performSave = async () => {
        let newCustomer = this.state.customCustomer ? {
          name: this.state.customer.name,
          label: this.state.customer.name,
          phone: this.state.customer.phone,
          idcustomer: this.state.customer.idcustomer,
          value: this.state.customer.idcustomer,
        } : this.state.customer;
        if (this.state.customCustomer) {
          await axios
            .post("https://eposgestofinal.pt/api/customers/set.php", {
              idempresa: profile.idEmpresa,
              name: this.state.customCustomerName,
              phone: this.state.customCustomerPhone,
            })
            .then(async (response) => {
              if (response.data !== "NOK") {
                newCustomer = {
                  name: this.state.customCustomerName,
                  label: this.state.customCustomerName,
                  phone: this.state.customCustomerPhone,
                  idcustomer: response.data.idcustomer,
                  value: response.data.idcustomer,
                }
                args.e.data.customer = newCustomer;
                args.e.data.text = this.state.eventFormat.format(
                  this.state.employee.name,
                  this.state.description,
                  newCustomer.name,
                  newCustomer.phone
                );
                await this.setState({
                  customCustomer: false,
                  customCustomerName: '',
                  customCustomerPhone: '',
                  customer: newCustomer,
                  customers: [
                    ...this.state.customers,
                    newCustomer,
                  ]
                })
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
        let newHousehold = this.state.customHousehold ? {
          name: this.state.household.name,
          label: this.state.household.name,
          phone: this.state.household.phone,
          idhousehold: this.state.household.idhousehold,
          value: this.state.household.idhousehold,
        } : this.state.household;
        if (this.state.customHousehold) {
          await axios
            .post("https://eposgestofinal.pt/api/customers/household/set.php", {
              idempresa: profile.idEmpresa,
              idcustomer: newCustomer.idcustomer,
              name: this.state.customHouseholdName,
              phone: this.state.customHouseholdPhone,
            })
            .then(async (response) => {
              if (response.data !== "NOK") {
                newHousehold = {
                  name: this.state.customHouseholdName,
                  label: this.state.customHouseholdName,
                  phone: this.state.customHouseholdPhone,
                  idhousehold: response.data.idhousehold,
                  value: response.data.idhousehold,
                }
                args.e.data.household = newHousehold;
                args.e.data.text = this.state.eventFormat.format(
                  this.state.employee.name,
                  this.state.description,
                  newHousehold.name,
                  newHousehold.phone
                );
                await this.setState({
                  customHousehold: false,
                  customHouseholdName: '',
                  customHouseholdPhone: '',
                  household: newHousehold,
                  households: [
                    ...this.state.households,
                    newHousehold,
                  ]
                })
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
        axios
          .post("https://eposgestofinal.pt/api/calendar/set.php", {
            idevent: args.e.data.id,
            idempresa: profile.idEmpresa,
            idemployee: args.e.data.employee.idemployee,
            idcustomer: newCustomer.idcustomer,
            idhousehold: newHousehold.idhousehold,
            description: this.state.description,
            startDate: startTimestamp,
            endDate: endTimestamp,
          })
          .then((response) => {
            if (response.data !== "NOK") {
              dp.events.update(args.e);
              dp.update({
                startDate: args.e.data.start,
              });
              this.setState({ eventsBackup: JSON.parse(JSON.stringify(this.state.events.map((event) => {
                if (event.id === args.e.data.id) {
                  return args.e.data;
                } else {
                  return event;
                }
              }))) });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }

      const auxEvent = {
        start_date: startTimestamp,
        end_date: endTimestamp,
      }
      const conflict = this.state.events.filter(
        (event) =>
          event.id !== args.e.data.id && event.employee.idemployee === args.e.data.employee.idemployee && areOverlapping(event, auxEvent)
      );

      if (conflict.length) {
        confirmAlert({
          title: "Confirmação",
          message: "O evento que está a editar ficará em conflito com os seguintes eventos: " + conflict.map((event) => `${event.description}`).join("; "),
          buttons: [
            {
              label: "Sim",
              onClick: () => {
                performSave();
              },
            },
            {
              label: "Não",
              onClick: () => {
                const dp = this.calendar;
                const currentEvent = this.state.eventsBackup.find(
                  (event) =>
                    event.id === args.e.data.id
                );
                dp.events.update({
                  ...currentEvent,
                  backColor: args.e.data.backColor,
                });
              },
            },
          ],
        });
      } else {
        performSave();
      }
    } else if (
      !edit &&
      this.state.description &&
      this.state.customer &&
      this.state.household &&
      this.state.employee &&
      this.state.startTime &&
      this.state.endTime &&
      this.state.eventDate
    ) {
      dp.clearSelection();
      const newStartDate = `${this.state.eventDate}T${this.state.startTime}:00`;
      const newEndDate = `${this.state.eventDate}T${this.state.endTime}:00`;

      const startTimestamp = datetimeToTimestamp(newStartDate);
      const endTimestamp = datetimeToTimestamp(newEndDate);

      const performSave = async () => {
        let newCustomer = this.state.customCustomer ? {
          name: this.state.customer.name,
          label: this.state.customer.name,
          phone: this.state.customer.phone,
          idcustomer: this.state.customer.idcustomer,
          value: this.state.customer.idcustomer,
        } : this.state.customer;
        if (this.state.customCustomer) {
          await axios
            .post("https://eposgestofinal.pt/api/customers/set.php", {
              idempresa: profile.idEmpresa,
              name: this.state.customCustomerName,
              phone: this.state.customCustomerPhone,
            })
            .then(async (response) => {
              if (response.data !== "NOK") {
                newCustomer = {
                  name: this.state.customCustomerName,
                  label: this.state.customCustomerName,
                  phone: this.state.customCustomerPhone,
                  idcustomer: response.data.idcustomer,
                  value: response.data.idcustomer,
                }
                await this.setState({
                  customCustomer: false,
                  customCustomerName: '',
                  customCustomerPhone: '',
                  customer: newCustomer,
                  customers: [
                    ...this.state.customers,
                    newCustomer,
                  ]
                })
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
        let newHousehold = this.state.customHousehold ? {
          name: this.state.household.name,
          label: this.state.household.name,
          phone: this.state.household.phone,
          idhousehold: this.state.household.idhousehold,
          value: this.state.household.idhousehold,
        } : this.state.household;
        if (this.state.customHousehold) {
          await axios
            .post("https://eposgestofinal.pt/api/customers/household/set.php", {
              idempresa: profile.idEmpresa,
              idcustomer: newCustomer.idcustomer,
              name: this.state.customHouseholdName,
              phone: this.state.customHouseholdPhone,
            })
            .then(async (response) => {
              if (response.data !== "NOK") {
                newHousehold = {
                  name: this.state.customHouseholdName,
                  label: this.state.customHouseholdName,
                  phone: this.state.customHouseholdPhone,
                  idhousehold: response.data.idhousehold,
                  value: response.data.idhousehold,
                }
                await this.setState({
                  customHousehold: false,
                  customHouseholdName: '',
                  customHouseholdPhone: '',
                  household: newHousehold,
                  households: [
                    ...this.state.households,
                    newHousehold,
                  ]
                })
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
        axios
          .post("https://eposgestofinal.pt/api/calendar/set.php", {
            idempresa: profile.idEmpresa,
            idemployee: this.state.employee.idemployee,
            idcustomer: newCustomer.idcustomer,
            idhousehold: newHousehold.idhousehold,
            description: this.state.description,
            startDate: startTimestamp,
            endDate: endTimestamp,
          })
          .then((response) => {
            if (response.data !== "NOK") {
              const employeeData = this.state.employees.find((employee) => employee.idemployee === this.state.employee.idemployee) || {};
              const event = {
                start: newStartDate,
                end: newEndDate,
                id: response.data.idevent,
                text: this.state.eventFormat.format(
                  employeeData.name,
                  this.state.description,
                  newHousehold && newHousehold.idhousehold && newHousehold.idhousehold !== '-1' ? newHousehold.name : newCustomer.name,
                  newHousehold && newHousehold.idhousehold && newHousehold.idhousehold !== '-1' ? newHousehold.name : newCustomer.phone
                ),
                description: this.state.description,
                customer: newCustomer,
                household: newHousehold,
                employee: employeeData,
                backColor: employeeData.color,
                resource: employeeData.idemployee,
                visible: employeeData.selected,
              };
              dp.events.add(event);
              dp.update({
                startDate: newStartDate,
              });
              this.setState({ eventsBackup: JSON.parse(JSON.stringify([
                ...this.state.eventsBackup,
                event
              ])) });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }

      const auxEvent = {
        start_date: startTimestamp,
        end_date: endTimestamp,
      }
      const conflict = this.state.events.filter(
        (event) =>
          event.employee.idemployee === this.state.employee.idemployee && areOverlapping(event, auxEvent)
      );

      if (conflict.length) {
        confirmAlert({
          title: "Confirmação",
          message: "O evento que está a criar ficará em conflito com os seguintes eventos: " + conflict.map((event) => `${event.description}`).join("; "),
          buttons: [
            {
              label: "Sim",
              onClick: () => {
                performSave();
              },
            },
            {
              label: "Não",
              onClick: () => {
                
              },
            },
          ],
        });
      } else {
        performSave();
      }
    }
    eventBus.$emit("Modal-agenda", { isOpen: false });
  }

  handleChange(e) {
    const profile = this.context;
    let value = e.target.value;
    let name = e.target.name;

    this.setState({
      [name]: value,
    });

    if (name === 'customer' && value.idcustomer === '0') {
      this.setState({
          customCustomer: true,
      });  
    } else if (name === 'customer') {
      this.setState({
          customCustomer: false,
      });
      if (!['-1', '0'].includes(value.idcustomer)) {
        axios
          .get(
            `https://eposgestofinal.pt/api/customers/household/get.php?idempresa=${profile.idEmpresa}&idcustomer=${value.idcustomer}`
          )
          .then((response) => {
            const households = response.data !== "NOK" ? response.data : [];
            this.setState({
              households: [{
                idhousehold: "-1",
                name: '',
                label: '-',
                phone: '',
                value: "-1",
              }, {
                idhousehold: "0",
                name: 'Novo membro do agregado',
                label: 'Novo membro do agregado',
                phone: '',
                value: "0",
              }, ...households.map((household) => ({
                ...household,
                kinship: this.state.kinship.find((kinship) => household.kinship === kinship.idkinship),
                label: `${household.idagregado ? `[${household.idagregado}] ` : ''}${household.name} - ${household.phone} - ${household.email} - ${household.nif}`,
                value: household.idhousehold,
              }))],
              loaded: true,
            }, () => {
              this.setState({
                household: this.state.households.find((household) => household.idhousehold === "-1")
              });
            });
          });
      }
    }

    if (name === 'household' && value.idhousehold === '0') {
      this.setState({
          customHousehold: true,
      });  
    } else if (name === 'household') {
      this.setState({
          customHousehold: false,
      });
    }

    if (name === "date") {
      this.calendar.update({
        startDate: value,
      });
    }
  }

  getEvents() {
    const profile = this.context;
    axios
      .get(
        `https://eposgestofinal.pt/api/calendar/birthdays/getByRange.php?idempresa=${profile.idEmpresa}&start=${datetimeToTimestamp(this.state.viewStartDate)}&end=${datetimeToTimestamp(this.state.viewEndDate)}`
      )
      .then((response) => {
        const birthdays = response.data !== "NOK" ? response.data : [];

        axios
          .get(
            `https://eposgestofinal.pt/api/calendar/getByRange.php?idempresa=${profile.idEmpresa}&start=${datetimeToTimestamp(this.state.viewStartDate)}&end=${datetimeToTimestamp(this.state.viewEndDate)}`
          )
          .then((response) => {
            const calendar = response.data !== "NOK" ? response.data : [];

            const events = [ ...calendar, ...birthdays ];

            const households = _.uniqBy(
              events.map((event) => ({
                idcustomer: event.idcustomer,
                idhousehold: event.idhousehold,
              })),
              onlyUniqueHousehold
            );

            Promise.all([
              ...households.map((household) =>
                axios.get(
                  `https://eposgestofinal.pt/api/customers/household/getById.php?idempresa=${profile.idEmpresa}&idcustomer=${household.idcustomer}&idhousehold=${household.idhousehold}`
                )
              ),
            ]).then((allHouseholds) => {
              const newEvents = events.map((event) => {
                const customerData =
                  this.state.customers.find(
                    (customer) => customer.idcustomer === event.idcustomer
                  ) || {};
                const householdData = allHouseholds.map((household) => ({
                  ...household,
                  kinship: this.state.kinship.find((kinship) => household.kinship === kinship.idkinship),
                })).find(
                  (household) =>
                    household.data.idhousehold === event.idhousehold
                ) || { data: { idhousehold: "-1" } };
                const employeeData = this.state.employees.find(
                  (employee) => employee.idemployee === event.idemployee
                ) || { selected: true };
                return {
                  ...event,
                  id: event.idevent,
                  text: event.idcustomers && event.idcustomers.length > 0 ? this.state.eventBirthdayFormat.format(employeeData.name, event.descriptions.join("\n")) : this.state.eventFormat.format(
                    employeeData.name,
                    event.description,
                    householdData.data.name || customerData.name,
                    householdData.data.name
                      ? householdData.data.phone
                      : customerData.phone
                  ),
                  customer: customerData,
                  moreThanOneCustomer: (event.idcustomers || []).map((idcustomer) => {
                    return this.state.customers.find(
                      (customer) => customer.idcustomer === idcustomer
                    ) || {};
                  }),
                  household: householdData.data,
                  employee: employeeData,
                  description: event.description,
                  start: TimestampToDatetime(event.start_date),
                  end: TimestampToDatetime(event.end_date),
                  resource: event.idemployee,
                  backColor: employeeData.color,
                  visible: employeeData.selected,
                };
              });
              this.setState({
                eventsBackup: JSON.parse(JSON.stringify(newEvents)),
                events: newEvents.map((event) => {
                  return event.visible
                    ? event
                    : {
                        ...event,
                        text: "",
                        start: "5000-01-01T00:00:00",
                        end: "5000-01-01T00:00:00",
                      };
                }),
                loaded: true,
              });
            });

            this.setState({
              eventsBackup: JSON.parse(JSON.stringify(this.state.events)),
              events: this.state.events.map((event) => {
                const employeeData =
                  this.state.employees.find(
                    (employee) => employee.idemployee === event.idemployee
                  ) || {};
                return employeeData.selected
                  ? event
                  : {
                      ...event,
                      text: "",
                      start: "5000-01-01T00:00:00",
                      end: "5000-01-01T00:00:00",
                    };
              }),
            });

            this.calendar.init();
          });
        });
  }

  loadFunction() {
    if (!this.state.loaded) {
      const {
        match: { params },
      } = this.props;
      const profile = this.context;
      axios
        .get(
          `https://eposgestofinal.pt/api/kinship/get.php`
        )
          .then((response) => {
            const kinship = response.data !== "NOK" ? response.data : [];
            this.setState({
              kinship,
              loaded: true,
            });
          });
      axios
        .get(
          `https://eposgestofinal.pt/api/health_systems/get.php`
        )
          .then((response) => {
            const healthSystems = response.data !== "NOK" ? response.data : [];
            this.setState({
              healthSystems,
              loaded: true,
            });
          });
      axios
        .get(
          `https://eposgestofinal.pt/api/employees/get.php?idempresa=${profile.idEmpresa}`
        )
        .then((response) => {
          let employees = response.data !== "NOK" ? response.data : [];

          employees = ![3, 4].includes(profile.memberType) || profile.permissions.includes("site.sharedcalendar") ? employees : employees.filter((employee) => employee.idemployee == profile.idEmployee);

          const palette = distinctColors({ count: employees.length })

          this.setState({
            employees: [
              {
                avatar: 'site/images/birthday-cake.png',
                id: "-2",
                idemployee: "-2",
                name: "Aniversários",
                label: "Aniversários",
                value: "-2",
                color: distinctColors({ count: 2 })[1],
                selected: true,
              },
              ...employees.map((employee, index) => ({
                ...employee,
                id: employee.idemployee,
                label: employee.name,
                value: employee.idemployee,
                color: palette[index],
                selected: profile.memberType === 3 ? `${profile.idEmployee}` === `${employee.idemployee}` : true,
              }))
            ],
            loaded: true,
          });

          axios
            .get(
              `https://eposgestofinal.pt/api/customers/get.php?idempresa=${profile.idEmpresa}`
            )
            .then((response) => {
              const customers = response.data !== "NOK" ? response.data : [];
              this.setState({
                customers: [{
                  idcustomer: "-1",
                  name: '',
                  label: '-',
                  phone: '',
                  value: "-1",
                }, {
                  idcustomer: "0",
                  name: 'Novo Cliente',
                  label: 'Novo Cliente',
                  phone: '',
                  value: "0",
                }, ...customers.map((customer) => ({
                  ...customer,
                  healthsystem: this.state.healthSystems.find((healthSystem) => customer.health_system == healthSystem.idhealthsystem),
                  label: `${customer.idassociado ? `[${customer.idassociado}] ` : ''}${customer.name} - ${customer.phone} - ${customer.email} - ${customer.nif}`,
                  value: customer.idcustomer,
                }))],
                households: [{
                  idhousehold: "-1",
                  name: '',
                  label: '-',
                  phone: '',
                  value: "-1",
                }],
                loaded: true,
              });
              this.getEvents();
            });
        });
    }
  }

  handleEmployeeSelection(idEmployee) {
    const allAction = !idEmployee;
    if (!idEmployee) {
      this.setState({ hideAvailable: !this.state.hideAvailable })
    }
    const dp = this.calendar;
    const events = this.state.eventsBackup;
    const newEmployees = this.state.employees.map((e) => ({
      ...e,
      selected: allAction ? !this.state.hideAvailable : (e.idemployee === idEmployee ? !e.selected : e.selected),
    }));

    if (newEmployees.every((employee) => employee.selected)) {
      this.setState({ hideAvailable: true })
    }

    if (newEmployees.every((employee) => !employee.selected)) {
      this.setState({ hideAvailable: false })
    }

    this.setState({ employees: newEmployees });

    const newEvents = events.map((event) => {
      const employeeData = newEmployees.find((employee) => employee.idemployee === event.employee.idemployee) || {};
      return {
        ...event,
        backColor: employeeData.color,
        visible: employeeData.selected,
      };
    });

    this.setState({
      eventsBackup: JSON.parse(JSON.stringify(newEvents)),
    })

    newEvents.forEach((event) => {
      dp.events.update(event);
    });
  }

  render() {
    const { location, history } = this.props;
    const cookies = new URLSearchParams(location.search);

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li className="active">Agenda</li>
            </ol>
          </div>
        </div>
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Agenda
                </div>
              </div>
              <BrowserView>
                <div style={styles.wrap}>
                  <div style={styles.left}>
                    <DayPilotNavigator
                      locale={"pt-pt"}
                      selectMode={"day"}
                      showMonths={1}
                      onTimeRangeSelected={(args) => {
                        this.setState({
                          viewStartDate: args.start.value,
                          viewEndDate: args.end.value,
                        }, () => {
                          this.getEvents();
                        })
                        this.calendar.update({
                          startDate: args.day,
                        });
                      }}
                    />
                    <h4>Colaboradores</h4>
                    {
                      this.state.hideAvailable ? (<span
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => this.handleEmployeeSelection()}
                      >Esconder todos</span>) : (<span
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => this.handleEmployeeSelection()}
                      >Mostrar todos</span>)
                    }
                    <table className="table">
                      {this.state.employees.map((employee, index) => (
                        <tr
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => this.handleEmployeeSelection(employee.idemployee)}
                        >
                          <td style={{
                            paddingBottom: '5px',
                          }}>
                            <table style={{
                              width: '100%',
                              backgroundColor: employee.selected ? employee.color : 'white',
                              border: employee.selected ? `1px solid white` : `3px solid ${employee.color}`,
                            }}>
                              <tr>
                                <td style={{ paddingTop: '10px' }}>
                                  {/* <span
                                    class="dot"
                                    style={{
                                      backgroundColor: employee.selected ? employee.color : 'white',
                                      border: employee.selected ? '' : '1px solid black',
                                      cursor: 'pointer',
                                    }}
                                  ></span> */}
                                  <img style={{ borderRadius: '50%' }} width="40%" src={`https://eposgestofinal.pt/${employee.avatar || 'site/images/profile.jpg'}`} />
                                </td>
                              </tr>
                              <tr>
                                <td style={{
                                  paddingTop: '5px',
                                  paddingBottom: '10px',
                                  fontWeight: employee.selected ? 'bolder' : 'normal',
                                  fontSize: 'medium',
                                  color: getContrastColor(employee.selected ? employee.color : [255, 255, 255, 1])
                                }}>{employee.name}</td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                  <div style={styles.main}>
                    <DayPilotCalendar
                      {...this.state}
                      headerDateFormat={"dddd d MMM yyyy"}
                      locale={"pt-pt"}
                      viewType={"Resources"}
                      dayBeginsHour={8}
                      dayEndsHour={20}
                      businessBeginsHour={8}
                      businessEndsHour={20}
                      ref={this.calendarRef}
                      columns={this.state.employees.filter((employee) => employee.selected)}
                    />
                  </div>
                </div>
              </BrowserView>
              <MobileView>
                <div className="col-xs-12">
                  <table className="table">
                    <tr>
                      <td>
                        <h4>Colaboradores</h4>
                        {
                          this.state.hideAvailable ? (<span
                            style={{
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            onClick={() => this.handleEmployeeSelection()}
                          >Esconder todos</span>) : (<span
                            style={{
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            onClick={() => this.handleEmployeeSelection()}
                          >Mostrar todos</span>)
                        }
                        <table className="table">
                          {this.state.employees.map((employee) => (
                            <tr
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => this.handleEmployeeSelection(employee.idemployee)}
                            >
                              <td>
                                <span
                                  class="dot"
                                  style={{
                                    backgroundColor: employee.selected ? employee.color : 'white',
                                    border: employee.selected ? '' : '1px solid black',
                                  }}
                                ></span>
                              </td>
                              <td style={{
                                fontWeight: employee.selected ? 'bolder' : 'normal',
                              }}>{employee.name}</td>
                            </tr>
                          ))}
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h4>Data</h4>
                        <Input
                          name={"date"}
                          inputType={"date"}
                          value={this.state.date}
                          handleChange={this.handleChange}
                        />
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="col-xs-12">
                  <DayPilotCalendar
                    {...this.state}
                    headerDateFormat={"dddd d MMM yyyy"}
                    locale={"pt-pt"}
                    viewType={"Resources"}
                    dayBeginsHour={9}
                    dayEndsHour={20}
                    businessBeginsHour={9}
                    businessEndsHour={20}
                    ref={this.calendarRef}
                    columns={this.state.employees.filter((employee) => employee.selected)}
                  />
                </div>
              </MobileView>
            </div>
          </div>
        </div>
        <Modal modifier="small" id="agenda" />
      </div>
    );
  }
}

export default withCookies(Agenda);
