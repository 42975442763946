import React, { Component } from 'react';

// Components
import POSMenu from '@components/POSMenu'
import Cards from '@components/Cards'
import Modal from '@components/Modal'
import Admin from './admin'
import Read from './read'

import { EventBus as eventBus } from "@components/EventBus";
class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navigation: [{
                icon: 'fa-arrow-left',
                text: 'Voltar',
                link: '/pos',
            }, {
                icon: 'fa-qrcode',
                text: 'Validar',
                link: '/pos/tickets/read',
                condition: '[3,4].includes(profile.memberType)'
            }, {
                icon: 'fa-plus-square',
                text: 'Criar',
                link: '/pos/tickets/new',
                condition: '[3].includes(profile.memberType)'
            }, {
                icon: 'fa-cogs',
                text: 'Configurar',
                click: () => eventBus.$emit("Modal-tickets", { header: "Configurar Senhas", body: <Admin /> }),
                condition: '[3].includes(profile.memberType)'
            }],
        };
    }
    
    render () {
        const { navigation } = this.state;

        return (
            <>
                <POSMenu { ...this.props } />
                <section className="main-section">
                    <Cards items={ navigation } />
                </section>
                <Modal id="tickets"/>
            </>
        );
    }
}

export default Page;