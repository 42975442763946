import React from "react";
import ReactToPrint from "react-to-print";

import { EventBus as eventBus } from "@components/EventBus";

import './Print.css';

class Example extends React.Component {
    constructor(props) {
        super(props);
        this.state = { show: false };

        this.handleDidMount = this.handleDidMount.bind(this);

    }
  handleDidMount() {
    eventBus.$emit("Loading", { active: false });
  }
  componentWillMount() {
    eventBus.$emit("Loading", { active: true })
  }
    
  render() {
    const { avoidLandscape, pageWidth } = this.props;
    
    return (
      <div>
        <ReactToPrint
          copyStyles
          trigger={() => <button type="button" className="btn btn-success">{this.props.button}</button>}
          content={() => this.componentRef}
        />
        <div style={{ width: avoidLandscape ? pageWidth || '21cm' : '100%' }} className='print-source container-fluid'>
            <this.props.printComponent {...this.props} handleDidMount={this.handleDidMount} show={this.state.show} ref={el => (this.componentRef = el)} />
        </div>
      </div>
    );
  }
}

export default Example;
