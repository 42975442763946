import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { Tabs, TabNav, TabNavItem, TabContent, TabPanel } from 'react-smarttab'

// Components
import Details from "../common/details";
import CheckingAccount from "../common/checking-account";
import Tickets from "../common/tickets";

import 'react-smarttab/dist/index.css'

class Page extends Component {
    render () {
        const { idcustomer } = this.props;

        return (
            <form className="well form-horizontal" action=" " method="post"  id="contact_form">
                <Tabs
                    selected="0"
                    orientation={'horizontal'}
                    theme={'github'}
                >
                    <TabNav>
                        <TabNavItem>Detalhes</TabNavItem>
                        <TabNavItem>Conta corrente</TabNavItem>
                        <TabNavItem>Movimento senhas</TabNavItem>
                    </TabNav>

                    <TabContent>
                        <TabPanel>
                            <Details idcustomer={idcustomer} />
                        </TabPanel>
                        <TabPanel>
                            <CheckingAccount idcustomer={idcustomer} />
                        </TabPanel>
                        <TabPanel>
                            <Tickets idcustomer={idcustomer} />
                        </TabPanel>
                    </TabContent>
                </Tabs>
            </form>
        );
    }
}

export default withCookies(Page);