import { valueWithIva, applyDiscount } from '@utils/format'

const getRealValue = (price, originalPrice) => {
    return price ? Number(price) : Number(originalPrice)
}

export const totals = (lines, doctype) => (lines || []).reduce((results, line) => {
    const desc = Number(line.desc);
    const desc1 = Number(line.desc1);
    const desc2 = Number(line.desc2);
    const costprice = ['orders', 'returns', 'inventory'].indexOf(doctype) >= 0 ? getRealValue(line.costprice, (line.product || {}).costprice) : getRealValue(line.price, (line.product || {}).price);
    const quantity = Number(line.quantity);
    const taxtaIva = getRealValue(line.iva, (line.product || {}).iva);

    const subtotal = costprice * quantity;
    let costpriceWithDesc = applyDiscount(costprice, desc1);
    costpriceWithDesc = applyDiscount(costpriceWithDesc, desc2);

    const discount = costprice - costpriceWithDesc;
    const desconto = desc > 0 ? desc * quantity : discount * quantity;

    const totalWithoutIVA = subtotal - desconto;
    const total = valueWithIva(totalWithoutIVA, taxtaIva);
    const iva = total - totalWithoutIVA;

    return {
        total: results.total + total,
        subtotal: results.subtotal + subtotal,
        desconto: results.desconto + desconto,
        iva: results.iva + iva,
    };
}, {
    total: 0,
    subtotal: 0,
    desconto: 0,
    iva: 0,
});