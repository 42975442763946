import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

// Components
import EditableTable, { deleteColumnFormatter } from '@components/EditableTable'
import Button from "@components/Button";
import Input from "@components/Input";
import Switch from "@components/Switch";

import 'react-smarttab/dist/index.css'

import UserContext from '@components/context/userContext';

class Page extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            values: [],
            currency: '',
            configs: {},
            loaded: false,
        };

        this.loadFunction = this.loadFunction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.onTableAddRow = this.onTableAddRow.bind(this);
        this.onTableDelRow = this.onTableDelRow.bind(this);
        this.onTableChange = this.onTableChange.bind(this);
    }

    loadFunction() {
        const profile = this.context;

        if (!this.state.loaded) {
            axios.get(`https://eposgestofinal.pt/api/tickets/values/get.php?idempresa=${profile.idEmpresa}`)
                .then(response => {
                    const values = response.data !== 'NOK' ? response.data : [];

                    this.setState({ values: values.map((value, id) => ({
                        ...value,
                        id,
                    })) });

                    axios.get(`https://eposgestofinal.pt/api/config/currency/get.php?idempresa=${profile.idEmpresa}`)
                        .then(response => {
                            const result = response.data !== 'NOK' ? response.data : '';
                            const currency = (result[0] && result[0]['currency']) || '';

                            this.setState({ currency, loaded: true });
                            
                            axios.get(`https://eposgestofinal.pt/api/tickets/config/get.php?idempresa=${profile.idEmpresa}`)
                                .then(response => {
                                    const result = response.data !== 'NOK' ? response.data : [];
                                    
                                    const hydratateConfigs = result.reduce((acc, eachConfig) => {
                                        return {
                                            ...acc,
                                            [eachConfig.key]: eachConfig.value,
                                        }
                                    }, {});

                                    this.setState({ configs: hydratateConfigs, loaded: true });                            
                                })
                                .catch(error => {
                                    NotificationManager.error(error.message, 'Moeda');
                                });
                        })
                        .catch(error => {
                            NotificationManager.error(error.message, 'Moeda');
                        });
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Valores');
                });
        }
    }

    handleChange(e, callback) {
        let value = e.target ? e.target.value : e ;
        let name = e.target.name;
        
        let newState = this.state;

        if (name.includes("|")) {
            const properties = name.split("|");
        
            newState[properties[0]][properties[1]] = value;
        
            this.setState({
                ...newState,
            });

            if (properties[0] === 'configs') {
                const profile = this.context;
    
                axios.post('https://eposgestofinal.pt/api/tickets/config/set.php', {
                    key: properties[1],
                    value,
                    idempresa: profile.idEmpresa,
                })
            }
        } else {
            this.setState({
                [name]: value,
            });
        }
    }

    handleBlur() {
        const profile = this.context;

        axios.post('https://eposgestofinal.pt/api/config/currency/set.php', {
            currency: this.state.currency,
            idempresa: profile.idEmpresa,
        })
    }

    onTableSubmit() {
        const profile = this.context;

        axios.post('https://eposgestofinal.pt/api/tickets/values/set.php', {
            values: this.state.values.map((value) => value.value),
            idempresa: profile.idEmpresa,
        })
    }

    onTableAddRow() {
        const { values } = this.state;
        this.setState({ values: [ { id: Date.now(), value: '' }, ...values ]}, () => this.onTableSubmit());
    }

    onTableDelRow(cell, row) {
        const { values } = this.state;
        const result = values.filter((value) => value.id !== row.id);
        this.setState({ values: result }, () => this.onTableSubmit())
    }

    onTableChange(newValue, row, column) {
        const { values } = this.state;
        if (isNaN(newValue) || newValue <= 0) {
            return {
                valid: false,
                message: 'Apenas são permitidos valores positivos numéricos.'
            };
        }
        const allValues = values
            .filter((value) => value.id !== row.id)
        const unique = !allValues.find((value) => value.value === newValue);
        if (!unique) {
            return {
                valid: false,
                message: 'Não são permitidos valores repetidos.'
            };
        }
        const result = values.map((value) => {
            if (value.id === row.id) {
                const newRow = { ...value };
                newRow[column.dataField] = newValue;
                return newRow;
            }
            return value;
        });
        this.setState({ values: result }, () => this.onTableSubmit())
        return true;
    }

    render () {
        this.loadFunction()
        const { values, currency, loaded, configs } = this.state;
        return loaded ? (
            <>
                <h3>Moeda:</h3>
                <Input
                    inputType={"text"}
                    name={"currency"}
                    value={currency}
                    handleChange={this.handleChange}
                    onBlur={this.handleBlur}
                />
                <h3>Modo Fatura:</h3>
                <Switch
                    name={"configs|invoices"}
                    checked={configs.invoices}
                    handleChange={this.handleChange}
                />
                <h3>Valores:</h3>
                <Button
                    action={() => this.onTableAddRow()}
                    type={"success"}
                    title={(<><i className="fas fa-plus fa-lg"></i> Adicionar</>)}
                    style={{ marginBottom: '15px' }}
                />
                <EditableTable
                    columns={[
                        {
                            dataField: 'value',
                            text: 'Value',
                            validator: this.onTableChange,
                        }, {
                            dataField: 'delete',
                            text: 'Delete',
                            formatter: deleteColumnFormatter(this.onTableDelRow),
                            editable: false,
                        }
                    ]}
                    data={values} />
            </>
        ) : null;
    }
}

export default withCookies(Page);