import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { MdVisibility, MdAddCircleOutline } from "react-icons/md";
import ReactTable from "@components/ReactTable";

// Components
import Menu from "@components/Menu";

class Page extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);

    const partners = [{}];

    this.state = {
      table: {
        columns: [
          {
            Header: "Nome",
            accessor: "name",
          },
          {
            Header: "Morada",
            accessor: "address",
          },
          {
            Header: "Telefone",
            accessor: "phone",
          },
          {
            Header: "Email",
            accessor: "email",
          },
          {
            Header: "",
            accessor: "view",
          },
        ],
        rows: [],
        loading: true,
      },
      loaded: false,
      partners,
    };
    this.loadFunction = this.loadFunction.bind(this);
  }

  loadFunction() {
    if (!this.state.loaded) {
      const { history } = this.props;
      axios
        .get(
          `https://eposgestofinal.pt/api/partners/get.php?idempresa=${this.props.cookies.get(
            "member_idempresa"
          )}`
        )
        .then((response) => {
          const partners = response.data !== "NOK" ? response.data : [];

          const enrichedPartners = partners.map((partner) => ({
            ...partner,
            view: (
              <MdVisibility
                style={{ fontSize: "15px", cursor: "pointer" }}
                onClick={() => history.push(`/partners/${partner.idpartner}`)}
              />
            ),
          }));

          this.setState({
            table: {
              ...this.state.table,
              rows: enrichedPartners,
              loading: false,
            },
            loaded: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  async removeFunction(data) {
    confirmAlert({
      title: "Confirmação",
      message: `Tem a certeza que deseja apagar o parceiro ${data.name}?`,
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            axios
              .get(
                `https://eposgestofinal.pt/api/partners/unset.php?idempresa=${this.props.cookies.get(
                  "member_idempresa"
                )}&idpartner=${data.idpartner}`
              )
              .then((response) => {
                if (response.data !== "NOK") {
                  this.setState({
                    partners: this.state.partners.filter(
                      (partner) => partner.idpartner !== data.idpartner
                    ),
                  });
                }
              })
              .catch((error) => {
                console.error(error);
              });
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    const { table } = this.state;
    const { location, history } = this.props;
    const cookies = new URLSearchParams(location.search);

    return (
      <div>
        <Menu
          loadFunction={this.loadFunction()}
          location={location}
          history={history}
          newCookies={cookies}
        />
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-xs-12">
            <ol
              style={{
                textAlign: "left",
                marginTop: "75px",
              }}
              className="breadcrumb"
            >
              <li>
                <a href="http://eposgestofinal.pt/index.php">Portal</a>
              </li>
              <li className="active">Parceiros</li>
            </ol>
          </div>
        </div>
        <div className="fullContainer" style={{ maxWidth: "100%" }}>
          <div className="col-sm-12" style={{ textAlign: "center" }}>
            <div className="panel panel-default">
              <div
                className="panel-heading"
                style={{
                  fontWeight: "bold",
                  fontSize: "15pt",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  Parceiros
                </div>
                <div>
                  <table
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/partners/new")}
                  >
                    <tr>
                      <td>
                        <MdAddCircleOutline style={{ fontSize: "20px" }} />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: "xx-small" }}>Criar parceiro</td>
                    </tr>
                  </table>
                </div>
              </div>
              <ReactTable
                columns={table.columns}
                sortable={true}
                loading={table.loading}
                data={table.rows}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(Page));
