import React, { Component } from "react";
import { withCookies } from "react-cookie";

// Components
import QRCode from "react-qr-code";

import "./card.css";

import UserContext from "@components/context/userContext";

class Page extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
  }

  render() {
    const {
      nome,
      card,
      nif,
      fotografia,
    } = this.props;
    const profile = this.context;

    return (
        <div id="badgecard" class="badgecard" style={{ display: 'block', width: '500px' }}>
            <div class="badgecard-header">
                <img src={`https://eposgestofinal.pt/${profile.companyAvatar}`} />
            </div>
            <div class="badgecard-body">
              {fotografia ? (
                <table>
                  <tr>
                    <td style={{ width: '120px' }}>
                      <img src={fotografia} style={{ width: '120px', height: '120px', borderRadius: '82px', padding: 0 }} />
                    </td>
                    <td>
                      <h2 id="badgecardName">{nome}</h2>
                      <p id="badgecardDesignation">{nif}</p>
                    </td>
                  </tr>
                </table>
              ) : (
                <>
                  <h2 id="badgecardName">{nome}</h2>
                  <p id="badgecardDesignation">{nif}</p>
                </>
              )}
            </div>
            <div id="qrcode" class="badgecard-qr">
                <QRCode
                    size="150"
                    value={card}
                />
            </div>
        </div>
    );
  }
}

export default withCookies(Page);
