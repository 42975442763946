import React, { Component } from "react";

// Components

import "./table.css";

const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}

class Page extends Component {
  componentDidMount() {
    if (typeof this.props.handleDidMount == "function") {
      this.props.handleDidMount();
    }
  }

  render() {
    const { table, date } = this.props;

    return (
      <table style={{ margin: "15px", width: "100%" }}>
        <tr>
          <td>
            Aniversários no dia {date}
          </td>
        </tr>
        <tr>
          <td>
            <table style={{ width: "100%" }}>
              <tr>
                {table.columns.map(({ Header }, index) => (
                  <th>{Header}</th>
                ))}
              </tr>
              {table.rows.map((row) => (
                <tr>
                  <td>{row.name}</td>
                  <td>{getAge(row.birthday)}</td>
                  <td>{row.phone}</td>
                  <td>{row.email}</td>
                </tr>
              ))}
            </table>
          </td>
        </tr>
      </table>
    );
  }
}

export default Page;
