import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const Button = props => {
  return (
    <button
      style={props.style}
      className={
        `btn btn-${props.type}`
      }
      type="button"
      onClick={props.action}
      disabled={props.disabled || props.loading}
    >
      {props.loading ? (
        <ClipLoader css={{
              marginRight: '10px'
            }} size={10} />
      ) : null}
            {props.title}
    </button>
  );
};

export default Button;
