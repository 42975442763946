import React, { Component } from "react";
import { withCookies } from "react-cookie";
import axios from "axios";

// Components
import Print from "./print";
import PrintTable from "./print/table";
import ReactTable from "@components/ReactTable";
import Button from "@components/Button";
import AutoComplete from "@components/AutoComplete";
import Input from "@components/Input";
import { NotificationManager } from 'react-notifications';

import "react-smarttab/dist/index.css";

import "./new.css";

import UserContext from "@components/context/userContext";

const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}

class Page extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      description: this.props.description,
      employee: this.props.employee,
      customer: this.props.customer,
      moreThanOneCustomer: this.props.moreThanOneCustomer,
      household: this.props.household,
      startTime: this.props.startTime,
      endTime: this.props.endTime,
      eventDate: this.props.eventDate,
      edit: this.props.edit,
      customCustomer: this.props.customCustomer,
      customCustomerName: this.props.customCustomerName,
      customCustomerPhone: this.props.customCustomerPhone,
      customHousehold: this.props.customHousehold,
      customHouseholdName: this.props.customHouseholdName,
      customHouseholdPhone: this.props.customHouseholdPhone,
      households: this.props.households,
      birthdayTable: {
        columns: [
          {
              Header: "Nome",
              accessor: "name",
          },
          {
            Header: "Idade",
            id: "age",
            accessor: (d) => getAge(d.birthday),
          },
          {
            Header: "Telemóvel",
            accessor: "phone",
          },
          {
            Header: "Email",
            accessor: "email",
          },
        ],
        loading: false,
      },
    };
    this.internalHandleChange = this.internalHandleChange.bind(this);
    this.internalNewEvent = this.internalNewEvent.bind(this);
  }

  internalHandleChange(e) {
    const profile = this.context;
    let value = e.target.value;
    let name = e.target.name;

    this.setState({
        [name]: value,
    });

    if (name === 'customer' && value.idcustomer === '0') {
      this.setState({
          customCustomer: true,
      });  
    } else if (name === 'customer') {
      this.setState({
          customCustomer: false,
      });
      if (!['-1', '0'].includes(value.idcustomer)) {
        axios
          .get(
            `https://eposgestofinal.pt/api/customers/household/get.php?idempresa=${profile.idEmpresa}&idcustomer=${value.idcustomer}`
          )
          .then((response) => {
            const households = response.data !== "NOK" ? response.data : [];
            this.setState({
              households: [{
                idhousehold: "-1",
                name: '',
                label: '-',
                phone: '',
                value: "-1",
              }, {
                idhousehold: "0",
                name: 'Novo membro do agregado',
                label: 'Novo membro do agregado',
                phone: '',
                value: "0",
              }, ...households.map((household) => ({
                ...household,
                label: `${household.idagregado ? `[${household.idagregado}] ` : ''}${household.name} - ${household.phone} - ${household.email} - ${household.nif}`,
                value: household.idhousehold,
              }))],
              loaded: true,
            }, () => {
              this.setState({
                household: this.state.households.find((household) => household.idhousehold === "-1")
              });
            });
          });
      }
    }

    if (name === 'household' && value.idhousehold === '0') {
      this.setState({
          customHousehold: true,
      });  
    } else if (name === 'household') {
      this.setState({
          customHousehold: false,
      });
    }

    this.props.handleChange(e);
  }

  internalNewEvent() {
    if (this.state.description === '') {
      NotificationManager.error('Tem de definir uma descrição para o evento', 'Agenda');
    } else {
      this.props.newEvent();
    }
  }

  componentDidMount() {
    const profile = this.context;

    if (!['-1', '0'].includes(this.state.customer.idcustomer)) {
      axios
        .get(
          `https://eposgestofinal.pt/api/customers/household/get.php?idempresa=${profile.idEmpresa}&idcustomer=${this.state.customer.idcustomer}`
        )
        .then((response) => {
          const households = response.data !== "NOK" ? response.data : [];
          this.setState({
            households: [{
              idhousehold: "-1",
              name: '',
              label: '-',
              phone: '',
              value: "-1",
            }, {
              idhousehold: "0",
              name: 'Novo membro do agregado',
              label: 'Novo membro do agregado',
              phone: '',
              value: "0",
            }, ...households.map((household) => ({
              ...household,
              label: `${household.idagregado ? `[${household.idagregado}] ` : ''}${household.name} - ${household.phone} - ${household.email} - ${household.nif}`,
              value: household.idhousehold,
            }))],
            loaded: true,
          }, () => {
            this.setState({
              household: this.state.households.find((household) => household.idhousehold === this.props.household.idhousehold) || {
                idhousehold: "-1"
              }
            });
          });
        });
    } else {
      this.setState({
        household: {
          idhousehold: "-1"
        }
      });
    }
  }

  render() {
    const {
      description,
      employee,
      customer,
      household,
      startTime,
      endTime,
      eventDate,
      edit,
      customCustomer,
      customCustomerName,
      customCustomerPhone,
      customHousehold,
      customHouseholdName,
      customHouseholdPhone,
      households,
      birthdayTable,
    } = this.state;
    const {
      employees,
      customers,
    } = this.props;

    const filteredEmployees = employees.filter(({ idemployee }) => idemployee >= -1);

    birthdayTable.rows = this.props.moreThanOneCustomer;

    return (
      <form
        className="well form-horizontal"
        action=" "
        method="post"
        id="contact_form"
      >
        <div className="panel panel-default">
          <div
              className="panel-heading"
              style={{
                  fontWeight: 'bold',
                  fontSize: '15pt',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
              }}
          >
              <div
                  style={{
                      width: '100%',
                      textAlign: 'left',
                  }}
              >
                  Detalhes
              </div>
          </div>
          <div className="container-fluid" style={{ paddingTop: '20px', textAlign: 'left' }}>
            <form class="form-horizontal" action=" " method="post" id="contact_form">
              <div class="st st-theme-github st-horizontal">
                <fieldset>
                  <div className="form-group">
                    <div className="col-md-12 inputGroupContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="fas fa-calendar"></i></span>
                            {edit ? (
                              <AutoComplete
                                name={"employee"}
                                value={employee}
                                handleChange={this.internalHandleChange}
                                options={filteredEmployees}
                              />
                            ) : employee.name}
                        </div>
                    </div>
                  </div>
                  {(edit || description) && (<div className="form-group">
                    <div className="col-md-12 inputGroupContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="fas fa-quote-right"></i></span>
                            {edit ? (
                              <Input
                                inputType={"text"}
                                name={"description"}
                                value={description}
                                handleChange={this.internalHandleChange}
                              />
                            ) : (<h4>{description}</h4>)}
                        </div>
                    </div>
                  </div>)}
                  <div className="form-group">
                    <div className="col-md-12 inputGroupContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="fas fa-clock"></i></span>
                            {edit ? (
                              <table className="table">
                                <tr>
                                  <th>Data</th>
                                  <th>Horário</th>
                                </tr>
                                <tr>
                                  <td>
                                    <Input
                                      inputType={"date"}
                                      name={"eventDate"}
                                      value={eventDate}
                                      handleChange={this.internalHandleChange}
                                    />
                                  </td>
                                  <td>
                                    <table className="" style={{ width: '100%' }}>
                                        <tr>
                                            <td style={{ textAlign: 'right' }}>
                                              <Input
                                                inputType={"time"}
                                                name={"startTime"}
                                                value={startTime}
                                                handleChange={this.internalHandleChange}
                                              />
                                            </td>
                                            <td style={{ width: '20px' }}>
                                                -
                                            </td>
                                            <td style={{ textAlign: 'left' }}>
                                              <Input
                                                inputType={"time"}
                                                name={"endTime"}
                                                value={endTime}
                                                handleChange={this.internalHandleChange}
                                              />
                                            </td>
                                        </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            ) : (<>{startTime === '00:00' && endTime === '23:59' ? eventDate : `${eventDate} das ${startTime} até ${endTime}`}</>)}
                        </div>
                      </div>
                  </div>
                </fieldset>
              </div>
            </form>
          </div>
        </div>
        {(this.props.moreThanOneCustomer || []).length > 0 ? (<div className="panel panel-default">
          <div
              className="panel-heading"
              style={{
                  fontWeight: 'bold',
                  fontSize: '15pt',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
              }}
          >
              <div
                  style={{
                      width: '100%',
                      textAlign: 'left',
                  }}
              >
                  Clientes
              </div>
          </div>
          <div className="container-fluid" style={{ paddingTop: '20px', textAlign: 'left' }}>
            <div className="form-group">
              <div className="col-md-12 inputGroupContainer">
                  <div className="input-group">
                      <ReactTable
                        columns={birthdayTable.columns}
                        sortable={true}
                        loading={birthdayTable.loading}
                        data={birthdayTable.rows}
                      />
                  </div>
              </div>
            </div>
          </div>
        </div>) : ((edit || (!edit && this.props.customer.idcustomer !== "-1")) && (<div className="panel panel-default">
          <div
              className="panel-heading"
              style={{
                  fontWeight: 'bold',
                  fontSize: '15pt',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
              }}
          >
              <div
                  style={{
                      width: '100%',
                      textAlign: 'left',
                  }}
              >
                  Cliente
              </div>
          </div>
          <div className="container-fluid" style={{ paddingTop: '20px', textAlign: 'left' }}>
            <div className="form-group">
              <div className="col-md-12 inputGroupContainer">
                  <div className="input-group">
                      {edit ? (
                        <AutoComplete
                          name={"customer"}
                          value={customer}
                          handleChange={this.internalHandleChange}
                          options={customers}
                        />
                      ) : `${customer.idassociado ? `[${customer.idassociado}] ` : ''}${customer.name}`}
                  </div>
              </div>
              {customer.number_ss && (
                <div className="col-md-12 inputGroupContainer">
                  <div className="input-group">
                    <b>Número SS: </b>
                    { customer.number_ss }
                  </div>
                </div>
              )}
              {customer.numero_utente && (
                <div className="col-md-12 inputGroupContainer">
                  <div className="input-group">
                    <b>Número Utente SNS: </b>
                    { customer.numero_utente }
                  </div>
                </div>
              )}
            </div>
          </div>
          {edit && customCustomer ? (
            <form class="form-horizontal" action=" " method="post" id="contact_form">
              <div class="st st-theme-github st-horizontal">
                <fieldset>
                  <div className="form-group">
                    <label className="col-md-2 control-label">Nome</label>  
                    <div className="col-md-10 inputGroupContainer">
                        <div className="input-group">
                          <span className="input-group-addon"><i className="fas fa-user"></i></span>
                          <Input
                            inputType={"text"}
                            name={"customCustomerName"}
                            value={customCustomerName}
                            handleChange={this.internalHandleChange}
                          />
                        </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-2 control-label">Telefone</label>  
                    <div className="col-md-10 inputGroupContainer">
                        <div className="input-group">
                          <span className="input-group-addon"><i className="fas fa-phone"></i></span>
                          <Input
                            inputType={"number"}
                            name={"customCustomerPhone"}
                            value={customCustomerPhone}
                            handleChange={this.internalHandleChange}
                          />
                        </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </form>
          ) : (<></>)}
        </div>))}
        {customer.healthsystem && (
          <div className="panel panel-default">
            <div
                  className="panel-heading"
                  style={{
                      fontWeight: 'bold',
                      fontSize: '15pt',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                  }}
              >
                <div
                    style={{
                        width: '100%',
                        textAlign: 'left',
                    }}
                >
                    Subsistema de Saúde
                </div>
              </div>
              <div className="container-fluid" style={{ paddingTop: '20px', textAlign: 'left' }}>
                <div className="form-group">
                  <div className="col-md-12 inputGroupContainer">
                      <div className="input-group">
                        { customer.healthsystem.name }
                      </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        {((edit && !["-1", "0"].includes(customer.idcustomer)) || (!edit && !["-1", "0"].includes(household.idhousehold))) && (
        <div className="panel panel-default">
          <div
              className="panel-heading"
              style={{
                  fontWeight: 'bold',
                  fontSize: '15pt',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
              }}
          >
              <div
                  style={{
                      width: '100%',
                      textAlign: 'left',
                  }}
              >
                  Agregado
              </div>
          </div>
          <div className="container-fluid" style={{ paddingTop: '20px', textAlign: 'left' }}>
            <div className="form-group">
              <div className="col-md-12 inputGroupContainer">
                  <div className="input-group">
                    {(edit && !["-1", "0"].includes(customer.idcustomer)) ? (
                      <AutoComplete
                        name={"household"}
                        value={household}
                        handleChange={this.internalHandleChange}
                        options={households}
                      />
                    ) : (!["-1", "0"].includes(household.idhousehold) ? `${household.idagregado ? `[${household.idagregado}] ` : ''}${household.name}` : '')}
                  </div>
              </div>
              {household.number_ss && (
                <div className="col-md-12 inputGroupContainer">
                    <div className="input-group">
                        <b>Número SS: </b>
                        { household.number_ss }
                    </div>
                </div>
              )}
              {household.numero_utente && (
                <div className="col-md-12 inputGroupContainer">
                    <div className="input-group">
                        <b>Número Utente SNS: </b>
                        { household.numero_utente }
                    </div>
                </div>
              )}
            </div>
          </div>
          {edit && customHousehold ? (
            <form class="form-horizontal" action=" " method="post" id="contact_form">
              <div class="st st-theme-github st-horizontal">
                <fieldset>
                  <div className="form-group">
                    <label className="col-md-2 control-label">Nome</label>  
                    <div className="col-md-10 inputGroupContainer">
                        <div className="input-group">
                          <span className="input-group-addon"><i className="fas fa-user"></i></span>
                          <Input
                            title={ 'Nome' }
                            inputType={"text"}
                            name={"customHouseholdName"}
                            value={customHouseholdName}
                            handleChange={this.internalHandleChange}
                          />
                        </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-2 control-label">Telefone</label>  
                    <div className="col-md-10 inputGroupContainer">
                        <div className="input-group">
                          <span className="input-group-addon"><i className="fas fa-phone"></i></span>
                          <Input
                            title={ 'Telefone' }
                            inputType={"number"}
                            name={"customHouseholdPhone"}
                            value={customHouseholdPhone}
                            handleChange={this.internalHandleChange}
                          />
                        </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </form>
          ) : (<></>)}
        </div>)}
        {edit ? (
          <Button
            action={() => this.internalNewEvent()}
            type={"success"}
            title={"Guardar"}
            style={buttonStyle}
          />
        ): (
          <>
            {employee.idemployee == -2 && (
              <PrintTable
                  table={ birthdayTable }
                  date={ eventDate }
              />
            )}
            {employee.idemployee >= -1 && (
              <>
                <Print
                  date={ eventDate }
                  time={ startTime }
                  description={ description }
                  employee={ employee.name }
                  customer={ `${customer.idassociado ? `[${customer.idassociado}] ` : ''}${customer.name}` }
                  household={ !["-1", "0"].includes(household.idhousehold) && `${household.idagregado ? `[${household.idagregado}] ` : ''}${household.name}` }
                  healthsystem={ customer.healthsystem }
                  number_ss_associado={ customer.number_ss }
                  numero_utente_associado={ customer.numero_utente }
                  number_ss_agregado={ household.number_ss }
                  numero_utente_agregado={ household.numero_utente }
                  emptyLinesNumber={ 5 }
                />
                <Button
                  action={() => this.setState({ edit: true })}
                  type={"success"}
                  title={"Editar"}
                  style={buttonStyle}
                />
              </>
            )}
            {customer.idcustomer !== "-1" && (
              <Button
                action={() => window.open(`/customers/${customer.idcustomer}?closeAfter=true`, "Ficha do cliente", 'width=800,height=600')}
                type={"success"}
                title={"Ficha do cliente"}
                style={buttonStyle}
              />
            )}
            {customer.idcustomer !== "-1" && household.idhousehold !== "-1" && (
              <Button
                action={() => window.open(`/customers/${customer.idcustomer}/household/${household.idhousehold}?closeAfter=true`, "Ficha do membro do agregado", 'width=800,height=600')}
                type={"success"}
                title={"Ficha do agregado"}
                style={buttonStyle}
              />
            )}
          </>
        )}
      </form>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px",
};

export default withCookies(Page);
