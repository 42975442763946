const extraZero = (value) => value < 10 ? `0${value}` : value;

export const currentDateTime = () => {
    var now = new Date();
    var year = now.getFullYear();
    var month = extraZero(now.getMonth() + 1);
    var day = extraZero(now.getDate());
    var hour = extraZero(now.getHours());
    var minute = extraZero(now.getMinutes());
    return `${year}-${month}-${day}T${hour}:${minute}`;
}

export const currentDate = () => {
    var now = new Date();
    var year = now.getFullYear();
    var month = extraZero(now.getMonth() + 1);
    var day = extraZero(now.getDate());
    return `${year}-${month}-${day}`;
}

export const transformDateTime = (dateTime) => {
    var date = new Date(dateTime);
    var year = date.getFullYear();
    var month = extraZero(date.getMonth() + 1);
    var day = extraZero(date.getDate());
    var hour = extraZero(date.getHours());
    var minute = extraZero(date.getMinutes());
    return {
        date: `${day}/${month}/${year}`,
        time: `${hour}:${minute}`,
    };
}

export const dateToTimestamp = (strDate) => {
    var datum = Date.parse(strDate);
    return datum/1000;
}

export const TimestampToDate = (timestamp) => {
    var datum = new Date(timestamp * 1000);
    var year = datum.getFullYear();
    var month = extraZero(datum.getMonth() + 1);
    var day = extraZero(datum.getDate());
    var hour = extraZero(datum.getHours());
    var min = extraZero(datum.getMinutes());
    return `${day}/${month}/${year} ${hour}:${min}`;
}