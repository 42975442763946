import React, { Component } from "react";

// Components
import { twoDecimal, numberWithCommas } from "@utils/format.js";

import "./table.css";

class Page extends Component {
  componentDidMount() {
    if (typeof this.props.handleDidMount == "function") {
      this.props.handleDidMount();
    }
  }

  render() {
    const { customers, total, debit, credit, currency } = this.props;

    return (
      <table style={{ width: "100%" }}>
        <tr>
          <td>
            <table style={{ width: "100%" }}>
              <tr>
                <th>Débito</th>
                <th>Crédito</th>
                <th>Saldo Total</th>
              </tr>
              <tr>
                <td>
                  {currency} {numberWithCommas(twoDecimal(debit))}
                </td>
                <td>
                  {currency} {numberWithCommas(twoDecimal(credit))}
                </td>
                <td>
                  {currency} {numberWithCommas(twoDecimal(total))}
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table style={{ width: "100%" }}>
              <tr>
                {customers.columns.map(({ Header }) => (
                  <th>{Header}</th>
                ))}
              </tr>
              {customers.rows.map((row) => (
                <tr>
                  <td>{row.name}</td>
                  <td>{row.credit}</td>
                  <td>{row.debit}</td>
                  {row.total > 0 ? (
                    <td
                      className="greenColorPrint"
                      style={{ color: "green !important" }}
                    >
                      {row.total}
                    </td>
                  ) : row.total < 0 ? (
                    <td
                      className="redColorPrint"
                      style={{ color: "red !important" }}
                    >
                      {row.total}
                    </td>
                  ) : (
                    <td>{row.total}</td>
                  )}
                </tr>
              ))}
            </table>
          </td>
        </tr>
      </table>
    );
  }
}

export default Page;
